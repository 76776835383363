import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import VideoViewModal from '../../Model/EventDetailModel/VideoViewModal';

import SubmitCompleteModal from '../../Model/EventDetailModel/FarEastThrowdownOnlineModel/SubmitCompleteModal';
// import FitForceTeamLeagueEvent1 from './Model/FitForceTeamLeagueEvent1';
// import FitForceTeamLeagueRankingSection from './Model/FitForceTeamLeagueRankingSection';
// import FitForceTeamLeagueEvent2 from './Model/FitForceTeamLeagueEvent2';
// import FitForceTeamLeagueEvent3 from './Model/FitForceTeamLeagueEvent3';
import ShowYourPerformanceEvent1 from './Model/ShowYourPerformanceEvent1';
import ShowYourPerformanceEvent2 from './Model/ShowYourPerformanceEvent2';
import ShowYourPerformanceEvent4 from './Model/ShowYourPerformanceEvent4';

import CrownIcon from '../../Image/CrownIcon.png';

export default function ShowYourPerformanceDetailPage() {
  const { i18n } = useTranslation();

  const changeLanguageToKo = () => i18n.changeLanguage('ko');
  const changeLanguageToEn = () => i18n.changeLanguage('en');

  const params = useParams();

  const navigate = useNavigate();

  const [totalVideoWidth, setTotalVideoWidth] = useState(374);
  const [totalVideoHeight, setTotalVideoHeight] = useState(374);
  const [modalVideoWidth, setModalVideoWidth] = useState(414);
  const [modalVideoHeight, setModalVideoHeight] = useState(232);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [nowSection, setNowSection] = useState('A');
  const [videoId, setVideoId] = useState('');
  const [title, setTitle] = useState('');
  const [isClickedViewVideo, setIsClickedViewVideo] = useState(false);
  const [isCompleteSubmit, setIsCompleteSubmit] = useState(false);

  const [language, setLanguage] = useState('Korea');

  const TopLevelRef = useRef();

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: 'Show Your Performance', isAvailableGoback: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // Token 저장 //
  useEffect(() => {
    (
      async () => {
        await window.localStorage.setItem('Token', 'Bearer ' + params.token);
      }
    )();
  }, [params])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    let totalVideoWidth = windowSize.width * 0.9033;
    let totalVideoHeight = totalVideoWidth * 0.5614;

    let modalVideoWidth = windowSize.width;
    let modalVideoHeight = modalVideoWidth * 0.5603;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);

    setModalVideoWidth(modalVideoWidth);
    setModalVideoHeight(modalVideoHeight);
  }, [windowSize])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // Section 저장 //
  useEffect(() => {
    (
      async () => {
        if (nowSection === '') {
          var prevSection = await window.localStorage.getItem('NowSection') === 'null' ? 'A' : window.localStorage.getItem('NowSection');

          setNowSection(prevSection);
        }
        else await window.localStorage.setItem('NowSection', nowSection);
      }
    )();

    let title = 'Show Your Performance';

    setTitle(title);

    TopLevelRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [nowSection])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    // 언어 정보 받아오기 //
    let language = JSON.parse(e.data).language;

    if (language === 'Korea') changeLanguageToKo();
    else if (language === 'English') changeLanguageToEn();

    if (language !== undefined) setLanguage(language);
  }

  // Section Button 클릭시 //
  function onClickSectionButton(section) {
    // 날짜별 공개시점 정리 //
    setNowSection(section);
  }

  // 순위표 버튼 클릭시 //
  function onClickGoRanking() {
    // alert('준비중입니다');
    navigate('/rank/show_your_performance/');
    // setNowSection('Total');
    // setIsCompleteSubmit(false);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {
        isClickedViewVideo &&
        <VideoViewModal
          videoId={videoId}
          videoWidth={modalVideoWidth}
          videoHeight={modalVideoHeight}
          closeFn={() => setIsClickedViewVideo(false)} />
      }
      {
        isCompleteSubmit && <SubmitCompleteModal yesFn={onClickGoRanking}/>
      }
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#4F4F4F'>{title}</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        <SectionButton isClicked={nowSection === 'A'} onClick={() => onClickSectionButton('A')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT1</Text>
        </SectionButton>
        <SectionButton style={{ width: '91px' }} isClicked={nowSection === 'B'} onClick={() => onClickSectionButton('B')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT2&3</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'D'} onClick={() => onClickSectionButton('D')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT4</Text>
        </SectionButton>
        <TotalSectionButton onClick={onClickGoRanking}>
          <CrownImage src={CrownIcon} />
        </TotalSectionButton>
      </SectionButtonWrapper>
      <DivisionLine />
      {nowSection === 'A' && <ShowYourPerformanceEvent1 eventId={52} totalVideoWidth={totalVideoWidth} totalVideoHeight={totalVideoHeight} setIsCompleteSubmit={setIsCompleteSubmit} />}
      {nowSection === 'B' && <ShowYourPerformanceEvent2 eventId={52} totalVideoWidth={totalVideoWidth} totalVideoHeight={totalVideoHeight} setIsCompleteSubmit={setIsCompleteSubmit} />}
      {nowSection === 'D' && <ShowYourPerformanceEvent4 eventId={52} totalVideoWidth={totalVideoWidth} totalVideoHeight={totalVideoHeight} setIsCompleteSubmit={setIsCompleteSubmit} />}
      {/* {nowSection === 'Total' && <FitForceTeamLeagueRankingSection eventId={34}/>} */}
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #FFFFFF;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSectionAButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FF3131`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSectionBButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#2F80ED`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TotalSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: #FFB800;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;