import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import NoneProfile from '../../Image/NoneProfile.png';

export default function MemberDetailModal(props) {
  const { data, closeFn } = props;

  const [imageSize, setImageSize] = useState(150);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const ImageRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // 프로필사진 크기 계산 //
  useEffect(() => {
    var newImageSize = windowSize.width * 0.3623188405;

    setImageSize(newImageSize);
  }, [windowSize])

  return (
    <Background>
      <Modal>
        <DataWrapper>
          <SectionWrapper>
            <Profile size={imageSize} src={data.linkCoachProfile === '' ? NoneProfile : data.linkCoachProfile}/>
          </SectionWrapper>
          <SectionWrapper>
            <TextTotalWrapper>
              <TextLayerWrapper>
                <ColumnTextWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>이름</Text>
                </ColumnTextWrapper>
                <ContentTextWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{data.name}</Text>
                </ContentTextWrapper>
              </TextLayerWrapper>
              <TextLayerWrapper>
                <ColumnTextWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>성별</Text>
                </ColumnTextWrapper>
                <ContentTextWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{data.gender === 'male' ? '남성' : '여성'}</Text>
                </ContentTextWrapper>
              </TextLayerWrapper>
              <TextLayerWrapper>
                <ColumnTextWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>혈액형</Text>
                </ColumnTextWrapper>
                <ContentTextWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{data.bloodType}</Text>
                </ContentTextWrapper>
              </TextLayerWrapper>
              <TextLayerWrapper>
                <ColumnTextWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>닉네임</Text>
                </ColumnTextWrapper>
                <ContentTextWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{data.linkCoachNickName}</Text>
                </ContentTextWrapper>
              </TextLayerWrapper>
              {/* <ColumnTextWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>이름</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>성별</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>혈액형</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>닉네임</Text>
              </ColumnTextWrapper>
              <ContentTextWrapper>
                <IndividualTextWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{data.name}</Text>
                </IndividualTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{data.gender === 'male' ? '남성' : '여성'}</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{data.bloodType}</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{data.linkCoachNickName}</Text>
              </ContentTextWrapper> */}
            </TextTotalWrapper>
          </SectionWrapper>
        </DataWrapper>
        <Button onClick={closeFn}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>닫기</Text>
        </Button>
      </Modal>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  width: 90.33%;
  // height: 276px;

  border-radius: 12px;
  background-color: #FFFFFF;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 89.3%;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 50%;
`;

const Profile = styled.img`
  // width: 150px;
  // height: 150px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 12px;
`;

const TextTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 6px;
`;

const TextLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const ColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 40.71%; 
`;

const ContentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 59.29%;
  height: 17px;

  overflow: hidden;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  margin-top: 28px;
  margin-bottom: 18px;

  width: 89.3%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
