import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment, { invalid } from 'moment';

import FetchModule from '../../Model/Network/FetchModule';

import CrownIcon from '../../Image/CrownIcon.png';
import FirstPlaceImg from '../../Image/FirstPlace.png';
import SecondPlaceImg from '../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../Image/ThirdPlace.png';
import RightImg from '../../Image/NotAvailableRight.png';
import NoneProfile from '../../Image/NoneProfile.png';
import WhiteMale from '../../Image/WhiteMale.png';
import ColorMale from '../../Image/ColorMale.png';
import WhiteFemale from '../../Image/WhiteFemale.png';
import ColorFemale from '../../Image/ColorFemale.png';

import { useTranslation } from 'react-i18next';

export default function FinalSUFFIndividualRankingPage() {

  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [nowSection, setNowSection] = useState('A');
  const [isClickedGender, setIsClickedGender] = useState(window.localStorage.getItem('GenderDivision') === null ? 'female' : window.localStorage.getItem('GenderDivision'));

  // const [data, setData] = useState([]);
  const [myRanking, setMyRanking] = useState(undefined);
  const [responseRankingList, setResponseRankingList] = useState([]);
  const [rankingList, setRankingList] = useState([]);

  const [isClickedMyRank, setIsClickedMyRank] = useState(false);
  const [myRankTotalHeight, setMyRankTotalHeight] = useState(64);
  const [myRankHeight, setMyRankHeight] = useState(62);

  const [isLoading, setIsLoading] = useState(false);


  const [event1MyRank, setEvent1MyRank] = useState(undefined);
  const [event2MyRank, setEvent2MyRank] = useState(undefined);
  const [event3MyRank, setEvent3MyRank] = useState(undefined);
  const [event4MyRank, setEvent4MyRank] = useState(undefined);
  const [event5MyRank, setEvent5MyRank] = useState(undefined);
  const [event6MyRank, setEvent6MyRank] = useState(undefined);
  const [totalMyRank, setTotalMyRank] = useState(undefined);

  const [event1RankingList, setEvent1RankingList] = useState([]);
  const [event2RankingList, setEvent2RankingList] = useState([]);
  const [event3RankingList, setEvent3RankingList] = useState([]);
  const [event4RankingList, setEvent4RankingList] = useState([]);
  const [event5RankingList, setEvent5RankingList] = useState([]);
  const [event6RankingList, setEvent6RankingList] = useState([]);
  const [totalRankingList, setTotalRankingList] = useState([]);


  // Ranking Info API CALL //
  useEffect(() => {
    if (nowSection === 'Total') return;

    setIsLoading(true);
    setIsClickedMyRank(false);

    let requestData = {
      eventId: 19,
      section: nowSection
    }

    const fetchModule = new FetchModule();
    (
      async () => {

        const responseData = await fetchModule.postDataOfEvent('score/rank/final/SUFF', 'POST', requestData);
        
        // Rx로 구분 //
        if (responseData.status === 200) {
          let rankData = responseData.data.eventRankList.slice();
          let myRank = responseData.data.myRankData;
          let totalRankData = [];

          let validRankData = [];
          let invalidRankData = [];

          // 남성 순위 데이터 합산본 //
          let maleValidRankData = [];
          // 여성 순위 데이터 합산본 //
          let femaleValidRankData = [];

          // 순위 데이터 정리 //
          for (let i = 0; i < rankData.length; i++) {
            if (rankData[i].section === 'A1' && rankData[i].tieBreak === 0) invalidRankData.push(rankData[i]);
            else if (rankData[i].section === 'A2' && rankData[i].time === 0) invalidRankData.push(rankData[i]);
            else if (rankData[i].section === 'A3' && rankData[i].time === 0 && rankData[i].completeRep === 0) invalidRankData.push(rankData[i]);
            else if (rankData[i].section === 'A') invalidRankData.push(rankData[i]); 
            else if (nowSection === 'B' && rankData[i].firstExercise === 0) invalidRankData.push(rankData[i]);
            else if (nowSection === 'C' && rankData[i].time === 0 && rankData[i].completeRep === 0) invalidRankData.push(rankData[i]);
            else if (nowSection === 'D' && rankData[i].time === 0 && rankData[i].completeRep === 0) invalidRankData.push(rankData[i]);
            else if (nowSection === 'E' && rankData[i].distance === 0) invalidRankData.push(rankData[i]);
            else if (nowSection === 'F' && rankData[i].gender === 'male' && rankData[i].completeRep === 0) invalidRankData.push(rankData[i]);
            else if (nowSection === 'F' && rankData[i].gender === 'female' && rankData[i].time === 0) invalidRankData.push(rankData[i]);
            else {
              if (rankData[i].gender === 'male') maleValidRankData.push(rankData[i]);
              else femaleValidRankData.push(rankData[i]);
            }
          }

          if (nowSection === 'A') {
            let maleFirstMissionData = [];
            let maleSecondMissionData = [];
            let maleThirdMissionTimeData = [];
            let maleThirdMissionRepsData = [];
            let femaleFirstMissionData = [];
            let femaleSecondMissionData = [];
            let femaleThirdMissionTimeData = [];
            let femaleThirdMissionRepsData = [];

            for (let i = 0; i < maleValidRankData.length; i++) {
              if (maleValidRankData[i].section === 'A1') maleFirstMissionData.push(maleValidRankData[i]);
              else if (maleValidRankData[i].section === 'A2') maleSecondMissionData.push(maleValidRankData[i]);
              else if (maleValidRankData[i].section === 'A3') {
                if (maleValidRankData[i].time !== 0) maleThirdMissionTimeData.push(maleValidRankData[i]);
                else maleThirdMissionRepsData.push(maleValidRankData[i]);
              }
            }

            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (femaleValidRankData[i].section === 'A1') femaleFirstMissionData.push(femaleValidRankData[i]);
              else if (femaleValidRankData[i].section === 'A2') femaleSecondMissionData.push(femaleValidRankData[i]);
              else if (femaleValidRankData[i].section === 'A3') {
                if (femaleValidRankData[i].time !== 0) femaleThirdMissionTimeData.push(femaleValidRankData[i]);
                else femaleThirdMissionRepsData.push(femaleValidRankData[i]);
              }
            }

            // 남성 데이터 순위 정렬 //
            maleFirstMissionData.sort((a, b) => a.tieBreak - b.tieBreak);
            maleSecondMissionData.sort((a, b) => a.time - b.time);
            maleThirdMissionTimeData.sort((a, b) => a.time - b.time);
            maleThirdMissionRepsData.sort((a, b) => b.completeRep - a.completeRep);

            // 여성데이터 순위 정렬 //
            femaleFirstMissionData.sort((a, b) => a.tieBreak - b.tieBreak);
            femaleSecondMissionData.sort((a, b) => a.time - b.time);
            femaleThirdMissionTimeData.sort((a, b) => a.time - b.time);
            femaleThirdMissionRepsData.sort((a, b) => b.completeRep - a.completeRep);

            maleThirdMissionTimeData = maleThirdMissionTimeData.concat(maleThirdMissionRepsData);
            maleThirdMissionTimeData = maleThirdMissionTimeData.concat(maleSecondMissionData);
            maleValidRankData = maleThirdMissionTimeData.concat(maleFirstMissionData);

            femaleThirdMissionTimeData = femaleThirdMissionTimeData.concat(femaleThirdMissionRepsData);
            femaleThirdMissionTimeData = femaleThirdMissionTimeData.concat(femaleSecondMissionData);
            femaleValidRankData = femaleThirdMissionTimeData.concat(femaleFirstMissionData);

            // console.log(maleValidRankData);
            // console.log(femaleValidRankData);
            // console.log(invalidRankData);
            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            for (let i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0
                && maleValidRankData[i].section === 'A1'
                && maleValidRankData[i - 1].section === 'A1'
                && maleValidRankData[i].tieBreak === maleValidRankData[i - 1].tieBreak) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].section === 'A2'
                && maleValidRankData[i - 1].section === 'A2'
                && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].section === 'A3'
                && maleValidRankData[i - 1].section === 'A3'
                && maleValidRankData[i].time !== 0
                && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].section === 'A3'
                && maleValidRankData[i - 1].section === 'A3'
                && maleValidRankData[i].completeRep !== 0
                && maleValidRankData[i].completeRep === maleValidRankData[i - 1].completeRep) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (maleValidRankData[i].section === 'A') maleValidRankData[i].rank = i;
              else maleValidRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0
                && femaleValidRankData[i].section === 'A1'
                && femaleValidRankData[i - 1].section === 'A1'
                && femaleValidRankData[i].tieBreak === femaleValidRankData[i - 1].tieBreak) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].section === 'A2'
                && femaleValidRankData[i - 1].section === 'A2'
                && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].section === 'A3'
                && femaleValidRankData[i - 1].section === 'A3'
                && femaleValidRankData[i].time !== 0
                && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].section === 'A3'
                && femaleValidRankData[i - 1].section === 'A3'
                && femaleValidRankData[i].completeRep !== 0
                && femaleValidRankData[i].completeRep === femaleValidRankData[i - 1].completeRep) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else femaleValidRankData[i].rank = (i + 1);
            }

            let maleInvalidCount = 0;
            let femaleInvalidCount = 0;

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') maleInvalidCount += 1;
              else femaleInvalidCount += 1;
            }

            // for (let i = 0; i < invalidRankData.length; i++) {
            //   if (invalidRankData[i].gender === 'male') {
            //     invalidRankData[i].rank = maleValidRankData.length + maleInvalidCount - 1;
            //   }
            //   else invalidRankData[i].rank = femaleValidRankData.length + femaleInvalidCount - 1;
            // }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') {
                if (maleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = maleValidRankData.length + maleInvalidCount - 1;
              }
              else {
                if (femaleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = femaleValidRankData.length + femaleInvalidCount - 1;
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].userId === myRank.userId) {
                  myRank.rank = totalRankData[i].rank;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event3 = {
                rank: -1,
                firstExercise: 0
              }

              myRank.event4 = {
                rank: -1,
                time: 0
              }

              myRank.event5 = {
                rank: -1,
                time: 0
              }

              myRank.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }

              setMyRanking(myRank);

              let savedGender = window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              data.event3 = {
                rank: -1,
                firstExercise: 0
              }

              data.event4 = {
                rank: -1,
                time: 0
              }

              data.event5 = {
                rank: -1,
                distance: 0
              }

              data.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'B') {
            // // 남성 데이터 순위 정렬 //
            // maleValidRankData.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);
            // // 여성데이터 순위 정렬 //
            // femaleValidRankData.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);
            // 남성 데이터 순위 정렬 //
            maleValidRankData.sort((a, b) => b.firstExercise - a.firstExercise);
            // 여성데이터 순위 정렬 //
            femaleValidRankData.sort((a, b) => b.firstExercise - a.firstExercise);

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            for (let i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].firstExercise === 0) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].firstExercise === maleValidRankData[i - 1].firstExercise) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else {
                maleValidRankData[i].rank = (i + 1);
              }
            }

            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0 && femaleValidRankData[i].firstExercise === 0) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].firstExercise === femaleValidRankData[i - 1].firstExercise) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else {
                femaleValidRankData[i].rank = (i + 1);
              }
            }

            let maleInvalidCount = 0;
            let femaleInvalidCount = 0;

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') maleInvalidCount += 1;
              else femaleInvalidCount += 1;
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') {
                if (maleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = maleValidRankData.length + maleInvalidCount - 1;
              }
              else {
                if (femaleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = femaleValidRankData.length + femaleInvalidCount - 1;
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].userId === myRank.userId) {
                  myRank.rank = totalRankData[i].rank;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event5 = {
                rank: -1,
                distance: 0
              }

              myRank.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }

              setMyRanking(myRank);

              let savedGender = window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              data.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              data.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              data.event5 = {
                rank: -1,
                distance: 0
              }

              data.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'C') {

            let maleTimeRankData = [];
            let femaleTimeRankData = [];
            let maleRepsRankData = [];
            let femaleRepsRankData = [];

            // Time Cap 초과 여부 걸러내기 //
            for (let i = 0; i < maleValidRankData.length; i++) {
              if (maleValidRankData[i].completeRep === 0) maleTimeRankData.push(maleValidRankData[i]);
              else maleRepsRankData.push(maleValidRankData[i]);
            }

            // Time Cap 초과 여부 걸러내기 //
            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (femaleValidRankData[i].completeRep === 0) femaleTimeRankData.push(femaleValidRankData[i]);
              else femaleRepsRankData.push(femaleValidRankData[i]);
            }

            // 남성 Time 데이터 순위 정렬 //
            maleTimeRankData.sort((a, b) => a.time - b.time);
            // 남성 Reps 데이터 순위 정렬 //
            maleRepsRankData.sort((a, b) => b.completeRep - a.completeRep);

            // 여성 Time 데이터 순위 정렬 //
            femaleTimeRankData.sort((a, b) => a.time - b.time);
            // 여성 Reps 데이터 순위 정렬 //
            femaleRepsRankData.sort((a, b) => b.completeRep - a.completeRep);

            // 남성 데이터 합산 //
            maleValidRankData = maleTimeRankData.concat(maleRepsRankData);
            // 여성 데이터 합산 //
            femaleValidRankData = femaleTimeRankData.concat(femaleRepsRankData);
            // console.log(femaleValidRankData);
            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })
            
            for (let i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0
                && maleValidRankData[i].completeRep !== 0
                && maleValidRankData[i].completeRep === maleValidRankData[i - 1].completeRep) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].time !== 0
                && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                  maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
                }
              else maleValidRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0 
                && femaleValidRankData[i].completeRep !== 0
                && femaleValidRankData[i].completeRep === femaleValidRankData[i - 1].completeRep) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].time !== 0
                && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else {
                femaleValidRankData[i].rank = (i + 1);
              }
            }

            let maleInvalidCount = 0;
            let femaleInvalidCount = 0;

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') maleInvalidCount += 1;
              else femaleInvalidCount += 1;
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') {
                if (maleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = maleValidRankData.length + maleInvalidCount - 1;
              }
              else {
                if (femaleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = femaleValidRankData.length + femaleInvalidCount - 1;
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);
            
            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].userId === myRank.userId) {
                  myRank.rank = totalRankData[i].rank;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event5 = {
                rank: -1,
                distance: 0
              }

              myRank.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }

              setMyRanking(myRank);

              let savedGender = window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              data.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              data.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              data.event5 = {
                rank: -1,
                distance: 0
              }

              data.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'D') {
            let maleTimeRankData = [];
            let femaleTimeRankData = [];
            let maleRepsRankData = [];
            let femaleRepsRankData = [];

            // Time Cap 초과 여부 걸러내기 //
            for (let i = 0; i < maleValidRankData.length; i++) {
              if (maleValidRankData[i].completeRep === 0) maleTimeRankData.push(maleValidRankData[i]);
              else maleRepsRankData.push(maleValidRankData[i]);
            }

            // Time Cap 초과 여부 걸러내기 //
            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (femaleValidRankData[i].completeRep === 0) femaleTimeRankData.push(femaleValidRankData[i]);
              else femaleRepsRankData.push(femaleValidRankData[i]);
            }

            // 남성 Time 데이터 순위 정렬 //
            maleTimeRankData.sort((a, b) => a.time - b.time);
            // 남성 Reps 데이터 순위 정렬 //
            maleRepsRankData.sort((a, b) => b.completeRep - a.completeRep);

            // 여성 Time 데이터 순위 정렬 //
            femaleTimeRankData.sort((a, b) => a.time - b.time);
            // 여성 Reps 데이터 순위 정렬 //
            femaleRepsRankData.sort((a, b) => b.completeRep - a.completeRep);

            // 남성 데이터 합산 //
            maleValidRankData = maleTimeRankData.concat(maleRepsRankData);
            // 여성 데이터 합산 //
            femaleValidRankData = femaleTimeRankData.concat(femaleRepsRankData);

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })
            
            for (let i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0
                && maleValidRankData[i].completeRep !== 0
                && maleValidRankData[i].completeRep === maleValidRankData[i - 1].completeRep) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].time !== 0
                && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                  maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
                }
              else maleValidRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0 
                && femaleValidRankData[i].completeRep !== 0
                && femaleValidRankData[i].completeRep === femaleValidRankData[i - 1].completeRep) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].time !== 0
                && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else {
                femaleValidRankData[i].rank = (i + 1);
              }
            }

            let maleInvalidCount = 0;
            let femaleInvalidCount = 0;

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') maleInvalidCount += 1;
              else femaleInvalidCount += 1;
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') {
                if (maleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = maleValidRankData.length + maleInvalidCount - 1;
              }
              else {
                if (femaleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = femaleValidRankData.length + femaleInvalidCount - 1;
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);
            
            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].userId === myRank.userId) {
                  myRank.rank = totalRankData[i].rank;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event5 = {
                rank: -1,
                distance: 0
              }

              myRank.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }

              setMyRanking(myRank);

              let savedGender = window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              data.event3 = {
                rank: -1,
                firstExercise: 0
              }

              data.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              data.event5 = {
                rank: -1,
                distance: 0
              }

              data.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'E') {
            // // 남성 데이터 순위 정렬 //
            // maleValidRankData.sort((a, b) => a.time - b.time);
            // // 여성 데이터 순위 정렬 //
            // femaleValidRankData.sort((a, b) => a.time - b.time);
            // 남성 데이터 순위 정렬 //
            maleValidRankData.sort((a, b) => b.distance - a.distance);
            // 여성 데이터 순위 정렬 //
            femaleValidRankData.sort((a, b) => b.distance - a.distance);

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            for (let i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].distance === 0) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].distance === maleValidRankData[i - 1].distance) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else {
                maleValidRankData[i].rank = (i + 1);
              }
            }

            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0 && femaleValidRankData[i].distance === 0) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].distance === femaleValidRankData[i - 1].distance) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else {
                femaleValidRankData[i].rank = (i + 1);
              }
            }

            let maleInvalidCount = 0;
            let femaleInvalidCount = 0;

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') maleInvalidCount += 1;
              else femaleInvalidCount += 1;
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') {
                if (maleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = maleValidRankData.length + maleInvalidCount - 1;
              }
              else {
                if (femaleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = femaleValidRankData.length + femaleInvalidCount - 1;
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].userId === myRank.userId) {
                  myRank.rank = totalRankData[i].rank;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event5 = {
                rank: -1,
                distance: 0
              }

              myRank.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }

              setMyRanking(myRank);

              let savedGender = window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              data.event3 = {
                rank: -1,
                firstExercise: 0
              }

              data.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              data.event5 = {
                rank: -1,
                distance: 0
              }

              data.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'F') {
            // 남성 데이터 순위 정렬 //
            maleValidRankData.sort((a, b) => b.completeRep - a.completeRep);
            // 여성 데이터 순위 정렬 //
            femaleValidRankData.sort((a, b) => b.time - a.time);

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            for (let i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].completeRep === 0) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].completeRep === maleValidRankData[i - 1].completeRep) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else {
                maleValidRankData[i].rank = (i + 1);
              }
            }

            for (let i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0 && femaleValidRankData[i].time === 0) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else {
                femaleValidRankData[i].rank = (i + 1);
              }
            }

            let maleInvalidCount = 0;
            let femaleInvalidCount = 0;

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') maleInvalidCount += 1;
              else femaleInvalidCount += 1;
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              if (invalidRankData[i].gender === 'male') {
                if (maleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = maleValidRankData.length + maleInvalidCount - 1;
              }
              else {
                if (femaleValidRankData.length === 0) invalidRankData[i].rank = 0;
                else invalidRankData[i].rank = femaleValidRankData.length + femaleInvalidCount - 1;
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].userId === myRank.userId) {
                  myRank.rank = totalRankData[i].rank;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              myRank.event3 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              myRank.event5 = {
                rank: -1,
                distance: 0
              }

              myRank.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }

              setMyRanking(myRank);

              let savedGender = window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }

              data.event3 = {
                rank: -1,
                firstExercise: 0
              }

              data.event4 = {
                rank: -1,
                time: 0,
                completeRep: 0
              }

              data.event5 = {
                rank: -1,
                distance: 0
              }

              data.event6 = {
                rank: -1,
                completeRep: 0,
                time: 0
              }
            })

            setResponseRankingList(totalRankData);

          }
        }

        // setIsLoading(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    )();
  }, [params, nowSection])

  // 전체 순위 산출일 경우 //
  useEffect(() => {
    if (nowSection !== 'Total') return;

    setIsLoading(true);
    setIsClickedMyRank(false);

    let requestData = {
      eventId: 19
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('score/rank/total_final_SUFF/individual', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let myRankData = responseData.data.myRankData;
          let event1 = responseData.data.event1.slice();
          let event2 = responseData.data.event2.slice();
          let event3 = responseData.data.event3.slice();
          let event4 = responseData.data.event4.slice();
          let event5 = responseData.data.event5.slice();
          let event6 = responseData.data.event6.slice();

          // Event 1 순위 산출 //
          let event1ValidRank = [];
          let event1MaleValidRank = [];
          let event1FemaleValidRank = [];
          let event1InvalidRank = [];
          let event1TotalRank = [];

          event1.map((data) => {
            if (data.section === 'A1' && data.tieBreak === 0) event1InvalidRank.push(data);
            else if (data.section === 'A2' && data.time === 0) event1InvalidRank.push(data);
            else if (data.section === 'A3' && data.time === 0 && data.completeRep === 0) event1InvalidRank.push(data);
            else if (data.section === 'A') event1InvalidRank.push(data);
            else {
              if (data.gender === 'male') event1MaleValidRank.push(data);
              else event1FemaleValidRank.push(data);
            }
          })

          let maleFirstMissionData = [];
          let maleSecondMissionData = [];
          let maleThirdMissionTimeData = [];
          let maleThirdMissionRepsData = [];
          let femaleFirstMissionData = [];
          let femaleSecondMissionData = [];
          let femaleThirdMissionTimeData = [];
          let femaleThirdMissionRepsData = [];

          for (let i = 0; i < event1MaleValidRank.length; i++) {
            if (event1MaleValidRank[i].section === 'A1') maleFirstMissionData.push(event1MaleValidRank[i]);
            else if (event1MaleValidRank[i].section === 'A2') maleSecondMissionData.push(event1MaleValidRank[i]);
            else if (event1MaleValidRank[i].section === 'A3') {
              if (event1MaleValidRank[i].time !== 0) maleThirdMissionTimeData.push(event1MaleValidRank[i]);
              else maleThirdMissionRepsData.push(event1MaleValidRank[i]);
            }
          }

          for (let i = 0; i < event1FemaleValidRank.length; i++) {
            if (event1FemaleValidRank[i].section === 'A1') femaleFirstMissionData.push(event1FemaleValidRank[i]);
            else if (event1FemaleValidRank[i].section === 'A2') femaleSecondMissionData.push(event1FemaleValidRank[i]);
            else if (event1FemaleValidRank[i].section === 'A3') {
              if (event1FemaleValidRank[i].time !== 0) femaleThirdMissionTimeData.push(event1FemaleValidRank[i]);
              else femaleThirdMissionRepsData.push(event1FemaleValidRank[i]);
            }
          }

          // 남성 데이터 순위 정렬 //
          maleFirstMissionData.sort((a, b) => a.tieBreak - b.tieBreak);
          maleSecondMissionData.sort((a, b) => a.time - b.time);
          maleThirdMissionTimeData.sort((a, b) => a.time - b.time);
          maleThirdMissionRepsData.sort((a, b) => b.completeRep - a.completeRep);

          // 여성데이터 순위 정렬 //
          femaleFirstMissionData.sort((a, b) => a.tieBreak - b.tieBreak);
          femaleSecondMissionData.sort((a, b) => a.time - b.time);
          femaleThirdMissionTimeData.sort((a, b) => a.time - b.time);
          femaleThirdMissionRepsData.sort((a, b) => b.completeRep - a.completeRep);

          maleThirdMissionTimeData = maleThirdMissionTimeData.concat(maleThirdMissionRepsData);
          maleThirdMissionTimeData = maleThirdMissionTimeData.concat(maleSecondMissionData);
          event1MaleValidRank = maleThirdMissionTimeData.concat(maleFirstMissionData);

          femaleThirdMissionTimeData = femaleThirdMissionTimeData.concat(femaleThirdMissionRepsData);
          femaleThirdMissionTimeData = femaleThirdMissionTimeData.concat(femaleSecondMissionData);
          event1FemaleValidRank = femaleThirdMissionTimeData.concat(femaleFirstMissionData);

          // 올바르지 않은 데이터는 이름순 정렬 //
          event1InvalidRank.sort(function (a, b) {
            let x = a.name;
            let y = b.name;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          for (let i = 0; i < event1MaleValidRank.length; i++) {
            if (i !== 0
              && event1MaleValidRank[i].section === 'A1'
              && event1MaleValidRank[i - 1].section === 'A1'
              && event1MaleValidRank[i].tieBreak === event1MaleValidRank[i - 1].tieBreak) {
              event1MaleValidRank[i].rank = event1MaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event1MaleValidRank[i].section === 'A2'
              && event1MaleValidRank[i - 1].section === 'A2'
              && event1MaleValidRank[i].time === event1MaleValidRank[i - 1].time) {
              event1MaleValidRank[i].rank = event1MaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event1MaleValidRank[i].section === 'A3'
              && event1MaleValidRank[i - 1].section === 'A3'
              && event1MaleValidRank[i].time !== 0
              && event1MaleValidRank[i].time === event1MaleValidRank[i - 1].time) {
              event1MaleValidRank[i].rank = event1MaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event1MaleValidRank[i].section === 'A3'
              && event1MaleValidRank[i - 1].section === 'A3'
              && event1MaleValidRank[i].completeRep !== 0
              && event1MaleValidRank[i].completeRep === event1MaleValidRank[i - 1].completeRep) {
                event1MaleValidRank[i].rank = event1MaleValidRank[i - 1].rank;
            }
            else if (event1MaleValidRank[i].section === 'A') event1MaleValidRank[i].rank = i;
            else event1MaleValidRank[i].rank = (i + 1);
          }

          for (let i = 0; i < event1FemaleValidRank.length; i++) {
            if (i !== 0
              && event1FemaleValidRank[i].section === 'A1'
              && event1FemaleValidRank[i - 1].section === 'A1'
              && event1FemaleValidRank[i].tieBreak === event1FemaleValidRank[i - 1].tieBreak) {
                event1FemaleValidRank[i].rank = event1FemaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event1FemaleValidRank[i].section === 'A2'
              && event1FemaleValidRank[i - 1].section === 'A2'
              && event1FemaleValidRank[i].time === event1FemaleValidRank[i - 1].time) {
                event1FemaleValidRank[i].rank = event1FemaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event1FemaleValidRank[i].section === 'A3'
              && event1FemaleValidRank[i - 1].section === 'A3'
              && event1FemaleValidRank[i].time !== 0
              && event1FemaleValidRank[i].time === event1FemaleValidRank[i - 1].time) {
                event1FemaleValidRank[i].rank = event1FemaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event1FemaleValidRank[i].section === 'A3'
              && event1FemaleValidRank[i - 1].section === 'A3'
              && event1FemaleValidRank[i].completeRep !== 0
              && event1FemaleValidRank[i].completeRep === event1FemaleValidRank[i - 1].completeRep) {
                event1FemaleValidRank[i].rank = event1FemaleValidRank[i - 1].rank;
            }
            else event1FemaleValidRank[i].rank = (i + 1);
          }

          let event1MaleInvalidCount = 0;
          let event1FemaleInvalidCount = 0;

          for (let i = 0; i < event1InvalidRank.length; i++) {
            if (event1InvalidRank[i].gender === 'male') event1MaleInvalidCount += 1;
            else event1FemaleInvalidCount += 1;
          }

          for (let i = 0; i < event1InvalidRank.length; i++) {
            if (event1InvalidRank[i].gender === 'male') event1InvalidRank[i].rank = event1MaleValidRank.length + event1MaleInvalidCount - 1;
            else event1InvalidRank[i].rank = event1FemaleValidRank.length + event1FemaleInvalidCount - 1;
          }

          event1ValidRank = event1MaleValidRank.concat(event1FemaleValidRank);
          event1TotalRank = event1ValidRank.concat(event1InvalidRank);
          setEvent1RankingList(event1ValidRank);
          // Event 1 순위 산출 //


          // Event 2 순위 산출 //
          let event2ValidRank = [];
          let event2MaleValidRank = [];
          let event2FemaleValidRank = [];
          let event2InvalidRank = [];
          let event2TotalRank = [];

          event2.map((data) => {
            if (data.firstExercise === 0) event2InvalidRank.push(data);
            else {
              if (data.gender === 'male') event2MaleValidRank.push(data);
              else event2FemaleValidRank.push(data);
            }
          })

          // // 데이터 순위 정렬 //
          // event2MaleValidRank.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);
          // event2FemaleValidRank.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);
          // 데이터 순위 정렬 //
          event2MaleValidRank.sort((a, b) => b.firstExercise - a.firstExercise);
          event2FemaleValidRank.sort((a, b) => b.firstExercise - a.firstExercise);

          // 올바르지 않은 데이터는 이름순 정렬 //
          event2InvalidRank.sort(function (a, b) {
            let x = a.name;
            let y = b.name;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          // event2MaleValidRank = event2MaleValidRank.concat(event2MaleInvalidRank);
          // event2FemaleValidRank = event2FemaleValidRank.concat(event2FemaleInvalidRank);

          // 남성 순위 산출 //
          for (let i = 0; i < event2MaleValidRank.length; i++) {
            if (i !== 0
              && event2MaleValidRank[i].firstExercise === event2MaleValidRank[i - 1].firstExercise) {
              event2MaleValidRank[i].rank = event2MaleValidRank[i - 1].rank;
            }
            else {
              event2MaleValidRank[i].rank = (i + 1);
            }
          }

          // 여성 순위 산출 //
          for (let i = 0; i < event2FemaleValidRank.length; i++) {
            if (i !== 0
              && event2FemaleValidRank[i].firstExercise === event2FemaleValidRank[i - 1].firstExercise) {
              event2FemaleValidRank[i].rank = event2FemaleValidRank[i - 1].rank;
            }
            else {
              event2FemaleValidRank[i].rank = (i + 1);
            }
          }

          event2ValidRank = event2MaleValidRank.concat(event2FemaleValidRank);
          event2TotalRank = event2ValidRank.concat(event2InvalidRank);

          setEvent2RankingList(event2ValidRank);
          // Event 2 순위 산출 //


          // Event 3 순위 산출 //
          let event3ValidRank = [];
          let event3MaleValidRank = [];
          let event3FemaleValidRank = [];
          let event3MaleTimeValidRank = [];
          let event3MaleRepsValidRank = [];
          let event3FemaleTimeValidRank = [];
          let event3FemaleRepsValidRank = [];
          let event3InvalidRank = [];
          let event3TotalRank = [];

          event3.map((data) => {
            if (data.time === 0 && data.completeRep === 0) event3InvalidRank.push(data);
            else {
              if (data.completeRep === 0) {
                if (data.gender === 'male') event3MaleTimeValidRank.push(data);
                else event3FemaleTimeValidRank.push(data);
              }
              else {
                if (data.gender === 'male') event3MaleRepsValidRank.push(data);
                else event3FemaleRepsValidRank.push(data);
              }
            }
          })

          // 순위 정렬 //
          event3MaleTimeValidRank.sort((a, b) => a.time - b.time);
          event3FemaleTimeValidRank.sort((a, b) => a.time - b.time);
          // 순위 정렬 //
          event3MaleRepsValidRank.sort((a, b) => b.completeRep - a.completeRep);
          event3FemaleRepsValidRank.sort((a, b) => b.completeRep - a.completeRep);

          // 올바르지 않은 데이터는 이름순 정렬 //
          event3InvalidRank.sort(function (a, b) {
            let x = a.name;
            let y = b.name;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          event3MaleValidRank = event3MaleTimeValidRank.concat(event3MaleRepsValidRank);
          event3FemaleValidRank = event3FemaleTimeValidRank.concat(event3FemaleRepsValidRank);

          for (let i = 0; i < event3MaleValidRank.length; i++) {
            if (i !== 0
              && event3MaleValidRank[i].completeRep !== 0
              && event3MaleValidRank[i].completeRep === event3MaleValidRank[i - 1].completeRep) {
                event3MaleValidRank[i].rank = event3MaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event3MaleValidRank[i].time !== 0
              && event3MaleValidRank[i].time === event3MaleValidRank[i - 1].time) {
                event3MaleValidRank[i].rank = event3MaleValidRank[i - 1].rank;
              }
            else event3MaleValidRank[i].rank = (i + 1);
          }

          for (let i = 0; i < event3FemaleValidRank.length; i++) {
            if (i !== 0 
              && event3FemaleValidRank[i].completeRep !== 0
              && event3FemaleValidRank[i].completeRep === event3FemaleValidRank[i - 1].completeRep) {
                event3FemaleValidRank[i].rank = event3FemaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event3FemaleValidRank[i].time !== 0
              && event3FemaleValidRank[i].time === event3FemaleValidRank[i - 1].time) {
                event3FemaleValidRank[i].rank = event3FemaleValidRank[i - 1].rank;
            }
            else {
              event3FemaleValidRank[i].rank = (i + 1);
            }
          }

          let event3MaleInvalidCount = 0;
          let event3FemaleInvalidCount = 0;

          for (let i = 0; i < event3InvalidRank.length; i++) {
            if (event3InvalidRank[i].gender === 'male') event3MaleInvalidCount += 1;
            else event3FemaleInvalidCount += 1;
          }

          for (let i = 0; i < event3InvalidRank.length; i++) {
            if (event3InvalidRank[i].gender === 'male') {
              if (event3MaleValidRank.length === 0) event3InvalidRank[i].rank = 0;
              else event3InvalidRank[i].rank = event3MaleValidRank.length + event3MaleInvalidCount - 1;
            }
            else {
              if (event3FemaleValidRank.length === 0) event3InvalidRank[i].rank = 0;
              else event3InvalidRank[i].rank = event3FemaleValidRank.length + event3FemaleInvalidCount - 1;
            }
          }

          event3ValidRank = event3MaleValidRank.concat(event3FemaleValidRank);
          event3TotalRank = event3ValidRank.concat(event3InvalidRank);

          setEvent3RankingList(event3ValidRank);
          // Event 3 순위 산출 //

          // Event 4 순위 산출 //
          let event4ValidRank = [];
          let event4MaleValidRank = [];
          let event4FemaleValidRank = [];
          let event4MaleTimeValidRank = [];
          let event4FemaleTimeValidRank = [];
          let event4MaleRepsValidRank = [];
          let event4FemaleRepsValidRank = [];
          let event4InvalidRank = [];
          let event4MaleInvalidRank = [];
          let event4FemaleInvalidRank = [];
          let event4TotalRank = [];

          event4.map((data) => {
            // && rankData[i].time === 0 && rankData[i].completeRep === 0)
            if (data.time === 0 && data.completeRep === 0) event4InvalidRank.push(data);
            else {
              if (data.completeRep === 0) {
                if (data.gender === 'male') event4MaleTimeValidRank.push(data);
                else event4FemaleTimeValidRank.push(data);
              }
              else {
                if (data.gender === 'male') event4MaleRepsValidRank.push(data);
                else event4FemaleRepsValidRank.push(data);
              } 
            }
          })

          // 순위 정렬 //
          event4MaleTimeValidRank.sort((a, b) => a.time - b.time);
          event4FemaleTimeValidRank.sort((a, b) => a.time - b.time);
          // 순위 정렬 //
          event4MaleRepsValidRank.sort((a, b) => b.completeRep - a.completeRep);
          event4FemaleRepsValidRank.sort((a, b) => b.completeRep - a.completeRep);

          // 올바르지 않은 데이터는 이름순 정렬 //
          event4InvalidRank.sort(function (a, b) {
            let x = a.name;
            let y = b.name;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          event4MaleValidRank = event4MaleTimeValidRank.concat(event4MaleRepsValidRank);
          event4FemaleValidRank = event4FemaleTimeValidRank.concat(event4FemaleRepsValidRank);

          for (let i = 0; i < event4MaleValidRank.length; i++) {
            if (i !== 0
              && event4MaleValidRank[i].completeRep !== 0
              && event4MaleValidRank[i].completeRep === event4MaleValidRank[i - 1].completeRep) {
                event4MaleValidRank[i].rank = event4MaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event4MaleValidRank[i].time !== 0
              && event4MaleValidRank[i].time === event4MaleValidRank[i - 1].time) {
                event4MaleValidRank[i].rank = event4MaleValidRank[i - 1].rank;
              }
            else event4MaleValidRank[i].rank = (i + 1);
          }

          for (let i = 0; i < event4FemaleValidRank.length; i++) {
            if (i !== 0 
              && event4FemaleValidRank[i].completeRep !== 0
              && event4FemaleValidRank[i].completeRep === event4FemaleValidRank[i].completeRep) {
                event4FemaleValidRank[i].rank = event4FemaleValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event4FemaleValidRank[i].time !== 0
              && event4FemaleValidRank[i].time === event4FemaleValidRank[i - 1].time) {
                event4FemaleValidRank[i].rank = event4FemaleValidRank[i - 1].rank;
            }
            else {
              event4FemaleValidRank[i].rank = (i + 1);
            }
          }

          let event4MaleInvalidCount = 0;
          let event4FemaleInvalidCount = 0;

          for (let i = 0; i < event4InvalidRank.length; i++) {
            if (event4InvalidRank[i].gender === 'male') event4MaleInvalidCount += 1;
            else event4FemaleInvalidCount += 1;
          }

          for (let i = 0; i < event4InvalidRank.length; i++) {
            if (event4InvalidRank[i].gender === 'male') {
              if (event4MaleValidRank.length === 0) event4InvalidRank[i].rank = 0;
              else event4InvalidRank[i].rank = event4MaleValidRank.length + event4MaleInvalidCount - 1;
            }
            else {
              if (event4FemaleValidRank.length === 0) event4InvalidRank[i].rank = 0;
              else event4InvalidRank[i].rank = event4FemaleValidRank.length + event4FemaleInvalidCount - 1;
            }
          }

          event4ValidRank = event4MaleValidRank.concat(event4FemaleValidRank);
          event4TotalRank = event4ValidRank.concat(event4InvalidRank);

          setEvent4RankingList(event4ValidRank);
          // Event 4 순위 산출 //

           // Event 5 순위 산출 //
           let event5ValidRank = [];
           let event5MaleValidRank = [];
           let event5FemaleValidRank = [];
           let event5InvalidRank = [];
           let event5TotalRank = [];
 
           event5.map((data) => {
             if (data.distance === 0) event5InvalidRank.push(data);
             else {
                if (data.gender === 'male') event5MaleValidRank.push(data);
                else event5FemaleValidRank.push(data);
             }
           })
 
          //  // 순위 정렬 //
          //  event5MaleValidRank.sort((a, b) => a.time - b.time);
          //  event5FemaleValidRank.sort((a, b) => a.time - b.time);
           // 순위 정렬 //
           event5MaleValidRank.sort((a, b) => b.distance - a.distance);
           event5FemaleValidRank.sort((a, b) => b.distance - a.distance);
 
           // 올바르지 않은 데이터는 이름순 정렬 //
           event5InvalidRank.sort(function (a, b) {
             let x = a.name;
             let y = b.name;
 
             if (x > y) return 1;
             if (x < y) return -1;
           })

 
           for (let i = 0; i < event5MaleValidRank.length; i++) {
             if (i !== 0
               && event5MaleValidRank[i].distance !== 0
               && event5MaleValidRank[i].distance === event5MaleValidRank[i - 1].distance) {
                 event5MaleValidRank[i].rank = event5MaleValidRank[i - 1].rank;
             }
             else event5MaleValidRank[i].rank = (i + 1);
           }
 
           for (let i = 0; i < event5FemaleValidRank.length; i++) {
            if (i !== 0
              && event5FemaleValidRank[i].distance !== 0
              && event5FemaleValidRank[i].distance === event5FemaleValidRank[i - 1].distance) {
                event5FemaleValidRank[i].rank = event5FemaleValidRank[i - 1].rank;
            }
            else event5FemaleValidRank[i].rank = (i + 1);
           }
 
           let event5MaleInvalidCount = 0;
           let event5FemaleInvalidCount = 0;
 
           for (let i = 0; i < event5InvalidRank.length; i++) {
             if (event5InvalidRank[i].gender === 'male') event5MaleInvalidCount += 1;
             else event5FemaleInvalidCount += 1;
           }
 
           for (let i = 0; i < event5InvalidRank.length; i++) {
             if (event5InvalidRank[i].gender === 'male') {
               if (event5MaleValidRank.length === 0) event5InvalidRank[i].rank = 0;
               else event5InvalidRank[i].rank = event5MaleValidRank.length + event5MaleInvalidCount - 1;
             }
             else {
               if (event5FemaleValidRank.length === 0) event5InvalidRank[i].rank = 0;
               else event5InvalidRank[i].rank = event5FemaleValidRank.length + event5FemaleInvalidCount - 1;
             }
           }
 
           event5ValidRank = event5MaleValidRank.concat(event5FemaleValidRank);
           event5TotalRank = event5ValidRank.concat(event5InvalidRank);
 
           setEvent5RankingList(event5ValidRank);
           // Event 5 순위 산출 //

           // Event 6 순위 산출 //
           let event6ValidRank = [];
           let event6MaleValidRank = [];
           let event6FemaleValidRank = [];
           let event6InvalidRank = [];
           let event6TotalRank = [];
 
           event6.map((data) => {
             if (data.gender === 'male' && data.completeRep === 0) event6InvalidRank.push(data);
             else if (data.gender === 'female' && data.time === 0) event6InvalidRank.push(data);
             else {
                if (data.gender === 'male') event6MaleValidRank.push(data);
                else event6FemaleValidRank.push(data);
             }
           })
 
           // 순위 정렬 //
           event6MaleValidRank.sort((a, b) => b.completeRep - a.completeRep);
           event6FemaleValidRank.sort((a, b) => b.time - a.time);
 
           // 올바르지 않은 데이터는 이름순 정렬 //
           event6InvalidRank.sort(function (a, b) {
             let x = a.name;
             let y = b.name;
 
             if (x > y) return 1;
             if (x < y) return -1;
           })

 
           for (let i = 0; i < event6MaleValidRank.length; i++) {
             if (i !== 0
               && event6MaleValidRank[i].completeRep !== 0
               && event6MaleValidRank[i].completeRep === event6MaleValidRank[i - 1].completeRep) {
                 event6MaleValidRank[i].rank = event6MaleValidRank[i - 1].rank;
             }
             else event6MaleValidRank[i].rank = (i + 1);
           }
 
           for (let i = 0; i < event6FemaleValidRank.length; i++) {
            if (i !== 0
              && event6FemaleValidRank[i].time !== 0
              && event6FemaleValidRank[i].time === event6FemaleValidRank[i - 1].time) {
                event6FemaleValidRank[i].rank = event6FemaleValidRank[i - 1].rank;
            }
            else event6FemaleValidRank[i].rank = (i + 1);
           }
 
           let event6MaleInvalidCount = 0;
           let event6FemaleInvalidCount = 0;
 
           for (let i = 0; i < event6InvalidRank.length; i++) {
             if (event6InvalidRank[i].gender === 'male') event6MaleInvalidCount += 1;
             else event6FemaleInvalidCount += 1;
           }
 
           for (let i = 0; i < event6InvalidRank.length; i++) {
             if (event6InvalidRank[i].gender === 'male') {
               if (event6MaleValidRank.length === 0) event6InvalidRank[i].rank = 0;
               else event6InvalidRank[i].rank = event6MaleValidRank.length + event6MaleInvalidCount - 1;
             }
             else {
               if (event6FemaleValidRank.length === 0) event6InvalidRank[i].rank = 0;
               else event6InvalidRank[i].rank = event6FemaleValidRank.length + event6FemaleInvalidCount - 1;
             }
           }
 
           event6ValidRank = event6MaleValidRank.concat(event6FemaleValidRank);
           event6TotalRank = event6ValidRank.concat(event6InvalidRank);
 
           setEvent6RankingList(event6ValidRank);
           // Event 6 순위 산출 //

          let totalRank = [];
          // Event1 데이터 정리 //
          for (let i = 0; i < event1TotalRank.length; i++) {
            totalRank.push({
              gender: event1TotalRank[i].gender,
              name: event1TotalRank[i].name,
              profile: event1TotalRank[i].profile,
              members: event1TotalRank[i].members,
              userId: event1TotalRank[i].userId,
              event1: {
                rank: event1TotalRank[i].rank,
                section: event1TotalRank[i].section,
                completeRep: event1TotalRank[i].completeRep,
                time: event1TotalRank[i].time,
                tieBreak: event1TotalRank[i].tieBreak
              },
              event2: {
                rank: 0,
                firstExercise: 0,
                tieBreak: 0
              },
              event3: {
                rank: 0,
                time: 0,
                completeRep: 0
              },
              event4: {
                rank: 0,
                time: 0,
                completeRep: 0
              },
              event5: {
                rank: 0,
                distance: 0
              },
              event6: {
                rank: 0,
                completeRep: 0,
                time: 0
              }
            })
          }
          // Event1 데이터 정리 //

          // Event 2 데이터 정리 //
          for (let i = 0; i < event2TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].userId === event2TotalRank[i].userId) {
                totalRank[j].event2.rank = event2TotalRank[i].rank;
                totalRank[j].event2.firstExercise = event2TotalRank[i].firstExercise;
                totalRank[j].event2.tieBreak = event2TotalRank[i].tieBreak;

                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event2TotalRank[i].gender,
                name: event2TotalRank[i].name,
                profile: event2TotalRank[i].profile,
                members: event2TotalRank[i].members,
                userId: event2TotalRank[i].userId,
                event1: {
                  rank: 0,
                  section: 'A',
                  completeRep: 0,
                  time: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: event2TotalRank[i].rank,
                  firstExercise: event2TotalRank[i].firstExercise,
                  tieBreak: event2TotalRank[i].tieBreak
                },
                event3: {
                  rank: 0,
                  time: 0,
                  completeRep: 0
                },
                event4: {
                  rank: 0,
                  time: 0,
                  completeRep: 0
                },
                event5: {
                  rank: 0,
                  distance: 0
                },
                event6: {
                  rank: 0,
                  completeRep: 0,
                  time: 0
                }
              })
            }
          }
          // Event 2 데이터 정리 //

          // Event 3 데이터 정리 //
          for (let i = 0; i < event3TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].userId === event3TotalRank[i].userId) {
                totalRank[j].event3.rank = event3TotalRank[i].rank;
                totalRank[j].event3.time = event3TotalRank[i].time;
                totalRank[j].event3.completeRep = event3TotalRank[i].completeRep;

                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event3TotalRank[i].gender,
                name: event3TotalRank[i].name,
                profile: event3TotalRank[i].profile,
                members: event3TotalRank[i].members,
                userId: event3TotalRank[i].userId,
                event1: {
                  rank: 0,
                  section: 'A',
                  completeRep: 0,
                  time: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: 0,
                  firstExercise: 0,
                  tieBreak: 0
                },
                event3: {
                  rank: event3TotalRank[i].rank,
                  time: event3TotalRank[i].time,
                  completeRep: event3TotalRank[i].completeRep
                },
                event4: {
                  rank: 0,
                  time: 0,
                  completeRep: 0
                },
                event5: {
                  rank: 0,
                  distance: 0
                },
                event6: {
                  rank: 0,
                  completeRep: 0,
                  time: 0
                }
              })
            }
          }
          // Event 3 데이터 정리 //

          // Event 4 데이터 정리 //
          for (let i = 0; i < event4TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].userId === event4TotalRank[i].userId) {
                totalRank[j].event4.rank = event4TotalRank[i].rank;
                totalRank[j].event4.time = event4TotalRank[i].time;

                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event4TotalRank[i].gender,
                name: event4TotalRank[i].name,
                profile: event4TotalRank[i].profile,
                members: event4TotalRank[i].members,
                userId: event4TotalRank[i].userId,
                event1: {
                  rank: 0,
                  section: 'A',
                  completeRep: 0,
                  time: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: 0,
                  firstExercise: 0,
                  tieBreak: 0
                },
                event3: {
                  rank: 0,
                  time: 0,
                  completeRep: 0
                },
                event4: {
                  rank: event4TotalRank[i].rank,
                  time: event4TotalRank[i].time,
                  completeRep: event4TotalRank[i].completeRep
                },
                event5: {
                  rank: 0,
                  distance: 0
                },
                event6: {
                  rank: 0,
                  completeRep: 0,
                  time: 0
                }
              })
            }
          }
          // Event 4 데이터 정리 //

          // Event 5 데이터 정리 //
          for (let i = 0; i < event5TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].userId === event5TotalRank[i].userId) {
                totalRank[j].event5.rank = event5TotalRank[i].rank;
                totalRank[j].event5.distance = event5TotalRank[i].distance;

                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event5TotalRank[i].gender,
                name: event5TotalRank[i].name,
                profile: event5TotalRank[i].profile,
                members: event5TotalRank[i].members,
                userId: event5TotalRank[i].userId,
                event1: {
                  rank: 0,
                  section: 'A',
                  completeRep: 0,
                  time: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: 0,
                  firstExercise: 0,
                  tieBreak: 0
                },
                event3: {
                  rank: 0,
                  time: 0,
                  completeRep: 0
                },
                event4: {
                  rank: 0,
                  time: 0,
                  completeRep: 0
                },
                event5: {
                  rank: event5TotalRank[i].rank,
                  distance: event5TotalRank[i].distance
                },
                event6: {
                  rank: 0,
                  completeRep: 0,
                  time: 0
                }
              })
            }
          }
          // Event 5 데이터 정리 //

          // Event 6 데이터 정리 //
          for (let i = 0; i < event6TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].userId === event6TotalRank[i].userId) {
                totalRank[j].event6.rank = event6TotalRank[i].rank;
                totalRank[j].event6.time = event6TotalRank[i].time;
                totalRank[j].event6.completeRep = event6TotalRank[i].completeRep;

                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event6TotalRank[i].gender,
                name: event6TotalRank[i].name,
                profile: event6TotalRank[i].profile,
                members: event6TotalRank[i].members,
                userId: event6TotalRank[i].userId,
                event1: {
                  rank: 0,
                  section: 'A',
                  completeRep: 0,
                  time: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: 0,
                  firstExercise: 0,
                  tieBreak: 0
                },
                event3: {
                  rank: 0,
                  time: 0,
                  completeRep: 0
                },
                event4: {
                  rank: 0,
                  time: 0,
                  completeRep: 0
                },
                event5: {
                  rank: 0,
                  distance: 0
                },
                event6: {
                  rank: event6TotalRank[i].rank,
                  completeRep: event6TotalRank[i].completeRep,
                  time: event6TotalRank[i].time
                }
              })
            }
          }
          // Event 6 데이터 정리 //

          // 최종 순위 정리 //
          let finalTotalRank = [];
          let finalMaleRank = [];
          let finalFemaleRank = [];
          let maleTotalRank = [];
          let femaleTotalRank = [];
          let maleValidTotalRank = [];
          let maleInvalidTotalRank = [];
          let femaleValidTotalRank = [];
          let femaleInvalidTotalRank = [];
          // console.log(totalRank);
          totalRank.map((data) => {
            if (data.gender === 'male') maleTotalRank.push(data);
            else femaleTotalRank.push(data);
          })

          for (let i = 0; i < maleTotalRank.length; i++) {
            if (maleTotalRank[i].event1.rank === 0) {
              maleTotalRank[i].event1.rank = event1MaleValidRank.length + 1;
            }

            if (maleTotalRank[i].event2.rank === 0) {
              maleTotalRank[i].event2.rank = event2MaleValidRank.length + 1;
            }

            if (maleTotalRank[i].event3.rank === 0) {
              maleTotalRank[i].event3.rank = event3MaleValidRank.length + 1;
            }

            if (maleTotalRank[i].event4.rank === 0) {
              maleTotalRank[i].event4.rank = event4MaleValidRank.length + 1;
            }

            if (maleTotalRank[i].event5.rank === 0) {
              maleTotalRank[i].event5.rank = event5MaleValidRank.length + 1;
            }

            if (maleTotalRank[i].event6.rank === 0) {
              maleTotalRank[i].event6.rank = event6MaleValidRank.length + 1;
            }

          }

          for (let i = 0; i < femaleTotalRank.length; i++) {
            if (femaleTotalRank[i].event1.rank === 0) {
              femaleTotalRank[i].event1.rank = event1FemaleValidRank.length + 1;
            }

            if (femaleTotalRank[i].event2.rank === 0) {
              femaleTotalRank[i].event2.rank = event2FemaleValidRank.length + 1;
            }

            if (femaleTotalRank[i].event3.rank === 0) {
              femaleTotalRank[i].event3.rank = event3FemaleValidRank.length + 1;
            }

            if (femaleTotalRank[i].event4.rank === 0) {
              femaleTotalRank[i].event4.rank = event4FemaleValidRank.length + 1;
            }
            
            if (femaleTotalRank[i].event5.rank === 0) {
              femaleTotalRank[i].event5.rank = event5FemaleValidRank.length + 1;
            }

            if (femaleTotalRank[i].event6.rank === 0) {
              femaleTotalRank[i].event6.rank = event6FemaleValidRank.length + 1;
            }
          }

          // maleTotalRank.map((data) => {
          //   // Event중 한개라도 기록이 없는경우는 순위 산정 제외 //
          //   if (data.event1.rank === -1
          //     || data.event2.rank === -1
          //     || data.event3.rank === -1
          //     || data.event4.rank === -1) maleInvalidTotalRank.push(data);
          //   else maleValidTotalRank.push(data);
          // })

          // femaleTotalRank.map((data) => {
          //   // Event중 한개라도 기록이 없는경우는 순위 산정 제외 //
          //   if (data.event1.rank === -1
          //     || data.event2.rank === -1
          //     || data.event3.rank === -1
          //     || data.event4.rank === -1) femaleInvalidTotalRank.push(data);
          //   else femaleValidTotalRank.push(data);
          // })

          maleTotalRank.map((data) => {
            data.rank = -1;
            data.score = (data.event1.rank
              + data.event2.rank
              + data.event3.rank
              + data.event4.rank
              + (data.event5.rank * 0.5)
              + (data.event6.rank * 0.5))
          })

          femaleTotalRank.map((data) => {
            data.rank = -1;
            data.score = (data.event1.rank
              + data.event2.rank
              + data.event3.rank
              + data.event4.rank
              + (data.event5.rank * 0.5)
              + (data.event6.rank * 0.5))
          })

          // 최종 순위 정렬 //
          maleTotalRank.sort((a, b) => a.score - b.score);
          // 최종 순위 정렬 //
          femaleTotalRank.sort((a, b) => a.score - b.score);

          // // 올바르지 않은 데이터는 이름순 정렬 //
          // maleInvalidTotalRank.sort(function (a, b) {
          //   let x = a.name;
          //   let y = b.name;

          //   if (x > y) return 1;
          //   if (x < y) return -1;
          // })

          // // 올바르지 않은 데이터는 이름순 정렬 //
          // femaleInvalidTotalRank.sort(function (a, b) {
          //   let x = a.name;
          //   let y = b.name;

          //   if (x > y) return 1;
          //   if (x < y) return -1;
          // })

          // 순위 산출 //
          for (let i = 0; i < maleTotalRank.length; i++) {
            if (i !== 0 && maleTotalRank[i].score === 0) {
              maleTotalRank[i].rank = maleTotalRank[i - 1].rank;
            }
            else if (i !== 0
              && maleTotalRank[i].score === maleTotalRank[i - 1].score) {
              maleTotalRank[i].rank = maleTotalRank[i - 1].rank;
            }
            else {
              maleTotalRank[i].rank = (i + 1);
            }
          }

          // 순위 산출 //
          for (let i = 0; i < femaleTotalRank.length; i++) {
            if (i !== 0 && femaleTotalRank[i].score === 0) {
              femaleTotalRank[i].rank = femaleValidTotalRank[i - 1].rank;
            }
            else if (i !== 0
              && femaleTotalRank[i].score === femaleTotalRank[i - 1].score) {
              femaleTotalRank[i].rank = femaleTotalRank[i - 1].rank;
            }
            else {
              femaleTotalRank[i].rank = (i + 1);
            }
          }

          // finalMaleRank = maleValidTotalRank.concat(maleInvalidTotalRank);
          // finalFemaleRank = femaleValidTotalRank.concat(femaleInvalidTotalRank);

          // finalTotalRank = finalMaleRank.concat(finalFemaleRank);

          finalTotalRank = maleTotalRank.concat(femaleTotalRank);

          if (myRankData !== undefined) {
            // Event 1 내순위 찾기 //
            for (let i = 0; i < event1TotalRank.length; i++) {
              if (event1TotalRank[i].userId === myRankData.userId) {
                setEvent1MyRank(event1TotalRank[i]);
                break;
              }
            }

            // Event 2 내순위 찾기 //
            for (let i = 0; i < event2TotalRank.length; i++) {
              if (event2TotalRank[i].userId === myRankData.userId) {
                setEvent2MyRank(event2TotalRank[i]);
                break;
              }
            }

            // Event 3 내순위 찾기 //
            for (let i = 0; i < event3TotalRank.length; i++) {
              if (event3TotalRank[i].userId === myRankData.userId) {
                setEvent3MyRank(event3TotalRank[i]);
                break;
              }
            }

            // Event 4 내순위 찾기 //
            for (let i = 0; i < event4TotalRank.length; i++) {
              if (event4TotalRank[i].userId === myRankData.userId) {
                setEvent4MyRank(event4TotalRank[i]);
                break;
              }
            }

            // Event 5 내순위 찾기 //
            for (let i = 0; i < event5TotalRank.length; i++) {
              if (event5TotalRank[i].userId === myRankData.userId) {
                setEvent5MyRank(event5TotalRank[i]);
                break;
              }
            }

            // Event 6 내순위 찾기 //
            for (let i = 0; i < event6TotalRank.length; i++) {
              if (event6TotalRank[i].userId === myRankData.userId) {
                setEvent6MyRank(event6TotalRank[i]);
                break;
              }
            }

            // Total 내 순위 찾기 //
            for (let i = 0; i < finalTotalRank.length; i++) {
              if (finalTotalRank[i].userId === myRankData.userId) {
                setMyRanking(finalTotalRank[i]);
                break;
                // setTotalMyRank(finalTotalRank[i]);
                // break;
              }
              finalTotalRank[i].isClicked = false;
            }
          }
        
          // setTotalRankingList(finalTotalRank);
          setResponseRankingList(finalTotalRank);
        }
      }
    )();
  }, [nowSection])

  // // 섹션 선택에 따른 정보 변환 //
  // useEffect(() => {
  //   setIsLoading(true);

  //   if (nowSection === 'A') {
  //     setMyRanking(event1MyRank);
  //     // setRankingList(event1RankingList);
  //     setResponseRankingList(event1RankingList);
  //   }
  //   else if (nowSection === 'B') {
  //     setMyRanking(event2MyRank);
  //     // setRankingList(event2RankingList);
  //     setResponseRankingList(event2RankingList);
  //   }
  //   else if (nowSection === 'C') {
  //     setMyRanking(event3MyRank);
  //     // setRankingList(event3RankingList);
  //     setResponseRankingList(event3RankingList);
  //   }
  //   else if (nowSection === 'D') {
  //     setMyRanking(event4MyRank);
  //     // setRankingList(event4RankingList);
  //     setResponseRankingList(event4RankingList);
  //   }
  //   else if (nowSection === 'Total') {
  //     setMyRanking(totalMyRank);
  //     // setRankingList(totalRankingList);
  //     setResponseRankingList(totalRankingList);
  //   }
  // }, [nowSection, event1MyRank, event2MyRank, event3MyRank, event4MyRank, totalMyRank, event1RankingList, event2RankingList, event3RankingList, event4RankingList, totalRankingList])

  // 성별 구분으로 정리하기 //
  useEffect(() => {
    setIsLoading(true);

    let finalList = [];

    for (let i = 0; i < responseRankingList.length; i++) {
      if (responseRankingList[i].gender === isClickedGender) finalList.push(responseRankingList[i]);
    }

    setRankingList(finalList);

    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [isClickedGender, responseRankingList])

  // 내 순위 자세히 보기에 따른 컴포넌트 크기 변환 //
  useEffect(() => {
    // if (isClickedMyRank && nowSection === 'Total') setMyRankTotalHeight(244);
    if (isClickedMyRank && nowSection === 'Total') setMyRankTotalHeight(366);
    else setMyRankTotalHeight(64);
  }, [isClickedMyRank, myRanking, nowSection])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('순위표'), isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // React Native에서 웹으로 보내는 데이터 handler //
  async function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) {
      await window.localStorage.removeItem('GenderDivision');
      navigate(-1);
    }
  }

  // 내순위 팀 구성원 자세히보기 클릭시 //
  function onClickMyRecordDetailButton() {
    if (nowSection !== 'Total') return;

    let data = rankingList.slice();

    for (let i = 0; i < data.length; i++) {
      data[i].isClicked = false;
    }

    setRankingList(data);
    setIsClickedMyRank(!isClickedMyRank);
  }

  // 팀 구성원 자세히보기 클릭시 //
  function onClickRecordDetailButton(index) {
    if (nowSection !== 'Total') return;

    let data = rankingList.slice();

    for (let i = 0; i < data.length; i++) {
      if (i !== index) data[i].isClicked = false;
    }

    data[index].isClicked = !data[index].isClicked;

    setIsClickedMyRank(false);
    setRankingList(data);
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToFemale() {
    await window.localStorage.setItem('GenderDivision', 'female');
    setIsClickedGender('female');
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToMale() {
    await window.localStorage.setItem('GenderDivision', 'male');
    setIsClickedGender('male');
  }

  // 걸린시간 분/초로 계산 //
  function calcTime(data) {
    if (nowSection === 'A') {
      if (data.section === 'A1') {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.tieBreak % 1).toFixed(2);

        min = parseInt(data.tieBreak / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.tieBreak % 60);

        if (sec === 0) {
          if (min < 10) return 'First mission Tiebreak ' + '0' + min + ':00';
          else return 'First mission ' + min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return 'First mission Tiebreak ' + '0' + min + ':0' + sec;
            else return 'First mission Tiebreak ' + '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return 'First mission Tiebreak ' + min + ':0' + sec;
            else return 'First mission Tiebreak ' + min + ':' + sec;
          }
        }
      }
      else if (data.section === 'A2') {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.time % 1).toFixed(2);

        min = parseInt(data.time / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.time % 60);

        // if (sec === 0) {
        //   if (min < 10) return 'Second mission ' + '0' + min + ':00';
        //   else return 'Second mission ' + min + ':00';
        // }
        // else {
        //   if (min < 10) {
        //     if (sec < 10) return 'Second mission ' + '0' + min + ':0' + sec;
        //     else return 'Second mission ' + '0' + min + ':' + sec;
        //   }
        //   else {
        //     if (sec < 10) return 'Second mission ' + min + ':0' + sec;
        //     else return 'Second mission ' + min + ':' + sec;
        //   }
        // }
        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return '0' + min + ':0' + sec;
            else return '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
        }
      }
      else if (data.section === 'A3') {
        if (data.time === 0) return 'Third mission ' + data.completeRep;
        else {
          let min = 0;
          let sec = 0;
          let decimalPoint = (data.time % 1).toFixed(2);

          min = parseInt(data.time / 60);

          if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
          else sec = parseInt(data.time % 60);

          if (sec === 0) {
            if (min < 10) return 'Third mission ' + '0' + min + ':00';
            else return 'Third mission ' + min + ':00';
          }
          else {
            if (min < 10) {
              if (sec < 10) return 'Third mission ' + '0' + min + ':0' + sec;
              else return 'Third mission ' + '0' + min + ':' + sec;
            }
            else {
              if (sec < 10) return 'Third mission ' + min + ':0' + sec;
              else return 'Third mission ' + min + ':' + sec;
            }
          }
        }
      }
      else if (data.section === 'A') return '미제출';
    }
    else if (nowSection === 'B') {
      if (data.firstExercise === 0) return '미제출';
      else {
        return data.firstExercise + 'lb';
        // let min = 0;
        // let sec = 0;
        // let decimalPoint = (data.tieBreak % 1).toFixed(2);

        // min = parseInt(data.tieBreak / 60);

        // if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
        // else sec = parseInt(data.tieBreak % 60);

        // if (sec === 0) {
        //   if (min < 10) return data.firstExercise + 'lb - Tiebreak ' + '0' + min + ':00';
        //   else return data.firstExercise + 'lb - Tiebreak ' + min + ':00';
        // }
        // else {
        //   if (min < 10) {
        //     if (sec < 10) return data.firstExercise + 'lb - Tiebreak ' + '0' + min + ':0' + sec;
        //     else return data.firstExercise + 'lb - Tiebreak ' + '0' + min + ':' + sec;
        //   }
        //   else {
        //     if (sec < 10) return data.firstExercise + 'lb - Tiebreak ' + min + ':0' + sec;
        //     else return data.firstExercise + 'lb - Tiebreak ' + min + ':' + sec;
        //   }
        // }
      }
    }
    else if (nowSection === 'C') {
      if (data.completeRep === 0) {
        if (data.time === 0) return '미제출';
        else {
          let min = 0;
          let sec = 0;
          let decimalPoint = (data.time % 1).toFixed(2);
  
          min = parseInt(data.time / 60);
  
          if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
          else sec = parseInt(data.time % 60);
  
          if (sec === 0) {
            if (min < 10) return '0' + min + ':00';
            else return min + ':00';
          }
          else {
            if (min < 10) {
              if (sec < 10) return min + ':0' + sec;
              else return min + ':' + sec;
            }
            else {
              if (sec < 10) return min + ':0' + sec;
              else return min + ':' + sec;
            }
          }
        }
      }
      else return data.completeRep + ' Reps';
    }
    else if (nowSection === 'D') {
      if (data.completeRep === 0) {
        if (data.time === 0) return '미제출';
        else {
          let min = 0;
          let sec = 0;
          let decimalPoint = (data.time % 1).toFixed(2);

          min = parseInt(data.time / 60);

          if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
          else sec = parseInt(data.time % 60);

          if (sec === 0) {
            if (min < 10) return '0' + min + ':00';
            else return min + ':00';
          }
          else {
            if (min < 10) {
              if (sec < 10) return min + ':0' + sec;
              else return min + ':' + sec;
            }
            else {
              if (sec < 10) return min + ':0' + sec;
              else return min + ':' + sec;
            }
          } 
        }
      }
      else return data.completeRep + ' Reps';
    }
    else if (nowSection === 'E') {
      if (data.distance === 0) return '미제출';
      else return data.distance + 'm';
      // if (data.time === 0) return '미제출';
      // else {
      //   let min = 0;
      //   let sec = 0;
      //   let decimalPoint = (data.time % 1).toFixed(2);

      //   min = parseInt(data.time / 60);

      //   if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      //   else sec = parseInt(data.time % 60);

      //   if (sec === 0) {
      //     if (min < 10) return '0' + min + ':00';
      //     else return min + ':00';
      //   }
      //   else {
      //     if (min < 10) {
      //       if (sec < 10) return min + ':0' + sec;
      //       else return min + ':' + sec;
      //     }
      //     else {
      //       if (sec < 10) return min + ':0' + sec;
      //       else return min + ':' + sec;
      //     }
      //   } 
      // }
    }
    else if (nowSection === 'F') {
      if (data.gender === 'male') {
        if (data.completeRep === 0) return '미제출';
        return data.completeRep + ' Reps';
      }
      else if (data.gender === 'female') {
        if (data.time === 0) return '미제출';
        else {
          let min = 0;
          let sec = 0;
          let decimalPoint = (data.time % 1).toFixed(2);
  
          min = parseInt(data.time / 60);
  
          if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
          else sec = parseInt(data.time % 60);
  
          if (sec === 0) {
            if (min < 10) return '0' + min + ':00';
            else return min + ':00';
          }
          else {
            if (min < 10) {
              if (sec < 10) return min + ':0' + sec;
              else return min + ':' + sec;
            }
            else {
              if (sec < 10) return min + ':0' + sec;
              else return min + ':' + sec;
            }
          } 
        }
      }
    }
    else if (nowSection === 'Total') {
      return data.score + ' Point'
    }
  }

  // 걸린시간 분/초로 계산의 단위 정리 //
  function calcTimeUnit(data) {
    if (nowSection === 'A') {
      if (data.section === 'A1') return '';
      else if (data.section === 'A2') return '';
      else if (data.section === 'A3') {
        if (data.time !== 0) return '';
        else return 'REPS';
      }
    }
    else if (nowSection === 'B') {}
    else if (nowSection === 'C') {
      if (data.completeRep !== 0) return 'REPS';
      else return '';
    }
    else if (nowSection === 'D') {
      if (data.completeRep !== 0) return 'REPS';
      else return '';
    }
  }

  // Tie Break 계산 //
  function calcTieBreak(data) {
    if (nowSection === 'A' || nowSection === 'B' || nowSection === 'Total') {
      if (data.tieBreak === 0 || data.tieBreak === undefined || data.completeRep === 0) return '';
      else {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.tieBreak % 1).toFixed(2);

        min = parseInt(data.tieBreak / 60);
        // sec = data.tieBreak % 60;

        if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.tieBreak % 60);


        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return '0' + min + ':' + '0' + sec;
            else return '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':' + '0' + sec;
            else return min + ':' + sec;
          }
        }
      }
    }
  }

  // 각 섹션별 올바른 데이터인지 확인 //
  function checkValidData(data) {
    // if ((nowSection === 'A' || nowSection === 'B') && data.completeRep === 0) return false;
    if ((nowSection === 'A' || nowSection === 'B') && data.rank === -1) return false;
    else if (nowSection === 'C' && data.firstExercise === 0) return false;
    else if (nowSection === 'D' && data.time === 0) return false;
    else if (nowSection === 'Total' && data.rank === undefined) return false;
    else return true;
  }


  // 클릭시 최종컴포넌트 늘어나는 크기 정하기 //
  function calcTotalComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    // if (data.isClicked && nowSection === 'Total') return 244;
    if (data.isClicked && nowSection === 'Total') return 366;
    else return 64;
  }

  // 최종 순위표 내의 순위 산출시 배경색 결정 //
  function selectTotalEventRankingBoxBackgroundColor(data) {
    if (nowSection === 'Total' && data === undefined) return '#E0E0E0';
    else if (data.rank === 1) return '#FEB501';
    else if (data.rank === 2) return '#94B1C1';
    else if (data.rank === 3) return '#F38D30';
    else return '#E0E0E0';
  }

  // 걸린시간 분/초로 계산 //
  function calcTotalTime(data) {

    if (data.time === 0) return '미제출';
    else {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (min === 0 && sec === 0) return '미제출';
      else {
        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return '0' + min + ':0' + sec;
            else return '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
        }
      }
    }
  }

  // Total 컬럼에서 A Section 데이터 보기 //
  function calcTotalEvent1View(data) {
    if (data.section === 'A3') {
      if (data.time === 0) return 'Third mission ' + data.completeRep;
      else {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.time % 1).toFixed(2);

        min = parseInt(data.time / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.time % 60);

        if (sec === 0) {
          if (min < 10) return 'Third mission ' + '0' + min + ':00';
          else return 'Third mission ' + min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return 'Third mission ' + '0' + min + ':0' + sec;
            else return 'Third mission ' + '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return 'Third mission ' + min + ':0' + sec;
            else return 'Third mission ' + min + ':' + sec;
          }
        }
      }
    }
    else if (data.section === 'A2') {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (sec === 0) {
        if (min < 10) return '0' + min + ':00';
        else return min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) return '0' + min + ':0' + sec;
          else return '0' + min + ':' + sec;
        }
        else {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
      }
    }
    else if (data.section === 'A1') {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.tieBreak % 1).toFixed(2);

      min = parseInt(data.tieBreak / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.tieBreak % 60);

      if (sec === 0) {
        if (min < 10) return 'First mission Tiebreak ' + '0' + min + ':00';
        else return 'First mission ' + min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) return 'First mission Tiebreak ' + '0' + min + ':0' + sec;
          else return 'First mission Tiebreak ' + '0' + min + ':' + sec;
        }
        else {
          if (sec < 10) return 'First mission Tiebreak ' + min + ':0' + sec;
          else return 'First mission Tiebreak ' + min + ':' + sec;
        }
      }
    }
    else return 'None';
  }

  // Total 컬럼에서 B Section 데이터 보기 //
  function calcTotalEvent2View(data) {
    if (data.firstExercise === 0) return 'None';
    else {
      return data.firstExercise + 'lb';
      // let min = 0;
      // let sec = 0;
      // let decimalPoint = (data.tieBreak % 1).toFixed(2);

      // min = parseInt(data.tieBreak / 60);

      // if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
      // else sec = parseInt(data.tieBreak % 60);

      // if (sec === 0) {
      //   if (min < 10) return data.firstExercise + 'lb - Tiebreak ' + '0' + min + ':00';
      //   else return data.firstExercise + 'lb - Tiebreak ' + min + ':00';
      // }
      // else {
      //   if (min < 10) {
      //     if (sec < 10) return data.firstExercise + 'lb - Tiebreak ' + '0' + min + ':0' + sec;
      //     else return data.firstExercise + 'lb - Tiebreak ' + '0' + min + ':' + sec;
      //   }
      //   else {
      //     if (sec < 10) return data.firstExercise + 'lb - Tiebreak ' + min + ':0' + sec;
      //     else return data.firstExercise + 'lb - Tiebreak ' + min + ':' + sec;
      //   }
      // }
    }
  }

  // Total 컬럼에서 C Section 데이터 보기 //
  function calcTotalEvent3View(data) {
    if (data.time !== 0) {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (sec === 0) {
        if (min < 10) return '0' + min + ':00';
        else return min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
        else {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
      }
    }
    else if (data.completeRep !== 0) return data.completeRep + 'Reps';
    else return 'None';
  }

  // Total 컬럼에서 D Section 데이터 보기 //
  function calcTotalEvent4View(data) {
    if (data.time !== 0) {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (sec === 0) {
        if (min < 10) return '0' + min + ':00';
        else return min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
        else {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
      }
    }
    else if (data.completeRep !== 0) return data.completeRep + 'Reps';
    else return 'None';
  }

  // Total 컬럼에서 E, F Section 데이터 보기 //
  function calcTotalEvent5View(data) {
    // if (data.time !== 0) {
    //   let min = 0;
    //   let sec = 0;
    //   let decimalPoint = (data.time % 1).toFixed(2);

    //   min = parseInt(data.time / 60);

    //   if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
    //   else sec = parseInt(data.time % 60);

    //   if (sec === 0) {
    //     if (min < 10) return '0' + min + ':00';
    //     else return min + ':00';
    //   }
    //   else {
    //     if (min < 10) {
    //       if (sec < 10) return min + ':0' + sec;
    //       else return min + ':' + sec;
    //     }
    //     else {
    //       if (sec < 10) return min + ':0' + sec;
    //       else return min + ':' + sec;
    //     }
    //   }
    // }
    // else return 'None';
    if (data.distance === 0) return 'None';
    else return data.distance + 'm';
  }

  // Total 컬럼에서 E, F Section 데이터 보기 //
  function calcTotalEvent6View(data, gender) {
    if (gender === 'male') {
      if (data.completeRep === 0) return 'None';
      else return data.completeRep + ' Reps';
    }
    else {
      if (data.time !== 0) {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.time % 1).toFixed(2);
  
        min = parseInt(data.time / 60);
  
        if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.time % 60);
  
        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
        }
      }
      else return 'None';
    }
  }

  // 현재 섹션 수정시 //
  function onClickNowSection(data) {
    setIsLoading(true);
    setNowSection(data);
  }

  return (
    <TopLevelWrapper>
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#333333'>Final SUFF Individual Event</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        <SectionButton isClicked={nowSection === 'A'} onClick={() => onClickNowSection('A')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E1</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'B'} onClick={() => onClickNowSection('B')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E2</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'E'} onClick={() => onClickNowSection('E')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>H1</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'C'} onClick={() => onClickNowSection('C')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E3</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'D'} onClick={() => onClickNowSection('D')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E4</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'F'} onClick={() => onClickNowSection('F')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>H2</Text>
        </SectionButton>
        <TotalSection isClicked={nowSection === 'Total'} onClick={() => onClickNowSection('Total')}>
          <CrownImage src={CrownIcon} />
        </TotalSection>
      </SectionButtonWrapper>
      <DivisionLine />
      <GenderSelectWrapper>
        <GenderSelectBox isClicked={isClickedGender === 'female'} onClick={onClickChangeGenderToFemale}>
          <GenderImageWrapper>
            <GenderImage style={isClickedGender === 'female' ? { opacity: 1 } : { opacity: 0 }} src={ColorFemale} />
            <GenderImage style={isClickedGender === 'female' ? { position: 'absolute', opacity: 0 } : { position: 'absolute', opacity: 1 }} src={WhiteFemale} />
          </GenderImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color={isClickedGender === 'female' ? `#4F4F4F` : '#828282'}>{t('여성')}</Text>
        </GenderSelectBox>
        <GenderSelectBox isClicked={isClickedGender === 'male'} onClick={onClickChangeGenderToMale}>
          <GenderImageWrapper>
            <GenderImage style={isClickedGender === 'male' ? { opacity: 1 } : { opacity: 0 }} src={ColorMale} />
            <GenderImage style={isClickedGender === 'male' ? { position: 'absolute', opacity: 0 } : { position: 'absolute', opacity: 1 }} src={WhiteMale} />
          </GenderImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color={isClickedGender === 'male' ? `#4F4F4F` : '#828282'}>{t('남성')}</Text>
        </GenderSelectBox>
      </GenderSelectWrapper>
      {
        // (nowSection === 'E') ?
        <TotalWrapper>
          {
            (myRanking !== undefined && myRanking.gender === isClickedGender) &&
            <TempWrapper>
              <TopLineWrapper>
                <TitleWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('내 순위')}</Text>
                </TitleWrapper>
              </TopLineWrapper>
              {
                isLoading ?
                  <RankingTotalWrapper>
                    <RankingLoadingView>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                    </RankingLoadingView>
                  </RankingTotalWrapper>
                  :
                  <RankingTotalWrapper>
                    <RankingViewWrapper height={myRankTotalHeight} onClick={() => onClickMyRecordDetailButton()}>
                      {
                        // checkValidData(myRanking) ?
                        <RankingNumberBoxWrapper>
                          {
                            (myRanking.rank > 0 && myRanking.rank < 4) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={myRanking.rank === 1 ? FirstPlaceImg : myRanking.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                        // :
                        // <RankingNumberBoxWrapper>
                        //   <RankingNumberBox>
                        //     <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>0</Text>
                        //   </RankingNumberBox>
                        // </RankingNumberBoxWrapper>
                      }
                      <RankingClickBoxWrapper>
                        <RankingClickBox>
                          <InfoBox>
                            <LeftWrapper>
                              <Profile src={myRanking.profile === '' ? NoneProfile : myRanking.profile} />
                              <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                <InnerInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{myRanking.name}</Text>
                                </InnerInfoTextWrapper>
                                <RecordBox>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(myRanking)}</Text>
                                    {/* <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTimeUnit(myRanking)}</Text> */}
                                  </RecordTextWrapper>
                                  {/* <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#828282'>{(myRanking.tieBreak === 0 || myRanking.tieBreak === undefined || myRanking.completeRep === 0) ? `` : `TieBreak`}</Text>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#4F4F4F'>{calcTieBreak(myRanking)}</Text>
                                  </RecordTextWrapper> */}
                                </RecordBox>
                              </InfoTextWrapper>
                            </LeftWrapper>
                            {nowSection === 'Total' && <DetailButton isClicked={isClickedMyRank} src={RightImg} />}
                          </InfoBox>
                        </RankingClickBox>
                        {
                          nowSection === 'Total' &&
                          <TotalRecordWrapper isClicked={isClickedMyRank && nowSection === 'Total'}>
                            {/* Event 1 */}
                            <EventRowWrapper>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 13px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event1)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event1.rank === -1 ? '0' : myRanking.event1.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  (myRanking.event1.completeRep !== 0 && myRanking.event1.time !== 0) ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.event1.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(myRanking.event1)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent1View(myRanking.event1)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 1 */}
                            {/* Event 2 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event2)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event2.rank === -1 ? '0' : myRanking.event2.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  myRanking.event2.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.event2.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(myRanking.event2)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent2View(myRanking.event2)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 2 */}
                            {/* Event 3 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event3)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event3.rank === -1 ? '0' : myRanking.event3.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  myRanking.event3.firstExercise !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.event3.firstExercise}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalEvent3View(myRanking.event3)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 3 */}
                            {/* Event 4 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT4</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event4)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event4.rank === -1 ? '0' : myRanking.event4.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  myRanking.event4.time !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalTime(myRanking.event4)}</Text>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalEvent4View(myRanking.event4)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 4 */}
                            {/* Event 5 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event5)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event5.rank === -1 ? '0' : myRanking.event5.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalEvent5View(myRanking.event5)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 5 */}
                            {/* Event 6 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event6)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event6.rank === -1 ? '0' : myRanking.event6.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalEvent6View(myRanking.event6, myRanking.gender)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 6 */}
                          </TotalRecordWrapper>
                        }
                      </RankingClickBoxWrapper>
                    </RankingViewWrapper>
                  </RankingTotalWrapper>
              }
            </TempWrapper>
          }
          <TopLineWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('전체 순위')}</Text>
            </TitleWrapper>
          </TopLineWrapper>
          {
            isLoading ?
              <RankingTotalWrapper>
                <RankingLoadingView>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                </RankingLoadingView>
              </RankingTotalWrapper>
              :
              <RankingTotalWrapper>
                {
                  rankingList.map((data, index) => (
                    <RankingViewWrapper key={index} height={calcTotalComponentHeight(data)} onClick={() => onClickRecordDetailButton(index)}>
                      {
                        // checkValidData(data) ?
                        <RankingNumberBoxWrapper>
                          {
                            (data.rank > 0 && data.rank < 4) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={data.rank === 1 ? FirstPlaceImg : data.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                        // :
                        // <RankingNumberBoxWrapper>
                        //   <RankingNumberBox>
                        //     <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>0</Text>
                        //   </RankingNumberBox>
                        // </RankingNumberBoxWrapper>
                      }
                      <RankingClickBoxWrapper>
                        <RankingClickBox>
                          <InfoBox>
                            <LeftWrapper>
                              <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                              <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                <InnerInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                </InnerInfoTextWrapper>
                                <RecordBox>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(data)}</Text>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTimeUnit(data)}</Text>
                                  </RecordTextWrapper>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#828282'>{(data.tieBreak === 0 || data.tieBreak === undefined || data.completeRep === 0) ? `` : `TieBreak`}</Text>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#4F4F4F'>{calcTieBreak(data)}</Text>
                                  </RecordTextWrapper>
                                </RecordBox>
                              </InfoTextWrapper>
                            </LeftWrapper>
                            {nowSection === 'Total' && <DetailButton isClicked={data.isClicked} src={RightImg} />}
                          </InfoBox>
                        </RankingClickBox>
                        {
                          nowSection === 'Total' &&
                          <TotalRecordWrapper isClicked={data.isClicked && nowSection === 'Total'}>
                            {/* Event 1 */}
                            <EventRowWrapper>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 13px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event1)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event1.rank === -1 ? 'DNF' : data.event1.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  data.event1.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.event1.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(data.event1)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                  <EventInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent1View(data.event1)}</Text>
                                  </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 1 */}
                            {/* Event 2 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event2)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event2.rank === -1 ? 'DNF' : data.event2.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  data.event2.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.event2.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(data.event2)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent2View(data.event2)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 2 */}
                            {/* Event 3 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event3)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event3.rank === -1 ? 'DNF' : data.event3.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  data.event3.firstExercise !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.event3.firstExercise}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent3View(data.event3)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 3 */}
                            {/* Event 4 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT4</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event4)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event4.rank === -1 ? 'DNF' : data.event4.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  data.event4.time !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalTime(data.event4)}</Text>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent4View(data.event4)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 4 */}
                            {/* Event 5 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event5)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event5.rank === -1 ? '0' : data.event5.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent5View(data.event5)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 5 */}
                            {/* Event 6 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event6)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event6.rank === -1 ? '0' : data.event6.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent6View(data.event6, data.gender)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 6 */}
                          </TotalRecordWrapper>
                        }
                      </RankingClickBoxWrapper>
                    </RankingViewWrapper>
                  ))
                }
              </RankingTotalWrapper>
          }
        </TotalWrapper>
        // :
        // <TotalWrapper>
        //   <WaitWrapper>
        //     <WaitImage src={WaitIcon} />
        //     <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>온라인 저징 이후</Text>
        //     <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>재업로드 예정입니다</Text>
        //   </WaitWrapper>
        // </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 80px;

  width: 100%;
  height: 100%;

  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const GenderSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const GenderSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  width: 50%;
  height: 32px;

  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.isClicked ? '#F9F9F9' : '#E0E0E0'};

  transition: all 0.3s ease-in-out;
`;

const GenderImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const GenderImage = styled.img`
  width: 11px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin: 4px 0px 80px 0px;
  marigin-top: 4px;

  width: 90.33%;
`;

const RankingLoadingView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  // height: 64px;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 87.16%;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(100% - 28px);
  height: 62px;

  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const TotalRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isClicked ? 10 : 0}px;

  width: 100%;
  // height: ${props => props.isClicked ? 170 : 0}px;
  height: ${props => props.isClicked ? 255 : 0}px;

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const EventRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const EventBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 7px;
  background-color: #E0E0E0;
`;

const EventInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 85.27%;
  height: 40px;

  background-color: #FFFFFF;
`;

const EventRankingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: ${props => props.backgroundColor};
`;

const EventInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-end;

  margin-left: 8px;

  height: 20px;
`;

const TotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const InnerInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const RecordBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RecordTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const RxBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 23px;
  height: 13px;

  border-radius: 3px;
  background-color: #FF3131;
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 33px;
  height: 13px;

  border-radius: 3px;
  background-color: #4161AF;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;

  transform: ${props => props.isClicked ? `rotateZ(90deg)` : `rotateZ(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
  
  -webkit-transform: scale(${props => props.scale});
  transition: all 0.3s ease-in-out;
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;