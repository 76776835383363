import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import NormalInput from '../Component/LoginComponent/NormalInput';
import PasswordInput from '../Component/LoginComponent/PasswordInput';
import SelectEventModal from '../Model/ModifyRankingModel/SelectEventModal';

import FetchModule from '../Model/Network/FetchModule';

export default function LoginPage() {

  const navigate = useNavigate();

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [isSuccessLogin, setIsSuccessLogin] = useState(false);
  
  // 관리자 로그인 클릭시 //
  function onClickLogin() {

    let requestData = {
      account: id,
      password: password
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('account/login', 'POST', requestData);

        if (responseData.status === 200) {
          window.localStorage.setItem('Token', 'Bearer ' + responseData.data.accessToken);
          window.localStorage.setItem('RefreshToken', 'Bearer ' + responseData.data.refreshToken);
          window.localStorage.setItem('OrganizerId', responseData.data.organizerId);

          // // BattleCrew //
          // if (responseData.data.organizerId === 9) navigate('/battlecrew3/admin');
          // else if (responseData.data.organizerId === 13) navigate('/girlfit/admin');
          if (responseData.data.organizerId === 28) navigate('/participant/2024_team_of_4');
          else if (responseData.data.organizerId === 29) navigate('/participant/girl_crush_season2');
          else if (responseData.data.organizerId === 30) navigate('/participant/the_mentality');
          else if (responseData.data.organizerId === 31) navigate('/participant/urbanfit_invitational');
          else alert('Login 성공');
        }
        else {
          window.localStorage.setItem('Token', null)
          window.localStorage.setItem('RefreshToken', null);

          alert("알맞지 않은 인증정보입니다!");
          return;
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      { isSuccessLogin && <SelectEventModal/> }
      <ComponentWrapper>
        <IconWrapper>
          <Icon src='https://picture.link-zone.org/CoachLogo.jpg'/>
        </IconWrapper>
        <TitleWrapper>
          <Text fontFamily='Poppins-Bold' fontSize={24} color='#6DD49E'>Link Event</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#6DD49E'>이벤트 관리자 로그인 페이지</Text>
        </TitleWrapper>
        <InfoWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>아이디</Text>
          <NormalInput value={id} setValue={setId} placeholder='아이디를 입력해주세요'/>
          <Text style={{ marginTop: '32px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>아이디</Text>
          <PasswordInput value={password} setValue={setPassword} placeholder='패스워드를 입력해주세요'/>
        </InfoWrapper>
        <LoginButton onClick={onClickLogin}>
          <HoverText fontFamily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF'>로그인</HoverText>
        </LoginButton>
      </ComponentWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  // width: 100%;
  // height: 100%;

  background-color: #C9C9C9;
  // background-color: red;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
  max-width: 560px;

  border-radius: 12px;
  background-color: #FFFFFF;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 19.32vw;
  height: 19.32vw;

  overflow: hidden;
  border-radius: 8px;
`;

const Icon = styled.img`
  display: flex;
  flex-direction: 

  width: 100%;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 56px;

  width: 86.47%;
`;

const LoginButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 32px;
  margin-bottom: 16px;

  width: 86.47%;
  height: 48px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: cursor;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
