import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import Lottie from 'lottie-react-web';
import XLSX from 'sheetjs-style';

import LoadingAnimation from '../../Animation/LoadingAnimation.json';
import FetchModule from "../../Model/Network/FetchModule";

export default function TeamOfFourParticipantListPage() {

  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.getDataOfEventVer2('tournament/event/participant/' + 54, 'GET');
  //       // console.log(responseData.data);
  //       if (responseData.status === 200) {

  //         let dates = responseData.data.slice();
  //         let finalList = [];

  //         for (let i = 0; i < dates.length; i++) {

  //           let salesList = '';
  //           let address = dates[i].members[0].address + ' ' + dates[i].members[0].addressDetail + ' ' + dates[i].members[0].zipCode;

  //           // for (let j = 0; j < dates[i].salesDetailSet.length; j++) {
  //           //   if (dates[i].salesDetailSet[j].itemName === '참가비') continue;

  //           //   if (j !== 0) salesList += '\n';
  //           //   if (dates[i].salesDetailSet[j].count === 1) salesList += dates[i].salesDetailSet[j].itemName;
  //           //   else salesList += dates[i].salesDetailSet[j].itemName + ' - ' + dates[i].salesDetailSet[j].count + ' 개';
  //           // }

  //           finalList.push({
  //             teamId: dates[i].teamId,
  //             createdDate: dates[i].createdDate,
  //             profile: dates[i].profile,
  //             teamName: dates[i].teamName,
  //             address: address,
  //             memberName1: dates[i].members[0].name,
  //             memberPhone1: dates[i].members[0].phone,
  //             memberBirth1: dates[i].members[0].birth,
  //             memberName2: dates[i].members[1].name,
  //             memberPhone2: dates[i].members[1].phone,
  //             memberBirth2: dates[i].members[1].birth,
  //             memberName3: dates[i].members[2].name,
  //             memberPhone3: dates[i].members[2].phone,
  //             memberBirth3: dates[i].members[2].birth,
  //             memberName4: dates[i].members[4].name,
  //             memberPhone4: dates[i].members[4].phone,
  //             memberBirth4: dates[i].members[4].birth,
  //             merchantUid: dates[i].merchantUid,
  //             totalPrice: dates[i].totalPrice,
  //             // salesList: salesList
  //           })

  //           // if (parseInt(params.eventId) === 36) {
  //           //   finalList.push({
  //           //     teamId: dates[i].teamId,
  //           //     createdDate: dates[i].createdDate,
  //           //     profile: dates[i].profile,
  //           //     teamName: dates[i].teamName,
  //           //     address: address,
  //           //     memberName1: dates[i].members[0].name,
  //           //     memberPhone1: dates[i].members[0].phone,
  //           //     memberBelong1: dates[i].members[0].belong,
  //           //     memberName2: dates[i].members[1].name,
  //           //     memberPhone2: dates[i].members[1].phone,
  //           //     memberBelong2: dates[i].members[1].belong,
  //           //     memberName3: dates[i].members[2].name,
  //           //     memberPhone3: dates[i].members[2].phone,
  //           //     memberBelong3: dates[i].members[2].belong,
  //           //     memberName4: dates[i].members[3].name,
  //           //     memberPhone4: dates[i].members[3].phone,
  //           //     memberBelong4: dates[i].members[3].belong,
  //           //     merchantUid: dates[i].merchantUid,
  //           //     totalPrice: dates[i].totalPrice,
  //           //     salesList: salesList
  //           //   })
  //           // }
  //           // else {
  //           //   finalList.push({
  //           //     teamId: dates[i].teamId,
  //           //     createdDate: dates[i].createdDate,
  //           //     profile: dates[i].profile,
  //           //     teamName: dates[i].teamName,
  //           //     address: address,
  //           //     memberName1: dates[i].members[0].name,
  //           //     memberPhone1: dates[i].members[0].phone,
  //           //     memberBelong1: dates[i].members[0].belong,
  //           //     memberName2: dates[i].members[1].name,
  //           //     memberPhone2: dates[i].members[1].phone,
  //           //     memberBelong2: dates[i].members[1].belong,
  //           //     merchantUid: dates[i].merchantUid,
  //           //     totalPrice: dates[i].totalPrice,
  //           //     salesList: salesList
  //           //   })
  //           // }
  //         }


  //         finalList.sort((a, b) => a.teamId - b.teamId);

  //         const ws = await XLSX.utils.json_to_sheet(finalList);
  //         const wb = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(wb, ws, "Member")
  //         XLSX.writeFile(wb, '2024_team_of_4 신청자 명단.xlsx');
  //       }
  //     }
  //   )();
  // }, [])

  function onClickMakeExcel() {
    if (isLoading) {
      alert('추출을 진행중입니다!');
      return;
    }
    
    setIsLoading(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('tournament/event/participant/' + 54, 'GET');
        console.log(responseData.data);
        if (responseData.status === 200) {

          let dates = responseData.data.slice();
          let finalList = [];

          for (let i = 0; i < dates.length; i++) {

            let address = dates[i].members[0].address + ' ' + dates[i].members[0].addressDetail + ' ' + dates[i].members[0].zipCode;

            finalList.push({
              teamId: dates[i].teamId,
              createdDate: dates[i].createdDate,
              profile: dates[i].profile,
              teamName: dates[i].teamName,
              address: address,
              memberName1: dates[i].members[0].name,
              memberPhone1: dates[i].members[0].phone,
              // memberBirth1: dates[i].members[0].birth,
              memberName2: dates[i].members[1].name,
              memberPhone2: dates[i].members[1].phone,
              // memberBirth2: dates[i].members[1].birth,
              memberName3: dates[i].members[2].name,
              memberPhone3: dates[i].members[2].phone,
              // memberBirth3: dates[i].members[2].birth,
              memberName4: dates[i].members[3].name,
              memberPhone4: dates[i].members[3].phone,
              // memberBirth4: dates[i].members[3].birth,
              merchantUid: dates[i].merchantUid,
              totalPrice: dates[i].totalPrice,
              // salesList: salesList
            })
          }

          finalList.sort((a, b) => a.teamId - b.teamId);

          const ws = await XLSX.utils.json_to_sheet(finalList);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Member")
          XLSX.writeFile(wb, '2024_team_of_4 신청자 명단.xlsx');

          setTimeout(() => {
            alert('명단 추출이 완료되었습니다.');
            setIsLoading(false);
          }, 500)
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      {
        isLoading ?
          <LottieWrapper>
            <LottieBox>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>명단 추출 진행중...</Text>
          </LottieWrapper>
          :
          <CompleteModal>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#333333'>이벤트 신청자 명단 추출</Text>
            <TextWrapper>
              {/* <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{decideModalText()}</Text> */}
            </TextWrapper>
            <GoAppButton onClick={onClickMakeExcel}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>엑셀로 추출하기</Text>
            </GoAppButton>
          </CompleteModal>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  // background-color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.2);
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const LottieBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;

const CompleteModal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px 12px 0px;

  width: 80%;
  max-width: 400px;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 90.33%;
`;

const GoAppButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 90.33%;
  height: 40px;

  border-radius: 8px;
  background-color: #6DD49E;
`;


const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};

  color: ${props => props.color};

  margin: 0 0 0 0;
`;