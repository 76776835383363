import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import moment from 'moment';

import FetchModule from '../../Model/Network/FetchModule';
// import NameArrow from '../../Image/NameArrow.png';
// import EventBox from '../../Model/EventInfoModel/EventBox';
import { useTranslation } from 'react-i18next';
import CancellationModal from '../../Model/EventApplicationModel/CancellationModal';
import LoadingAnimation from '../../Model/EventApplicationModel/LoadingAnimation';
import CancellationInfoModal from '../../Model/EventApplicationModel/CancellationInfoModal';
import EventBox from './Model/EventBox';

export default function BelieverInfoPage() {

  const { t, i18n } = useTranslation();

  const changeLanguageToKo = () => i18n.changeLanguage('ko');
  const changeLanguageToEn = () => i18n.changeLanguage('en');

  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [titleFontSize, setTitleFontSize] = useState(30);
  const [eventTitleFontSize, setEventTitleFontSize] = useState(18);
  const [isFixed, setIsFixed] = useState(false);
  const [sectionY, setSectionY] = useState(0);
  const [profileSize, setProfileSize] = useState(0);
  const [buttonTopMargin, setButtonTopMargin] = useState(0);
  const [scrollDivision, setScrollDivision] = useState('info');
  const [language, setLanguage] = useState('English');
  const [merchantUid, setMerchantUid] = useState('');
  const [eventId, setEventId] = useState(0);
  const [availableModifyDate, setAvailableModifyDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [isAlreadyPayment, setIsAlreadyPayment] = useState(false);
  const [isClickedCancellation, setIsClickedCancellation] = useState(false);
  const [isViewCancellationInfoModal, setIsViewCancellationInfoModal] = useState(false);

  const [organizerId, setOrganizerId] = useState(0);
  const [isAuthority, setIsAuthority] = useState(false);

  const [depositor, setDepositor] = useState('');
  const [bank, setBank] = useState('');
  const [accountNum, setAccountNum] = useState('');

  const [totalVideoWidth, setTotalVideoWidth] = useState(374);
  const [totalVideoHeight, setTotalVideoHeight] = useState(374);

  const TopLevelRef = useRef();
  const SectionRef = useRef();
  const InfoRef = useRef();
  const EventRef = useRef();

  // 이벤트 데이터 받아오기 //
  useEffect(() => {
    setOrganizerId(19);

    let requestData = {
      organizerId: 19
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('tournament/', 'POST', requestData);
        
        if (responseData.status === 200) {
          let data = responseData.data;
          let eventData = responseData.data.events;

          eventData.sort(function (a, b) {
            let x = a.eventId;
            let y = b.eventId;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          data.events = eventData;

          setData(data);
          setIsAlreadyPayment(responseData.data.isAlreadyPayment);
          setIsAuthority(responseData.data.isAuthority);
          setMerchantUid(responseData.data.merchantUid);
          setEventId(responseData.data.eventId);
          setAvailableModifyDate(moment(responseData.data.availableModifyDate));

          window.localStorage.removeItem('EventData');
        }
      }
    )();
  }, [])

  // Window Resize Handler And Scroll Detect Save And Delete //
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);

      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 화면 크기 추출후 사진, 참가신청 공백 크기 계산 //
  useEffect(() => {
    let finalSize = windowSize.width * 0.9033;
    // let finalSize = windowSize.width * 0.60;
    let eventMargin = 0;

    if (data.events === undefined) eventMargin = 36;
    else eventMargin = windowSize.height - (159 + (178 * data.events.length) + (24 * (data.events.length - 1)));
    // else eventMargin = windowSize.height - (109 + (178 * data.events.length) + (24 * (data.events.length - 1)));
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        pageName: t('대회 정보'),
        sectionY: parseInt(140 + finalSize),
        // sectionY: parseInt(finalSize),
        isAvailableGoback: true
      }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }

    setButtonTopMargin(eventMargin > 36 ? eventMargin : 36);
    setProfileSize(finalSize);
    setTitleFontSize(Math.round((20 * windowSize.width) / 414));
    setEventTitleFontSize(Math.round((17 * windowSize.width) / 414));
  }, [windowSize, data])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    let totalVideoWidth = windowSize.width * 0.9033;
    let totalVideoHeight = totalVideoWidth * 0.5614;

    let modalVideoWidth = windowSize.width;
    let modalVideoHeight = modalVideoWidth * 0.5603;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])

  // Window Resize Handler //
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  // Window Scroll Handler //
  function handleScroll(e) {
    if ((window.scrollY + 35) >= SectionRef.current.offsetTop && SectionRef.current.offsetTop !== 0) {
      setIsFixed(true);
      setSectionY(SectionRef.current.offsetTop);
    }
    else if ((window.scrollY + 35) < sectionY && SectionRef.current.offsetTop === 0) setIsFixed(false);
    else if (((window.scrollY + 35) + SectionRef.current.clientHeight) >= EventRef.current.offsetTop) setScrollDivision('event');
    else if (((window.scrollY + 35) + SectionRef.current.clientHeight) < EventRef.current.offsetTop) setScrollDivision('info');
  }

  // React Native에서 웹으로 보내는 데이터 handler //
  async function handleMassage(e) {

    // 언어 정보 받아오기 //
    let language = JSON.parse(e.data).language;
    // alert("language : " + language);
    if (language === 'Korea') {
      changeLanguageToKo();
      setLanguage(language);

      window.localStorage.setItem('Language', 'Korea');

      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          pageName: '대회 정보',
        }));
      }
      catch (e) {
        console.log("Web Message Post Error : " + e);
      }
    }
    else if (language === 'English') {
      changeLanguageToEn();
      setLanguage(language);

      window.localStorage.setItem('Language', 'English');

      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          pageName: 'Event Infomation',
        }));
      }
      catch (e) {
        console.log("Web Message Post Error : " + e);
      }
    }

    let nowScrollY = JSON.parse(e.data).scrollY;
    let nowSectionY = JSON.parse(e.data).sectionY;
    let nowIsFixed = JSON.parse(e.data).isFixed;

    if (nowScrollY >= SectionRef.current.offsetTop && !nowIsFixed) {
      setIsFixed(true);
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: true, sectionY: parseInt(SectionRef.current.offsetTop) }));
    }
    else if (nowScrollY < nowSectionY && nowIsFixed) {
      setIsFixed(false);
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: false, sectionY: parseInt(SectionRef.current.offsetTop) }));
    }
    else if ((nowScrollY + SectionRef.current.clientHeight) >= EventRef.current.offsetTop) setScrollDivision('event');
    else if ((nowScrollY + SectionRef.current.clientHeight) < EventRef.current.offsetTop) setScrollDivision('info');
    else {
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: nowIsFixed, scrollY: nowScrollY, sectionY: nowSectionY }));
    }
  }

  // 대회내용 버튼 클릭시 //
  function onClickInfoButton() {
    setScrollDivision('info');

    if (SectionRef.current.offsetTop === 0) window.scrollTo({ top: (InfoRef.current.offsetTop - SectionRef.current.clientHeight + 12), behavior: 'smooth' });
    else window.scrollTo({ top: (SectionRef.current.offsetTop - SectionRef.current.clientHeight + 24), behavior: 'smooth' });
  }

  // 참가 종목 버튼 클릭시 //
  function onClickEventButton() {
    setScrollDivision('event');

    window.scrollTo({ top: EventRef.current.offsetTop - SectionRef.current.clientHeight, behavior: 'smooth' });
  }

  // 참가신청 바로가기 버튼 클릭시 //
  function onClickAppication() {
    if (data.applicationEndDate < moment().format('YYYY-MM-DDTHH:mm:ss')) {
      alert(t('참가신청 가능 기간이 지났습니다'));
      return;
    }
    else if (data.applicationStartDate > moment().format('YYYY-MM-DDTHH:mm:ss')) {
      alert(t('참가신청 가능 기간이 아닙니다'));
      return;
    }
    else {
      alert('참가 신청 인원이 초과되었습니다.');
      return;
      // navigate('/agree/believer');
    }
    // else alert('준비중입니다');
  }

  // 신청 정보 수정 버튼 클릭시 //
  function onClickModifyInfo() {
    const now = new Date(moment());
    const modifyDate = new Date(availableModifyDate);

    // 수정 가능날짜 확인 //
    if (modifyDate > now) navigate('/application/' + eventId + '/' + false + '/' + true);
    else alert(t('수정 가능 기간이 지났습니다'));
  }

  // 참가 취소 버튼 클릭시 //
  function onClickCancellation() {
    if (!isAuthority) {
      alert('신청 취소 권한이 없습니다.');
      return;
    }

    setIsClickedCancellation(true);
  }

  // 참가 취소 경고모달에서 취소버튼 클릭시 //
  function onClickCancellationYes() {
    setIsClickedCancellation(false);
    setIsViewCancellationInfoModal(true);
    // setIsLoading(true);
    // setIsClickedCancellation(false);
    
    // let requestData = {
    //   merchantUid: merchantUid
    // }

    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.postDataOfEvent('sales/cancellation', 'POST', requestData);
        
    //     if (responseData.status === 200) {
    //       window.localStorage.removeItem('EventData');

    //       setTimeout(() => setIsLoading(false), 1000);

    //       try {
    //         window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
    //       }
    //       catch (e) {
    //         console.log("Web Message Post Error : " + e);
    //       }
    //     }
    //     else if (responseData.status === 204) {
    //       setTimeout(() => setIsLoading(false), 1000);

    //       alert(t("이미 취소된 결제건 입니다."));
    //     }
    //     else if (responseData.status === 401) {
    //       setTimeout(() => setIsLoading(false), 1000);

    //       alert(t('동일한 결제자가 아닙니다.\n참가 취소는 결제자만 가능합니다.'));
    //     }
    //     else if (responseData.status === 402) {
    //       setTimeout(() => setIsLoading(false), 1000);

    //       alert(t('완료된 결제건이 아닙니다.'));
    //     }
    //     else if (responseData.status === 403) {
    //       setTimeout(() => setIsLoading(false), 1000);

    //       alert(t('환불 가능기간이 지났습니다.'));
    //     }
    //   }
    // )();
  }

  // 계좌내역 환불진행 클릭시 //
  function onClickRefund() {
    
    if (depositor === '') {
      alert('입금자 명을 입력해주세요');
      return;
    }
    else if (bank === '') {
      alert('환불받을 계좌의 은행을 입력해주세요');
      return;
    }
    else if (accountNum == '') {
      alert('환불받을 계좌번호를 입력해주세요');
      return;
    }

    setIsLoading(true);

    let requestData = {
      merchantUid: merchantUid,
      depositor: depositor,
      bank: bank,
      accountNum: accountNum
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('refund/application', 'POST', requestData);
        
        if (responseData.status === 200) {
          alert('취소 신청이 완료되었습니다');
          setIsViewCancellationInfoModal(false);
          setTimeout(() => setIsLoading(false), 1000);

          try {
            window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
          }
          catch (e) {
            console.log("Web Message Post Error : " + e);
          }
        }
        else if (responseData.status === 201) {
          setTimeout(() => setIsLoading(false), 1000);

          alert('올바르지 않은 결제 내역입니다');
        }
        else if (responseData.status === 204) {
          setTimeout(() => setIsLoading(false), 1000);

          alert('취소 권한이 없습니다.');
        }
      }
    )();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {isLoading && <LoadingAnimation />}
      {isClickedCancellation && <CancellationModal yesFn={onClickCancellationYes} closeFn={() => setIsClickedCancellation(false)} />}
      { isViewCancellationInfoModal && <CancellationInfoModal
        depositor={depositor}
        setDepositor={setDepositor}
        bank={bank}
        setBank={setBank}
        accountNum={accountNum}
        setAccountNum={setAccountNum}
        yesFn={onClickRefund}
        closeFn={() => setIsViewCancellationInfoModal(false)}/>}
      <ComponentWrapper>
        <ImageWrapper>
          <Image src={data.maintenanceRateProfile} width={profileSize} height={profileSize} />
        </ImageWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={titleFontSize} color='#4F4F4F'>{data.title}</Text>
        </TitleWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={SectionRef} isFixed={isFixed} style={{ backgroundColor: '#F9F9F9' }}>
        <SectionTotalWrapper>
          <SectionWrapper onClick={onClickInfoButton}>
            <SectionTextBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('대회 내용')}</Text>
            </SectionTextBox>
            <SectionDivisionLine isClicked={scrollDivision === 'info'} />
          </SectionWrapper>
          <SectionWrapper onClick={onClickEventButton}>
            <SectionTextBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('참가 종목')}</Text>
            </SectionTextBox>
            <SectionDivisionLine isClicked={scrollDivision === 'event'} />
          </SectionWrapper>
        </SectionTotalWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={InfoRef} style={{ marginTop: `0px` }}>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
          <iframe
            width='100%'
            height='100%'
            src={`https://www.youtube.com/embed/Ak30gykpo_c`}
            style={{ borderRadius: '12px' }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube" />
        </TotalVideoBox>
        <InfoTotalWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 이름')}</Text>
            {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.title}</Text> */}
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>컴피티션 빌리버 (Believer)</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px`, gap: '4px' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 내용')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>TRUST YOUR TEAM? </Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>ENUF 컴피티션은 팀원 간에 믿음을 확인할 수 있는 대회 입니다.</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>3인 1팀으로 구성되며, 예선과 본선 이벤트 모두</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>여러분의 '믿음'을 확인 할 수 있는 와드로 짜여집니다.</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>여러분의 팀워크와 서로를 의지할 수 있다는 믿음을</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>ENUF 컴피티션에서 보여주세요!</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px`}}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>대회 기간</Text>
            {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>2024년 5월 10일 ~ 20일 (온라인예선)</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>2024년 6월 29일 (오프라인 본선)</Text> */}
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>온라인 예선 대회 : 2024년 4월 29일(월) ~ 2024년 5월 17일(금)</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>오프라인 본선 : 2024년 5월 25일(토)</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('신청 기간')}</Text>
            <InfoInnerWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>3월 15일(금) ~ 3월 30일(토) 23:59</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>- 얼리버드 기간 : 3월 15일(금) ~ 3월 22일(금) 23:59</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>- 얼리버드 참가비 : 105,000원 (1인 35,000원)</Text>
              {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('신청 정보 수정은') + ' ' + moment(data.availableModifyDate).format('YYYY.MM.DD') + ' ' + t('까지 가능합니다.')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('접수 취소는') + ' ' + moment(data.availableDeleteDate).format('YYYY.MM.DD') + ' ' + t('까지 가능합니다.')}</Text> */}
            </InfoInnerWrapper>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>대회 장소</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>예선: 온라인</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>본선: 티어원그라운드 (인천 십정동)</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('주관사')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.subjectivity}</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('후원사')}</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.sponsor}</PreText>
          </InfoWrapper>
          {
            data.support !== 'None' &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('협찬사')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.support}</Text>
            </InfoWrapper>
          }
          {
            data.host !== 'None' &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('주최')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.host}</Text>
            </InfoWrapper>
          }
        </InfoTotalWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={EventRef} style={{ paddingTop: `36px` }}>
        {
          data.events !== undefined &&
          <EventWrapper>
            {
              data.events.map((data, index) => (
                // <EventBox key={index} data={data} index={index} language={language} eventTitleFontSize={eventTitleFontSize} />
                <EventBox key={index} data={data} index={index} language={language} eventTitleFontSize={eventTitleFontSize} />
              ))
            }
          </EventWrapper>
        }
        {/* {
          isAlreadyPayment ?
            <ButtonWrapper marginTop={buttonTopMargin}>
              <ModifyButton onClick={onClickModifyInfo}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#F9F9F9'>{t('수정')}</Text>
              </ModifyButton>
              <CancelButton onClick={onClickCancellation}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#F9F9F9'>{t('참가 취소')}</Text>
              </CancelButton>
            </ButtonWrapper>
            : */}
            <Button marginTop={buttonTopMargin} onClick={onClickAppication}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#F9F9F9'>{t('참가 신청 바로 가기')}</Text>
            </Button>
        {/* } */}
      </ComponentWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
  // height: 100vh;

  background-color: #F9F9F9;
  // background-color: #FFFFFF;
`;

const EmptyWrapper  = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 85%;
`;

const EmptyImage = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const ComponentWrapper = styled.div`
  position: ${props => props.isFixed ? 'fixed' : 'static'};
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: #F9F9F9;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  
  margin-top: 30px;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;

  margin-top: 12px;
`;

const SectionTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  // margin-top: 28px;
  margin-top: 18px;


  :hover {
    cursor: pointer;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 35px;
`;

const SectionTextBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 33px;
`;

const SectionDivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3px;

  background-color: ${props => props.isClicked ? '#FF8B48' : '#D9D9D9'};

  transition: all 0.3s ease-in-out;
`;

const InfoTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const InfoInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PreText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  white-space: pre-line;

  :hover {
    cursor: default;
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 60px;

  margin-top: ${props => props.marginTop}px;
  margin-bottom: 27px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
  
  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 93.23%;

  margin-top: ${props => props.marginTop}px;
  margin-bottom: 27px;
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.44%;
  height: 60px;

  border-radius: 12px;
  background-color: #E0E0E0;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 48.44%;
  width: 100%;
  height: 60px;

  border-radius: 12px;
  background-color: #FF3131;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;