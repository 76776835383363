import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment, { invalid } from 'moment';

import FetchModule from '../../Model/Network/FetchModule';

import CrownIcon from '../../Image/CrownIcon.png';
import FirstPlaceImg from '../../Image/FirstPlace.png';
import SecondPlaceImg from '../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../Image/ThirdPlace.png';
import RightImg from '../../Image/NotAvailableRight.png';
import NoneProfile from '../../Image/NoneProfile.png';
import WhiteMale from '../../Image/WhiteMale.png';
import ColorMale from '../../Image/ColorMale.png';
import WhiteFemale from '../../Image/WhiteFemale.png';
import ColorFemale from '../../Image/ColorFemale.png';

import { useTranslation } from 'react-i18next';

export default function GirlFitIndividualRanking() {

  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [nowSection, setNowSection] = useState('A');
  // const [isClickedGender, setIsClickedGender] = useState(window.localStorage.getItem('GenderDivision') === null ? 'female' : window.localStorage.getItem('GenderDivision'));

  // const [data, setData] = useState([]);
  const [myRanking, setMyRanking] = useState(undefined);
  const [responseRankingList, setResponseRankingList] = useState([]);
  const [rankingList, setRankingList] = useState([]);

  const [isClickedMyRank, setIsClickedMyRank] = useState(false);
  const [myRankTotalHeight, setMyRankTotalHeight] = useState(64);
  const [myRankHeight, setMyRankHeight] = useState(62);

  const [isLoading, setIsLoading] = useState(false);


  const [event1MyRank, setEvent1MyRank] = useState(undefined);
  const [event2MyRank, setEvent2MyRank] = useState(undefined);
  const [event3MyRank, setEvent3MyRank] = useState(undefined);
  const [event4MyRank, setEvent4MyRank] = useState(undefined);
  const [event5MyRank, setEvent5MyRank] = useState(undefined);
  const [event6MyRank, setEvent6MyRank] = useState(undefined);
  const [totalMyRank, setTotalMyRank] = useState(undefined);

  const [event1RankingList, setEvent1RankingList] = useState([]);
  const [event2RankingList, setEvent2RankingList] = useState([]);
  const [event3RankingList, setEvent3RankingList] = useState([]);
  const [event4RankingList, setEvent4RankingList] = useState([]);
  const [event5RankingList, setEvent5RankingList] = useState([]);
  const [event6RankingList, setEvent6RankingList] = useState([]);
  const [totalRankingList, setTotalRankingList] = useState([]);


  // Ranking Info API CALL //
  useEffect(() => {
    if (nowSection === 'Total') return;

    setIsLoading(true);
    setIsClickedMyRank(false);

    let requestData = {
      eventId: 22,
      section: nowSection
    }

    const fetchModule = new FetchModule();
    (
      async () => {

        const responseData = await fetchModule.postDataOfEvent('score/rank/girlfit', 'POST', requestData);
        
        // Rx로 구분 //
        if (responseData.status === 200) {
          let rankData = responseData.data.eventRankList.slice();
          let myRank = responseData.data.myRankData;
          let totalRankData = [];

          let validRankData = [];
          let validTimeRankData = [];
          let validRepsRankData = [];
          let scaleValidRankData = [];
          let scaleValidTimeRankData = [];
          let scaleValidRepsRankData = [];
          let invalidRankData = [];

          // 순위 데이터 정리 //
          for (let i = 0; i < rankData.length; i++) { 
            if (nowSection === 'A') {
              // RXD 구분 //
              if (rankData[i].memberEventId === 22) {
                if (rankData[i].time !== 0) validTimeRankData.push(rankData[i]);
                else if (rankData[i].completeRep !== 0) validRepsRankData.push(rankData[i]);
                else invalidRankData.push(rankData[i]);
              }
              // SCALE 구분 //
              else {
                if (rankData[i].time !== 0) scaleValidTimeRankData.push(rankData[i]);
                else if (rankData[i].completeRep !== 0) scaleValidRepsRankData.push(rankData[i]);
                else invalidRankData.push(rankData[i]);
              }
            }
            else if (nowSection === 'B') {
              // RXD 구분 //
              if (rankData[i].memberEventId === 22) {
                if (rankData[i].firstExercise !== 0) validRankData.push(rankData[i]);
                else invalidRankData.push(rankData[i]);
              }
              // SCALE 구분 //
              else {
                if (rankData[i].firstExercise !== 0) scaleValidRankData.push(rankData[i]);
                else invalidRankData.push(rankData[i]);
              }
            }
          }

          if (nowSection === 'A') {
            // RXD 데이터 순위 정렬 //
            validTimeRankData.sort((a, b) => a.time - b.time || a.tieBreak - b.tieBreak);
            validRepsRankData.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);
  
            // SCALE 데이터 순위 정렬 //
            scaleValidTimeRankData.sort((a, b) => a.time - b.time || a.tieBreak - b.tieBreak);
            scaleValidRepsRankData.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);

            scaleValidRankData = scaleValidTimeRankData.concat(scaleValidRepsRankData);
            validRankData = validTimeRankData.concat(validRepsRankData);
            validRankData = validRankData.concat(scaleValidRankData);
            
            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0 
                && validRankData[i].time !== 0
                && validRankData[i].time === validRankData[i - 1].time
                && validRankData[i].tieBreak === validRankData[i - 1].tieBreak) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else if (i !== 0 
                && validRankData[i].time === 0
                && validRankData[i].completeRep !== 0
                && validRankData[i].completeRep === validRankData[i - 1].completeRep
                && validRankData[i].tieBreak === validRankData[i - 1].tieBreak){
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else validRankData[i].rank = (i + 1);
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;
            }

            totalRankData = validRankData.concat(invalidRankData);

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].userId === myRank.userId) {
                  myRank.rank = totalRankData[i].rank;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                time: 0,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              setMyRanking(myRank);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                completeRep: 0,
                tieBreak: 0
              }
            })

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'B') {
            console.log(validRankData);
            // RXD 데이터 순위 정렬 //
            validRankData.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);
            // SCALE 데이터 순위 정렬 //
            scaleValidRankData.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            validRankData = validRankData.concat(scaleValidRankData);

            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0 && validRankData[i].firstExercise === 0 || validRankData[i].tieBreak === 0) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else if (i !== 0
                && validRankData[i].firstExercise === validRankData[i - 1].firstExercise
                && validRankData[i].tieBreak === validRankData[i - 1].tieBreak) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else {
                validRankData[i].rank = (i + 1);
              }
            }

            for (let i = 0; i < invalidRankData.length; i++) {
              invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;
            }

            totalRankData = validRankData.concat(invalidRankData);

            // 내 순위 찾기
            if (myRank !== undefined) {

              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].userId === myRank.userId) {
                  myRank.rank = totalRankData[i].rank;
                  break;
                }
              }
              // Total Section 내 순위 에러 방지용 초기화 //
              myRank.event1 = {
                rank: -1,
                time: 0,
                completeRep: 0,
                tieBreak: 0
              }

              myRank.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }

              setMyRanking(myRank);
            }

            // Total Section 전체 순위 에러 방지용 초기화 //
            totalRankData.map((data) => {
              data.event1 = {
                rank: -1,
                completeRe: 0,
                tieBreak: 0
              }

              data.event2 = {
                rank: -1,
                firstExercise: 0,
                tieBreak: 0
              }
            })

            setResponseRankingList(totalRankData);
          }
        }

        // setIsLoading(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    )();
  }, [params, nowSection])

  // 전체 순위 산출일 경우 //
  useEffect(() => {
    if (nowSection !== 'Total') return;

    setIsLoading(true);
    setIsClickedMyRank(false);

    let requestData = {
      eventId: 22
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('score/rank/total_girlfit/individual', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let myRankData = responseData.data.myRankData;
          let event1 = responseData.data.event1.slice();
          let event2 = responseData.data.event2.slice();

          // Event 1 순위 산출 //
          let event1ValidRank = [];
          let event1TimeValidRank = [];
          let event1RepsValidRank = [];
          let event1ScaleValidRank = [];
          let event1ScaleTimeValidRank = [];
          let event1ScaleRepsValidRank = [];
          let event1InvalidRank = [];
          let event1TotalRank = [];

          event1.map((data) => {
            if (data.memberEventId === 22) {
              if (data.time !== 0) event1TimeValidRank.push(data);
              else if (data.completeRep !== 0) event1RepsValidRank.push(data);
              else event1InvalidRank.push(data);
            }
            else {
              if (data.time !== 0) event1ScaleTimeValidRank.push(data);
              else if (data.completeRep !== 0) event1ScaleRepsValidRank.push(data);
              else event1InvalidRank.push(data);
            }
          })

          // 데이터 순위 정렬 //
          event1TimeValidRank.sort((a, b) => a.time - b.time || a.tieBreak - b.tieBreak);
          event1RepsValidRank.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);

          // 데이터 순위 정렬 //
          event1ScaleTimeValidRank.sort((a, b) => a.time - b.time || a.tieBreak - b.tieBreak);
          event1ScaleRepsValidRank.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);

          event1ScaleValidRank = event1ScaleTimeValidRank.concat(event1ScaleRepsValidRank);
          event1ValidRank = event1TimeValidRank.concat(event1RepsValidRank);
          event1ValidRank = event1ValidRank.concat(event1ScaleValidRank);

          // 올바르지 않은 데이터는 이름순 정렬 //
          event1InvalidRank.sort(function (a, b) {
            let x = a.name;
            let y = b.name;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          for (let i = 0; i < event1ValidRank.length; i++) {
            if (i !== 0
              && event1ValidRank[i].time !== 0
              && event1ValidRank[i].time === event1ValidRank[i - 1].time
              && event1ValidRank[i].tieBreak === event1ValidRank[i - 1].tieBreak) {
              event1ValidRank[i].rank = event1ValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event1ValidRank[i].time === 0
              && event1ValidRank[i].completeRep !== 0
              && event1ValidRank[i].completeRep === event1ValidRank[i - 1].completeRep
              && event1ValidRank[i].tieBreak === event1ValidRank[i - 1].tieBreak) {
              event1ValidRank[i].rank = event1ValidRank[i - 1].rank;
            }
            else event1ValidRank[i].rank = (i + 1);
          }

          for (let i = 0; i < event1InvalidRank.length; i++) {
            event1InvalidRank[i].rank = event1ValidRank.length + event1InvalidRank.length - 1;
          }

          event1TotalRank = event1ValidRank.concat(event1InvalidRank);
          setEvent1RankingList(event1ValidRank);
          // Event 1 순위 산출 //


          // Event 2 순위 산출 //
          let event2ValidRank = [];
          let event2ScaleValidRank = [];
          let event2InvalidRank = [];
          let event2TotalRank = [];

          event2.map((data) => {
            // if (data.firstExercise === 0) event2InvalidRank.push(data);
            // else event2ValidRank.push(data);
            if (data.memberEventId === 22) {
              if (data.firstExercise !== 0) event2ValidRank.push(data);
              else event2InvalidRank.push(data);
            }
            else {
              if (data.firstExercise !== 0) event2ScaleValidRank.push(data);
              else event2InvalidRank.push(data);
            }
          })

          // 데이터 순위 정렬 //
          event2ValidRank.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);
          event2ScaleValidRank.sort((a, b) => b.firstExercise - a.firstExercise || a.tieBreak - b.tieBreak);

          // 올바르지 않은 데이터는 이름순 정렬 //
          event2InvalidRank.sort(function (a, b) {
            let x = a.name;
            let y = b.name;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          event2ValidRank = event2ValidRank.concat(event2ScaleValidRank);

          // 남성 순위 산출 //
          for (let i = 0; i < event2ValidRank.length; i++) {
            if (i !== 0
              && event2ValidRank[i].firstExercise === 0
              && event2ValidRank[i].tieBreak === 0) {
              event2ValidRank[i].rank = event2ValidRank[i - 1].rank;
            }
            else if (i !== 0
              && event2ValidRank[i].firstExercise === event2ValidRank[i - 1].firstExercise
              && event2ValidRank[i].tieBreak === event2ValidRank[i - 1].tieBreak) {
              event2ValidRank[i].rank = event2ValidRank[i - 1].rank;
            }
            else {
              event2ValidRank[i].rank = (i + 1);
            }
          }

          event2TotalRank = event2ValidRank.concat(event2InvalidRank);

          setEvent2RankingList(event2ValidRank);
          // Event 2 순위 산출 //

          let totalRank = [];
          // Event1 데이터 정리 //
          for (let i = 0; i < event1TotalRank.length; i++) {
            totalRank.push({
              gender: event1TotalRank[i].gender,
              name: event1TotalRank[i].name,
              profile: event1TotalRank[i].profile,
              members: event1TotalRank[i].members,
              userId: event1TotalRank[i].userId,
              memberEventId: event1TotalRank[i].memberEventId,
              event1: {
                rank: event1TotalRank[i].rank,
                time: event1TotalRank[i].time,
                completeRep: event1TotalRank[i].completeRep,
                tieBreak: event1TotalRank[i].tieBreak
              },
              event2: {
                rank: 0,
                firstExercise: 0,
                tieBreak: 0
              }
            })
          }
          // Event1 데이터 정리 //

          // Event 2 데이터 정리 //
          for (let i = 0; i < event2TotalRank.length; i++) {
            let isExist = false;

            for (let j = 0; j < totalRank.length; j++) {
              if (totalRank[j].userId === event2TotalRank[i].userId) {
                totalRank[j].event2.rank = event2TotalRank[i].rank;
                totalRank[j].event2.firstExercise = event2TotalRank[i].firstExercise;
                totalRank[j].event2.tieBreak = event2TotalRank[i].tieBreak;

                isExist = true;
                break;
              }
            }

            // 데이터가 삽입되어있지 않은 경우 //
            if (!isExist) {
              totalRank.push({
                gender: event2TotalRank[i].gender,
                name: event2TotalRank[i].name,
                profile: event2TotalRank[i].profile,
                members: event2TotalRank[i].members,
                userId: event2TotalRank[i].userId,
                memberEventId: event2TotalRank[i].memberEventId,
                event1: {
                  rank: 0,
                  time: 0,
                  completeRep: 0,
                  tieBreak: 0
                },
                event2: {
                  rank: event2TotalRank[i].rank,
                  firstExercise: event2TotalRank[i].firstExercise,
                  tieBreak: event2TotalRank[i].tieBreak
                }
              })
            }
          }
          // Event 2 데이터 정리 //

          // 최종 순위 정리 //
          let finalTotalRank = [];

          // console.log(totalRank);
          for (let i = 0; i < totalRank.length; i++) {
            if (totalRank[i].event1.rank === 0) {
              totalRank[i].event1.rank = event1ValidRank.length + 1;
            }

            if (totalRank[i].event2.rank === 0) {
              totalRank[i].event2.rank = event2ValidRank.length + 1;
            }
          }

          totalRank.map((data) => {
            data.rank = -1;
            data.score = (data.event1.rank + data.event2.rank)
          })

          // 최종 순위 정렬 //
          totalRank.sort((a, b) => a.score - b.score);

          // 순위 산출 //
          for (let i = 0; i < totalRank.length; i++) {
            if (i !== 0 && totalRank[i].score === 0) {
              totalRank[i].rank = totalRank[i - 1].rank;
            }
            else if (i !== 0
              && totalRank[i].score === totalRank[i - 1].score) {
              totalRank[i].rank = totalRank[i - 1].rank;
            }
            else {
              totalRank[i].rank = (i + 1);
            }
          }

          finalTotalRank = totalRank;

          if (myRankData !== undefined) {
            // Event 1 내순위 찾기 //
            for (let i = 0; i < event1TotalRank.length; i++) {
              if (event1TotalRank[i].userId === myRankData.userId) {
                setEvent1MyRank(event1TotalRank[i]);
                break;
              }
            }

            // Event 2 내순위 찾기 //
            for (let i = 0; i < event2TotalRank.length; i++) {
              if (event2TotalRank[i].userId === myRankData.userId) {
                setEvent2MyRank(event2TotalRank[i]);
                break;
              }
            }

            // Total 내 순위 찾기 //
            for (let i = 0; i < finalTotalRank.length; i++) {
              if (finalTotalRank[i].userId === myRankData.userId) {
                setMyRanking(finalTotalRank[i]);
                break;
                // setTotalMyRank(finalTotalRank[i]);
                // break;
              }
              finalTotalRank[i].isClicked = false;
            }
          }
        
          // setTotalRankingList(finalTotalRank);
          setResponseRankingList(finalTotalRank);
        }
      }
    )();
  }, [nowSection])

  // // 섹션 선택에 따른 정보 변환 //
  // useEffect(() => {
  //   setIsLoading(true);

  //   if (nowSection === 'A') {
  //     setMyRanking(event1MyRank);
  //     // setRankingList(event1RankingList);
  //     setResponseRankingList(event1RankingList);
  //   }
  //   else if (nowSection === 'B') {
  //     setMyRanking(event2MyRank);
  //     // setRankingList(event2RankingList);
  //     setResponseRankingList(event2RankingList);
  //   }
  //   else if (nowSection === 'C') {
  //     setMyRanking(event3MyRank);
  //     // setRankingList(event3RankingList);
  //     setResponseRankingList(event3RankingList);
  //   }
  //   else if (nowSection === 'D') {
  //     setMyRanking(event4MyRank);
  //     // setRankingList(event4RankingList);
  //     setResponseRankingList(event4RankingList);
  //   }
  //   else if (nowSection === 'Total') {
  //     setMyRanking(totalMyRank);
  //     // setRankingList(totalRankingList);
  //     setResponseRankingList(totalRankingList);
  //   }
  // }, [nowSection, event1MyRank, event2MyRank, event3MyRank, event4MyRank, totalMyRank, event1RankingList, event2RankingList, event3RankingList, event4RankingList, totalRankingList])

  // 성별 구분으로 정리하기 //
  useEffect(() => {
    setIsLoading(true);

    // let finalList = [];

    // for (let i = 0; i < responseRankingList.length; i++) {
    //   if (responseRankingList[i].gender === isClickedGender) finalList.push(responseRankingList[i]);
    // }

    // setRankingList(finalList);

    setRankingList(responseRankingList);

    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [responseRankingList])

  // 내 순위 자세히 보기에 따른 컴포넌트 크기 변환 //
  useEffect(() => {
    // if (isClickedMyRank && nowSection === 'Total') setMyRankTotalHeight(244);
    // if (isClickedMyRank && nowSection === 'Total') setMyRankTotalHeight(366);
    if (isClickedMyRank && nowSection === 'Total') setMyRankTotalHeight(152);
    else setMyRankTotalHeight(64);
  }, [isClickedMyRank, myRanking, nowSection])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('순위표'), isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // React Native에서 웹으로 보내는 데이터 handler //
  async function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) {
      await window.localStorage.removeItem('GenderDivision');
      navigate(-1);
    }
  }

  // 내순위 팀 구성원 자세히보기 클릭시 //
  function onClickMyRecordDetailButton() {
    if (nowSection !== 'Total') return;

    let data = rankingList.slice();

    for (let i = 0; i < data.length; i++) {
      data[i].isClicked = false;
    }

    setRankingList(data);
    setIsClickedMyRank(!isClickedMyRank);
  }

  // 팀 구성원 자세히보기 클릭시 //
  function onClickRecordDetailButton(index) {
    if (nowSection !== 'Total') return;

    let data = rankingList.slice();

    for (let i = 0; i < data.length; i++) {
      if (i !== index) data[i].isClicked = false;
    }

    data[index].isClicked = !data[index].isClicked;

    setIsClickedMyRank(false);
    setRankingList(data);
  }

  // // 성별 구분 보기 변경 버튼 클릭시 //
  // async function onClickChangeGenderToFemale() {
  //   await window.localStorage.setItem('GenderDivision', 'female');
  //   setIsClickedGender('female');
  // }

  // // 성별 구분 보기 변경 버튼 클릭시 //
  // async function onClickChangeGenderToMale() {
  //   await window.localStorage.setItem('GenderDivision', 'male');
  //   setIsClickedGender('male');
  // }

  // 걸린시간 분/초로 계산 //
  function calcTime(data) {
    if (nowSection === 'A') {
      let time = '';
      let tieBreak = '';

      if (data.time === 0 && data.completeRep === 0) return '미제출';
      else if (data.time !== 0) {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.time % 1).toFixed(2);

        min = parseInt(data.time / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.time % 60);

        if (sec === 0) {
          if (min < 10) time = '0' + min + ':00';
          else time = min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) time = min + ':0' + sec;
            else time = min + ':' + sec;
          }
          else {
            if (sec < 10) time = min + ':0' + sec;
            else time = min + ':' + sec;
          }
        }

        let tmin = 0;
        let tsec = 0;
        let tdecimalPoint = (data.tieBreak % 1).toFixed(2);

        tmin = parseInt(data.tieBreak / 60);

        if (tdecimalPoint !== '0.00') tsec = parseInt(data.tieBreak % 60) + parseFloat(tdecimalPoint);
        else tsec = parseInt(data.tieBreak % 60);

        if (tsec === 0) {
          if (tmin < 10) tieBreak = '0' + tmin + ':00';
          else tieBreak = tmin + ':00';
        }
        else {
          if (tmin < 10) {
            if (tsec < 10) tieBreak = '0' + tmin + ':0' + tsec;
            else tieBreak = '0' + tmin + ':' + tsec;
          }
          else {
            if (tsec < 10) tieBreak = tmin + ':0' + tsec;
            else tieBreak = tmin + ':' + tsec;
          }
        }

        if (tieBreak === '00:00') return time;
        return time + ' - Tie Break ' + tieBreak;
      }
      else {
        let tmin = 0;
        let tsec = 0;
        let tdecimalPoint = (data.tieBreak % 1).toFixed(2);

        tmin = parseInt(data.tieBreak / 60);

        if (tdecimalPoint !== '0.00') tsec = parseInt(data.tieBreak % 60) + parseFloat(tdecimalPoint);
        else tsec = parseInt(data.tieBreak % 60);

        if (tsec === 0) {
          if (tmin < 10) tieBreak = '0' + tmin + ':00';
          else tieBreak = tmin + ':00';
        }
        else {
          if (tmin < 10) {
            if (tsec < 10) tieBreak = '0' + tmin + ':0' + tsec;
            else tieBreak = '0' + tmin + ':' + tsec;
          }
          else {
            if (tsec < 10) tieBreak = tmin + ':0' + tsec;
            else tieBreak = tmin + ':' + tsec;
          }
        }

        if (tieBreak === '00:00') return data.completeRep + ' REPS';
        else return data.completeRep + ' REPS - Tie Break ' + tieBreak;
      }
    }
    else if (nowSection === 'B') {
      if (data.firstExercise === 0) return '미제출';
      else {
        let tieBreak = '';
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.tieBreak % 1).toFixed(2);

        min = parseInt(data.tieBreak / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.tieBreak % 60);

        if (sec === 0) {
          if (min < 10) tieBreak = '0' + min + ':00';
          else tieBreak = min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) tieBreak = '0' + min + ':0' + sec;
            else tieBreak = '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) tieBreak = min + ':0' + sec;
            else tieBreak = min + ':' + sec;
          }
        }

        if (tieBreak === '00:00') return data.firstExercise + ' lb';
        else return data.firstExercise + 'lb - Tiebreak ' + tieBreak;
      }
    }
    else if (nowSection === 'Total') {
      return data.score + ' Point'
    }
  }

  // 걸린시간 분/초로 계산의 단위 정리 //
  function calcTimeUnit(data) {
    if (nowSection === 'A') {
      if (data.section === 'A1') return '';
      else if (data.section === 'A2') return '';
      else if (data.section === 'A3') {
        if (data.time !== 0) return '';
        else return 'REPS';
      }
    }
    else if (nowSection === 'B') {}
    else if (nowSection === 'C') {
      if (data.completeRep !== 0) return 'REPS';
      else return '';
    }
    else if (nowSection === 'D') {
      if (data.completeRep !== 0) return 'REPS';
      else return '';
    }
  }

  // Tie Break 계산 //
  function calcTieBreak(data) {
    if (nowSection === 'A' || nowSection === 'B' || nowSection === 'Total') {
      if (data.tieBreak === 0 || data.tieBreak === undefined || data.completeRep === 0) return '';
      else {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.tieBreak % 1).toFixed(2);

        min = parseInt(data.tieBreak / 60);
        // sec = data.tieBreak % 60;

        if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.tieBreak % 60);


        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return '0' + min + ':' + '0' + sec;
            else return '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':' + '0' + sec;
            else return min + ':' + sec;
          }
        }
      }
    }
  }

  // 각 섹션별 올바른 데이터인지 확인 //
  function checkValidData(data) {
    // if ((nowSection === 'A' || nowSection === 'B') && data.completeRep === 0) return false;
    if ((nowSection === 'A' || nowSection === 'B') && data.rank === -1) return false;
    else if (nowSection === 'C' && data.firstExercise === 0) return false;
    else if (nowSection === 'D' && data.time === 0) return false;
    else if (nowSection === 'Total' && data.rank === undefined) return false;
    else return true;
  }


  // 클릭시 최종컴포넌트 늘어나는 크기 정하기 //
  function calcTotalComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    // if (data.isClicked && nowSection === 'Total') return 244;
    // if (data.isClicked && nowSection === 'Total') return 366;
    if (data.isClicked && nowSection === 'Total') return 152;
    else return 64;
  }

  // 최종 순위표 내의 순위 산출시 배경색 결정 //
  function selectTotalEventRankingBoxBackgroundColor(data) {
    if (nowSection === 'Total' && data === undefined) return '#E0E0E0';
    else if (data.rank === 1) return '#FEB501';
    else if (data.rank === 2) return '#94B1C1';
    else if (data.rank === 3) return '#F38D30';
    else return '#E0E0E0';
  }

  // 걸린시간 분/초로 계산 //
  function calcTotalTime(data) {

    if (data.time === 0) return '미제출';
    else {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (min === 0 && sec === 0) return '미제출';
      else {
        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return '0' + min + ':0' + sec;
            else return '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
        }
      }
    }
  }

  // Total 컬럼에서 A Section 데이터 보기 //
  function calcTotalEvent1View(data) {
    let time = '';
    let tieBreak = '';

    if (data.time === 0 && data.completeRep === 0) return '미제출';
    else if (data.time !== 0) {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (sec === 0) {
        if (min < 10) time = '0' + min + ':00';
        else time = min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) time = min + ':0' + sec;
          else time = min + ':' + sec;
        }
        else {
          if (sec < 10) time = min + ':0' + sec;
          else time = min + ':' + sec;
        }
      }

      let tmin = 0;
      let tsec = 0;
      let tdecimalPoint = (data.tieBreak % 1).toFixed(2);

      tmin = parseInt(data.tieBreak / 60);

      if (tdecimalPoint !== '0.00') tsec = parseInt(data.tieBreak % 60) + parseFloat(tdecimalPoint);
      else tsec = parseInt(data.tieBreak % 60);

      if (tsec === 0) {
        if (tmin < 10) tieBreak = '0' + tmin + ':00';
        else tieBreak = tmin + ':00';
      }
      else {
        if (tmin < 10) {
          if (tsec < 10) tieBreak = '0' + tmin + ':0' + tsec;
          else tieBreak = '0' + tmin + ':' + tsec;
        }
        else {
          if (tsec < 10) tieBreak = tmin + ':0' + tsec;
          else tieBreak = tmin + ':' + tsec;
        }
      }

      if (tieBreak === '00:00') return time;
      return time + ' - Tie Break ' + tieBreak;
    }
    else {
      let tmin = 0;
      let tsec = 0;
      let tdecimalPoint = (data.tieBreak % 1).toFixed(2);

      tmin = parseInt(data.tieBreak / 60);

      if (tdecimalPoint !== '0.00') tsec = parseInt(data.tieBreak % 60) + parseFloat(tdecimalPoint);
      else tsec = parseInt(data.tieBreak % 60);

      if (tsec === 0) {
        if (tmin < 10) tieBreak = '0' + tmin + ':00';
        else tieBreak = tmin + ':00';
      }
      else {
        if (tmin < 10) {
          if (tsec < 10) tieBreak = '0' + tmin + ':0' + tsec;
          else tieBreak = '0' + tmin + ':' + tsec;
        }
        else {
          if (tsec < 10) tieBreak = tmin + ':0' + tsec;
          else tieBreak = tmin + ':' + tsec;
        }
      }

      if (tieBreak === '00:00') return data.completeRep + ' REPS';
      else return data.completeRep + ' REPS - Tie Break ' + tieBreak;
    }
  }

  // Total 컬럼에서 B Section 데이터 보기 //
  function calcTotalEvent2View(data) {
    if (data.firstExercise === 0) return '미제출';
    else {
      let tieBreak = '';
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.tieBreak % 1).toFixed(2);

      min = parseInt(data.tieBreak / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.tieBreak % 60);

      if (sec === 0) {
        if (min < 10) tieBreak = '0' + min + ':00';
        else tieBreak = min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) tieBreak = '0' + min + ':0' + sec;
          else tieBreak = '0' + min + ':' + sec;
        }
        else {
          if (sec < 10) tieBreak = min + ':0' + sec;
          else tieBreak = min + ':' + sec;
        }
      }

      if (tieBreak === '00:00') return data.firstExercise + ' lb';
      else return data.firstExercise + 'lb - Tiebreak ' + tieBreak;
    }
  }

  // Total 컬럼에서 C Section 데이터 보기 //
  function calcTotalEvent3View(data) {
    if (data.time !== 0) {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (sec === 0) {
        if (min < 10) return '0' + min + ':00';
        else return min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
        else {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
      }
    }
    else if (data.completeRep !== 0) return data.completeRep + 'Reps';
    else return 'None';
  }

  // Total 컬럼에서 D Section 데이터 보기 //
  function calcTotalEvent4View(data) {
    if (data.time !== 0) {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (sec === 0) {
        if (min < 10) return '0' + min + ':00';
        else return min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
        else {
          if (sec < 10) return min + ':0' + sec;
          else return min + ':' + sec;
        }
      }
    }
    else if (data.completeRep !== 0) return data.completeRep + 'Reps';
    else return 'None';
  }

  // Total 컬럼에서 E, F Section 데이터 보기 //
  function calcTotalEvent5View(data) {
    // if (data.time !== 0) {
    //   let min = 0;
    //   let sec = 0;
    //   let decimalPoint = (data.time % 1).toFixed(2);

    //   min = parseInt(data.time / 60);

    //   if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
    //   else sec = parseInt(data.time % 60);

    //   if (sec === 0) {
    //     if (min < 10) return '0' + min + ':00';
    //     else return min + ':00';
    //   }
    //   else {
    //     if (min < 10) {
    //       if (sec < 10) return min + ':0' + sec;
    //       else return min + ':' + sec;
    //     }
    //     else {
    //       if (sec < 10) return min + ':0' + sec;
    //       else return min + ':' + sec;
    //     }
    //   }
    // }
    // else return 'None';
    if (data.distance === 0) return 'None';
    else return data.distance + 'm';
  }

  // Total 컬럼에서 E, F Section 데이터 보기 //
  function calcTotalEvent6View(data, gender) {
    if (gender === 'male') {
      if (data.completeRep === 0) return 'None';
      else return data.completeRep + ' Reps';
    }
    else {
      if (data.time !== 0) {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.time % 1).toFixed(2);
  
        min = parseInt(data.time / 60);
  
        if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.time % 60);
  
        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
        }
      }
      else return 'None';
    }
  }

  // 현재 섹션 수정시 //
  function onClickNowSection(data) {
    setIsLoading(true);
    setNowSection(data);
  }

  return (
    <TopLevelWrapper>
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#333333'>GirFit Individual Event</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        <SectionButton isClicked={nowSection === 'A'} onClick={() => onClickNowSection('A')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E1</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'B'} onClick={() => onClickNowSection('B')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E2</Text>
        </SectionButton>
        {/* <SectionButton isClicked={nowSection === 'E'} onClick={() => onClickNowSection('E')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>H1</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'C'} onClick={() => onClickNowSection('C')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E3</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'D'} onClick={() => onClickNowSection('D')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>E4</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'F'} onClick={() => onClickNowSection('F')}>
          <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>H2</Text>
        </SectionButton> */}
        <TotalSection isClicked={nowSection === 'Total'} onClick={() => onClickNowSection('Total')}>
          <CrownImage src={CrownIcon} />
        </TotalSection>
      </SectionButtonWrapper>
      <DivisionLine />
      {/* <GenderSelectWrapper>
        <GenderSelectBox isClicked={isClickedGender === 'female'} onClick={onClickChangeGenderToFemale}>
          <GenderImageWrapper>
            <GenderImage style={isClickedGender === 'female' ? { opacity: 1 } : { opacity: 0 }} src={ColorFemale} />
            <GenderImage style={isClickedGender === 'female' ? { position: 'absolute', opacity: 0 } : { position: 'absolute', opacity: 1 }} src={WhiteFemale} />
          </GenderImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color={isClickedGender === 'female' ? `#4F4F4F` : '#828282'}>{t('여성')}</Text>
        </GenderSelectBox>
        <GenderSelectBox isClicked={isClickedGender === 'male'} onClick={onClickChangeGenderToMale}>
          <GenderImageWrapper>
            <GenderImage style={isClickedGender === 'male' ? { opacity: 1 } : { opacity: 0 }} src={ColorMale} />
            <GenderImage style={isClickedGender === 'male' ? { position: 'absolute', opacity: 0 } : { position: 'absolute', opacity: 1 }} src={WhiteMale} />
          </GenderImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color={isClickedGender === 'male' ? `#4F4F4F` : '#828282'}>{t('남성')}</Text>
        </GenderSelectBox>
      </GenderSelectWrapper> */}
      {
        // (nowSection === 'E') ?
        <TotalWrapper>
          {
            (myRanking !== undefined) &&
            <TempWrapper>
              <TopLineWrapper>
                <TitleWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('내 순위')}</Text>
                </TitleWrapper>
              </TopLineWrapper>
              {
                isLoading ?
                  <RankingTotalWrapper>
                    <RankingLoadingView>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                    </RankingLoadingView>
                  </RankingTotalWrapper>
                  :
                  <RankingTotalWrapper>
                    <RankingViewWrapper height={myRankTotalHeight} onClick={() => onClickMyRecordDetailButton()}>
                      {
                        // checkValidData(myRanking) ?
                        <RankingNumberBoxWrapper>
                          {
                            (myRanking.rank > 0 && myRanking.rank < 4) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={myRanking.rank === 1 ? FirstPlaceImg : myRanking.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                        // :
                        // <RankingNumberBoxWrapper>
                        //   <RankingNumberBox>
                        //     <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>0</Text>
                        //   </RankingNumberBox>
                        // </RankingNumberBoxWrapper>
                      }
                      <RankingClickBoxWrapper>
                        <RankingClickBox>
                          <InfoBox>
                            <LeftWrapper>
                              <Profile src={myRanking.profile === '' ? NoneProfile : myRanking.profile} />
                              <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                <InnerInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{myRanking.name}</Text>
                                  {
                                    myRanking.memberEventId === 23 &&
                                    <ScaleBox>
                                      <Text fontFamily='Poppins-Medium' fontSize={12} color='#FFFFFF'>Scale</Text>
                                    </ScaleBox>
                                  }
                                </InnerInfoTextWrapper>
                                <RecordBox>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(myRanking)}</Text>
                                    {/* <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTimeUnit(myRanking)}</Text> */}
                                  </RecordTextWrapper>
                                  {/* <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#828282'>{(myRanking.tieBreak === 0 || myRanking.tieBreak === undefined || myRanking.completeRep === 0) ? `` : `TieBreak`}</Text>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#4F4F4F'>{calcTieBreak(myRanking)}</Text>
                                  </RecordTextWrapper> */}
                                </RecordBox>
                              </InfoTextWrapper>
                            </LeftWrapper>
                            {nowSection === 'Total' && <DetailButton isClicked={isClickedMyRank} src={RightImg} />}
                          </InfoBox>
                        </RankingClickBox>
                        {
                          nowSection === 'Total' &&
                          <TotalRecordWrapper isClicked={isClickedMyRank && nowSection === 'Total'}>
                            {/* Event 1 */}
                            <EventRowWrapper>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 13px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event1)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event1.rank === -1 ? '0' : myRanking.event1.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  (myRanking.event1.completeRep !== 0 && myRanking.event1.time !== 0) ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.event1.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(myRanking.event1)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent1View(myRanking.event1)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 1 */}
                            {/* Event 2 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event2)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event2.rank === -1 ? '0' : myRanking.event2.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  myRanking.event2.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.event2.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(myRanking.event2)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent2View(myRanking.event2)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 2 */}
                            {/* Event 3 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event3)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event3.rank === -1 ? '0' : myRanking.event3.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalEvent3View(myRanking.event3)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 3 */}
                            {/* Event 4 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT4</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event4)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event4.rank === -1 ? '0' : myRanking.event4.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalEvent4View(myRanking.event4)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 4 */}
                            {/* Event 5 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event5)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event5.rank === -1 ? '0' : myRanking.event5.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalEvent5View(myRanking.event5)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 5 */}
                            {/* Event 6 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event6)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event6.rank === -1 ? '0' : myRanking.event6.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalEvent6View(myRanking.event6, myRanking.gender)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 6 */}
                          </TotalRecordWrapper>
                        }
                      </RankingClickBoxWrapper>
                    </RankingViewWrapper>
                  </RankingTotalWrapper>
              }
            </TempWrapper>
          }
          <TopLineWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('전체 순위')}</Text>
            </TitleWrapper>
          </TopLineWrapper>
          {
            isLoading ?
              <RankingTotalWrapper>
                <RankingLoadingView>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                </RankingLoadingView>
              </RankingTotalWrapper>
              :
              <RankingTotalWrapper>
                {
                  rankingList.map((data, index) => (
                    <RankingViewWrapper key={index} height={calcTotalComponentHeight(data)} onClick={() => onClickRecordDetailButton(index)}>
                      {
                        // checkValidData(data) ?
                        <RankingNumberBoxWrapper>
                          {
                            (data.rank > 0 && data.rank < 4) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={data.rank === 1 ? FirstPlaceImg : data.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                        // :
                        // <RankingNumberBoxWrapper>
                        //   <RankingNumberBox>
                        //     <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>0</Text>
                        //   </RankingNumberBox>
                        // </RankingNumberBoxWrapper>
                      }
                      <RankingClickBoxWrapper>
                        <RankingClickBox>
                          <InfoBox>
                            <LeftWrapper>
                              <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                              <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                <InnerInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                  {
                                    data.memberEventId === 23 &&
                                    <ScaleBox>
                                      <Text fontFamily='Poppins-Medium' fontSize={12} color='#FFFFFF'>Scale</Text>
                                    </ScaleBox>
                                  }
                                </InnerInfoTextWrapper>
                                <RecordBox>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(data)}</Text>
                                    {/* <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTimeUnit(data)}</Text> */}
                                  </RecordTextWrapper>
                                  {/* <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#828282'>{(data.tieBreak === 0 || data.tieBreak === undefined || data.completeRep === 0) ? `` : `TieBreak`}</Text>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#4F4F4F'>{calcTieBreak(data)}</Text>
                                  </RecordTextWrapper> */}
                                </RecordBox>
                              </InfoTextWrapper>
                            </LeftWrapper>
                            {nowSection === 'Total' && <DetailButton isClicked={data.isClicked} src={RightImg} />}
                          </InfoBox>
                        </RankingClickBox>
                        {
                          nowSection === 'Total' &&
                          <TotalRecordWrapper isClicked={data.isClicked && nowSection === 'Total'}>
                            {/* Event 1 */}
                            <EventRowWrapper>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 13px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event1)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event1.rank === -1 ? 'DNF' : data.event1.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  data.event1.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.event1.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(data.event1)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                  <EventInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent1View(data.event1)}</Text>
                                  </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 1 */}
                            {/* Event 2 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event2)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event2.rank === -1 ? 'DNF' : data.event2.rank}</Text>
                                </EventRankingBox>
                                {/* {
                                  data.event2.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.event2.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(data.event2)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                } */}
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent2View(data.event2)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 2 */}
                            {/* Event 3 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event3)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event3.rank === -1 ? 'DNF' : data.event3.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent3View(data.event3)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 3 */}
                            {/* Event 4 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT4</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event4)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event4.rank === -1 ? 'DNF' : data.event4.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent4View(data.event4)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 4 */}
                            {/* Event 5 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event5)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event5.rank === -1 ? '0' : data.event5.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent5View(data.event5)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 5 */}
                            {/* Event 6 */}
                            {/* <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>HIDDEN2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event6)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event6.rank === -1 ? '0' : data.event6.rank}</Text>
                                </EventRankingBox>
                                <EventInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTotalEvent6View(data.event6, data.gender)}</Text>
                                </EventInfoTextWrapper>
                              </EventInfoBox>
                            </EventRowWrapper> */}
                            {/* Event 6 */}
                          </TotalRecordWrapper>
                        }
                      </RankingClickBoxWrapper>
                    </RankingViewWrapper>
                  ))
                }
              </RankingTotalWrapper>
          }
        </TotalWrapper>
        // :
        // <TotalWrapper>
        //   <WaitWrapper>
        //     <WaitImage src={WaitIcon} />
        //     <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>온라인 저징 이후</Text>
        //     <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>재업로드 예정입니다</Text>
        //   </WaitWrapper>
        // </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 80px;

  width: 100%;
  height: 100%;

  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const GenderSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const GenderSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  width: 50%;
  height: 32px;

  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.isClicked ? '#F9F9F9' : '#E0E0E0'};

  transition: all 0.3s ease-in-out;
`;

const GenderImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const GenderImage = styled.img`
  width: 11px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin: 4px 0px 80px 0px;
  marigin-top: 4px;

  width: 90.33%;
`;

const RankingLoadingView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  // height: 64px;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 87.16%;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(100% - 28px);
  height: 62px;

  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const TotalRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isClicked ? 10 : 0}px;

  width: 100%;
  // height: ${props => props.isClicked ? 170 : 0}px;
  height: ${props => props.isClicked ? 255 : 0}px;

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const EventRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const EventBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 7px;
  background-color: #E0E0E0;
`;

const EventInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 85.27%;
  height: 40px;

  background-color: #FFFFFF;
`;

const EventRankingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: ${props => props.backgroundColor};
`;

const EventInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-end;

  margin-left: 8px;

  height: 20px;
`;

const TotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const InnerInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const RecordBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RecordTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const RxBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 23px;
  height: 13px;

  border-radius: 3px;
  background-color: #FF3131;
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 18px;

  border-radius: 6px;
  background-color: #4161AF;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;

  transform: ${props => props.isClicked ? `rotateZ(90deg)` : `rotateZ(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
  
  -webkit-transform: scale(${props => props.scale});
  transition: all 0.3s ease-in-out;
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;