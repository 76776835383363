import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import { useTranslation } from "react-i18next";
import FetchModule from "../../../Model/Network/FetchModule";
import BelieverSubmitViewModel from "./BelieverSubmitViewModel";
import BelieverSubmitModel from "./BelieverSubmitModel";

export default function BelieverEvent4(props) {
  const { totalVideoWidth, totalVideoHeight, setIsCompleteSubmit } = props;

  const { t } = useTranslation();

  const eventId = 37;

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [completeRep, setCompleteRep] = useState(0);
  const [lastExerciseRep, setLastExerciseRep] = useState(0);

  const [tieBreakMin, setTieBreakMin] = useState(0);
  const [tieBreakSec, setTieBreakSec] = useState(0);
  // const [lastExercise, setLastExercise] = useState('');
  // const [lastExerciseRep, setLastExerciseRep] = useState(0);
  
  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });

  const [isRx, setIsRx] = useState(true);
  const [isExistRecord, setIsExistRecord] = useState(false);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [viewIsRx, setViewIsRx] = useState(false);
  const [viewMin, setViewMin] = useState(0);
  const [viewSec, setViewSec] = useState(0);
  const [viewCompleteRep, setViewCompleteRep] = useState(0);
  const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);
  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);
  // const [viewLastExercise, setViewLastExercise] = useState(0);
  // const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    let requestData = {
      eventId: eventId,
      section: 'D'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {

          // let time = parseInt(responseData.data.time);
          // let decimalPoint = (responseData.data.time % 1).toFixed(2);

          // setViewMin(Math.floor(time / 60));
          // setMin(Math.floor(time / 60));
      
          // if (decimalPoint === '0.00') {
          //   setViewSec(time % 60);
          //   setSec(time % 60);
          // }
          // else {
          //   setViewSec((time % 60) + parseFloat(decimalPoint));
          //   setSec((time % 60) + parseFloat(decimalPoint));
          // }

          let tieBreakTime = parseInt(responseData.data.tieBreak);
          let tieBreakDecimalPoint = (responseData.data.tieBreak % 1).toFixed(2);

          setViewTieBreakMin(Math.floor(tieBreakTime / 60));
          setTieBreakMin(Math.floor(tieBreakTime / 60));

          if (tieBreakDecimalPoint === '0.00') {
            setViewTieBreakSec((tieBreakTime % 60));
            setTieBreakSec((tieBreakTime % 60));
          }
          else {
            setViewTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
            setTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
          }

          setIsExistRecord(true);
          setViewIsRx(responseData.data.isRx);
          setCompleteRep(responseData.data.completeRep);
          setViewCompleteRep(responseData.data.completeRep);

          setLastExerciseRep(responseData.data.lastExerciseRep);
          setViewLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewCompleteRound(responseData.data.completeRound);
          // setViewLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewLastExercise(responseData.data.lastExercise);
          setViewLink(responseData.data.link);
          setPicture(responseData.data.picture);
        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'EVENT3RULEBOOK.pdf';

      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/believer', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

   // 제출하기 클릭시 //
   function onClickRecordAndSubmit() {

    // if (moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-11-05T00:00:00').format('YYYY.MM.DD HH:mm:ss')) {
    //   alert('제출 기한이 마감되었습니다.');
    //   return;
    // }

    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {

      // let finalMin = min === '' ? 0 : parseInt(min * 60);
      // let finalSec = sec === '' ? 0 : parseFloat(sec);
      // let finalTieBreakMin = tieBreakMin === '' ? 0 : parseInt(tieBreakMin * 60);
      // let finalTieBreakSec = tieBreakSec === '' ? 0 : parseFloat(tieBreakSec);
      let finalCompleteRep = completeRep === '' ? 0 : completeRep;
      let finalLastExerciseRep = lastExerciseRep === '' ? 0 : lastExerciseRep;

      if (finalLastExerciseRep === 0
        && finalCompleteRep === 0) {
        alert('기록을 입력해주세요');
        return;
      }
      else if ((link !== ''
        && !link.includes('https://youtube.com/shorts/')
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }
      else if (finalLastExerciseRep === 0) {
        alert('TieBreak 을 입력해주세요!');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let requestData = {
        eventId: eventId,
        section: 'D',
        isRx: true,
        time: 0,
        tieBreak: 0,
        completeRep: finalCompleteRep,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: finalLastExerciseRep,
        firstExercise: 0,
        secondExercise: 0,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: link,
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);

          if (responseData.status === 200) {
            setIsCompleteSubmit(true);
          }
        }
      )();

      setIsClickedSubmit(false);
    }
    else {
      let requestData = {
        eventId: eventId,
        section: 'D'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }


  return (
    <TopLevelWrapper>
      {
        moment().isBefore(moment('2024-05-13T00:00:00')) ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>컴피티션 빌리버 [Believer]</Text>
            <Text style={{ marginTop: '2px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2024.05.13 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 3-2</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>5min Find to Heavy Complex</Text>
              </TitleBox>
            </TitleWrapper>
            {/* <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/LOCXWo3P6BU`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox> */}
            <WodWrapper>
              <OrangeBoxWrapper>
                {/* <OrangeBox> */}
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FF8B48'>5min Find to Heavy Complex (Synchro)</Text>
                {/* </OrangeBox> */}
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Synchro Hang Clean</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>3</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '10px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Synchro Front Squat</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>2</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '10px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Synchro Shoulder to Overhead</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '10px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
            </WodWrapper>
            {
              (isExistRecord || isClickedSubmit) ?
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  {
                    isClickedSubmit ?
                      <BelieverSubmitModel
                        eventId={eventId}
                        section='D'

                        isSuccess={isSuccess}
                        setIsSuccess={setIsSuccess}

                        // min={min}
                        // setMin={setMin}
                        // sec={sec}
                        // setSec={setSec}
                        completeRep={completeRep}
                        setCompleteRep={setCompleteRep}

                        lastExerciseRep={lastExerciseRep}
                        setLastExerciseRep={setLastExerciseRep}

                        // tieBreakMin={tieBreakMin}
                        // setTieBreakMin={setTieBreakMin}
                        // tieBreakSec={tieBreakSec}
                        // setTieBreakSec={setTieBreakSec}

                        link={link}
                        setLink={setLink}
                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                      />
                      :
                      <BelieverSubmitViewModel
                        eventId={eventId}
                        section='D'

                        setIsExistRecord={setIsExistRecord}
                        
                        // min={viewMin}
                        // sec={viewSec}
                        completeRep={viewCompleteRep}
                        lastExerciseRep={viewLastExerciseRep}
                        // tieBreakMin={viewTieBreakMin}
                        // tieBreakSec={viewTieBreakSec}

                        link={viewLink}
                        picture={picture}
                      />
                  }
                </ExerciseWrapper>
                :
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  <InfoWrapper>
                    <InfoTitleWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                    </InfoTitleWrapper>
                  </InfoWrapper>
                  <ScoreGuideLineImageWrapper onClick={downloadPDF}>
                    <ScoreGuideLineImage src='https://picture.link-zone.org/event/believer/BelieverEvent3RuleBook.png' />
                  </ScoreGuideLineImageWrapper>
                  <RuleBookWrapper>
                    <RuleColumnTextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                    </RuleColumnTextWrapper>
                    {/* <RuleRowTextWrapper style={{ marginTop: '24px' }}>
                      <Point color='#FFB800'/>
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>측정 시 인증 요소</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper style={{ marginTop: '4px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 3-1 영상과 원 테이크로 업로드후 재출</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'- 3-2. kg 플레이트 사용 시 바벨만 20/15kg -> 45/35#로 환산, 플레이트 무게는 총합 무게를 환산 후 소수점 버리기(반올림X) (ex. 남자 110kg -> 110kg-20kg(바벨) = 90 -> 90kg=198lb -> 45lb + 198lb = 243lb'}</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '32px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>이벤트 수행</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>반드시 3명의 선수 모두 옆모습</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>(락아웃 상태와 고관절, 무릎 높이가 확인 가능한 위치)을 카메라 구도로 잡고 수행</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}> 
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>3-2. 모든 Complex 시도 전에 3명의 선수는 각각 육성으로 자신이 들어올릴 무게를 고지합니다.</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}> 
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>3 Hang CL, 2 FS, 1 S2O를 언브로큰으로 수행.</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>세 동작 모두 동시에 수행할 필요는 없지만 각 동작의 마무리에서 몸이 완전히 1자가 된 모습(락아웃)이 3명 모두 동시에 보여져야함.</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}> 
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- Hang Clean</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> DL 후 무릎 위에서만 시작 가능. 동작 중 바벨이 무릎 밑으로 내려간다면 다시 DL 락아웃 상태를 만들고 진행 가능'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> 파워, 스쾃, 스플릿 전부 가능'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'(스플릿 시 양발이 평행한 위치로 되돌아와야 함)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> 무게를 받았을 때 무릎, 고관절이 명확히 펴져있고 프론트랙 상태(바벨이 어깨 위, 팔꿈치가 바벨보다 앞)여야함'}</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}> 
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- Front Squat</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> HCL이 끝난 후 프론트 랙 상태에서 시작 (단, 양 손이 바벨을 잡고있을 필요는 없음)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> 앉았을 때 명확히 고관절이 무릎 아래로 내려가야 함'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'(스플릿 시 양발이 평행한 위치로 되돌아와야 함)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> 일어났을 때 무릎, 고관절이 명확히 펴져있고 프론트랙 상태(바벨이 어깨 위, 팔꿈치가 바벨보다 앞)여야함'}</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}> 
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- Shoulder to Overhead</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> FS가 끝난 후 프론트 랙 상태에서 시작'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> 프레스, 푸시 프레스, 푸시 저크, 스플릿 저크 모두 가능. (스플릿 시 양발이 평행한 위치로 되돌아와야 함)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> 바벨은 머리 위 또는 머리 약간 뒤에. 양팔, 고관절, 무릎이 모두 펴져있어야함'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'> 일어났을 때 무릎, 고관절이 명확히 펴져있고 프론트랙 상태(바벨이 어깨 위, 팔꿈치가 바벨보다 앞)여야함'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'(바벨, 어깨, 고관절, 발 중간이 바닥과 수직인 1자로 펴져야함)'}</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>노랩 가이드</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>3-2 동작 수행 중간에 1명이라도 바벨 드랍 시 처음부터 다시 수행</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>파트너 무게 세팅을 도와줄 수 있음</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>반드시 3명의 선수 모두 옆모습(락아웃 상태와 고관절, 무릎 높이가 확인 가능한 위치)을 카메라 구도로 잡고 수행</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>실격 기준</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>3-1 무게 A,B,C 지키지 않았을 경우</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Snatch, Overhead Squats, Hang Snatch 동작</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Synchronized 기준은 동작 완성 후 동시 락아웃 (몸이 완전히 1자가 된 형태) 되어야 합니다</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>동작 완성 후 다른 팀원이 동작을 완성 후 락아웃 할 때까지 기다려주는 행위 가능</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>스트랩 사용 불가능 (사용 됐을 시, 실격)</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>영상은 무조건 원테이크로 촬영되어야 함 (영상이 편집 되거나, 공정한 기록 측정에 영향이 가는 재가공은 실격)</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>안전상에 이유로 반드시 고정 마구리가 장착되어야 하며, 가장 바깥쪽에 장착되어야 함.</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>기록</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>기록 : 합산 무게</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Tie Break : 가장 낮은 무게를 성공한 선수의 무게</Text>
                    </TextWrapper> */}
                  </RuleBookWrapper>
                </ExerciseWrapper>
            }
            {
              moment().isBefore(moment('2024-05-20T00:00:00')) &&
              <RankingButton onClick={onClickRecordAndSubmit}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>제출하기</Text>
              </RankingButton>
            }
            {/* {
            moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-04-30T23:59:59').format('YYYY.MM.DD HH:mm:ss') ?
              <RankingButton>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
              </RankingButton>
              :
              <RankingButton>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'></Text>
              </RankingButton>
          } */}
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 76px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const OrangeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  height: 18px;

  border-radius: 2px;
  background-color: #FF8B48;
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // padding: 0px 12px 0px 12px;
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 0px 0px;

  width: calc(90.33% - 76px);
  // width: calc(100% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const MissionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 1px 9px 1px 9px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const RestInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: 33px;

  background-color: #5BD98A;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const ExercisePlayerBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 59px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 130px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  border-radius: 10px;
  background-color: ${props => props.color};
`;

const NotRoundPoint = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  min-width: 10px;
  min-height: 10px;
  
  background-color: ${props => props.color};
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;