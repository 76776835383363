import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PictureRightIcon from '../../../Image/PictureRightArrow.png';
import PictureLeftIcon from '../../../Image/PictureLeftArrow.png';
import CameraIcon from '../../../Image/Camera.png';
import WhiteX from '../../../Image/WhiteX.png';

export default function PictureButton(props) {
  const { imgBase64, setImgBase64 } = props;

  const { t } = useTranslation();

	const hiddenFileInput = useRef(null);
  
	// const [imgBase64, setImgBase64] = useState('');
  const [imgFile, setImgFile] = useState('');
  const [imgLocalPath, setImgLocalPath] = useState('');
  const [nowIndex, setNowIndex] = useState(0);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [pictureSize, setPictureSize] = useState(374);

  // 화면 크기 추출후 총 사진 크기 계산 //
  useEffect(() => {
    var totalPictureSize = windowSize.width * 0.9033;
    // console.log(totalPictureSize);
    setPictureSize(totalPictureSize);
  }, [windowSize])

  // 사진 추가 버튼 클릭시 //
  function onClickAdditionPictureButton() {
    hiddenFileInput.current.click();
	};
	
  function handleChange(e) {
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      
      var tmp = e.target.value.split('\\');
      setImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;
        
        if (base64){
          // setImgBase64(base64.toString());
          let pictureList = imgBase64.slice();

          pictureList.push(base64.toString());

          setImgBase64(pictureList);
          setNowIndex(pictureList.length - 1);
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
        setImgFile(e.target.files[0]);
      }
    } 
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
	};

  // 사진 삭제버튼 클릭시 //
  function onClickDeletePicture() {
    let pictureList = imgBase64.slice();

    pictureList.splice(nowIndex, 1);

    setImgBase64(pictureList);
  }

  // 이전 사진 클릭시 //
  function onClickPrevPicture() {
    if (nowIndex === 0) return;

    setNowIndex(nowIndex - 1);
  }

  // 다음 사진 클릭시 //
  function onClickNextPicture() {
    if (nowIndex > 5 || imgBase64.length === (nowIndex + 1)) return;

    setNowIndex(nowIndex + 1);
  }

  return (
    <TopLevelWrapper>
      {
        imgBase64.length < 5 &&
          <PictureAdditionButton onClick={onClickAdditionPictureButton}>
            <Image src={CameraIcon} />
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{t('사진 추가')}</Text>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }} />
          </PictureAdditionButton>
      }
      {
        imgBase64.length !== 0 &&
          <PictureWrapper size={pictureSize}>
            <UserPicture src={imgBase64[nowIndex]}/>  
            <ButtonWrapper size={pictureSize}>
              <DeleteButtonWrapper>
                <DeleteButton onClick={onClickDeletePicture}>
                  <DeleteImage src={WhiteX}/>
                </DeleteButton>
              </DeleteButtonWrapper>
              <ImageMoveButtonWrapper marginTop={parseInt(pictureSize / 2) - 48}>
                {
                  nowIndex !== 0 ?
                    <MoveButton onClick={onClickPrevPicture}>
                      <ImageMoveButton src={PictureLeftIcon}/>
                    </MoveButton>
                    :
                    <MoveButton/>
                }
                {
                  (nowIndex !== 4 && imgBase64.length >= 2 && imgBase64.length !== (nowIndex + 1)) ?
                    <MoveButton onClick={onClickNextPicture}>
                      <ImageMoveButton src={PictureRightIcon} />
                    </MoveButton>
                    :
                    <MoveButton />
                }
              </ImageMoveButtonWrapper>
            </ButtonWrapper>
          </PictureWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 12px;

  overflow: hidden;
`;

const UserPicture = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const ButtonWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 24px);
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 12px 0px 0px 0px;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.6);
`;

const DeleteImage = styled.img`
  width: 24px;
  height: 24px;
`;

const ImageMoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.marginTop}px;
  padding: 0px 12px 0px 12px;

  width: calc(100% - 24px);
`;

const MoveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
`;

const ImageMoveButton = styled.img`
  width: 30px;
  height: 30px;
`;

const PictureAdditionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 103px;
  height: 32px;

  gap: 4px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
