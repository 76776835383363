import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../../Image/WaitIcon.png';
import VideoIcon from '../../../../Image/VideoIcon.png';
import { useTranslation } from "react-i18next";
import FetchModule from "../../../Network/FetchModule";
import SUFFSubmitModel from "../SUFFSubmitModel";
import SUFFSubmitViewModel from "../SUFFSubmitViewModel";
import SUFFIndividualDetailPage from "../../../../Page/EventDetailPage/SUFF/SUFFIndividualDetailPage";
import SubmittingRecordsModal from "../SubmittingRecordsModal";
// import FETOnlineMarchRX from "./FETOnlineMarchRX";
// import FETOnlineMarchScale from "./FETOnlineMarchScale";

export default function IndividualEvent2And3(props) {
  const { language, setIsCompleteSubmit, totalVideoWidth, totalVideoHeight, onClickVideo } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isRx, setIsRx] = useState(true);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [tieBreakMin, setTieBreakMin] = useState(0);
  const [tieBreakSec, setTieBreakSec] = useState(0);

  const [firstExercise, setFirstExercise] = useState(0);

  const [completeRep, setCompleteRep] = useState(0);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);

  const [isExistRecord, setIsExistRecord] = useState(false);
  const [viewIsRx, setViewIsRx] = useState(false);

  // const [viewMin, setViewMin] = useState(0);
  // const [viewSec, setViewSec] = useState(0);

  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);

  const [viewCompleteRep, setViewCompleteRep] = useState(0);

  const [viewFirstExercise, setViewFirstExercise] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    var requestData = {
      eventId: 9,
      sections: 'B,C'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently/multiple', 'POST', requestData);
        console.log(responseData);
        if (responseData.status === 200) {
          setIsExistRecord(true);
          // setViewIsRx(responseData.data.isRx);

          // let time = parseInt(responseData.data.time);
          // let decimalPoint = (responseData.data.time % 1).toFixed(2);
          let dates = responseData.data.slice();

          for (let i = 0; i < dates.length; i++) {
            if (dates[i].section === 'B') {
              let tieBreakTime = parseInt(dates[i].tieBreak);
              let tieBreakDecimalPoint = (dates[i].tieBreak % 1).toFixed(2);

              setViewTieBreakMin(Math.floor(tieBreakTime / 60));
              setTieBreakMin(Math.floor(tieBreakTime / 60));

              if (tieBreakDecimalPoint === '0.00') {
                setViewTieBreakSec((tieBreakTime % 60));
                setTieBreakSec((tieBreakTime % 60));
              }
              else {
                setViewTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
                setTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
              }

              // if (tieBreakTime !== 0) {
              //   setCompleteRep(responseData.data.completeRep);
              //   setIsSuccess({ value: '타임켑안에 못했어요' });
              // }

              setCompleteRep(dates[i].completeRep);
              setViewCompleteRep(dates[i].completeRep);
              setViewLink(dates[i].link);
              setPicture(dates[i].picture);
            }
            else {
              setFirstExercise(dates[i].firstExercise);
              setViewFirstExercise(dates[i].firstExercise);
            }
          }

          // setIsRx(responseData.data.isRx);

        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 순위표 버튼 클릭시 //
  function onClickRankingButton() {
    navigate('/rank/9');
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {
    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {
      if ((link !== ''
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let finalCompleteRep = completeRep === '' ? 0 : completeRep;
      let maximum = firstExercise === '' ? 0 : firstExercise;

      // let finalMin = min === '' ? 0 : parseInt(min * 60);
      // let finalSec = sec === '' ? 0 : parseFloat(sec);

      let finalTieBreakMin = tieBreakMin === '' ? 0 : parseInt(tieBreakMin * 60);
      let finalTieBreakSec = tieBreakSec === '' ? 0 : parseFloat(tieBreakSec);

      if (finalTieBreakMin + finalTieBreakSec === 0) {
        alert('TieBreak & Time Cap을 입력해주세요!');
        return;
      }

      setIsSubmitting(true);

      let eventRecordB = {
        eventId: 9,
        section: 'B',
        time: 0,
        tieBreak: finalTieBreakMin + finalTieBreakSec,
        isRx: true,
        distance: 0,
        completeRep: finalCompleteRep,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: 0,
        firstExercise: 0,
        secondExercise: 0,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: link,
        picture: imageString
      }

      let eventRecordC = {
        eventId: 9,
        section: 'C',
        time: 0,
        tieBreak: 0,
        isRx: true,
        distance: 0,
        completeRep: 0,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: 0,
        firstExercise: maximum,
        secondExercise: 0,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: '',
        picture: ''
      } 

      let requestData = {
        records: [
          eventRecordB,
          eventRecordC
        ]
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save/SUFF', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            // setIsSavingRecord(false);
            setIsCompleteSubmit(true);
          }

          setIsSubmitting(false);
          setIsClickedSubmit(false);
        }
      )();
    }
    else {
      var requestData = {
        eventId: 9,
        section: 'B'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'Individual_SUFF_Online_Event2&3.pdf';

      if (language === 'Korea') fileName = 'Individual_SUFF_Online_Event2&3.pdf';
      else fileName = 'Individual_SUFF_Online_Event2&3(Eng).pdf';

      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/SUFF/RuleBook', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  return (
    <TopLevelWrapper>
      { isSubmitting && <SubmittingRecordsModal/> }
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment('2023-05-01T00:00:00').format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SUFF Online Individual Event</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2023.05.01 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 2&3</Text>
              </SectionBox>
              <TitleBox>
                {/* <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>10min Amrap with Heavy Complex</Text> */}
                <Text fontFamily='NotoSansKR-Medium' fontSize={13} color='#333333'>10min Amrap with Heavy Complex</Text>
              </TitleBox>
            </TitleWrapper>
            {
              (!isExistRecord && !isClickedSubmit) &&
              <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
                <iframe
                  width='100%'
                  height='100%'
                  src={`https://www.youtube.com/embed/fM19TQtbYsU`}
                  style={{ borderRadius: '12px' }}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube" />
              </TotalVideoBox>
            }
            <WodWrapper>
              <WodTextWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>10min Amrap</Text>
              </WodTextWrapper>
              <ExerciseWrapper style={{ marginTop: '12px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Toes-to-Bar</Text>
                    </ExerciseNumberBoxWrapper>
                    <Image src={VideoIcon} onClick={() => onClickVideo('fM19TQtbYsU')} />
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>15</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '5px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Dumbbell Snatch (Left)</Text>
                    </ExerciseNumberBoxWrapper>
                    <Image src={VideoIcon} onClick={() => onClickVideo('fM19TQtbYsU')} />
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                      <DivisionLine width={55} />
                      <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:35</Text>
                      <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                      <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:50</Text>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                    </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>6</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '5px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Chest-to-bar pull ups</Text>
                    </ExerciseNumberBoxWrapper>
                    <Image src={VideoIcon} onClick={() => onClickVideo('fM19TQtbYsU')} />
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>12</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '5px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>04</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Dumbbell Snatch (Right)</Text>
                    </ExerciseNumberBoxWrapper>
                    <Image src={VideoIcon} onClick={() => onClickVideo('fM19TQtbYsU')} />
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                      <DivisionLine width={55} />
                      <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:35</Text>
                      <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                      <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:50</Text>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                    </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>6</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper> 
              <ExerciseWrapper style={{ marginTop: '5px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>05</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Bar muscle ups</Text>
                    </ExerciseNumberBoxWrapper>
                    <Image src={VideoIcon} onClick={() => onClickVideo('fM19TQtbYsU')} />
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px'}}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>9</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '5px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>06</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Dual Dumbbell Hang Snatch</Text>
                    </ExerciseNumberBoxWrapper>
                    <Image src={VideoIcon} onClick={() => onClickVideo('fM19TQtbYsU')} />
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                      <DivisionLine width={55} />
                      <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:35</Text>
                      <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                      <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:50</Text>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                    </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>6</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <WodTextWrapper style={{ marginTop: `3px` }}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>And Then Directly Into</Text>
              </WodTextWrapper>
              <WodTextWrapper style={{ marginBottom: `5px` }}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>Build To Heavy Complex in 5minute</Text>
              </WodTextWrapper>
              <ExerciseWrapper style={{ marginTop: '5px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>07</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>1 Squat Clean + 1 Hang Cluster</Text>
                    </ExerciseNumberBoxWrapper>
                    <Image src={VideoIcon} onClick={() => onClickVideo('fM19TQtbYsU')} />
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                      <DivisionLine width={74} />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
            </WodWrapper>
            {
              (isExistRecord || isClickedSubmit) ?
                <ExerciseWrapper>
                  {
                    isClickedSubmit ?
                      <SUFFSubmitModel
                        eventId={9}
                        section='B'

                        isRx={isRx}
                        setIsRx={setIsRx}
                        isSuccess={isSuccess}
                        setIsSuccess={setIsSuccess}
                        min={min}
                        setMin={setMin}
                        sec={sec}
                        setSec={setSec}
                        bodyWeight={bodyWeight}
                        setBodyWeight={setBodyWeight}
                        point={point}
                        setPoint={setPoint}

                        firstExercise={firstExercise}
                        setFirstExercise={setFirstExercise}

                        tieBreakMin={tieBreakMin}
                        setTieBreakMin={setTieBreakMin}
                        tieBreakSec={tieBreakSec}
                        setTieBreakSec={setTieBreakSec}

                        completeRep={completeRep}
                        setCompleteRep={setCompleteRep}
                        link={link}
                        setLink={setLink}
                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                        setIsExistRecord={setIsExistRecord} 
                      />
                      :
                      <SUFFSubmitViewModel
                        eventId={9}
                        section='B'

                        isRx={viewIsRx}

                        // min={viewMin}
                        // sec={viewSec}
                        tieBreakMin={viewTieBreakMin}
                        tieBreakSec={viewTieBreakSec}
                        completeRep={viewCompleteRep}
                        firstExercise={viewFirstExercise}
                        link={viewLink}
                        picture={picture}
                        setIsExistRecord={setIsExistRecord} />
                  }
                </ExerciseWrapper>
                :
                <ExerciseWrapper>
                  <InfoWrapper>
                    <InfoTitleWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                    </InfoTitleWrapper>
                  </InfoWrapper>
                  <ScoreGuideLineImageWrapper size={totalVideoWidth} onClick={downloadPDF}>
                    <ScoreGuideLineImage src='https://picture.link-zone.org/event/SUFF/SUFFIndividualEvent2And3.png' />
                  </ScoreGuideLineImageWrapper>
                  <RuleBookWrapper>
                    <RuleColumnTextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                    </RuleColumnTextWrapper> 
                    <RuleRowTextWrapper style={{ marginTop: '24px' }}>
                      <Point/>
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('Dumbbell 무게 변환 기준')}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'50lb ↔ 22.5kg'}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'45lb ↔ 20kg'}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'40lb ↔ 18kg'}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'35lb ↔ 15kg'}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'20lb ↔ 9kg'}</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <RuleRowTextWrapper style={{ marginTop: '10px' }}>
                      <Point/>
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('Barbell 무게 변환 기준')}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'95lb ↔ 43.5kg or 43.5kg or more'}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'65lb ↔ 30kg'}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'35lb ↔ 15kg'}</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <RuleColumnTextWrapper style={{ marginTop: '24px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('Event3에서 kg플레이트 이용시 기록제출은')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('lb로 변환해서 제출해주시기 바랍니다.')}</Text>
                    </RuleColumnTextWrapper> 
                  </RuleBookWrapper>
                </ExerciseWrapper>
            }
            {
              moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-05-15T23:59:59').format('YYYY.MM.DD HH:mm:ss') ?
                <RankingButton onClick={onClickRankingButton}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
                </RankingButton>
                :
                <RankingButton onClick={onClickRecordAndSubmit}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{isExistRecord ? t('수정하기') : isClickedSubmit ? t('제출하기') : t('기록하기')}</Text>
                </RankingButton>
            }
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-right: 8px;

  // width: 32px;
  width: 83px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  // width: calc(89.3% - 83px);
  width: calc(90.33% - 83px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;
  // margin: 15px 0px 8px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 25px;
  margin: 25px 0px 15px 0px;

  width: 100%;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const WodRoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 0px;

  height: 18px;

  border-radius: 2px;
  background-color: #6DD49E;
`;

const EllipsisWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 28px 0px 28px 0px;

  width: 100%;

  gap: 10px;
`;

const Ellipsis = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;

  border-radius: 10px;

  background-color: #D9D9D9;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 13px;

  // width: ${props => props.size}px;
  // height: ${props => props.size}px;
  width: 90.33%;
  height: auto;

  border-radius: 10px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 49px;

  margin-top: 21px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  background-color: #FF3131;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const OvertextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
