import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FetchModule from '../Model/Network/FetchModule';

export default function AccountTransferPage() {

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let token = params.token;
    let eventId = parseInt(params.eventId);

    window.localStorage.setItem('Token', 'Bearer ' + token);

    // if (organizerId < 9) navigate('/info/legacy/' + organizerId);
    // else if (organizerId === 9) navigate('/info/battlecrew3');
    // else if (organizerId === 10) navigate('/info/ThePerformanceWorkshop');
// alert(eventId);
    if (eventId === 13
      || eventId === 14
      || eventId === 15) {
      // :eventTitle/:eventDay/:teamName/:membersName
    
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.getDataOfEventVer2('event/application_state/battlecrew3', 'GET');
          // console.log(responseData);
          if (responseData.status === 200) {

            // navigate('/complete/battlecrew3/' + responseData.data.eventTitle + '/' + responseData.data.eventDay + '/' + responseData.data.teamName + '/' + responseData.data.memberName);
            // alert('/complete/battlecrew3/' + responseData.data.eventTitle + " 토요일" + '/' + responseData.data.eventDay + '/' + responseData.data.teamName + '/' + responseData.data.memberName);
            if (responseData.data.eventDay === 'Saturday') navigate('/complete/battlecrew3/' + responseData.data.eventTitle + " 토요일" + '/' + responseData.data.eventDay + '/' + responseData.data.teamName.replaceAll('?', '') + '/' + responseData.data.memberName);
            else navigate('/complete/battlecrew3/' + responseData.data.eventTitle + " 일요일" + '/' + responseData.data.eventDay + '/' + responseData.data.teamName.replaceAll('?', '') + '/' + responseData.data.memberName);
          }
        }
      )();
    }
    else if (eventId === 16 || eventId === 17) navigate('/complete/ThePerformanceWorkshop');
    else if (eventId === 22) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.getDataOfEventVer2('event/application_state/girlfit/22', 'GET');
          console.log(responseData);
          if (responseData.status === 200) {
            navigate('/complete/girlfit/22/None/' + responseData.data.memberNames);
          }
        }
      )();
    }
    else if (eventId === 23) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.getDataOfEventVer2('event/application_state/girlfit/23', 'GET');
          console.log(responseData);
          if (responseData.status === 200) {
            navigate('/complete/girlfit/23/None/' + responseData.data.memberNames);
          }
        }
      )();
    }
    else if (eventId === 24) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.getDataOfEventVer2('event/application_state/girlfit/24', 'GET');
          console.log(responseData);
          if (responseData.status === 200) {
            navigate('/complete/girlfit/24/' + responseData.data.teamName.replaceAll('?', '') + '/' + responseData.data.memberNames);
          }
        }
      )();
    }

    // Girl Crush //
    else if (eventId === 27) {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.getDataOfEventVer2('event/application_state/girlcrush', 'GET');
          // console.log(responseData);
          if (responseData.status === 200) {
            navigate('/complete/girlcrush/27/' + responseData.data.teamName.replaceAll('?', '') + '/' + responseData.data.memberNames + '/false');
          }
        }
      )();
    }
  }, [])

  return <></>;
}