import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


export default function TshirtsSelectBox(props) {
  const { value, setValue, index} = props;

  const { t } = useTranslation();

  return (
    <TopLevelWrapper>
      <InfoBox isSelected={value === '크롭티'} onClick={() => setValue('크롭티', index)}>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={value === '크롭티' ? '#FFFFFF' : '#595959'}>크롭티</Text>
      </InfoBox>
      <InfoBox isSelected={value === '티셔츠'} onClick={() => setValue('티셔츠', index)}>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={value === '티셔츠' ? '#FFFFFF' : '#595959'}>티셔츠</Text>
      </InfoBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 8px;

  width: 100%;
`;

// const TotalWrapper = styled.div`
//   display: flex;
//   flex-direction: row;

//   justify-content: space-between;
//   align-items: center;

//   margin-top: 8px;

//   width: 100%;
// `;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: calc(48.39% - 4px);
  width: calc(48.39% - 4px);
  height: 36px;

  border: ${props => props.isSelected ? '2px solid #6DD49E' : '2px solid #E0E0E0'};
  border-radius: 8px;
  background-color: ${props => props.isSelected ? '#6DD49E' : '#FFFFFF'};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;