import React from 'react';
import styled from 'styled-components';

export default function SizeButton(props) {
  const { isRx, setIsRx } = props;

  return (
    <TopLevelWrapper>
      <RxButton isClicked={isRx} onClick={() => setIsRx(!isRx)}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>Rx</Text>
      </RxButton>
      <ScaleButton isClicked={!isRx} onClick={() => setIsRx(!isRx)}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>Scale</Text>
      </ScaleButton>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RxButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 37px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#FF3131` : `#E0E0E0`};
  
  transition: all 0.3s ease-in-out;
`;

const ScaleButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 37px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#4161AF` : `#E0E0E0`};
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;
