import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../../Model/Network/FetchModule';

import CrownIcon from '../../../Image/CrownIcon.png';
import FirstPlaceImg from '../../../Image/FirstPlace.png';
import SecondPlaceImg from '../../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../../Image/ThirdPlace.png';
import RightImg from '../../../Image/NotAvailableRight.png';
import NoneProfile from '../../../Image/NoneProfile.png';
import WhiteMale from '../../../Image/WhiteMale.png';
import ColorMale from '../../../Image/ColorMale.png';
import WhiteFemale from '../../../Image/WhiteFemale.png';
import ColorFemale from '../../../Image/ColorFemale.png';
import WaitIcon from '../../../Image/WaitIcon.png';

import { useTranslation } from 'react-i18next';

export default function SweatChallengeCustomRankPage() {

  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [nowSection, setNowSection] = useState('A');
  const [isClickedGender, setIsClickedGender] = useState(window.localStorage.getItem('GenderDivision') === null ? 'female' : window.localStorage.getItem('GenderDivision'));

  // const [data, setData] = useState([]);
  const [myRanking, setMyRanking] = useState(undefined);
  const [responseRankingList, setResponseRankingList] = useState([]);
  const [rankingList, setRankingList] = useState([]);

  const [isClickedMyRank, setIsClickedMyRank] = useState(false);
  const [myRankTotalHeight, setMyRankTotalHeight] = useState(64);

  const [isLoading, setIsLoading] = useState(false);

  // Ranking Info API CALL //
  useEffect(() => {
    setIsLoading(true);
    setIsClickedMyRank(false);

    let requestData = {
      eventId: 12,
      section: nowSection
    }

    const fetchModule = new FetchModule();
    (
      async () => {

        const responseData = await fetchModule.postDataOfEvent('score/rank', 'POST', requestData);
        // Rx로 구분 //
        if (responseData.status === 200) {
          var rankData = responseData.data.eventRankList.slice();
          var myRank = responseData.data.myRankData;
          let totalRankData = [];

          let validRankData = [];
          let invalidRankData = [];

          // 남성 순위 데이터 합산본 //
          let maleValidRankData = [];
          // 여성 순위 데이터 합산본 //
          let femaleValidRankData = [];

          // 순위 데이터 분류 //
          for (var i = 0; i < rankData.length; i++) {
            // Event1, Event2이지만 데이터가 올바르지 않은경우 //
            if ((nowSection === 'A' || nowSection === 'B') && rankData[i].time === 0) invalidRankData.push(rankData[i]);
            // Event3이지만 데이터가 올바르지 않은 경우 //
            else if (nowSection === 'C' && rankData[i].firstExercise === 0) invalidRankData.push(rankData[i]);
            // 데이터가 올바른 경우 //
            else {
              // 남성 구분 //
              if (rankData[i].gender === 'male') maleValidRankData.push(rankData[i]);
              // 여성 구분 //
              else femaleValidRankData.push(rankData[i]);
            }
          }

          if (nowSection === 'A') {
            // 남성 데이터 순위 정렬 //
            maleValidRankData.sort((a, b) => b.time - a.time);
            // 여성데이터 순위 정렬 //
            femaleValidRankData.sort((a, b) => b.time - a.time);

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            // 내 순위 찾기
            if (myRank !== undefined) {

              if (myRank.gender === 'male') {
                for (var i = 0; i < maleValidRankData.length; i++) {
                  if (i !== 0 && maleValidRankData[i].time === 0) {
                    maleValidRankData[i].rank = maleValidRankData[i - 1].rank;

                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = maleValidRankData[i - 1].rank;
                  }
                  else if (i !== 0 && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = maleValidRankData[i - 1].rank;
                  }
                  else {
                    maleValidRankData[i].rank = (i + 1);

                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                  }
                }

                setMyRanking(myRank);
              }
              else {
                for (var i = 0; i < femaleValidRankData.length; i++) {
                  if (i !== 0 && femaleValidRankData[i].time === 0) {
                    femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;

                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = femaleValidRankData[i - 1].rank;
                  }
                  else if (i !== 0 && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = femaleValidRankData[i - 1].rank;
                  }
                  else {
                    femaleValidRankData[i].rank = (i + 1);

                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                  }
                }

                setMyRanking(myRank);
              }

              let savedGender = await window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            for (i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].time === 0) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0 && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else {
                maleValidRankData[i].rank = (i + 1);
              }
            }

            for (i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].time === 0) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0 && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else {
                femaleValidRankData[i].rank = (i + 1);
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'B') {
            // 남성 데이터 순위 정렬 //
            maleValidRankData.sort((a, b) => a.time - b.time);
            // 여성데이터 순위 정렬 //
            femaleValidRankData.sort((a, b) => a.time - b.time);

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            // 내 순위 찾기
            if (myRank !== undefined) {

              if (myRank.gender === 'male') {
                for (var i = 0; i < maleValidRankData.length; i++) {
                  if (i !== 0 && maleValidRankData[i].time === 0) {
                    maleValidRankData[i].rank = maleValidRankData[i - 1].rank;

                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = maleValidRankData[i - 1].rank;
                  }
                  else if (i !== 0 && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = maleValidRankData[i - 1].rank;
                  }
                  else {
                    maleValidRankData[i].rank = (i + 1);

                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                  }
                }

                setMyRanking(myRank);
              }
              else {
                for (var i = 0; i < femaleValidRankData.length; i++) {
                  if (i !== 0 && femaleValidRankData[i].time === 0) {
                    femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;

                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = femaleValidRankData[i - 1].rank;
                  }
                  else if (i !== 0 && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = femaleValidRankData[i - 1].rank;
                  }
                  else {
                    femaleValidRankData[i].rank = (i + 1);

                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                  }
                }

                setMyRanking(myRank);
              }

              let savedGender = await window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            for (i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].time === 0) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0 && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else {
                maleValidRankData[i].rank = (i + 1);
              }
            }

            for (i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].time === 0) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0 && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else {
                femaleValidRankData[i].rank = (i + 1);
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);

            setResponseRankingList(totalRankData);
          }
          else if (nowSection === 'C') {

            // 남성 데이터 순위 정렬 //
            maleValidRankData.sort((a, b) => b.completeRep - a.completeRep || b.firstExercise - a.firstExercise);
            // 여성데이터 순위 정렬 //
            femaleValidRankData.sort((a, b) => b.completeRep - a.completeRep || b.firstExercise - a.firstExercise);

            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            // 내 순위 찾기
            if (myRank !== undefined) {

              if (myRank.gender === 'male') {
                for (var i = 0; i < maleValidRankData.length; i++) {
                  if (i !== 0 && maleValidRankData[i].completeRep === 0) {
                    maleValidRankData[i].rank = maleValidRankData[i - 1].rank;

                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = maleValidRankData[i - 1].rank;
                  }
                  else if (i !== 0
                    && maleValidRankData[i].completeRep === maleValidRankData[i - 1].completeRep
                    && maleValidRankData[i].firstExercise === maleValidRankData[i - 1].firstExercise) {
                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = maleValidRankData[i - 1].rank;
                  }
                  else {
                    maleValidRankData[i].rank = (i + 1);

                    if (maleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                  }
                }

                setMyRanking(myRank);
              }
              else {
                for (var i = 0; i < femaleValidRankData.length; i++) {
                  if (i !== 0 && femaleValidRankData[i].completeRep === 0) {
                    femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;

                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = femaleValidRankData[i - 1].rank;
                  }
                  else if (i !== 0
                    && femaleValidRankData[i].completeRep === femaleValidRankData[i - 1].completeRep
                    && femaleValidRankData[i].firstExercise === femaleValidRankData[i - 1].firstExercise) {
                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = femaleValidRankData[i - 1].rank;
                  }
                  else {
                    femaleValidRankData[i].rank = (i + 1);

                    if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                  }
                }

                setMyRanking(myRank);
              }

              let savedGender = await window.localStorage.getItem('GenderDivision');

              if (savedGender === null) setIsClickedGender(myRank.gender);
            }

            for (i = 0; i < maleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].completeRep === 0) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && maleValidRankData[i].completeRep === maleValidRankData[i - 1].completeRep
                && maleValidRankData[i].firstExercise === maleValidRankData[i - 1].firstExercise) {
                maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
              }
              else {
                maleValidRankData[i].rank = (i + 1);
              }
            }

            for (i = 0; i < femaleValidRankData.length; i++) {
              if (i !== 0 && maleValidRankData[i].completeRep === 0) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else if (i !== 0
                && femaleValidRankData[i].completeRep === femaleValidRankData[i - 1].completeRep
                && femaleValidRankData[i].firstExercise === femaleValidRankData[i - 1].firstExercise) {
                femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
              }
              else {
                femaleValidRankData[i].rank = (i + 1);
              }
            }

            validRankData = maleValidRankData.concat(femaleValidRankData);
            totalRankData = validRankData.concat(invalidRankData);

            setResponseRankingList(totalRankData);
          }
        }

        // setIsLoading(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    )();
  }, [params, nowSection])

  // // 섹션 선택에 따른 정보 변환 //
  // useEffect(() => {
  //   setIsLoading(true);
    
  //   if (nowSection === 'A') {
  //     setMyRanking(event1MyRank);
  //     // setRankingList(event1RankingList);
  //     setResponseRankingList(event1RankingList);
  //   }
  //   else if (nowSection === 'B') {
  //     setMyRanking(event2MyRank);
  //     // setRankingList(event2RankingList);
  //     setResponseRankingList(event2RankingList);
  //   }
  //   else if (nowSection === 'C') {
  //     setMyRanking(event3MyRank);
  //     // setRankingList(event3RankingList);
  //     setResponseRankingList(event3RankingList);
  //   }
  //   else if (nowSection === 'D') {
  //     setMyRanking(event4MyRank);
  //     // setRankingList(event4RankingList);
  //     setResponseRankingList(event4RankingList);
  //   }
  //   else if (nowSection === 'Total') {
  //     setMyRanking(totalMyRank);
  //     // setRankingList(totalRankingList);
  //     setResponseRankingList(totalRankingList);
  //   }
  // }, [nowSection, event1MyRank, event2MyRank, event3MyRank, event4MyRank, totalMyRank, event1RankingList, event2RankingList, event3RankingList, event4RankingList, totalRankingList])

  // 성별 구분으로 정리하기 //
  useEffect(() => {
    setIsLoading(true);

    let finalList = [];

    for (let i = 0; i < responseRankingList.length; i++) {
      if (responseRankingList[i].gender === isClickedGender) finalList.push(responseRankingList[i]);
    }
    
    setRankingList(finalList);

    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [isClickedGender, responseRankingList])

  // // 내 순위 자세히 보기에 따른 컴포넌트 크기 변환 //
  // useEffect(() => {
  //   if (isClickedMyRank && nowSection === 'Total') setMyRankTotalHeight(244);
  //   else setMyRankTotalHeight(64);
  // }, [isClickedMyRank, myRanking, nowSection])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('순위표'), isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // // Section 불러오기 //
  // useEffect(() => {
  //   // (
  //   //   async () => {
  //   //     setNowSection(await window.localStorage.getItem('NowSection'));
  //   //   }
  //   // )();
  //   setNowSection(window.localStorage.getItem('NowSection'));
  // }, [])

  // // Section 저장 //
  // useEffect(() => {
  //   // (
  //   //   async () => {
  //   //     await window.localStorage.setItem('NowSection', nowSection);
  //   //   }
  //   // )();
  //   window.localStorage.setItem('NowSection', nowSection);
  // }, [nowSection])

  // React Native에서 웹으로 보내는 데이터 handler //
  async function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) {
      await window.localStorage.removeItem('GenderDivision');
      navigate(-1);
    }
  }

  // 내순위 팀 구성원 자세히보기 클릭시 //
  function onClickMyRecordDetailButton() {
    if (nowSection !== 'Total') return;

    let data = rankingList.slice();

    for (let i = 0; i < data.length; i++) {
      data[i].isClicked = false;
    }

    setRankingList(data);
    setIsClickedMyRank(!isClickedMyRank);
  }

  // 팀 구성원 자세히보기 클릭시 //
  function onClickRecordDetailButton(index) {
    if (nowSection !== 'Total') return;

    let data = rankingList.slice();

    for (let i = 0; i < data.length; i++) {
      if (i !== index) data[i].isClicked = false;
    }

    data[index].isClicked = !data[index].isClicked;

    setIsClickedMyRank(false);
    setRankingList(data);
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToFemale() {
    await window.localStorage.setItem('GenderDivision', 'female');
    setIsClickedGender('female');
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToMale() {
    await window.localStorage.setItem('GenderDivision', 'male');
    setIsClickedGender('male');
  }

  // 걸린시간 분/초로 계산 //
  function calcTime(data) {

    if (nowSection === 'A' || nowSection === 'B') { 
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (min === 0 && sec === 0) return '미제출';
      else {
        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return '0' + min + ':0' + sec;
            else return '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
        }
      }
    }
    else if (nowSection === 'C') {
      if (data.completeRep === 0) return '미제출';
      else return data.completeRep;
    }
  }

  // 걸린시간 분/초로 계산의 단위 정리 //
  function calcTimeUnit(data) {
    if (nowSection === 'A' || nowSection === 'B') {

    }
    else if (nowSection === 'C') {
      if (data.completeRep === 0) return '';
      else return 'REPS';
    }
  }

  // Tie Break 계산 //
  function calcTieBreak(data) {
    if (nowSection === 'A' || nowSection === 'B' || nowSection === 'Total') {
      if (data.tieBreak === 0 || data.tieBreak === undefined || data.completeRep === 0) return '';
      else {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.tieBreak % 1).toFixed(2);

        min = parseInt(data.tieBreak / 60);
        // sec = data.tieBreak % 60;

        if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.tieBreak % 60);


        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return '0' + min + ':' + '0' + sec;
            else return '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':' + '0' + sec;
            else return min + ':' + sec;
          }
        }
      }
    }
  }

  // 각 섹션별 올바른 데이터인지 확인 //
  function checkValidData(data) {
    if ((nowSection === 'A' || nowSection === 'B') && data.time === 0) return false;
    else if (nowSection === 'C' && data.completeRep === 0) return false;
    else return true;
  }


  // 클릭시 최종컴포넌트 늘어나는 크기 정하기 //
  function calcTotalComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    if (data.isClicked && nowSection === 'Total') return 244;
    else return 64;
  }

  // 최종 순위표 내의 순위 산출시 배경색 결정 //
  function selectTotalEventRankingBoxBackgroundColor(data) {
    if (nowSection === 'Total' && data === undefined) return '#E0E0E0';
    else if (data.rank === 1) return '#FEB501';
    else if (data.rank === 2) return '#94B1C1';
    else if (data.rank === 3) return '#F38D30';
    else return '#E0E0E0';
  }

  // 걸린시간 분/초로 계산 //
  function calcTotalTime(data) {

    if (data.time === 0) return '미제출';
    else {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.time % 1).toFixed(2);

      min = parseInt(data.time / 60);

      if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.time % 60);

      if (min === 0 && sec === 0) return '미제출';
      else {
        if (sec === 0) {
          if (min < 10) return '0' + min + ':00';
          else return min + ':00';
        }
        else {
          if (min < 10) {
            if (sec < 10) return '0' + min + ':0' + sec;
            else return '0' + min + ':' + sec;
          }
          else {
            if (sec < 10) return min + ':0' + sec;
            else return min + ':' + sec;
          }
        }
      }
    }
  }

  // 무게 정리 //
  function calcFirstExercise(data) {
    if (nowSection === 'C') {
      if ((data.firstExercise !== 0 && data.firstExercise !== undefined && data.completeRep !== 0)) {
        return data.firstExercise + 'lb';
      }
    }
  }

  // 무게 정리 //
  function calcFirstExerciseUnit(data) {
    if (nowSection === 'C') {
      if ((data.firstExercise !== 0 && data.firstExercise !== undefined && data.completeRep !== 0)) {
        return 'weight'
      }
    }
  }

  // 현재 섹션 수정시 //
  function onClickNowSection(data) {
    setIsLoading(true);
    setNowSection(data);
  }

  return (
    <TopLevelWrapper>
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#333333'>FET SWEAT CHALLENGE</Text>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>2023.01.01</Text> */}
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        <SectionButton isClicked={nowSection === 'A'} onClick={() => onClickNowSection('A')}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>EVENT 1</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'B'} onClick={() => onClickNowSection('B')}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>EVENT 2</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'C'} onClick={() => onClickNowSection('C')}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>EVENT 3</Text>
        </SectionButton>
      </SectionButtonWrapper>
      <DivisionLine />
      <GenderSelectWrapper>
        <GenderSelectBox isClicked={isClickedGender === 'female'} onClick={onClickChangeGenderToFemale}>
          <GenderImageWrapper>
            <GenderImage style={isClickedGender === 'female' ? { opacity: 1 } : { opacity: 0 }} src={ColorFemale} />
            <GenderImage style={isClickedGender === 'female' ? { position: 'absolute', opacity: 0 } : { position: 'absolute', opacity: 1 }} src={WhiteFemale} />
          </GenderImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color={isClickedGender === 'female' ? `#4F4F4F` : '#828282'}>{t('여성')}</Text>
        </GenderSelectBox>
        <GenderSelectBox isClicked={isClickedGender === 'male'} onClick={onClickChangeGenderToMale}>
          <GenderImageWrapper>
            <GenderImage style={isClickedGender === 'male' ? { opacity: 1 } : { opacity: 0 }} src={ColorMale} />
            <GenderImage style={isClickedGender === 'male' ? { position: 'absolute', opacity: 0 } : { position: 'absolute', opacity: 1 }} src={WhiteMale} />
          </GenderImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color={isClickedGender === 'male' ? `#4F4F4F` : '#828282'}>{t('남성')}</Text>
        </GenderSelectBox>
      </GenderSelectWrapper>
      {
        // nowSection !== 'Total' ?
          <TotalWrapper>
            {
              (myRanking !== undefined && myRanking.gender === isClickedGender) &&
              <TempWrapper>
                <TopLineWrapper>
                  <TitleWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('내 순위')}</Text>
                  </TitleWrapper>
                </TopLineWrapper>
                {
                  isLoading ?
                    <RankingTotalWrapper>
                      <RankingLoadingView>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                      </RankingLoadingView>
                    </RankingTotalWrapper>
                    :
                    <RankingTotalWrapper>
                      <RankingViewWrapper height={myRankTotalHeight} onClick={() => onClickMyRecordDetailButton()}>
                        {
                          checkValidData(myRanking) ?
                            <RankingNumberBoxWrapper>
                              {
                                myRanking.rank < 4 ?
                                  <RankingLeaderBoxWrapper>
                                    <RankingLeaderBox src={myRanking.rank === 1 ? FirstPlaceImg : myRanking.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                    <RankingImageTextWrapper>
                                      <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                                    </RankingImageTextWrapper>
                                  </RankingLeaderBoxWrapper>
                                  :
                                  <RankingNumberBox>
                                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                                  </RankingNumberBox>
                              }
                            </RankingNumberBoxWrapper>
                            :
                            <RankingNumberBoxWrapper>
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>DNF</Text>
                              </RankingNumberBox>
                            </RankingNumberBoxWrapper>
                        }
                        <RankingClickBoxWrapper>
                          <RankingClickBox>
                            <InfoBox>
                              <LeftWrapper>
                                <Profile src={myRanking.profile === '' ? NoneProfile : myRanking.profile} />
                                <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                  <InnerInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{myRanking.name}</Text>
                                  </InnerInfoTextWrapper>
                                  <RecordBox>
                                    <RecordTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(myRanking)}</Text>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTimeUnit(myRanking)}</Text>
                                    </RecordTextWrapper>
                                    <RecordTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#000000'>{calcFirstExercise(myRanking)}</Text>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#BDBDBD'>{calcFirstExerciseUnit(myRanking)}</Text>
                                    </RecordTextWrapper>
                                  </RecordBox>
                                </InfoTextWrapper>
                              </LeftWrapper>
                              {nowSection === 'Total' && <DetailButton isClicked={isClickedMyRank} src={RightImg} />}
                            </InfoBox>
                          </RankingClickBox>
                        </RankingClickBoxWrapper>
                      </RankingViewWrapper>
                    </RankingTotalWrapper>
                }
              </TempWrapper>
            }
            <TopLineWrapper>
              <TitleWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('전체 순위')}</Text>
              </TitleWrapper>
            </TopLineWrapper>
            {
              isLoading ?
                <RankingTotalWrapper>
                  <RankingLoadingView>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                  </RankingLoadingView>
                </RankingTotalWrapper>
                :
                <RankingTotalWrapper>
                  {
                    rankingList.map((data, index) => (
                      <RankingViewWrapper key={index} height={calcTotalComponentHeight(data)} onClick={() => onClickRecordDetailButton(index)}>
                        {
                          checkValidData(data) ?
                            <RankingNumberBoxWrapper>
                              {
                                data.rank < 4 ?
                                  <RankingLeaderBoxWrapper>
                                    <RankingLeaderBox src={data.rank === 1 ? FirstPlaceImg : data.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                    <RankingImageTextWrapper>
                                      <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                                    </RankingImageTextWrapper>
                                  </RankingLeaderBoxWrapper>
                                  :
                                  <RankingNumberBox>
                                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                                  </RankingNumberBox>
                              }
                            </RankingNumberBoxWrapper>
                            :
                            <RankingNumberBoxWrapper>
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>DNF</Text>
                              </RankingNumberBox>
                            </RankingNumberBoxWrapper>
                        }
                        <RankingClickBoxWrapper>
                          <RankingClickBox>
                            <InfoBox>
                              <LeftWrapper>
                                <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                                <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                  <InnerInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                  </InnerInfoTextWrapper>
                                  <RecordBox>
                                    <RecordTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(data)}</Text>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTimeUnit(data)}</Text>
                                    </RecordTextWrapper>
                                    <RecordTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#000000'>{calcFirstExercise(data)}</Text>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#BDBDBD'>{calcFirstExerciseUnit(data)}</Text>
                                    </RecordTextWrapper>
                                  </RecordBox>
                                </InfoTextWrapper>
                              </LeftWrapper>
                            </InfoBox>
                          </RankingClickBox>
                        </RankingClickBoxWrapper>
                      </RankingViewWrapper>
                    ))
                  }
                </RankingTotalWrapper>
            }
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 80px;

  width: 100%;
  height: 100%;

  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const GenderSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const GenderSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  width: 50%;
  height: 32px;

  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.isClicked ? '#F9F9F9' : '#E0E0E0'};

  transition: all 0.3s ease-in-out;
`;

const GenderImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const GenderImage = styled.img`
  width: 11px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin: 4px 0px 80px 0px;
  marigin-top: 4px;

  width: 90.33%;
`;

const RankingLoadingView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  // height: 64px;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 87.16%;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(100% - 28px);
  height: 62px;

  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const TotalRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isClicked ? 10 : 0}px;

  width: 100%;
  height: ${props => props.isClicked ? 170 : 0}px;

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const EventRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const EventBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 7px;
  background-color: #E0E0E0;
`;

const EventInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 85.27%;
  height: 40px;

  background-color: #FFFFFF;
`;

const EventRankingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: ${props => props.backgroundColor};
`;

const EventInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-end;

  margin-left: 8px;

  height: 20px;
`;

const TotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const InnerInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const RecordBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RecordTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const RxBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 23px;
  height: 13px;

  border-radius: 3px;
  background-color: #FF3131;
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 33px;
  height: 13px;

  border-radius: 3px;
  background-color: #4161AF;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;

  transform: ${props => props.isClicked ? `rotateZ(90deg)` : `rotateZ(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
  
  -webkit-transform: scale(${props => props.scale});
  transition: all 0.3s ease-in-out;
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;