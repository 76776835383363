import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import XLSX from 'sheetjs-style';

import FetchModule from "../../Model/Network/FetchModule";

export default function FitForceTeamLeagueGetParticipantListPage() {
  
  const params = useParams();

  useEffect (() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('tournament/event/participant/' + params.eventId, 'GET');
        console.log(responseData.data);
        if (responseData.status === 200) {

          let dates = responseData.data.slice();
          let finalList = [];

          for (let i = 0; i < dates.length; i++) {
            
            let salesList = '';
            let address = dates[i].members[0].address + ' ' + dates[i].members[0].addressDetail + ' ' + dates[i].members[0].zipCode;
            
            // for (let j = 0; j < dates[i].salesDetailSet.length; j++) {
            //   if (dates[i].salesDetailSet[j].itemName === '참가비') continue;

            //   if (j !== 0) salesList += '\n';
            //   if (dates[i].salesDetailSet[j].count === 1) salesList += dates[i].salesDetailSet[j].itemName;
            //   else salesList += dates[i].salesDetailSet[j].itemName + ' - ' + dates[i].salesDetailSet[j].count + ' 개';
            // }

            finalList.push({
              teamId: dates[i].teamId,
              createdDate: dates[i].createdDate,
              profile: dates[i].profile,
              teamName: dates[i].teamName,
              address: address,
              memberName1: dates[i].members[0].name,
              memberPhone1: dates[i].members[0].phone,
              memberBelong1: dates[i].members[0].belong,
              memberName2: dates[i].members[1].name,
              memberPhone2: dates[i].members[1].phone,
              memberBelong2: dates[i].members[1].belong,
              merchantUid: dates[i].merchantUid,
              totalPrice: dates[i].totalPrice,
              // salesList: salesList
            })
            
            // if (parseInt(params.eventId) === 36) {
            //   finalList.push({
            //     teamId: dates[i].teamId,
            //     createdDate: dates[i].createdDate,
            //     profile: dates[i].profile,
            //     teamName: dates[i].teamName,
            //     address: address,
            //     memberName1: dates[i].members[0].name,
            //     memberPhone1: dates[i].members[0].phone,
            //     memberBelong1: dates[i].members[0].belong,
            //     memberName2: dates[i].members[1].name,
            //     memberPhone2: dates[i].members[1].phone,
            //     memberBelong2: dates[i].members[1].belong,
            //     memberName3: dates[i].members[2].name,
            //     memberPhone3: dates[i].members[2].phone,
            //     memberBelong3: dates[i].members[2].belong,
            //     memberName4: dates[i].members[3].name,
            //     memberPhone4: dates[i].members[3].phone,
            //     memberBelong4: dates[i].members[3].belong,
            //     merchantUid: dates[i].merchantUid,
            //     totalPrice: dates[i].totalPrice,
            //     salesList: salesList
            //   })
            // }
            // else {
            //   finalList.push({
            //     teamId: dates[i].teamId,
            //     createdDate: dates[i].createdDate,
            //     profile: dates[i].profile,
            //     teamName: dates[i].teamName,
            //     address: address,
            //     memberName1: dates[i].members[0].name,
            //     memberPhone1: dates[i].members[0].phone,
            //     memberBelong1: dates[i].members[0].belong,
            //     memberName2: dates[i].members[1].name,
            //     memberPhone2: dates[i].members[1].phone,
            //     memberBelong2: dates[i].members[1].belong,
            //     merchantUid: dates[i].merchantUid,
            //     totalPrice: dates[i].totalPrice,
            //     salesList: salesList
            //   })
            // }
          }
          // console.log(finalList);

          
          finalList.sort((a, b) => a.teamId - b.teamId);

          const ws = await XLSX.utils.json_to_sheet(finalList);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Member")
          XLSX.writeFile(wb, 'FitForceTeamLeague' + params.eventId + '가입자명단.xlsx');
        }
      }
    )();
  }, [])

  return <></>;
}