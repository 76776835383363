import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ExitIcon from '../../Image/ExitIcon.png';
import PlusIcon from '../../Image/PlusIcon.png';

export default function AdditionalSouvenirModal(props) {
  const { data, index, souvenirList, setSouvenirList, closeFn } = props;

  const { t } = useTranslation();

  const [pointY, setPointY] = useState(undefined);
  const [isClicked, setIsClicked] = useState('');
  const [modalSize, setModalSize] = useState(false);

  const TopLevelRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      TopLevelRef.current.style.marginTop = `0px`;
    }, 100)
  }, [TopLevelRef])

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%;`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    if (data.isUseSelectGender && data.isUseSelectSize) setModalSize('94.04%');
    else if (data.isUseSelectGender || data.isUseSelectSize) setModalSize('61.74%');
    else {
      let newList = souvenirList.slice();
      let isExist = false;

      for (let i = 0; i < newList.length; i++) {
        if (newList[i].id === data.additionalSouvenirId) {
          isExist = true;
          break;
        }
      }

      if (!isExist) {
        newList.push({
          id: data.additionalSouvenirId,
          title: data.title,
          gender: '',
          size: '',
          price: data.price,
          count: 1
        })
  
        setSouvenirList(newList);
      }
      
      // setModalSize('43.55%');
      setModalSize('50%');
    }
  }, [data, souvenirList])

  // PC일경우 마우스 클릭 감지 //
  function onMouseDown(e) {
    if (pointY !== undefined) return;

    setPointY(e.screenY);
    TopLevelRef.current.style.transition = 'none';
  }

  // PC일경우 마우스 움직임 감지 //
  function onMouseMove(e) {
    if (pointY === undefined) return;

    if (e.screenY - pointY > 0) TopLevelRef.current.style.marginTop = (e.screenY - pointY) + 'px';
  }

  // PC일경우 마우스 클릭 마무리 감지 //
  function onMouseUp(e) {
    TopLevelRef.current.style.transition = 'all 0.5s ease-in-out';

    if (e.screenY - pointY > 130) onClickExitButton();
    else {
      TopLevelRef.current.style.marginTop = `0px`;
      setPointY(undefined);
    }
  }

  // 모바일일 경우 터치 감지 //
  function onTouchStart(e) {
    if (pointY !== undefined) return;

    setPointY(e.changedTouches[0].screenY);
    TopLevelRef.current.style.transition = 'none';
  }

  // 모바일일 경우 터치 움직임 감지 //
  function onTouchMove(e) {
    if (pointY === undefined) return;

    if (e.changedTouches[0].screenY - pointY > 0) TopLevelRef.current.style.marginTop = (e.changedTouches[0].screenY - pointY) + 'px';
  }

  // 모바일일 경우 터치 움직임 마무리 감지 //
  function onTouchEnd(e) {
    TopLevelRef.current.style.transition = 'all 0.5s ease-in-out';

    if (e.changedTouches[0].screenY - pointY > 130) {
      onClickExitButton();
    }
    else {
      TopLevelRef.current.style.marginTop = `0px`;
      setPointY(undefined);
    }
  }

  // 선택 버튼 클릭시 //
  function onClickSelectButton() {
    // if (!isClicked) {
    //   alert('기념품을 선택해주세요');
    //   return;
    // }
    // else if (commonSouvenirCount === 0) {
    //   alert('기념품 개수를 확인해주세요!');
    //   return;
    // }

    onClickExitButton();
  }

  //  닫기 버튼 클릭시 //
  function onClickExitButton() {
    TopLevelRef.current.style.marginTop = `790px`;

    setTimeout(() => {
      closeFn();
    }, 300);
  }

  // 사이즈 클릭시 //
  function onClickSize(size) {
    if (isClicked === '') {
      // alert(t('성별을 선택해주세요!'));
      alert('색상을 선택해주세요!');
      return;
    }

    let newList = souvenirList.slice();

    newList.push({
      id: data.additionalSouvenirId,
      gender: isClicked === 'black' ? '블랙' : '화이트',
      title: data.title,
      size: size,
      count: 1,
      price: data.price
    })

    setIsClicked('');
    setSouvenirList(newList);
  }

  // 개수 더하기 클릭시 //
  function onClickPlusButton(index) {
    let newList = souvenirList.slice();

    newList[index].count += 1;
    setSouvenirList(newList);
  }

  // 개수 빼기 클릭시 //
  function onClickMinusButton(index) {
    let newList = souvenirList.slice();

    if (newList[index].count > 1) {
      newList[index].count -= 1;
      setSouvenirList(newList);
    }
    else if (newList[index].count === 1) {
      newList.splice(index, 1);
      setSouvenirList(newList);
    }
  }

  return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef} modalSize={modalSize}>
        <TitleLineWrapper
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}>
          <TitleWrapper>
            <TitleMaximumWrapper>
              <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#4F4F4F'>{t('추가 기념품 선택') + ' ' + String.fromCharCode(index + 65)}</Text>
            </TitleMaximumWrapper>
            <Image src={ExitIcon} onClick={onClickExitButton}/>
          </TitleWrapper>
        </TitleLineWrapper>
        <TitleWrapper style={{ marginTop: `10px` }}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#4F4F4F'>{data.title}</Text>
        </TitleWrapper>
        {/* {
          data.isUseSelectGender && */}
          <EventBoxWrapper>
            {/* <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('성별')}</Text> */}
            <BoxWrapper>
              <GenderBox
                style={{ marginTop: '8px' }}
                isClicked={isClicked === 'black'}
                onClick={() => setIsClicked('black')}>
                <HoverText fontFamily='NotoSansKR-Regular' fontSize={16} color={isClicked === 'black' ? `#FFFFFF` : '#4F4F4F'}>블랙</HoverText>
              </GenderBox>
              <GenderBox
                style={{ marginTop: '8px' }}
                isClicked={isClicked === 'white'}
                onClick={() => setIsClicked('white')}>
                <HoverText fontFamily='NotoSansKR-Regular' fontSize={16} color={isClicked === 'white' ? `#FFFFFF` : '#4F4F4F'}>화이트</HoverText>
              </GenderBox>
            </BoxWrapper>
          </EventBoxWrapper>
        {/* } */}
        {
          data.isUseSelectSize &&
          <EventBoxWrapper>
            {/* <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('사이즈')}</Text> */}
            <BoxWrapper>
              <SizeBox
                style={{ marginTop: '8px' }}
                onClick={() => onClickSize('M')}>
                <HoverText fontFamily='Poppins-Regular' fontSize={16} color='#4F4F4F'>M</HoverText>
              </SizeBox>
              <SizeBox
                style={{ marginTop: '8px' }}
                onClick={() => onClickSize('L')}>
                <HoverText fontFamily='Poppins-Regular' fontSize={16} color='#4F4F4F'>L</HoverText>
              </SizeBox>
              <SizeBox
                style={{ marginTop: '8px' }}
                onClick={() => onClickSize('XL')}>
                <HoverText fontFamily='Poppins-Regular' fontSize={16} color='#4F4F4F'>XL</HoverText>
              </SizeBox>
            </BoxWrapper>
          </EventBoxWrapper>
        }
        <EventBoxWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('최종 수량')}</Text>
        </EventBoxWrapper>
        <SuovenirListWrapper>
        {
          souvenirList.map((innerData, index) => (
            data.additionalSouvenirId === innerData.id &&
            <CountBoxWrapper key={index} style={{ marginTop: '8px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{innerData.title + innerData.gender + innerData.size}</Text>
              <InputWrapper>
                <Input style={{ marginRight: '6px' }}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{innerData.count}</Text>
                </Input>
                <IncreaseButton style={{ marginRight: '4px' }} onClick={() => onClickMinusButton(index)}>
                  <div style={{ width: `20px`, height: `3px`, borderRadius: `12px`, backgroundColor: '#FFFFFF' }} />
                </IncreaseButton>
                <IncreaseButton style={{ marginRight: '4px' }} onClick={() => onClickPlusButton(index)}>
                  <Image src={PlusIcon} />
                </IncreaseButton>
              </InputWrapper>
            </CountBoxWrapper>
          ))
        }
        </SuovenirListWrapper>
        <SelectButton onClick={onClickSelectButton}>
          <HoverText fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('선택')}</HoverText>
        </SelectButton>
      </TopLevelWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 790px;

	width: 100%;
	// height: 88.16%;
  height: ${props => props.modalSize};
  
	border-radius: 20px 20px 0px 0px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.5s ease-in-out;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 56px;

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const TitleMaximumWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 83.68%;
  height: 25px;

  overflow: hidden;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const EventBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // margin-top: 22px;
  margin-top: 6px;

  width: 90.33%;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const GenderBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(48.39% - 4px);
  height: 44px;

  border: ${props => props.isClicked ? '2px solid #6DD49E' : '2px solid #DFDFDF'};
  // border: 2px solid #DFDFDF;
  border-radius: 8px;
  // background-color: #FFFFFF;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#F9F9F9`};

  transition: all 0.3s ease-in-out;
`;

const SizeBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(30.48% - 4px);
  height: 44px;

  // border: ${props => props.isClicked ? '2px solid #6DD49E' : '2px solid #DFDFDF'};
  border: 2px solid #DFDFDF;
  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#F9F9F9`};

  transition: all 0.3s ease-in-out;
`;

const SuovenirListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  // min-height: 35.18%;
  // max-height: 35.18%;
  min-height: 27%;
  max-height: 27%;

  overflow: scroll;
`;

const CountBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  margin-top: 12px;

  width: 90.33%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Input = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: 34px;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 6px;
  background-color: #FFFFFF;
`;

const IncreaseButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 6px;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const SelectButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 34px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;