import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function TestRoutingPage() {

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let token = params.token;
    let organizerId = parseInt(params.organizerId);

    window.localStorage.setItem('Token', 'Bearer ' + token);

    // navigate('/info/girlcrush');

    if (organizerId === 18) navigate('/info_test/fitforceteamleague/');
    else if (organizerId === 19) navigate('/info_test/believer/');
    else if (organizerId === 20) navigate('/info_test/afk/');
    else if (organizerId === 21) navigate('/info_test/linkcoach_deadlift/');
  }, [])

  return <></>;
}