import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import VideoViewModal from '../../../Model/EventDetailModel/VideoViewModal';
import BattleCrew2ASection from '../../../Model/EventDetailModel/BattleCrewDetailModel/BattleCrew2ASection';
import ImageFocusModal from '../../../Model/EventDetailModel/ImageFocusModal';

import CrownIcon from '../../../Image/CrownIcon.png';
import TotalSection from '../../../Model/EventDetailModel/TotalSection';

export default function BattleCrewEvent2DetailPage() {

  const params = useParams();

  const navigate = useNavigate();

  const [totalVideoWidth, setTotalVideoWidth] = useState(374);
  const [totalVideoHeight, setTotalVideoHeight] = useState(374);

  const [modalVideoWidth, setModalVideoWidth] = useState(414);
  const [modalVideoHeight, setModalVideoHeight] = useState(232);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [nowSection, setNowSection] = useState('');
  const [videoId, setVideoId] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [title, setTitle] = useState('');
  const [isClickedViewVideo, setIsClickedViewVideo] = useState(false);
  const [isClickedFocusImage, setIsClickedFocusImage] = useState(false);

  const TopLevelRef = useRef();

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: '배클크루2 정식 크루 선발전', isAvailableGoback: true  }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // Token 저장 //
  useEffect(() => {
    (
      async () => { 
        await window.localStorage.setItem('Token', 'Bearer ' + params.token);
      }
    )();
  },[params])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    var totalVideoWidth = windowSize.width * 0.9033;
    var totalVideoHeight = totalVideoWidth * 0.5614;

    var modalVideoWidth = windowSize.width;
    var modalVideoHeight = modalVideoWidth * 0.5603;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);

    setModalVideoWidth(modalVideoWidth);
    setModalVideoHeight(modalVideoHeight);
  }, [windowSize])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // Section 저장 //
  useEffect(() => {
    (
      async () => { 
        if (nowSection === '')  {
          var prevSection = await window.localStorage.getItem('NowSection') === 'null' ? 'A' : window.localStorage.getItem('NowSection');
          
          setNowSection(prevSection);
        }
        else await window.localStorage.setItem('NowSection', nowSection);
      }
    )();

    var title = '';

    if (nowSection === 'A') title = '배틀크루2 정식 크루 선발전';
    else if (nowSection === 'TotalA') title = '배클크루2 정식 크루 선발전 [부산]';
    else if (nowSection === 'TotalB') title = '배클크루2 정식 크루 선발전 [수도권]';

    setTitle(title);
  }, [nowSection, TopLevelRef])
  
  // // Section 불러오기 //
  // useEffect(() => {
  //   (
  //     async () => { 
  //       var prevSection = await window.localStorage.getItem('NowSection') === undefined ? 'A' : window.localStorage.getItem('NowSection');
        
  //       setNowSection(prevSection);
  //     }
  //   )();
  // }, [])

  // // Section 저장 //
  // useEffect(() => {
  //   (
  //     async () => { 
  //       await window.localStorage.setItem('NowSection', nowSection);
  //     }
  //   )();
  // }, [nowSection])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
  }

  // Section Button 클릭시 //
  function onClickSectionButton(section) {
    // 날짜별 공개시점 정리 //
    setNowSection(section);
  }

  // Video 보기 버튼 클릭시 //
  function onClickVideoButton(videoId) {
    setVideoId(videoId);
    setIsClickedViewVideo(true);
  }

  // 이미지 클릭시 //
  function onClickImageFocus(imageURL) {
    setImageURL(imageURL);
    setIsClickedFocusImage(true);
  }

  return (
    <TopLevelWrapper>
    { 
      isClickedViewVideo && 
      <VideoViewModal 
        videoId={videoId}
        videoWidth={modalVideoWidth}
        videoHeight={modalVideoHeight}
        closeFn={() => setIsClickedViewVideo(false)}/>
    }
    { isClickedFocusImage && <ImageFocusModal size={modalVideoWidth} image={imageURL} closeFn={() => setIsClickedFocusImage(false)}/>} 
    <DataWrapper>
     <TopLineWrapper>
        <TitleWrapper>
          {/* <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#4F4F4F'>배클크루2 정식 크루 선발전</Text> */}
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#4F4F4F'>{title}</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>2022.10.23(토)</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine ref={TopLevelRef}/>
      <SectionButtonWrapper>
        <SectionButton isClicked={nowSection === 'A'} onClick={() => onClickSectionButton('A')}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>A</Text>
        </SectionButton>
        <TotalSectionAButton isClicked={nowSection === 'TotalA'} onClick={() => onClickSectionButton('TotalA')}>
          <CrownImage src={CrownIcon} />
        </TotalSectionAButton>
        <TotalSectionBButton isClicked={nowSection === 'TotalB'} onClick={() => onClickSectionButton('TotalB')}>
          <CrownImage src={CrownIcon} />
        </TotalSectionBButton>
      </SectionButtonWrapper>
      <DivisionLine/>
      { nowSection === 'A' && <BattleCrew2ASection totalVideoWidth={totalVideoWidth} totalVideoHeight={totalVideoHeight} onClickVideo={onClickVideoButton} onClickImageFocus={onClickImageFocus}/> }
      { nowSection === 'TotalA' && <TotalSection eventId={2} eventTitle='배클크루2 정식 크루 선발전 [부산]' eventDate='2022.10.23(토)' openDate='2022-08-20T00:00:00'/> }
      { nowSection === 'TotalB' && <TotalSection eventId={3} eventTitle='배클크루2 정식 크루 선발전 [수도권]' eventDate='2022.10.29(토)' openDate='2022-08-20T00:00:00'/> }
      </DataWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #FFFFFF;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 100%;

  overflow: scroll;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSectionAButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FF3131`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSectionBButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#2F80ED`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
