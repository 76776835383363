import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

export default function BattleCrew3FinalType1(props) {
  const { totalVideoWidth } = props;

  const { t } = useTranslation();

  const move1 = 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Type1_Move1.png';
  const move2 = 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Type1_Move2.png';
  const move3 = 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Type1_Move3.png';

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper>
          <SectionBox>
            <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>MOVE 1</Text>
          </SectionBox>
          <TitleBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>혼합형 종목 1 - 타이어 넘기기</Text>
          </TitleBox>
        </TitleWrapper>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoWidth}>
          <Image src={move1}/>
        </TotalVideoBox>

        <TitleWrapper style={{ marginTop: '15px' }}>
          <SectionBox>
            <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>MOVE 2</Text>
          </SectionBox>
          <TitleBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>혼합형 종목 2 - 타이어 탑 쌓기</Text>
          </TitleBox>
        </TitleWrapper>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoWidth}>
          <Image src={move2}/>
        </TotalVideoBox>

        <TitleWrapper style={{ marginTop: '15px' }}>
          <SectionBox>
            <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>MOVE 3</Text>
          </SectionBox>
          <TitleBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>혼합형 종목 3 - 슬래드 밀고 당기기</Text>
          </TitleBox>
        </TitleWrapper>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoWidth}>
          <Image src={move3}/>
        </TotalVideoBox>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const OrangeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  height: 18px;

  border-radius: 2px;
  background-color: #FF8B48;
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  // width: calc(89.3% - 69px);
  width: calc(90.33% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const MissionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 1px 9px 1px 9px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 130px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  background-color: #FF3131;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  // margin-top: 25px;
  margin-top: 10px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;