import React from "react";
import styled from "styled-components";

export default function BattleCrew3FinalType3(props) {
  const { totalVideoWidth } = props;

  const move1 = 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Type1_Move1.png';
  const move2 = 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Type1_Move2.png';
  const move3 = 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Type1_Move3.png';

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper>
          <SectionBox>
            <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>MOVE 7</Text>
          </SectionBox>
          <TitleBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>신체 제어 종목 1 - 셔틀런</Text>
          </TitleBox>
        </TitleWrapper>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoWidth}>
          <Image src={move1}/>
        </TotalVideoBox>

        <TitleWrapper style={{ marginTop: '15px' }}>
          <SectionBox>
            <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>MOVE 8</Text>
          </SectionBox>
          <TitleBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>신체 제어 종목 2 - 장애물 넘기</Text>
          </TitleBox>
        </TitleWrapper>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoWidth}>
          <Image src={move2}/>
        </TotalVideoBox>

        <TitleWrapper style={{ marginTop: '15px' }}>
          <SectionBox>
            <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>MOVE 9</Text>
          </SectionBox>
          <TitleBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>신체 제어 종목 3 - 물구나무 걷기</Text>
          </TitleBox>
        </TitleWrapper>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoWidth}>
          <Image src={move3}/>
        </TotalVideoBox>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  // width: calc(89.3% - 69px);
  width: calc(90.33% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  // margin-top: 25px;
  margin-top: 10px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;