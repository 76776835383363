import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import GenderBox from '../../Component/EventApplicationComponent/GenderBox';
import InputBox from '../../Component/InputBox';
import MemberInputBox from '../../Component/EventApplicationComponent/MemberInputBox';
import BloodTypeBox from '../../Component/EventApplicationComponent/BloodTypeBox';
import AddressBox from '../../Component/EventApplicationComponent/AddressBox';
import FetchModule from '../../Model/Network/FetchModule';

import LogoFileInput from '../../Component/EventApplicationComponent/LogoFileInput';
import TeamNameInputBox from '../../Component/EventApplicationComponent/TeamNameInputBox';
import NumberInputBox from '../../Component/EventApplicationComponent/NumberInputBox';
import LoadingAnimation from '../../Model/EventApplicationModel/LoadingAnimation';
import ShirtSizeBox from '../../Component/EventApplicationComponent/ThirtSizeBox';
import FindMemberInputBox from '../../Component/EventApplicationComponent/FindMemberInput';

export default function TPWorkshopApplicationPage() {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [profileSize, setProfileSize] = useState(120);
  const [plusIconSize, setPlusIconSize] = useState(32);
  const [imgBase64, setImgBase64] = useState();

  const [teamId, setTeamId] = useState(0);
  // const [isTeam, setIsTeam] = useState(params.isTeamFight === 'true' ? true : false);
  const [isModify, setIsModify] = useState(params.isModify === 'true' ? true : false);
  const [teamName, setTeamName] = useState('');
  const [merchantUid, setMerchantUid] = useState(undefined);
  const [belong, setBelong] = useState('');

  const [memberId, setMemberId] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [memberList, setMemberList] = useState([{
    name: '',
    email: '',
    birth: '',
    gender: 'female',
    bloodType: 'None',
    belong: '',
    phone: '',
    zipCode: '',
    address: '',
    addressDetail: '',
    // representative (대표자) or member (팀원) //
    division: 'representative'
  }]);

  const isTeam = false;

  // 시작시 스크롤 맨 위로 //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // Window Resize Handler And Scroll Detect Save And Delete //
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      window.removeEventListener('resize', handleResize);

      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 화면 크기 추출후 사진, 참가신청 공백 크기 계산 //
  useEffect(() => {
    let finalSize = windowSize.width * 0.2898;
    let iconSize = windowSize.width * 0.0772;

    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('개인정보 입력'), isAvailableGoback: false, getTeamData: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }

    setProfileSize(finalSize);
    setPlusIconSize(iconSize);
  }, [windowSize])

  // 받아온 데이터 정리 //
  useEffect(() => {
    setIsLoading(true);

    let eventData = JSON.parse(window.localStorage.getItem('EventData'));
    let eventId = params.eventId;

    // let isTeamFight = params.isTeamFight;

    // console.log(eventData);

    let requestData = {
      eventId: eventId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('member/', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {

          let newList = [];//responseData.data;

          newList.push(responseData.data);

          setMemberId(responseData.data.memberId);
          setMemberList(newList);
          setMerchantUid(responseData.data.merchantUid === '' ? undefined : responseData.data.merchantUid);
        }
      }
    )();

    setIsLoading(false);
  }, [params])

  // Window Resize Handler //
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
    else if (responseData.teamData) {
      // setTeamData(responseData.teamData);
      setImgBase64(responseData.teamData.teamLogo);
      setTeamName(responseData.teamData.teamName);
    }
  }

  // 참가자 이름 변경시 //
  function onChangeMemberName(value, index) {
    let newData = memberList.slice();

    newData[index].name = value;
    setMemberList(newData);
  }

  // 참가자 이메일 변경시 //
  function onChangeMemberEmail(value, index) {
    let newData = memberList.slice();

    newData[index].email = value;
    setMemberList(newData);
  }

  // 참가자 연락처 변경시 //
  function onChangeMemberPhone(value, index) {
    let newData = memberList.slice();

    newData[index].phone = value;
    setMemberList(newData);
  }

  // 참가자 소속 변경시 //
  function onChangeMemberBelong(value, index) {
    let newData = memberList.slice();

    newData[index].belong = value;
    setMemberList(newData);
  }

  // 참가자 생년월일 변경시 //
  function onChangeMemberBirth(value, index) {
    let newData = memberList.slice();

    newData[index].birth = value;
    setMemberList(newData);
  }

  // 참가자 성별 변경시 //
  function onChangeMemberGender(value, index) {
    // 정보 변경일때 팀장은 변경할 수 없음 //
    if (isModify && index === 0) return;

    let newData = memberList.slice();

    newData[index].gender = value;
    setMemberList(newData);
  }

  // 참가자 혈액형 변경시 //
  function onChangeMemberBloodType(value, index) {
    let newData = memberList.slice();

    newData[index].bloodType = value;
    setMemberList(newData);
  }

  // 참가자 우편번호 변경시 //
  function onChangeZipCode(value) {
    let newData = memberList.slice();

    newData[0].zipCode = value;
    setMemberList(newData);
  }

  // 참가자 주소 변경시 //
  function onChangeAddress(value) {
    let newData = memberList.slice();

    newData[0].address = value;
    setMemberList(newData);
  }

  // 참가자 상세주소 변경시 //
  function onChangeAddressDetail(value) {
    let newData = memberList.slice();

    newData[0].addressDetail = value;
    setMemberList(newData);
  }

  // 길드 이름 변경시 //
  function onChangeTeamBelong(value, index) {
    setBelong(value);
  }

  // 이전으로 돌아가기 클릭시 //
  function onClickPrevButton() {
    navigate(-1);
  }

  // 다음으로 넘어가기 클릭시 //
  function onClickNextButton() {
    setIsLoading(true);
    // var eventData = JSON.parse(window.localStorage.getItem('EventData'));

    if (memberList[0].birth.length !== 6) {
      alert(t('생년월일(YYMMDD)을 알맞게 기재해주세요'));
      setIsLoading(false);
      return;
    }
    else if (memberList[0].phone.length !== 11) {
      alert(t('전화번호를 알맞게 기입해주세요'));
      setIsLoading(false);
      return;
    }
    else if (memberList[0].address === '') {
      alert(t('주소를 입력해주세요'));
      setIsLoading(false);
      return;
    }
    else if (memberList[0].addressDetail === '') {
      alert(t('상세주소를 기입해주세요'));
      setIsLoading(false);
      return;
    }

    for (let i = 1; i < memberList.length; i++) {
      if (memberList[i].name === '') {
        alert(t('참가자 이름을 알맞게 기재해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[i].birth.length !== 6) {
        alert(t('생년월일(YYMMDD)을 알맞게 기재해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[i].phone.length > 11 || memberList[i].phone.length < 11) {
        alert(t('전화번호를 알맞게 기입해주세요!'));
        setIsLoading(false);
        return;
      }
    }

    // let members = [];

    // for (let i = 0; i < memberList.length; i++) {

    //   for (let j = 0; j < members.length; j++) {
    //     if (members[j].phone === memberList[i].phone) {
    //       alert(t('참가자의 전화번호는 중복될 수 없습니다.\n올바른 전화번호를 적어주세요'));
    //       setIsLoading(false);
    //       return;
    //     }
    //   }

    //   members.push({
    //     memberId: memberList[i].memberId,
    //     teamId: teamId,
    //     eventId: params.eventId,
    //     name: memberList[i].name,
    //     email: memberList[i].email === '' ? 'None' : memberList[i].email,
    //     birth: memberList[i].birth,
    //     bloodType: memberList[i].bloodType,
    //     phone: memberList[i].phone,
    //     zipCode: memberList[i].zipCode === '' ? 'None' : memberList[i].zipCode,
    //     address: memberList[i].address === '' ? 'None' : memberList[i].address,
    //     addressDetail: memberList[i].addressDetail === '' ? 'None' : memberList[i].addressDetail,
    //     division: memberList[i].division,
    //     gender: memberList[i].gender,
    //     belong: belong
    //   })
    // }

    // let teamData = {
    //   teamId: teamId,
    //   eventId: params.eventId,
    //   teamName: teamName,
    //   profile: imgBase64,
    //   isAdmin: false,
    //   members: members,
    //   merchantUid: merchantUid
    // }
    // // console.log('-----Team-----');
    // // console.log(teamData);
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.postDataOfEvent('team/save', 'POST', teamData);

    //     if (responseData.status === 200) {
    //       if (isModify) navigate(-1);
    //       else navigate('/check/battlecrew3/' + params.eventId + '/' + params.eventDay);
    //     }
    //   }
    // )();
    // }
      if (memberList[0].birth.length !== 6) {
        alert(t('생년월일(YYMMDD)을 알맞게 기재해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].address === '') {
        alert(t('우편번호를 기입해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].addressDetail === '') {
        alert(t('상세주소를 기입해주세요'));
        setIsLoading(false);
        return;
      }

      let requestData = {
        memberId: memberList[0].memberId,
        teamId: memberId,
        eventId: params.eventId,
        name: memberList[0].name,
        email: memberList[0].email === '' ? 'None' : memberList[0].email,
        birth: memberList[0].birth,
        bloodType: memberList[0].bloodType,
        phone: memberList[0].phone,
        zipCode: memberList[0].zipCode === '' ? 'None' : memberList[0].zipCode,
        address: memberList[0].address === '' ? 'None' : memberList[0].address,
        addressDetail: memberList[0].addressDetail === '' ? 'None' : memberList[0].addressDetail,
        division: memberList[0].division,
        gender: memberList[0].gender,
        belong: memberList[0].belong === '' ? 'None' : memberList[0].belong,
        merchantUid: merchantUid
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('member/save', 'POST', requestData);

          if (responseData.status === 200) {
            navigate('/check/ThePerformanceWorkshop/' + params.eventId);
          }
        }
      )();

    setTimeout(() => setIsLoading(false), 1000);

    // await setIsLoading(false);
    // try {
    //   window.ReactNativeWebView.postMessage(JSON.stringify({teamData: teamData}));
    // }
    // catch (e) {
    //   console.log("Web Message Post Error : " + e);
    // }

    // await window.localStorage.setItem('EventTeamData', JSON.stringify(teamData));
    // await window.localStorage.setItem('EventMemberData', JSON.stringify(memberList));

    // navigate('/check/' + params.isTeamFight);
  }

  // 연락처 검색 버튼 클릭시 //
  function onClickFindUserInfo(phone, index) {
    if (phone.length !== 11) {
      alert('전화번호를 알맞게 기입해주세요!');
      return;
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('member/match/' + phone, 'POST');
        // alert(responseData.status);
        // console.log(responseData.data === undefined);
        if (responseData.status === 200) {
          if (responseData.data === undefined) alert('링크코치에 가입된 회원이 아닙니다\n정보를 기입해주세요');
          else {
            let birth = responseData.data.socialNum.split('-')[0];
            let genderNumStr = responseData.data.socialNum.split('-')[1].replaceAll('*');
            let gender = 'male';

            if (genderNumStr === '1' || genderNumStr === '3') gender = 'male';
            else if (genderNumStr === '2' || genderNumStr === '4') gender = 'female';
            let newData = memberList.slice();

            newData[index].birth = birth;
            newData[index].gender = gender;
            newData[index].name = responseData.data.userName;

            setMemberList(newData);
          }
        }
        else {
          alert('링크코치에 가입된 회원이 아닙니다\n정보를 기입해주세요');
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      {isLoading && <LoadingAnimation />}
      <TitleWrapper>
        <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('개인정보를 입력해 주세요')}</Text>
      </TitleWrapper>
      {
        memberList.map((data, index) => (
          <ComponentWrapper key={index}>
            <SectionWrapper style={index === 0 ? { marginTop: `24px` } : { marginTop: `0px` }} /*style={{ marginTop: `18px` }}*/>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('연락처')}</Text>
              {
                (isModify && index === 0) ?
                  <InputViewBox>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#000000'>{data.phone}</Text>
                  </InputViewBox>
                  :
                  index === 0 ?
                    <InputBoxWrapper>
                      <NumberInputBox value={data.phone} setValue={onChangeMemberPhone} placeholder={t('연락처을 작성해주세요')} index={index} />
                    </InputBoxWrapper>
                    :
                    <InputBoxWrapper>
                      <FindMemberInputBox value={data.phone} setValue={onChangeMemberPhone} placeholder={t('연락처을 작성해주세요')} index={index} onClickFindUserInfo={onClickFindUserInfo} />
                    </InputBoxWrapper>
              }
            </SectionWrapper>
            <SectionWrapper style={{ marginTop: `18px` }} /*style={index === 0 ? { marginTop: `24px` } : { marginTop: `0px` }}*/>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : (t('팀원') + String.fromCharCode(index + 64) + ' ') : (t('참가자') + ' ')}{t('이름')}</Text>
              {
                (isModify && index === 0) ?
                  <InputViewBox>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#000000'>{data.name}</Text>
                  </InputViewBox>
                  :
                  <InputBoxWrapper>
                    <MemberInputBox value={data.name} setValue={onChangeMemberName} placeholder={t('이름을 기재해주세요')} index={index} />
                  </InputBoxWrapper>
              }
              <ExplanationWrapper>
                <Text style={{ marginRight: `3px` }} fontFamily='NotoSansKR-Medium' fontSize={12} color='#4F4F4F'>{t('실명이 아닐 경우')}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#FF3131'>{t('대회 참가에 불이익')}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#4F4F4F'>{t('이 있을 수 있습니다.')}</Text>
              </ExplanationWrapper>
            </SectionWrapper>
            {
              index === 0 &&
              <SectionWrapper style={{ marginTop: `18px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자') + ' '}{t('이메일')}</Text>
                {
                  (isModify && index === 0) ?
                    <InputViewBox>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#000000'>{data.email}</Text>
                    </InputViewBox>
                    :
                    <InputBoxWrapper>
                      <MemberInputBox value={data.email} setValue={onChangeMemberEmail} placeholder={t('이메일을 작성해주세요')} index={index} />
                    </InputBoxWrapper>
                }
              </SectionWrapper>
            }
            <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('생년월일')}</Text>
              {
                (isModify && index === 0) ?
                  <InputViewBox>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#000000'>{data.birth}</Text>
                  </InputViewBox>
                  :
                  <InputBoxWrapper>
                    {/* <MemberInputBox value={data.birth} setValue={onChangeMemberBirth} placeholder='생년월일을 작성해주세요' index={index} /> */}
                    <NumberInputBox value={data.birth} setValue={onChangeMemberBirth} placeholder={t('생년월일을 작성해주세요')} index={index} />
                  </InputBoxWrapper>
              }
            </SectionWrapper>
            <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('성별')}</Text>
              <InputBoxWrapper>
                <GenderBox value={data.gender} setValue={onChangeMemberGender} index={index} />
              </InputBoxWrapper>
            </SectionWrapper>
            {/* <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')} 기념품 티셔츠 사이즈</Text>
              <InputBoxWrapper>
                <ShirtSizeBox value={data.bloodType} setValue={onChangeMemberBloodType} index={index} />
              </InputBoxWrapper>
            </SectionWrapper> */}
            {/* <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('혈액형')}</Text>
              <InputBoxWrapper>
                <BloodTypeBox value={data.bloodType} setValue={onChangeMemberBloodType} index={index} />
              </InputBoxWrapper>
            </SectionWrapper> */}
            {/* <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('연락처')}</Text>
              {
                (isModify && index === 0) ?
                  <InputViewBox>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#000000'>{data.phone}</Text>
                  </InputViewBox>
                  :
                  <InputBoxWrapper>
                    <NumberInputBox value={data.phone} setValue={onChangeMemberPhone} placeholder={t('연락처을 작성해주세요')} index={index} />
                  </InputBoxWrapper>
              }
            </SectionWrapper> */}
            <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('소속')}</Text>
              {
                <InputBoxWrapper>
                  <MemberInputBox value={data.belong} setValue={onChangeMemberBelong} placeholder={t('소속을 기재해주세요')} index={index} />
                  {/* <BelongExplanationWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={9} color='#FF3131'>{t('소속 박스명은 crossfit.com 에 등재된 그대로 작성해주세요. 만일 제휴 박스가 아닌 경우, \'없음\' 으로 기재하세요.')}</Text>
                  </BelongExplanationWrapper> */}
                </InputBoxWrapper>
              }
            </SectionWrapper>
            {
              index === 0 &&
              <SectionWrapper style={{ marginTop: `18px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('주소')}</Text>
                <InputBoxWrapper>
                  <AddressBox
                    windowSize={windowSize}
                    zipCode={data.zipCode}
                    onChangeZipCode={onChangeZipCode}
                    address={data.address}
                    onChangeAddress={onChangeAddress}
                    addressDetail={data.addressDetail}
                    onChangeAddressDetail={onChangeAddressDetail} />
                </InputBoxWrapper>
              </SectionWrapper>
            }
            <DivisionLine />
          </ComponentWrapper>
        ))
      }
      <NextButtonWrapper>
        <NextInnerButtonWrapper>
          <Button backgroundColor='#E0E0E0' onClick={onClickPrevButton}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('이전')}</Text>
          </Button>
          <Button backgroundColor='#6DD49E' onClick={onClickNextButton}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{isModify ? t('저장') : t('다음')}</Text>
          </Button>
        </NextInnerButtonWrapper>
      </NextButtonWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #F9F9F9;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 8px;

  width: 90.33%;
`;

const TeamInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 24px;

  width: 90.33%;
`;

const ProfileBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  margin-top: 8px;
  border-radius: 8px;
  background-color: #D9D9D9;

  :hover {
    cursor: pointer;
  }
`;

const TeamNameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 62.29%;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 8px;
`;

const BelongExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 87.96%;
  width: 100%;
`;

const InputViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 8px;
  
	width: calc(100% - 24px);
	height: 40px;

	padding: 0 12px 0 12px;

	border: 1px solid #E8E8E8;
	border-radius: 8px;
  background-color: #E0E0E0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-top: 8px;
`;

const NextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-end;

  // padding: 0px 0px 34px 0px;
  padding-bottom: 15px;
  margin: 52px 0px 0px 0px;

  width: 100%;
  height: 68px;

  background-color: #FFFFFF;
`;

const NextInnerButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 60px;

  border-radius: 12px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;
const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 36px 0px 36px 0px;

  background-color: #E0E0E0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;