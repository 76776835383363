import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export default function FreePaymentCompletePage() {

  const { t } = useTranslation();
  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('신청 완료'), isAvailableGoback: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    var responseData = JSON.parse(e.data);

    // if (responseData.goBack) navigate(-1);
  }

  // 메인화면으로 버튼 클릭시 //
  function onClickGoMainButton() {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  return (
    <TopLevelWrapper>
      <MessageBox>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('결제 및 등록신청이 완료되었습니다')}</Text>
        </TextWrapper>
        <GoMainButton onClick={onClickGoMainButton}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('메인 화면 으로')}</Text>
        </GoMainButton>
      </MessageBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  background-color: #F9F9F9;
`;

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
  height: 202px;

  border-radius: 12px;
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 53px;
`;

const GoMainButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 53px;

  width: 89.3%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;