import React, { useEffect, useState } from "react";
import styled from "styled-components";

import moment from "moment";

import NameArrow from '../../../Image/NameArrow.png';
import { useTranslation } from "react-i18next";

export default function EventBox(props) {
  const { data, index, language, eventTitleFontSize } = props;

  const { t } = useTranslation();

  const [ageDetail, setAgeDetail] = useState(t('(2003년 01월 01일 이전 출생자)'));

  // 참가 가능 생년월일 계산 //
  useEffect(() => {
    var ageDetailDate = moment().subtract(parseInt(data.minimumAgeLimit), 'years');
    // console.log('=====');
    // console.log(data);
    setAgeDetail('(' + ageDetailDate.format('YYYY') + t('년 01월 01일 이전 출생자)'));
  }, [data])

  // 참가비 글자화 //
  function numberToKorean(number, usd){
    return '80,000 KRW';
  }

  // 참가 자격 설정 함수 //
  function calcEligibility(data) {
    if (data.eventId === 58 || data.eventId === 59) return '남남 (2인 1팀), 남여 (2인 1팀)';
    else return '남/여';
  }

  // 참가 자격 설정 함수 //
  function calcEligibilityExplanation(data) {
    if (data.eventId === 58) return '제한 없음';
    else return '남남 (2인 1팀) / 전현직 코치 불가, 동일 박스 소속';
  }

  // 참가 자격 설정 함수 //
  function calcEligibilityExplanation2(data) {
    if (data.eventId === 58) return '';
    else if (data.eventId === 59) return '(지점 연합 불가)';
    else return '(지점 연합 불가) / 본선 진출 불가';
  }

  // 대회 장소 표출을 위한 설정 함수 //
  function calcPlace(data) {
    // return data.place;
    // if (data.eventId === 34 || data.eventId === 35) return data.place;
    // else return 'Offline';
    // return '60,000';
    return 'Online';
  }

  return (
    <TopLevelWrapper isFirst={index === 0}>
      <EventInnerWrapper>
        <EventNameWrapper>
          <EventNameImage src={NameArrow} style={{ marginRight: `6px` }}/>
          {/* <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#333333'>{data.eventTitle}</Text> */}
          <Text fontFamily='NotoSansKR-Black' fontSize={eventTitleFontSize} color='#333333'>{data.eventTitle}</Text>
        </EventNameWrapper>
        <EventInfoWrapper style={{ marginTop: `24px` }}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('참가자격')}</Text>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#333333'>{ data.minimumAgeLimit === 0 ? t('제한 없음') : t('만') + ' ' + data.minimumAgeLimit + ' ' + t('세 이상')}</Text> */}
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#333333'>{calcEligibility(data)}</Text>
        </EventInfoWrapper>
        {/* <EventInfoWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'></Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={'#828282'}>{t('(미성년자는 부모님 동의가 필요할 수 있습니다)')}</Text>
        </EventInfoWrapper> */}
        <EventInfoWrapper style={{ justifyContent: 'flex-end' }}>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color={'#828282'}>{calcEligibilityExplanation(data)}</Text>
        </EventInfoWrapper>
        <EventInfoWrapper style={{ justifyContent: 'flex-end' }}>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'></Text> */}
          <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color={'#828282'}>{calcEligibilityExplanation2(data)}</Text>
        </EventInfoWrapper>
        <EventInfoWrapper style={{ marginTop: `16px` }}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('참가비')}</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.participationFee === 0 ? t('무료') : numberToKorean(data.participationFee, data.participationFeeUSD)}</Text>
        </EventInfoWrapper>
        <EventInfoWrapper style={{ marginTop: `3px` }}>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('대회장소')}</Text> */}
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('장소')}</Text>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#333333'>{data.place}</Text> */}
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{calcPlace(data)}</Text>
        </EventInfoWrapper>
      </EventInnerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 16px 0px;

  width: 100%;
  // height: 178px;
  // height: 243px;

  margin-top: ${props => props.isFirst ? 0 : 24}px;

  border-radius: 12px;
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);
  background-color: #FFFFFF;
`;

const EventInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 92.24%;
`;

const EventNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const EventNameImage = styled.img`
  width: 12px;
  height: 12px;
`;

const EventInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;