import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import VideoIcon from '../../../Image/VideoIcon.png';
import { useTranslation } from "react-i18next";
import SubmitModel from "./SubmitModel";
import FetchModule from "../../Network/FetchModule";
import SubmitViewModel from "./SubmitViewModel";

export default function FETOnlineJANUARYSection(props) {
  const { setIsSavingRecord, setIsCompleteSubmit, totalVideoWidth, totalVideoHeight, ruleBookWidth, ruleBookHeight, onClickVideo, onClickImageFocus } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isClickedSubmit, setIsClickedSubmit] = useState(false);

  const [eventId, setEventId] = useState(4);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });
  const [distance, setDistance] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [link, setLink] = useState('');
  // const [imgBase64, setImgBase64] = useState('');
  const [imgBase64, setImgBase64] = useState([]);

  const [isExistRecord, setIsExistRecord] = useState(false);

  const [viewMin, setViewMin] = useState(0);
  const [viewSec, setViewSec] = useState(0);

  const [viewDistance, setViewDistance] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState([]);
  
  // 가장 최근기록 가져오기 //
  useEffect(() => {
    var url = window.location.pathname.split('/');
    // console.log(url[2]);
    setEventId(parseInt(url[2]));

    var requestData = {
      eventId: parseInt(url[2]),
      section: 'A'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setIsExistRecord(true);
          setViewMin(Math.floor(responseData.data.time / 60));
          setViewSec(responseData.data.time % 60);
          setViewLink(responseData.data.link);
          setViewDistance(responseData.data.distance); 

          setMin(Math.floor(responseData.data.time / 60));
          setSec(responseData.data.time % 60);
          setDistance(responseData.data.distance);
          setLink(responseData.data.link);
          setPicture(responseData.data.picture);
          // console.log(responseData.data.picture === '')
          // if (responseData.data.picture !== '') {
          //   let pictures = responseData.data.picture.split('<-@->');

          //   setPicture(pictures);
          // }
        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
          // if (window.confirm("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다")) setIsClickedSubmit(true);
        }
      }
    )();
  }, [])

  // 순위표 버튼 클릭시 //
  function onClickRankingButton() {
    // navigate('/ranking/2/3/2/A/배틀크루2 정식 크루 선발전 [안양]/2022.10.29(토)');
    navigate('/rank/4');
  }

  // // 참자가 정보 수정 //
  // function onClickModifyMember() {
  //   navigate('/check/3/true/true');
  // }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {
    
    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {
      // if (min === 0 && sec === 0) {
      //   alert('기록을 입력해주세요');
      //   return;
      // } 
      // else 
      if ((link !== '' 
        && !link.includes('https://www.youtube.com/watch?v=') 
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=') 
        && !link.includes('https://youtu.be/'))){
        alert('영상링크를 정확히 입력해주세요');
        return;
      }
      
      setIsSavingRecord(true);

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let finalMin = min === '' ? 0 : parseInt(min * 60);
      let finalSec = sec === '' ? 0 : parseFloat(sec);

      var requestData = {
        eventId: eventId,
        section: 'A',
        // time: parseInt(min * 60) + parseFloat(sec),
        time: finalMin + finalSec,
        isRx: true,
        distance: distance,
        completeRep: 0,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: 0,
        firstExercise: 0,
        secondExercise: 0,
        thirdExercise: 0,
        link: link,
        // picture: imgBase64
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);

          if (responseData.status === 200) {
            setIsSavingRecord(false);
            setIsCompleteSubmit(true);
          }
        }
      )();
      
      setIsClickedSubmit(false);
    }
    else {
      var requestData = {
        eventId: eventId,
        section: 'A'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n새로운 기록을 저장하시면 이전 기록은 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/FETOnline', fileName: '[FET_LinkCoach]final_ScorecardOQEvent1.pdf' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  function test() {               
    try {
      // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.29:3000/payment/' + responseData.data + '/' + orderName + '/' + eventData.totalPrice + "/" + window.localStorage.getItem('Token') }));
      window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://www.naver.com' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment('2022-08-20T00:00:00').format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>[FET] Online JANUARY</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>1월 1일 공개예정</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>A</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>For time of with Timecap 20min :</Text>
              </TitleBox>
            </TitleWrapper>
            {
              (!isExistRecord && !isClickedSubmit) &&
              <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
                <iframe
                  width='100%'
                  height='100%'
                  src={`https://www.youtube.com/embed/i9_KupTQlxk`}
                  style={{ borderRadius: '12px' }}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube" />
              </TotalVideoBox>
            }
            <AthleteBox>
              <AthleteTextWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>For time of :</Text>
              </AthleteTextWrapper>
            </AthleteBox>
            <ExerciseWrapper style={{ marginTop: '10px' }}>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                    </ExerciseNumberBox>
                    <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>ROW</Text>
                  </ExerciseNumberBoxWrapper>
                  <Image src={VideoIcon} onClick={() => onClickVideo('i9_KupTQlxk')} />
                </InfoTitleWrapper>
                {/* <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
                  <DivisionLine width={50} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:115</Text>
                  <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:160</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                </InfoDataWrapper> */}
                <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('거리')}</Text>
                  <DivisionLine width={60} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>2,000</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <AthleteBox style={{ marginTop: '6px' }} >
              <AthleteTextWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>EMOM</Text>
              </AthleteTextWrapper>
            </AthleteBox>
            <ExerciseWrapper style={{ marginTop: '10px' }}>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                    </ExerciseNumberBox>
                    <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Burpee over the Rower</Text>
                  </ExerciseNumberBoxWrapper>
                  <Image src={VideoIcon} onClick={() => onClickVideo('i9_KupTQlxk')} />
                </InfoTitleWrapper>
                <InfoDataWrapper style={{ marginTop: '16px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                  <DivisionLine width={68} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>6</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            {
              (isExistRecord || isClickedSubmit) ?
                <ExerciseWrapper>
                  {
                    isClickedSubmit ? 
                      <SubmitModel
                        eventId={eventId}
                        section='A'
                        isSuccess={isSuccess}
                        setIsSuccess={setIsSuccess}
                        min={min}
                        setMin={setMin}
                        sec={sec}
                        setSec={setSec}
                        distance={distance}
                        setDistance={setDistance}
                        link={link}
                        setLink={setLink}
                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}/>
                      :
                      <SubmitViewModel
                        eventId={eventId}
                        section='A'

                        setIsExistRecord={setIsExistRecord}

                        min={viewMin}
                        sec={viewSec}
                        distance={viewDistance}
                        link={viewLink}
                        picture={picture} />
                  }
                </ExerciseWrapper>
                :
                <ExerciseWrapper>
                  <InfoWrapper>
                    <InfoTitleWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                    </InfoTitleWrapper>
                  </InfoWrapper>
                  <ScoreGuideLineImageWrapper width={ruleBookWidth} height={ruleBookHeight} onClick={downloadPDF}>
                    <ScoreGuideLineImage src='https://picture.link-zone.org/event/FETOnline/FAREvent1.png' />
                  </ScoreGuideLineImageWrapper>
                  <RuleBookWrapper>
                    <RuleColumnTextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                    </RuleColumnTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `21px` }}>
                      <Point />
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('시작')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- Burpee over the row부터 시작합니다.')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `15px` }}>
                      <Point />
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>ROW</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 시작전 로잉 거리를 2,000m로 세팅해야 합니다.')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `15px` }}>
                      <Point />
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>Burpee over the row</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 버피 수행 중 신체의 어느 부위도 테이프 라인에 닿거나 벗어나면 안됩니다.')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 로잉 머신은 명확히 점프하여 넘어가야 합니다.')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 점프 중 로잉 머신에 닿으면 안됩니다.')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('(닿을시 점프만 다시 수행하여야 합니다.)')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 선수가 로잉머신을 넘어가는 순간 양 발은 모두 땅에서 떨어져있어야 합니다.')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 점프 시작 시 양발이 동시에 땅에서 떨어질 필요는 없습니다.')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 착지 시 양발이 동시에 땅에 닿을 필요는 없습니다.')}</Text>
                    </RuleTextWrapper>
                    <RuleTextWrapper style={{ alignItems: 'flex-start', marginTop: `15px` }}>
                      <Point style={{ marginTop: '7px' }}/>
                      <OvertextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('그 외 동작기준은 CrossFit 2023 Judge Course 를 따른다.')}</Text>
                      </OvertextWrapper>
                    </RuleTextWrapper>
                  </RuleBookWrapper>
                </ExerciseWrapper>
            }
            {/* {
              isClickedSubmit ?
              <ExerciseWrapper>
                <SubmitModel
                  eventId={eventId}
                  section='A'
                  min={min}
                  setMin={setMin}
                  sec={sec}
                  setSec={setSec}
                  link={link}
                  setLink={setLink}
                  imgBase64={imgBase64}
                  setImgBase64={setImgBase64}/>
              </ExerciseWrapper>
              :
              <ExerciseWrapper>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                  </InfoTitleWrapper>
                </InfoWrapper>
                <ScoreGuideLineImageWrapper size={totalVideoWidth} onClick={downloadPDF}>
                  <ScoreGuideLineImage src='https://picture.link-zone.org/event/FETOnline/FAREvent1.png' />
                </ScoreGuideLineImageWrapper>
                <RuleBookWrapper>
                  <RuleColumnTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                  </RuleColumnTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `21px` }}>
                    <Point />
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('시작')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- Burpee over the row부터 시작합니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `15px` }}>
                    <Point />
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>ROW</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 시작전 로잉 거리를 2,000m로 세팅해야 합니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `15px` }}>
                    <Point />
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>Burpee over the row</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 버피 수행 중 신체의 어느 부위도 테이프 라인에 닿거나 벗어나면 안됩니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 로잉 머신은 명확히 점프하여 넘어가야 합니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 점프 중 로잉 머신에 닿으면 안됩니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('(닿을시 점프만 다시 수행하여야 합니다.)')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 선수가 로잉머신을 넘어가는 순간 양 발은 모두 땅에서 떨어져있어야 합니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 점프 시작 시 양발이 동시에 땅에서 떨어질 필요는 없습니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('- 착지 시 양발이 동시에 땅에 닿을 필요는 없습니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `15px` }}>
                    <Point />
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('그 외 동작기준은 CrossFit 2023 Judge Course 를 따른다.')}</Text>
                  </RuleTextWrapper>
                </RuleBookWrapper>
              </ExerciseWrapper>
            } */}
          </TotalWrapper>
      }
      {
        moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-02-10T23:59:59').format('YYYY.MM.DD HH:mm:ss') ?
          <RankingButton onClick={onClickRankingButton}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
          </RankingButton>
          :
          <RankingButton onClick={onClickRecordAndSubmit}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{isExistRecord ? t('수정하기') : isClickedSubmit ? t('제출하기') : t('기록하기')}</Text>
          </RankingButton>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: #6DD49E;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  width: calc(89.3% - 24px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 25px 0px 25px 0px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;
  margin: 15px 0px 8px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const BetweenBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 9px 0px 13px 0px;

  width: 100%;
  height: 24px;

  background-color: #6DD49E;
`;

const BetweenTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const AthleteBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
  height: 20px;
`;

const AthleteTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 13px;

  width: ${props => props.width}px;
  // height: ${props => props.height}px;
  height: auto;

  border-radius: 10px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
  // background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 35px;

  margin-top: 15px;

  // gap: 15px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;

  gap: 10px;
  
  width: 100%;
`;

// const PointWrapper = styled.div`
//   display: flex;
//   flex-direction: column;

//   justify-content: center;
//   align-items: center;
// `;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;

  border-radius: 10px;
  
  background-color: #FF3131;
`;

const OvertextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
