import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GirlFitRecordSubmitModalForAdmin from './Model/GirlFitRecordSubmitModal';
import FetchModule from '../../Model/Network/FetchModule';

export default function GirlFitAdminPage() {
  const [totalMembers, setTotalMembers] = useState([]);
  const [finalMembers, setFinalMembers] = useState([]);
  const [value, setValue] = useState('');
  const [individuals, setIndividuals] = useState([]);
  const [teams, setTeams] = useState([]);
  const [clickData, setClickData] = useState(undefined);
  const [isViewModal, setIsViewModal] = useState(false);

  // 데이터 호출 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('event/girlfit/admin', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          let individuals = responseData.data.individuals.slice();
          let teams = responseData.data.teams.slice();
          let total = individuals.concat(teams);

          setIndividuals(individuals);
          setTeams(teams);
          setTotalMembers(total);
        }
        else alert('Error');
      }
    )();
  }, [])

  // 검색 기능 포함 ( 한글 검색 알고리즘 최신화 ) //
  useEffect(() => {
    let nameList = [];

    for (let i = 0; i < totalMembers.length; i++) {
      if (totalMembers[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(totalMembers[i]);
    }

    if (nameList.length === 0) {
      for (let i = 0; i < totalMembers.length; i++) {
        if (match(value.toLowerCase(), totalMembers[i].name.toLowerCase())) nameList.push(totalMembers[i]);
      }
    }

    setFinalMembers(nameList);
  }, [totalMembers, value, match()])

  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;
    
    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
            || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    let consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    let res = [value];

    let kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }
      
      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    let consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    let kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;
    
    let s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    let s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    let valueSize = value.length;
    let dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (let i = 0; i < (dataSize - valueSize + 1); i++) {
      let res = true;

      for (let j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }
    
    return false;
  }


	function handleChange(e) {
		setValue(e.target.value);
	}

  function calcData(data) {
    // console.log(data); 
    // if (data.teamId !== undefined) {
    //   if (data.eventId === 21) return data.name + ' ( Scale Team )';
    //   else return data.name + ' ( Rxd Team )';
    // }
    // else {
    //   if (data.gender === 'male') return data.name + ' ( 남성 )';
    //   else return data.name + ' ( 여성 )';
    // }
    if (data.eventId === 22) return data.name + ' (Rxd)';
    else if (data.eventId === 23) return data.name + ' (Scale)';
    else if (data.eventId === 24) return data.name + ' (Team)';
  }

  function onClickData(data) {
    setClickData(data);
    setIsViewModal(true);
  }

  function onClickNo() {
    setClickData(undefined);
    setIsViewModal(false);
  }

  return (
    <TopLevelWrapper>
      { isViewModal && <GirlFitRecordSubmitModalForAdmin clickData={clickData} closeFn={onClickNo}/> }
      <Text style={{ marginTop: '18px' }} fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>GirlFit 기록 게시</Text>
      <Input value={value} onChange={handleChange} placeholder='회원명 또는 팀명을 입력하세요'/>
      <ScrollBox>
        {
          finalMembers.map((data, index) => (
            <InfoBox key={index} onClick={() => onClickData(data)}>
              {calcData(data)}
            </InfoBox>
          ))
        }
      </ScrollBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #FFFFFF;
`;

const ScrollBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  gap: 12px;

  width: 90.33%;
  height: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 16px 8px 16px;

  width: calc(100% - 34px);
  height: 20px;

  border: 1px solid #FF8B48;
  border-radius: 12px;
  background-color: #FFFFFF;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #4F4F4F;

  :hover {
    cursor: pointer;
    background-color: #FF8B48;
    color: #FFFFFF;
  }

  transition: all 0.3s ease-in-out;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	
  margin-top: 8px;
	padding: 0 8px 0 8px;

	outline: none;
	// border-width: 0 0 1px 0;
	// border-color: #E0E0E0;
  border: 1px solid #E0E0E0;
	color: #000000;

  width: calc(90.33% - 18px);
  height: 30px;

  border-radius: 8px;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.5s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;