import { useState, React, useEffect } from 'react';
import styled from 'styled-components';

export default function NormalInput(props) {
	const { value, setValue, placeholder } = props;

	const [changed, setChanged] = useState(false);

	useEffect(() => {
	}, [value])

	function handleClick(e) {
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		if (value === "") {
			// setDefaultPlaceholder(placeholder);
			// inputRef.current.style.borderColor = '#FF3131';
		} 
		else {
			// inputRef.current.style.borderColor = '#E8E8E8';
		}
	}

	return <Input
		type='text'
		value={value}
    placeholder={placeholder}
		onClick={handleClick}
		onChange={handleChange}
		onFocus={handleFocus}
		onBlur={handleBlur} />
}

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	
	padding: 0 0 0 0;
	margin-top: 8px;

	outline: none;
	border-width: 0 0 1px 0;
	border-color: #E0E0E0;
	color: #000000;

  width: 100%;

	::placeholder {
		// color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
		color: ${props => props.changedColor ? `#FF3131` : `#CBCBCB`};
	}
	
  transition: all 0.5s ease-in-out;
`;