import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import NoneProfile from '../Image/NoneProfile.png';
import ImageFocusModal from '../Model/EventDetailModel/ImageFocusModal';
import MemberDetailModal from '../Model/EventDetailModel/MemberDetailModal';

export default function MemberDetailPage() {

  const location = useLocation();
  const navigate = useNavigate();

  const [eventTitle, setEventTitle] = useState(location.state.eventTitle);
  const [eventDate, setEventDate] = useState(location.state.eventDate);
  const [data, setData] = useState(location.state.data);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [clickMemberDetailData, setClickMemberDetailData] = useState({
    linkCoachProfile: '',
    name: '',
    birth: '',
    gender: '',
    bloodType: '',
    phone: '',
    linkCoachNickName: ''
  })
  const [isClickedFocusImage, setIsClickedFocusImage] = useState(false);
  const [isClickedMemberDetail, setIsClickedMemberDetail] = useState(false);

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: '팀원 보기', isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) { 
    var responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
  }

  // 팀원 자세히보기 클릭시 //
  function onClickMemberBox(data) {
    setClickMemberDetailData(data);
    setIsClickedMemberDetail(true);
  }

  return (
    <TopLevelWrapper>
      { isClickedFocusImage && <ImageFocusModal size={windowSize.width} image={data.profile} closeFn={() => setIsClickedFocusImage(false)}/> }
      { isClickedMemberDetail && <MemberDetailModal data={clickMemberDetailData} closeFn={() => setIsClickedMemberDetail(false)}/> }
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{eventTitle}</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>{eventDate}</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <TeamInfoWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
        <TeamImageWrapper onClick={() => setIsClickedFocusImage(true)}>
          <TeamImage src={data.profile === '' ? NoneProfile : data.profile} />
        </TeamImageWrapper>
        <Text style={{ marginTop: '16px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>팀 구성원</Text>
        <RankingTotalWrapper>
          {
            data.members.map((data, index) => (
              <RankingViewWrapper key={index}>
                <RankingNumberBoxWrapper>
                  <RankingNumberBox>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                  </RankingNumberBox>
                </RankingNumberBoxWrapper>
                <RankingClickBox onClick={() => onClickMemberBox(data)}>
                  <InfoBox>
                    <LeftWrapper>
                      <Profile src={data.linkCoachProfile === '' ? NoneProfile : data.linkCoachProfile} />
                      <InfoTextWrapper style={{ marginLeft: '8px' }}>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{data.linkCoachNickName}</Text>
                      </InfoTextWrapper>
                    </LeftWrapper>
                  </InfoBox>
                </RankingClickBox>
              </RankingViewWrapper>
            ))
          }
        </RankingTotalWrapper>
      </TeamInfoWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100vh;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const TeamInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const TeamImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 120px;
  height: 120px;

  border-radius: 12px;

  overflow: hidden;
`;

const TeamImage = styled.img`
  width: 100%;
  height: 100%;
`;


const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin: 4px 0px 80px 0px;

  width: 100%;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(87.16% - 28px);
  height: 62px;

  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  // :hover {
  //   cursor: pointer;
  //   background-color: #F9F9F9;
  // }

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
