import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputBox from '../../Component/InputBox';
import TimeInput from '../../Component/ModifyRankingComponent/TimeInput';
import FetchModule from '../../Model/Network/FetchModule';

export default function ModifyModal(props) {
  const { isTeamFight, data, nowSection, closeFn } = props;

  const [rank, setRank] = useState(0);
  const [score, setScore] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  useEffect(() => {
    // console.log(data);
    // console.log(nowSection);
    // if (nowSection === 'C' || nowSection === 'E') {
    //   setMinute(data.time === - 1 ? 0 : parseInt(data.time / 60));
    //   setSecond(data.time === - 1 ? 0 : parseInt(data.time % 60));
    // }
    // console.log(data);
    setRank(data.rank);
    setMinute(data.minute);
    setSecond(data.second);
    setScore(data.score);
  }, [data, nowSection])

  // 변경 버튼 클릭시 //
  function onClickChange() {
    if (rank === 99999) {
      alert('순위를 입력해주세요');
      return;
    }

    var requestData = {
      isTeamFight: isTeamFight,
      id: data.id,
      point: parseInt(score === -1 ? 0 : score),
      // time: (minute * 60) + second,
      section: nowSection,
      minute: minute,
      second: second,
      rank: parseInt(rank)
    }
    // console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('score/modify', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          alert("저장에 성공하였습니다.");
          closeFn();
        }
      }
    )();
  }

  //  닫기 버튼 클릭시 //
  function onClickExitButton() {
    closeFn();
  }

  // 점수 뷰 정리 //
  function calcViewPointAndTimeText(data) {
    // if (data.minute !== 0 && data.second !== 0) return 'Point ' + data.score + ' - ' + data.minute + 'M ' + data.second + 'S';
    // else if (data.minute !== 0 && data.second === 0) return 'Point ' + data.score + ' - ' + data.minute + 'M';
    // else if (data.minute === 0 && data.second !== 0) return 'Point ' + data.score + ' - ' + data.second + 'S';
    // else return 'Point ' + data.score;
    var str = '';

    if (data.minute !== 0) str += data.minute + '분 ';
    if (data.second !== 0) str += data.second + '초 ';
    if (data.score !== 0) {
      if (str === '') str += 'Point ' + data.score;
      else str += '(Point ' + data.score + ')';
    }

    return str;
  }

  return (
    <Background>
      <TopLevelWrapper>
        <TitleLineWrapper>
          <TitleWrapper>
            <HoverText fontFamily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF'>닫기</HoverText>
            <TitleMaximumWrapper>
              <HoverText fontFamily='NotoSansKR-Bold' fontSize={17} color='#4F4F4F'>점수 변경</HoverText>
            </TitleMaximumWrapper>
            <HoverText onClick={onClickExitButton} fontFamily='NotoSansKR-Medium' fontSize={16} color='#6DD49E'>닫기</HoverText>
          </TitleWrapper>
        </TitleLineWrapper>
        <InfoBoxTotalWrapper>
          <InfoBoxWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>팀 이름</Text>
            <InfoBox style={{ marginTop: '8px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{data.name}</Text>
            </InfoBox>
          </InfoBoxWrapper>
          <InfoBoxWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>섹션</Text>
            <InfoBox style={{ marginTop: '8px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{nowSection}</Text>
            </InfoBox>
          </InfoBoxWrapper>
          <InfoBoxWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>현재 점수</Text>
            <InfoBox style={{ marginTop: '8px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{data.score === -1 ? '점수 없음' : calcViewPointAndTimeText(data)}</Text>
            </InfoBox>
          </InfoBoxWrapper>
        </InfoBoxTotalWrapper>
        <BottomInputBoxTotalWrapper>
          <SmallInfoBoxWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>변경 순위</Text>
            <InputBoxWrapper>
              <InputBox value={rank} setValue={setRank} />
            </InputBoxWrapper>
          </SmallInfoBoxWrapper>
          <SmallInfoBoxWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>변경 분</Text>
            <InputBoxWrapper>
              <InputBox value={minute} setValue={setMinute} />
            </InputBoxWrapper>
          </SmallInfoBoxWrapper>
          <SmallInfoBoxWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>변경 초</Text>
            <InputBoxWrapper>
              <InputBox value={second} setValue={setSecond} />
            </InputBoxWrapper>
          </SmallInfoBoxWrapper>
          <SmallInfoBoxWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>변경 점수</Text>
            <InputBoxWrapper>
              <InputBox value={score} setValue={setScore} />
            </InputBoxWrapper>
          </SmallInfoBoxWrapper>
        </BottomInputBoxTotalWrapper>
        <ModifyButton onClick={onClickChange}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>점수 변경 하기</Text>
        </ModifyButton>
      </TopLevelWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

	width: 90.33%;
	height: 330px;
  
  padding: 0 0 0 0;
  margin: 0 0 0 0;

	border-radius: 28px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.5s ease-in-out;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 56px;

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const TitleMaximumWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 83.68%;
  height: 25px;

  overflow: hidden;
`;
const InfoBoxTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 90.33%;
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 30%;
`;


const SmallInfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 20%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0 8px 0 8px;

  // width: 64px;
  width: calc(100% - 18px);
  height: 32px;

  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #F9F9F9;
`;

const InputBoxTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  margin-top: 20px;

  width: 90.33%;
`;

const BottomInputBoxTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 90.33%;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 8px;

  width: 100%;
`;

const TimeInputBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 100%;
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 30px;

  width: 90.33%;
  height: 48px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }

  transition: all 0.3s ease-in-out;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;