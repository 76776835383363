import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SuccessSelectBox from '../../../Component/EventApplicationComponent/SuccessSelectBox';
import LastRoundSelectBox from './LastRoundSelectBox';
import PictureButton from './PictureButton';
import SizeButton from './SizeButton';

export default function SubmitModel(props) {
  const {
    eventId,
    section,

    isRx,
    setIsRx,

    isSuccess,
    setIsSuccess,

    min,
    setMin,
    sec,
    setSec,

    tieBreakMin,
    setTieBreakMin,

    tieBreakSec,
    setTieBreakSec,

    distance,
    setDistance,

    completeRep,
    setCompleteRep,
    completeRound,
    setCompleteRound,
    setLastExercise,
    lastExerciseRep,
    setLastExerciseRep,
    firstExercise,
    setFirstExercise,
    secondExercise,
    setSecondExercise,
    thirdExercise,
    setThirdExercise,
    bodyWeight,
    setBodyWeight,
    point,
    setPoint,

    link,
    setLink,
    imgBase64,
    setImgBase64
  } = props;

  const { t } = useTranslation();

  // 총 점수 산출 표출 //
  useEffect(() => {

    if (bodyWeight === 0 || bodyWeight === '') setPoint(0);
    else {
      let totalWeight = 0;

      if (firstExercise !== '') totalWeight += parseFloat(firstExercise);
      if (secondExercise !== '') totalWeight += parseFloat(secondExercise);
      if (thirdExercise !== '') totalWeight += parseFloat(thirdExercise);

      if (totalWeight === 0) setPoint(0);
      else {
        let finalPoint = totalWeight - (bodyWeight * 1.5);

        if (finalPoint < 0) setPoint(0);
        else setPoint(finalPoint);
      }
    }

    // var totalExercise = 0;

    // if (firstExercise !== '') totalExercise += parseInt(firstExercise);
    // if (secondExercise !== '') totalExercise += parseInt(secondExercise);
    // if (thirdExercise !== '') totalExercise += parseInt(thirdExercise);

    // setTotalPound(totalExercise);
  }, [firstExercise, secondExercise, thirdExercise, bodyWeight])

  // 기록된 시간중 분 데이터 변경시 //
  function minHandleChange(e) {
    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setMin(e.target.value);
    if (e.target.value === '') setMin('');
  }

  // 기록된 시간중 분 데이터 포커싱시 //
  function minHandleFocus(e) {
    setMin('');
  }

  // 기록된 시간중 초 데이터 변경시 //
  function secHandleChange(e) {
    // setSec(e.target.value);


    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setSec(e.target.value);
    if (e.target.value === '') setSec('');
  }

  // 기록된 시간중 초 데이터 포커싱시 //
  function secHandleFocus(e) {
    setSec('');
  }


  // Tie Break 분 데이터 변경시 //
  function tieBreakMinHandleChange(e) {
    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setTieBreakMin(e.target.value);
    if (e.target.value === '') setTieBreakMin('');
  }

  // Tie Break 분 데이터 포커싱시 //
  function tieBreakMinHandleFocus(e) {
    setTieBreakMin('');
  }

  // Tie Break 초 데이터 변경시 //
  function tieBreakSecHandleChange(e) {
    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setTieBreakSec(e.target.value);
    if (e.target.value === '') setTieBreakSec('');
  }

  // Tie Break 초 데이터 포커싱시 //
  function tieBreakSecHandleFocus(e) {
    setTieBreakSec('');
  }


  // 타임캡 오버시 로잉 길이 포커싱시 //
  function distanceHandleFocus(e) {
    setDistance('');
  }

  // 타임캡 오버시 로잉 길이 수정시 //
  function distanceHandleChange(e) {
    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setDistance(e.target.value);
    if (e.target.value === '') setDistance('');
  }

  // 완벽하게 끝낸 REP 데이터 변경시 //
  function repHandleChange(e) {
    // setCompleteRep(e.target.value);

    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setCompleteRep(parseInt(e.target.value));
    if (e.target.value === '') setCompleteRep('');
  }

  // 완벽하게 끝낸 REP 데이터 포커싱시 //
  function repHandleFocus(e) {
    setCompleteRep('');
  }

  // 완벽하게 끝낸 라운드 데이터 변경시 //
  function roundHandleChange(e) {
    // setCompleteRound(e.target.value);


    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setCompleteRound(e.target.value);
    if (e.target.value === '') setCompleteRound('');
  }

  // 완벽하게 끝낸 라운드 데이터 포커싱시 //
  function roundHandleFocus(e) {
    setCompleteRound('');
  }

  // 마지막 운동 개수 데이터 변경시 //
  function lastExerciseHandleChange(e) {
    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setLastExerciseRep(e.target.value);
    if (e.target.value === '') setLastExerciseRep('');
  }

  // 마지막 운동 개수 데이터 포커싱시 //
  function lastExerciseHandleFocus(e) {
    setLastExerciseRep('');
  }

  // 첫번째 운동 무게 데이터 변경시 //
  function firstExerciseHandleChange(e) {

    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setFirstExercise(e.target.value);
    if (e.target.value === '') setFirstExercise('');
  }

  // 첫번째 운동 무게 데이터 포커싱시 //
  function firstExerciseHandleFocus(e) {
    setFirstExercise('');
  }

  // 두번째 운동 무게 데이터 변경시 //
  function secondExerciseHandleChange(e) {

    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setSecondExercise(e.target.value);
    if (e.target.value === '') setSecondExercise('');
  }

  // 두번째 운동 무게 데이터 포커싱시 //
  function secondExerciseHandleFocus(e) {
    setSecondExercise('');
  }

  // 세번째 운동 무게 데이터 변경시 //
  function thirdExerciseHandleChange(e) {

    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setThirdExercise(e.target.value);
    if (e.target.value === '') setThirdExercise('');
  }

  // 세번째 운동 데이터 포커싱시 //
  function thirdExerciseHandleFocus(e) {
    setThirdExercise('');
  }

  // 몸무게 데이터 변경시 //
  function bodyWeightHandleChange(e) {

    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setBodyWeight(e.target.value);
    if (e.target.value === '') setBodyWeight('');
  }

  // 몸무게 데이터 포커싱시 //
  function bodyWeightHandleFocus(e) {
    setBodyWeight('');
  }

  // 유튜브 링크 데이터 변경시 //
  function linkHandleChange(e) {
    setLink(e.target.value);
  }

  return (
    <TopLevelWrapper>
      <InnerWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>EVENT {t('기록하기')}</Text>
        {
          (eventId === 4 || eventId === 5 || eventId === 6 || eventId === 7) &&
          <ComponentWrapper>
            {
              eventId === 4 ||
              <DivisionWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('디비전을 선택해 주세요')}</Text>
                <SizeButton isRx={isRx} setIsRx={setIsRx} />
              </DivisionWrapper>
            }
            {/* <ComponentInnerWrapper isFirst={eventId === 6}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('동작을 성공적으로 완료하셨나요?')}</Text>
              <SuccessSelectBox unit={isSuccess} setUnit={setIsSuccess} />
            </ComponentInnerWrapper> */}
            {
              // isSuccess.value === '완료하였습니다' ?
              // <ComponentInnerWrapper style={{ marginTop: `10px` }}>
              //   <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('기록된 시간을 알려주세요')}</Text>
              //   <TimeInputWrapper>
              //     <TimeBox>
              //       <TimeInput
              //         value={min}
              //         // type='decimal'
              //         type='number'
              //         pattern='\d*'
              //         onFocus={minHandleFocus}
              //         onChange={minHandleChange} />
              //       <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MIN</Text>
              //     </TimeBox>
              //     <TimeBox>
              //       <TimeInput
              //         value={sec}
              //         type='decimal'
              //         // type='number'
              //         // pattern='\d*'
              //         onFocus={secHandleFocus}
              //         onChange={secHandleChange} />
              //       <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
              //     </TimeBox>
              //   </TimeInputWrapper>
              // </ComponentInnerWrapper>

              // <ComponentInnerWrapper style={{ marginTop: `10px` }}>
              //   <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('총 몇 렙수를 수행했나요?')}</Text>
              //   <DistanceInputBox>
              //     <DistanceInput
              //       value={completeRep}
              //       type='decimal'
              //       onFocus={repHandleFocus}
              //       onChange={repHandleChange} />
              //     <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
              //   </DistanceInputBox>
              // </ComponentInnerWrapper>
              // :
              (eventId === 6 || eventId === 7) ?
                <AdditionComponentWrapper>
                  <ComponentInnerWrapper style={{ marginTop: `10px` }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('Tie Break & Time Cap 시간을 입력해주세요')}</Text>
                    <TimeInputWrapper>
                      <TimeBox>
                        <TimeInput
                          value={tieBreakMin}
                          type='number'
                          pattern='\d*'
                          onFocus={tieBreakMinHandleFocus}
                          onChange={tieBreakMinHandleChange} />
                        <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MIN</Text>
                      </TimeBox>
                      <TimeBox>
                        <TimeInput
                          value={tieBreakSec}
                          type='decimal'
                          onFocus={tieBreakSecHandleFocus}
                          onChange={tieBreakSecHandleChange} />
                        <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                      </TimeBox>
                    </TimeInputWrapper>
                  </ComponentInnerWrapper>
                  <ComponentInnerWrapper style={{ marginTop: `10px` }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('총 몇 렙수를 수행했나요?')}</Text>
                    <DistanceInputBox>
                      <DistanceInput
                        value={completeRep}
                        type='decimal'
                        onFocus={repHandleFocus}
                        onChange={repHandleChange} />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                    </DistanceInputBox>
                  </ComponentInnerWrapper>
                </AdditionComponentWrapper>
                :
                <ComponentInnerWrapper style={{ marginTop: `10px` }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('로잉을 몇미터 수행했나요?')}</Text>
                  <DistanceInputBox>
                    <DistanceInput
                      value={distance}
                      type='decimal'
                      onFocus={distanceHandleFocus}
                      onChange={distanceHandleChange} />
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>M</Text>
                  </DistanceInputBox>
                </ComponentInnerWrapper>
            }
          </ComponentWrapper>
        }
        {/* {
          eventId === 7 &&
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('몇 REP를 완벽하게 해내셨나요?')}</Text>
            <InputBox>
              <VideoLinkInput
                value={completeRep}
                type='text'
                onFocus={repHandleFocus}
                onChange={repHandleChange} />
            </InputBox>
          </ComponentWrapper>
        } */}
        {
          eventId === 8 && section === 'A' &&
          // <Event7Wrapper>
          //   <ComponentWrapper>
          //     <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('몇 라운드를 완벽하게 해내셨나요?')}</Text>
          //     <InputBox>
          //       <VideoLinkInput
          //         value={completeRound}
          //         type='text'
          //         onFocus={roundHandleFocus}
          //         onChange={roundHandleChange} />
          //     </InputBox>
          //   </ComponentWrapper>
          //   <ComponentWrapper>
          //     <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('마지막 라운드는 어디서 끝났나요?')}</Text>
          //     <TimeInputWrapper>
          //       <HalfInputBox>
          //         <HalfInput
          //           value={lastExerciseRep}
          //           type='text'
          //           onFocus={lastExerciseHandleFocus}
          //           onChange={lastExerciseHandleChange} />
          //       </HalfInputBox>
          //       <LastRoundSelectBox changeFn={setLastExercise} />
          //     </TimeInputWrapper>
          //   </ComponentWrapper>
          // </Event7Wrapper>
          <AdditionComponentWrapper>
            <DivisionWrapper style={{ marginTop: `20px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('디비전을 선택해 주세요')}</Text>
              <SizeButton isRx={isRx} setIsRx={setIsRx} />
            </DivisionWrapper>
            <ComponentInnerWrapper style={{ marginTop: `16px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('Tie Break & Time Cap 시간을 입력해주세요')}</Text>
              <TimeInputWrapper>
                <TimeBox>
                  <TimeInput
                    value={tieBreakMin}
                    type='number'
                    pattern='\d*'
                    onFocus={tieBreakMinHandleFocus}
                    onChange={tieBreakMinHandleChange} />
                  <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MIN</Text>
                </TimeBox>
                <TimeBox>
                  <TimeInput
                    value={tieBreakSec}
                    type='decimal'
                    onFocus={tieBreakSecHandleFocus}
                    onChange={tieBreakSecHandleChange} />
                  <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                </TimeBox>
              </TimeInputWrapper>
            </ComponentInnerWrapper>
            <ComponentInnerWrapper style={{ marginTop: `16px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('총 몇 렙수를 수행했나요?')}</Text>
              <DistanceInputBox>
                <DistanceInput
                  value={completeRep}
                  type='decimal'
                  onFocus={repHandleFocus}
                  onChange={repHandleChange} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
              </DistanceInputBox>
            </ComponentInnerWrapper>
          </AdditionComponentWrapper>
        }
        {
          eventId === 8 && section === 'B' &&
          <Event7Wrapper>
            <ComponentWrapper>
              <TitleWrapper>
                <NumberBox>
                  <Text style={{ marginTop: `-2px` }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                </NumberBox>
                <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>Shoulder press</Text>
              </TitleWrapper>
              <ExerciseInputWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                <WeightInputBox>
                  <WeightInput
                    value={firstExercise}
                    type='text'
                    onFocus={firstExerciseHandleFocus}
                    onChange={firstExerciseHandleChange} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                </WeightInputBox>
              </ExerciseInputWrapper>
            </ComponentWrapper>
            <ComponentWrapper>
              <TitleWrapper>
                <NumberBox>
                  <Text style={{ marginTop: `-2px` }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                </NumberBox>
                <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>Back Squat</Text>
              </TitleWrapper>
              <ExerciseInputWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                <WeightInputBox>
                  <WeightInput
                    value={secondExercise}
                    type='text'
                    onFocus={secondExerciseHandleFocus}
                    onChange={secondExerciseHandleChange} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                </WeightInputBox>
              </ExerciseInputWrapper>
            </ComponentWrapper>
            <ComponentWrapper>
              <TitleWrapper>
                <NumberBox>
                  <Text style={{ marginTop: `-2px` }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                </NumberBox>
                <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>Deadlift</Text>
              </TitleWrapper>
              <ExerciseInputWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                <WeightInputBox>
                  <WeightInput
                    value={thirdExercise}
                    type='text'
                    onFocus={thirdExerciseHandleFocus}
                    onChange={thirdExerciseHandleChange} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                </WeightInputBox>
              </ExerciseInputWrapper>
            </ComponentWrapper>
            <ComponentWrapper>
              <TitleWrapper>
                <GreenNumberBox>
                  <Text style={{ marginTop: `-2px` }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>04</Text>
                </GreenNumberBox>
                <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>Body Weight</Text>
              </TitleWrapper>
              <ExerciseInputWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                <WeightInputBox>
                  <WeightInput
                    value={bodyWeight}
                    type='text'
                    onFocus={bodyWeightHandleFocus}
                    onChange={bodyWeightHandleChange} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                </WeightInputBox>
              </ExerciseInputWrapper>
            </ComponentWrapper>
            <ComponentWrapper>
              <TotalWeightWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>(SP+BS+DL) - (Body Weight * 1.5)</Text>
                {/* <RedLine /> */}
                <TotalWeightTextWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>Record : </Text>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>{point}</Text>
                </TotalWeightTextWrapper>
              </TotalWeightWrapper>
            </ComponentWrapper>
          </Event7Wrapper>
        }
        {
          (eventId === 8 && section !== 'A') &&
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>EVENT {t('기록 영상')}</Text>
            <InputBox>
              <VideoLinkInput
                value={link}
                type='text'
                placeholder={t('유튜브 영상 링크를 붙여넣어 주세요')}
                onChange={linkHandleChange} />
            </InputBox>
          </ComponentWrapper>
        }
        <ComponentWrapper>
          <TextWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('사진 추가')}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#828282'>{t('( 최대 5개 추가 가능 )')}</Text>
          </TextWrapper>
          <PictureButton imgBase64={imgBase64} setImgBase64={setImgBase64} />
          {/* <FeelTextBox
            value={feel}
            type='text'
            placeholder={t('운동 후 느낀점을 기록해 보세요')}
            onChange={feelHandleChange} /> */}
        </ComponentWrapper>
      </InnerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 20px 0px 38px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const Event7Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;

  gap: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const DivisionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 13px;
`;

const ComponentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isFirst ? `20px` : `0px`};

  width: 100%;

  gap: 8px;
`;

const AdditionComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 48.39%;
  height: 40px;

	border: 1px solid #E8E8E8;
	border-radius: 12px;
	
	background-color: #FFFFFF;
`;

const TimeInput = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	// width: calc(100% - 24px);
  width: 60%;
	height: 100%;

	padding: 0 12px 0 12px;

	outline: none;
	border: 0px solid #E8E8E8;
  border-radius: 12px;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
	height: 38px;

	border: 1px solid #E8E8E8;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const VideoLinkInput = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	width: calc(100% - 24px);
  // width: 60%;
	height: 38px;

	padding: 0 12px 0 12px;

	outline: none;
	border: 0px solid #E8E8E8;
  border-radius: 12px;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const HalfInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 47%;
	height: 38px;
  
	border: 1px solid #E8E8E8;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const HalfInput = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	// width: calc(48.39% - 24px);
	width: calc(100% - 24px);
	height: 38px;

	padding: 0 12px 0 12px;

	outline: none;
	border: 0px solid #E8E8E8;
  border-radius: 12px;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const FeelTextBox = styled.textarea`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	width: calc(100% - 24px);
  // width: 60%;
	height: 131px;

	padding: 12px 12px 12px 12px;

	outline: none;
  resize: none;
	border: 1px solid #E8E8E8;
  border-radius: 12px;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const ExerciseInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const GreenNumberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const DistanceInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding-right: 12px;

  // width: calc(85% - 14px);
  width: calc(100% - 14px);
  height: 38px;

	border: 1px solid #E8E8E8;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const DistanceInput = styled.input`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20px;

  width: 70%;
  height: 38px;

  padding: 0 12px 0 12px;

  outline: none;
  border: 0px solid #E8E8E8;
  border-radius: 12px;

  color: #000000;

  ::placeholder {
    color: #CBCBCB;
  }

`;

const WeightInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding-right: 12px;

  width: calc(85% - 14px);
  height: 38px;

	border: 1px solid #E8E8E8;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const WeightInput = styled.input`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20px;

  width: 70%;
  height: 38px;

  padding: 0 12px 0 12px;

  outline: none;
  border: 0px solid #E8E8E8;
  border-radius: 12px;

  color: #000000;

  ::placeholder {
    color: #CBCBCB;
  }

`;

const TotalWeightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TotalWeightTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: calc(40.9% - 2px);
  width: calc(35% - 2px);
  height: 38px;

  gap: 2px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60%;
  height: 1px;

  background-color: #FF3131;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
