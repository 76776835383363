import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ExitButton from '../../Image/GrayExit.png';
import NumberInput from '../../Component/EventApplicationComponent/NumberInput';
import TextInput from '../../Component/EventApplicationComponent/TextInput';

export default function CancellationInfoModal(props) {
  const { depositor, setDepositor, bank, setBank, accountNum, setAccountNum, yesFn, closeFn } = props;

  const { t } = useTranslation();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  return (
    <Background>
      <Modal>
        <Text style={{ marginTop: '16px'}} fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>신청 취소를 위한 정보 입력</Text>
        {/* <Text style={{ marginTop: '25px'}} fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('신청을 취소하면 되돌릴 수 없습니다.')}</Text> */}
        <TotalWrapper>
          <ComponentWrapper>
            <Text style={{ marginBottom: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>예금자 명</Text>
            <TextInput value={depositor} setValue={setDepositor} placeholder='예금자 명을 입력해 주세요'/>
          </ComponentWrapper>
          <ComponentWrapper>
            <Text style={{ marginBottom: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>은행</Text>
            <TextInput value={bank} setValue={setBank} placeholder='환불 받을 은행을 입력해주세요'/>
          </ComponentWrapper>
          <ComponentWrapper>
            <Text style={{ marginBottom: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>계좌번호</Text>
            <TextInput value={accountNum} setValue={setAccountNum} placeholder='환불 받을 계좌번호를 입력해주세요'/>
          </ComponentWrapper>
        </TotalWrapper>
        <ButtonWrapper>
          <Button backgroundColor='#FF3131' onClick={yesFn}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>환불 진행</Text>
          </Button>
          <Button backgroundColor='#6DD49E' onClick={closeFn}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>취소</Text>
          </Button>
        </ButtonWrapper>
      </Modal>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

	width: 90.33%;
	// height: 202px;
  height: 400px;
  
	border-radius: 12px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width: 90.33%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 8px;

  width: 100%;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 39px;

  width: 85.02%;
`;

const Button = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 47.16%;
  height: 60px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;