import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

import ExitButton from '../../Image/GrayExit.png';
import FetchModule from '../Network/FetchModule';

export default function PaymentSelectModal(props) {
  const { setIsSelectPaymentMethod, amount, orderName, totalPrice, merchantUid, eventId } = props;

  const [isCompletePayment, setIsCompletePayment] = useState(false);

  const { t } = useTranslation();

  const ModalRef = useRef();

  const initialOptions = {
    // SendBox Account //
    // "client-id": "AZSR-FT_07mU_THjT90Bs-_OpmMk4VWPRDO9KGcfTWb_smzjyeG4_-0RrbuzSI6U58rSaBD_Ihhi1jIb",
    // Live Account //
    "client-id": "AUz-Dv_RCNLm3w7849S4NYXeokoz0nQP3YGro_q-oOPsV5bCFIEGxgcsaQAsgUwYw08Gfsa89FFmOiCt",
    currency: "USD",
    intent: "capture",
  }

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (ModalRef.current !== e.target && !ModalRef.current.contains(e.target)) {
      setIsSelectPaymentMethod(false);
    }
  }

  // 일반 결제 버튼 클릭시 //
  async function onClickNormalButton() {
    // try {
    //   // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.29:3000/payment/' + responseData.data + '/' + orderName + '/' + eventData.totalPrice + "/" + window.localStorage.getItem('Token') }));
    //   window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://event.link-coach.io/payment/' + merchantUid + '/' + orderName + '/' + totalPrice + "/" + window.localStorage.getItem('Token') }));
    // }
    // catch (e) {
    //   console.log("Web Message Post Error : " + e);
    // }
    // window.open('http://172.30.1.10:3000/payment/' + merchantUid + '/' + orderName + '/' + totalPrice + "/" + window.localStorage.getItem('Token'));
    try {
      // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.29:3000/payment/' + responseData.data + '/' + orderName + '/' + eventData.totalPrice + "/" + window.localStorage.getItem('Token') }));
      window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://event.link-coach.io/payment/' + merchantUid + '/' + orderName + '/' + totalPrice + "/" + window.localStorage.getItem('Token') }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 메인화면으로 버튼 클릭시 //
  function onClickGoMainButton() {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 성공시 메소드 //
  async function onApprove(data, actions) {
    // (data, actions) => {
    //   return actions.order.capture().then((details) => {
      const details = await actions.order.capture();
        // const name = details.payer.name.given_name;
        // console.log('---------');
        // console.log("Data");
        // console.log(data);
        // console.log("Detail");
        // console.log(details);
        // alert(`Transaction completed by ${name}`);

        let requestData = {
          paymentKey: "Paypal_" + data.orderID,
          merchant_uid: merchantUid,
          price: amount,
          eventId: eventId
        }

        const fetchModule = new FetchModule();
        const responseData = await fetchModule.postDataOfEvent('sales/paypal', 'POST', requestData);

        if (responseData.status === 200) setIsCompletePayment(true);
        // const fetchModule = new FetchModule();
        // (
        //   async () => {
        //     const responseData = await fetchModule.postDataOfEvent('sales/paypal', 'POST', requestData);

        //     if (responseData.status === 200) {
        //       setIsCompletePayment(true);
        //     }
        //   }
        // )();
  }

  return (
    <Background>
      <Modal ref={ModalRef}>
        {
          isCompletePayment ?
            <Text style={{ marginTop: '16px' }} fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('결제 및 등록신청이 완료되었습니다')}</Text>
            :
            <Text style={{ marginTop: '16px' }} fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('결제수단을 선택해주세요')}</Text>
        }
        {
          isCompletePayment ?
            <ButtonWrapper>
              <CompleteButton onClick={onClickGoMainButton}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('메인 화면 으로')}</Text>
              </CompleteButton>
            </ButtonWrapper>
            :
            <ButtonWrapper>
              <Button backgroundColor='#FFC439'>
                <PayPalScriptProvider options={initialOptions}>
                  <PayPalButtons
                    style={{ layout: "horizontal", tagline: false, }}
                    createOrder={
                      (data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: amount,
                              },
                            },
                          ],
                        });
                      }}
                    onApprove={onApprove
                      // (data, actions) => {
                      //   return actions.order.capture().then((details) => {
                      //     const name = details.payer.name.given_name;
                      //     console.log('---------')
                      //     console.log(data);
                      //     console.log(details);
                      //     alert(`Transaction completed by ${name}`);

                      //     var requestData = {
                      //       paymentKey: "Paypal_" + data.id,
                      //       merchant_uid: merchantUid,
                      //       price: amount
                      //     }

                      //     const fetchModule = new FetchModule();

                      //     const responseData = fetchModule.postDataOfEvent('sales/paypal', 'POST', requestData);

                      //     if (responseData.status === 200) {
                      //       setIsCompletePayment(true);
                      //     }
                      //     // const fetchModule = new FetchModule();
                      //     // (
                      //     //   async () => {
                      //     //     const responseData = await fetchModule.postDataOfEvent('sales/paypal', 'POST', requestData);

                      //     //     if (responseData.status === 200) {
                      //     //       setIsCompletePayment(true);
                      //     //     }
                      //     //   }
                      //     // )();

                      //   });
                      // }
                      } />
                </PayPalScriptProvider>
              </Button>
              <Button backgroundColor='#6DD49E' onClick={onClickNormalButton}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('일반 결제')}</Text>
              </Button>
            </ButtonWrapper>
        }
      </Modal>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

	width: 90.33%;
	// height: 202px;
  height: 170px;
  
	border-radius: 12px;
  background-color: #FFFFFF;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 39px;

  width: 85.02%;
`;

const CompleteButton = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60px;

  border-radius: 8px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Button = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  // min-width: 150px;
  height: 60px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor};

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;