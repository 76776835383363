import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../Image/WaitIcon.png';
import VideoIcon from '../../Image/VideoIcon.png';

export default function BattleCrew3ASection(props) {
  const { totalVideoWidth, totalVideoHeight, onClickVideo, onClickImageFocus } = props;

  const navigate = useNavigate();

  // 순위표 버튼 클릭시 //
  function onClickRankingButton() {
    navigate('/ranking/2/2/2/A/배틀크루2 정식 크루 선발전 [부산]/2022.10.22(토)');
  }

  // 참자가 정보 수정 //
  function onClickModifyMember() {
    navigate('/check/2/true/true');
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper>
          <SectionBox>
            <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>A</Text>
          </SectionBox>
          <TitleBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>3가지 미션 도전하라!</Text>
          </TitleBox>
        </TitleWrapper>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
          <iframe
            width='100%'
            height='100%'
            src={`https://www.youtube.com/embed/lzWSlGzrVVI`}
            style={{ borderRadius: '12px' }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube" />
        </TotalVideoBox>
        <AthleteBox style={{ margin: '0px 0px 10px 0px' }}>
          <AthleteTextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>Athlete .1</Text>
          </AthleteTextWrapper>
        </AthleteBox>
        <ExerciseWrapper style={{ marginTop: '10px' }}>
          <InfoWrapper>
            <InfoTitleWrapper>
              <ExerciseNumberBoxWrapper>
                <ExerciseNumberBox backgroundColor='#FF8B48'>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                </ExerciseNumberBox>
                <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#4F4F4F'>한발 뛰기</Text>
              </ExerciseNumberBoxWrapper>
              <Image src={VideoIcon} onClick={() => onClickVideo('mnSp0j-3Ikw')} />
            </InfoTitleWrapper>
            <InfoDataWrapper style={{ marginTop: '20px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
              <DivisionLine width={32} />
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>10</Text>
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>2번 왕복시 1point</Text>
            </InfoDataWrapper>
          </InfoWrapper>
        </ExerciseWrapper>
        <AthleteBox style={{ margin: '15px 0px 10px 0px' }}>
          <AthleteTextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>Athlete .2</Text>
          </AthleteTextWrapper>
        </AthleteBox>
        <ExerciseWrapper style={{ marginTop: '10px' }}>
          <InfoWrapper>
            <InfoTitleWrapper>
              <ExerciseNumberBoxWrapper>
                <ExerciseNumberBox backgroundColor='#FF8B48'>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                </ExerciseNumberBox>
                <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#4F4F4F'>샌드백 들고 이동하기</Text>
              </ExerciseNumberBoxWrapper>
              <Image src={VideoIcon} onClick={() => onClickVideo('l1C2UwixT1c')} />
            </InfoTitleWrapper>
            <InfoDataWrapper style={{ marginTop: '20px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
              <DivisionLine width={50} />
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:100</Text>
              <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:150</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
            </InfoDataWrapper>
            <InfoDataWrapper style={{ marginTop: '16px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
              <DivisionLine width={32} />
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>10</Text>
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1번 왕복시 1point</Text>
            </InfoDataWrapper>
          </InfoWrapper>
        </ExerciseWrapper>
        <AthleteBox style={{ margin: '15px 0px 10px 0px' }}>
          <AthleteTextWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>Athlete .3</Text>
          </AthleteTextWrapper>
        </AthleteBox>
        <ExerciseWrapper style={{ marginTop: '10px' }}>
          <InfoWrapper>
            <InfoTitleWrapper>
              <ExerciseNumberBoxWrapper>
                <ExerciseNumberBox backgroundColor='#FF8B48'>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                </ExerciseNumberBox>
                <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#4F4F4F'>파머스 워크</Text>
              </ExerciseNumberBoxWrapper>
              <Image src={VideoIcon} onClick={() => onClickVideo('lr_YX64O3yE')} />
            </InfoTitleWrapper>
            <InfoDataWrapper style={{ marginTop: '20px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
              <DivisionLine width={50} />
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:120</Text>
              <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:180</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
            </InfoDataWrapper>
            <InfoDataWrapper style={{ marginTop: '16px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
              <DivisionLine width={32} />
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>10</Text>
              <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1번 왕복시 1point</Text>
            </InfoDataWrapper>
          </InfoWrapper>
        </ExerciseWrapper>
        <ExerciseWrapper>
          <InfoWrapper>
            <InfoTitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>기록 측정 기준</Text>
            </InfoTitleWrapper>
            <InfoTitleWrapper style={{ marginTop: '20px' }}>
              <ExerciseNumberBoxWrapper>
                <ExerciseNumberBox backgroundColor='#FF8B48'>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                </ExerciseNumberBox>
                <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#4F4F4F'>한발 뛰기</Text>
              </ExerciseNumberBoxWrapper>
            </InfoTitleWrapper>
            <ScoreGuideLineImageWrapper size={totalVideoWidth} onClick={() => onClickImageFocus('https://picture.link-zone.org/event/battlecrew3/BattleCrew3_A.png')}>
              <ScoreGuideLineImage src='https://picture.link-zone.org/event/battlecrew3/BattleCrew3_A.png' />
            </ScoreGuideLineImageWrapper>
            <InfoTitleWrapper style={{ marginTop: '20px' }}>
              <ExerciseNumberBoxWrapper>
                <ExerciseNumberBox backgroundColor='#FF8B48'>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                </ExerciseNumberBox>
                <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#4F4F4F'>샌드백 들고 이동하기</Text>
              </ExerciseNumberBoxWrapper>
            </InfoTitleWrapper>
            <ScoreGuideLineImageWrapper size={totalVideoWidth} onClick={() => onClickImageFocus('https://picture.link-zone.org/event/battlecrew3/BattleCrew3_B.png')}>
              <ScoreGuideLineImage src='https://picture.link-zone.org/event/battlecrew3/BattleCrew3_B.png' />
            </ScoreGuideLineImageWrapper>
            <InfoTitleWrapper style={{ marginTop: '20px' }}>
              <ExerciseNumberBoxWrapper>
                <ExerciseNumberBox backgroundColor='#FF8B48'>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                </ExerciseNumberBox>
                <Text style={{ marginTop: '-2px' }} fontFamily='NotoSansKR-Bold' fontSize={12} color='#4F4F4F'>파머스 워크</Text>
              </ExerciseNumberBoxWrapper>
            </InfoTitleWrapper>
          </InfoWrapper>
          <ScoreGuideLineImageWrapper size={totalVideoWidth} onClick={() => onClickImageFocus('https://picture.link-zone.org/event/battlecrew3/BattleCrew3_C.png')}>
            <ScoreGuideLineImage src='https://picture.link-zone.org/event/battlecrew3/BattleCrew3_C.png' />
          </ScoreGuideLineImageWrapper>
        </ExerciseWrapper>
      </TotalWrapper>
      {/* {
        moment().format('YYYY.MM.DD HH:mm:ss') > moment('2022-09-30T23:59:59').format('YYYY.MM.DD HH:mm:ss') ?
          <RankingButton onClick={onClickRankingButton}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>순위표</Text>
          </RankingButton>
          :
          <RankingButton onClick={onClickModifyMember}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>신청 정보 수정</Text>
          </RankingButton>
      } */}
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: #6DD49E;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  width: calc(89.3% - 24px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 25px 0px 25px 0px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;
  margin: 0px 0px 8px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const AthleteBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 10px 0px;

  width: 100%;
  height: 24px;

  background-color: #6DD49E;
`;

const AthleteTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;


const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 13px;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 10px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 81.28%;
`;

const PointRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
  // background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
