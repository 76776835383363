import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import moment from 'moment';

import FetchModule from '../Model/Network/FetchModule';
import NameArrow from '../Image/NameArrow.png';
import EventBox from '../Model/EventInfoModel/EventBox';
import { useTranslation } from 'react-i18next';
import CancellationModal from '../Model/EventApplicationModel/CancellationModal';
import LoadingAnimation from '../Model/EventApplicationModel/LoadingAnimation';

export default function EventInfoPage() {

  const { t, i18n } = useTranslation();

  const changeLanguageToKo = () => i18n.changeLanguage('ko');
  const changeLanguageToEn = () => i18n.changeLanguage('en');

  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [titleFontSize, setTitleFontSize] = useState(30);
  const [eventTitleFontSize, setEventTitleFontSize] = useState(18);
  const [isFixed, setIsFixed] = useState(false);
  const [sectionY, setSectionY] = useState(0);
  const [profileSize, setProfileSize] = useState(0);
  const [buttonTopMargin, setButtonTopMargin] = useState(0);
  const [scrollDivision, setScrollDivision] = useState('info');
  const [language, setLanguage] = useState('English');
  const [merchantUid, setMerchantUid] = useState('');
  const [eventId, setEventId] = useState(0);
  const [availableModifyDate, setAvailableModifyDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [isAlreadyPayment, setIsAlreadyPayment] = useState(false);
  const [isClickedCancellation, setIsClickedCancellation] = useState(false);

  const [organizerId, setOrganizerId] = useState(0);

  const [totalVideoWidth, setTotalVideoWidth] = useState(374);
  const [totalVideoHeight, setTotalVideoHeight] = useState(374);

  const TopLevelRef = useRef();
  const SectionRef = useRef();
  const InfoRef = useRef();
  const EventRef = useRef();

  const params = useParams();

  useEffect(() => {
    setOrganizerId(parseInt(params.organizerId));

    var requestData = {
      organizerId: params.organizerId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        // await window.localStorage.setItem('Token', 'Bearer ' + params.token);
        // console.log(window.localStorage.getItem('Token'));
        const responseData = await fetchModule.postDataOfEvent('tournament/', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          var data = responseData.data;
          var eventData = responseData.data.events;

          eventData.sort(function (a, b) {
            let x = a.eventId;
            let y = b.eventId;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          data.events = eventData;

          setData(data);
          setIsAlreadyPayment(responseData.data.isAlreadyPayment);
          setMerchantUid(responseData.data.merchantUid);
          setEventId(responseData.data.eventId);
          setAvailableModifyDate(moment(responseData.data.availableModifyDate));

          await window.localStorage.removeItem('EventData');
          // setData(responseData.data);
        }
        // const responseData = await fetchModule.postDataOfEvent('terms/', 'POST', requestData);

        // if (responseData.status === 200) {
        //   setData(responseData.data);
        // }
      }
    )();
  }, [])

  // Window Resize Handler And Scroll Detect Save And Delete //
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);

      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 화면 크기 추출후 사진, 참가신청 공백 크기 계산 //
  useEffect(() => {
    var finalSize = windowSize.width * 0.9033;
    var eventMargin = 0;

    if (data.events === undefined) eventMargin = 36;
    else eventMargin = windowSize.height - (159 + (178 * data.events.length) + (24 * (data.events.length - 1)));
    // var eventMargin = windowSize.height - (123 + (178 * testData.event.length) + (24 * (testData.event.length - 1)));
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        pageName: t('대회 정보'),
        sectionY: parseInt(140 + finalSize),
        isAvailableGoback: true
      }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }

    setButtonTopMargin(eventMargin > 36 ? eventMargin : 36);
    setProfileSize(finalSize);
    // setTitleFontSize(Math.round((27 * windowSize.width) / 414));
    if (params.organizerId === '3') {
      setTitleFontSize(Math.round((20 * windowSize.width) / 414));
      setEventTitleFontSize(Math.round((17 * windowSize.width) / 414));
    }
    else if (params.organizerId === '4') {
      setTitleFontSize(Math.round((19 * windowSize.width) / 414));
      setEventTitleFontSize(Math.round((16 * windowSize.width) / 414));
    }
    else {
      setTitleFontSize(Math.round((20 * windowSize.width) / 414));
      setEventTitleFontSize(Math.round((17 * windowSize.width) / 414));
    }
  }, [windowSize, data])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    var totalVideoWidth = windowSize.width * 0.9033;
    var totalVideoHeight = totalVideoWidth * 0.5614;

    var modalVideoWidth = windowSize.width;
    var modalVideoHeight = modalVideoWidth * 0.5603;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])

  // Window Resize Handler //
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  // Window Scroll Handler //
  function handleScroll(e) {
    // console.log("e.target.scrollTop : " + e.target.scrollTop);
    // console.log(window.scrollY);
    // console.log("SectionRef.current.offsetTop " + (SectionRef.current.offsetTop));
    // console.log("sectionY : " + (sectionY));
    // console.log("SectionRef.current.clientHeight: " + (SectionRef.current.clientHeight));
    // console.log("Event : " + EventRef.current.offsetTop);
    // console.log("Event : " + EventRef.current.offsetTop);
    // console.log("isFixed : " + isFixed);
    if ((window.scrollY + 35) >= SectionRef.current.offsetTop && SectionRef.current.offsetTop !== 0) {
      setIsFixed(true);
      setSectionY(SectionRef.current.offsetTop);
    }
    else if ((window.scrollY + 35) < sectionY && SectionRef.current.offsetTop === 0) setIsFixed(false);
    else if (((window.scrollY + 35) + SectionRef.current.clientHeight) >= EventRef.current.offsetTop) setScrollDivision('event');
    else if (((window.scrollY + 35) + SectionRef.current.clientHeight) < EventRef.current.offsetTop) setScrollDivision('info');
  }

  // React Native에서 웹으로 보내는 데이터 handler //
  async function handleMassage(e) {

    // 언어 정보 받아오기 //
    var language = JSON.parse(e.data).language;
    // alert("language : " + language);
    if (language === 'Korea') {
      changeLanguageToKo();
      setLanguage(language);

      await window.localStorage.setItem('Language', 'Korea');

      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          pageName: '대회 정보',
        }));
      }
      catch (e) {
        console.log("Web Message Post Error : " + e);
      }
    }
    else if (language === 'English') {
      changeLanguageToEn();
      setLanguage(language);

      await window.localStorage.setItem('Language', 'English');

      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          pageName: 'Event Infomation',
        }));
      }
      catch (e) {
        console.log("Web Message Post Error : " + e);
      }
    }


    var nowScrollY = JSON.parse(e.data).scrollY;
    var nowSectionY = JSON.parse(e.data).sectionY;
    var nowIsFixed = JSON.parse(e.data).isFixed;

    if (nowScrollY >= SectionRef.current.offsetTop && !nowIsFixed) {
      setIsFixed(true);
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: true, sectionY: parseInt(SectionRef.current.offsetTop) }));
    }
    else if (nowScrollY < nowSectionY && nowIsFixed) {
      setIsFixed(false);
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: false, sectionY: parseInt(SectionRef.current.offsetTop) }));
    }
    else if ((nowScrollY + SectionRef.current.clientHeight) >= EventRef.current.offsetTop) setScrollDivision('event');
    else if ((nowScrollY + SectionRef.current.clientHeight) < EventRef.current.offsetTop) setScrollDivision('info');
    else {
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: nowIsFixed, scrollY: nowScrollY, sectionY: nowSectionY }));
    }
  }

  // 대회내용 버튼 클릭시 //
  function onClickInfoButton() {
    setScrollDivision('info');
    // console.log(SectionRef.current.offsetTop);
    // if (SectionRef.current.offsetTop === 0) InfoRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    // else SectionRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    if (SectionRef.current.offsetTop === 0) window.scrollTo({ top: (InfoRef.current.offsetTop - SectionRef.current.clientHeight + 12), behavior: 'smooth' });
    else {
      window.scrollTo({ top: (SectionRef.current.offsetTop - SectionRef.current.clientHeight + 24), behavior: 'smooth' });
      // setIsFixed()
    }
  }

  // 참가 종목 버튼 클릭시 //
  function onClickEventButton() {
    setScrollDivision('event');

    window.scrollTo({ top: EventRef.current.offsetTop - SectionRef.current.clientHeight, behavior: 'smooth' });
  }

  // 참가신청 바로가기 버튼 클릭시 //
  function onClickAppication() {
    // if (data.applicationEndDate > moment().format('YYYY-MM-DDTHH:mm:ss')) navigate('/agree');
    // else alert('참가신청 가능 기간이 지났습니다!');
    
    // navigate('/agree/' + params.organizerId);

    // Suff일 경우 외부 링크 연결해주기 //
    if (data.organizerId === 7) {
      // window.open('https://linkbio.co/suffestival');
      
      alert('참가 신청 가능 기간이 지났습니다');

      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://linkbio.co/suffestival' }));
      }
      catch (e) {
        console.log("Web Message Post Error : " + e);
      }

      return;
    }

    if (data.organizerId === 6) {
      navigate('/agree/' + params.organizerId);
      return;
    }
    else if (data.applicationEndDate < moment().format('YYYY-MM-DDTHH:mm:ss')) {
      alert(t('참가신청 가능 기간이 지났습니다'));
      return;
    }
    else if (data.applicationStartDate > moment().format('YYYY-MM-DDTHH:mm:ss')) {
      alert(t('아직 참가신청 가능 기간이 아닙니다'));
      return;
    }
    else navigate('/agree/' + params.organizerId);
  }

  // 신청 정보 수정 버튼 클릭시 //
  function onClickModifyInfo() {
    // navigate('/application/' + eventId + '/' + isTeam + '/' + isModify);
    const now = new Date(moment());
    const modifyDate = new Date(availableModifyDate);

    // 수정 가능날짜 확인 //
    if (modifyDate > now) navigate('/application/' + eventId + '/' + false + '/' + true);
    else alert(t('수정 가능 기간이 지났습니다'));
  }

  // 참가 취소 버튼 클릭시 //
  function onClickCancellation() {
    setIsClickedCancellation(true);
  }

  // 참가 취소 경고모달에서 취소버튼 클릭시 //
  function onClickCancellationYes() {
    setIsLoading(true);
    setIsClickedCancellation(false);
    // console.log(eventData);
    // console.log(eventId);
    let requestData = {
      // eventId: eventData.selectedEvent.eventId,
      // eventId: eventId,
      merchantUid: merchantUid
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('sales/cancellation', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          // setIsClickedCancellation(false);

          await window.localStorage.removeItem('EventData');

          setTimeout(() => setIsLoading(false), 1000);

          try {
            window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
          }
          catch (e) {
            console.log("Web Message Post Error : " + e);
          }
        }
        else if (responseData.status === 204) {
          setTimeout(() => setIsLoading(false), 1000);

          alert(t("이미 취소된 결제건 입니다."));
        }
        else if (responseData.status === 401) {
          // setIsClickedCancellation(false);
          setTimeout(() => setIsLoading(false), 1000);

          alert(t('동일한 결제자가 아닙니다.\n참가 취소는 결제자만 가능합니다.'));
        }
        else if (responseData.status === 402) {
          // setIsClickedCancellation(false);
          setTimeout(() => setIsLoading(false), 1000);

          alert(t('완료된 결제건이 아닙니다.'));
        }
        else if (responseData.status === 403) {
          // setIsClickedCancellation(false);
          setTimeout(() => setIsLoading(false), 1000);

          alert(t('환불 가능기간이 지났습니다.'));
        }
      }
    )();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {isLoading && <LoadingAnimation />}
      {isClickedCancellation && <CancellationModal yesFn={onClickCancellationYes} closeFn={() => setIsClickedCancellation(false)} />}
      <ComponentWrapper>
        <ImageWrapper>
          <Image src={data.maintenanceRateProfile} width={profileSize} height={profileSize} />
        </ImageWrapper>
        {
          organizerId === 7 ?
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Black' fontSize={30} color='#4F4F4F'>서프 [SUFF] 2023</Text>
            </TitleWrapper>
            :
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Black' fontSize={titleFontSize} color='#4F4F4F'>{data.title}</Text>
            </TitleWrapper>
        }
      </ComponentWrapper>
      <ComponentWrapper ref={SectionRef} isFixed={isFixed} style={{ backgroundColor: '#F9F9F9' }}>
        <SectionTotalWrapper>
          <SectionWrapper onClick={onClickInfoButton}>
            <SectionTextBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('대회 내용')}</Text>
            </SectionTextBox>
            <SectionDivisionLine isClicked={scrollDivision === 'info'} />
          </SectionWrapper>
          <SectionWrapper onClick={onClickEventButton}>
            <SectionTextBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('참가 종목')}</Text>
            </SectionTextBox>
            <SectionDivisionLine isClicked={scrollDivision === 'event'} />
          </SectionWrapper>
        </SectionTotalWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={InfoRef} style={{ marginTop: `0px` }}>
        {
          organizerId === 7 &&
          <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
            <iframe
              width='100%'
              height='100%'
              src={`https://www.youtube.com/embed/qfEzV0m4EcE`}
              style={{ borderRadius: '12px' }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube" />
          </TotalVideoBox>
        }
        {
          organizerId === 8 &&
          <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
            <iframe
              width='100%'
              height='100%'
              src={`https://www.youtube.com/embed/FpvuSf98_uU?`}
              style={{ borderRadius: '12px' }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube" />
          </TotalVideoBox>
        }
        {
          organizerId === 9 &&
          <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
            <iframe
              width='100%'
              height='100%'
              src={`https://www.youtube.com/embed/1lOFLgtT2LM`}
              style={{ borderRadius: '12px' }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube" />
          </TotalVideoBox>
        }
        <InfoTotalWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 이름')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.title}</Text>
          </InfoWrapper>
          {
            (params.organizerId === '3' || params.organizerId === '4' || params.organizerId === '5' || params.organizerId === '6') &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 내용')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('크로스핏을 즐기는 남녀노소 누구나 참여할 수 있습니다!')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('2023년도 Far East Throwdown 의 커뮤니티 이벤트 대회를')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('놓치지 마세요.')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('이벤트 진행 방식, 참여 조건 등 자세한 내용은')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('웹사이트를 참고해주시길 바랍니다.')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('www.fareastthrowdown.com')}</Text>
            </InfoWrapper>
          }
          {
            (organizerId === 7) &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 내용')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>SUFF는 앞으로 열정적인 크로스핏 선수 및 회원들을 위해</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>"하나의 페스티벌! 크로스핏 커뮤니티 형성! 모두함께 어우러지는 대회!" 라는 목적을 가지고 개최합니다.</Text>
              {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'></Text> */}
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>또한, 모두가 참여할 수 있도록 다양한 디비젼을 구성했습니다.</Text>
            </InfoWrapper>
          }
          {
            (organizerId === 8) &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 내용')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>부산 세미파이널 Far East Throwdawn</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>스웻프로젝트 부스에서  3가지 첼린지를 도전하시고</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>다양한 상품과 이벤트를 경험하고 가세요</Text>
            </InfoWrapper>
          }
          {
            (organizerId === 9) &&
            <InfoWrapper style={{ marginTop: `12px`, gap: '4px'  }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 내용')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>단 하나의 크루가 남을때까지 경쟁하는 대회입니다.</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>자기 주변의 동료들을 모아 크루를 결성해 얼마나 강한지</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>증명해 보시길 바랍니다.</Text>
              <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>1. 나이,인종,성별,운동분야 제한 없는 참가조건</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>2. 누구나 할 수 있는 동작</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>3. 서로 다른 신체능력을 요구하는 종목</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>4. 짧는 경기 시간</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>5. 예선전 오프라인 대회 진행</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>6. 본선전 미디어 콘텐츠 제작 등</Text>
              <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>모두 배틀크루를 통해 뜨거운 현장감과 잊을 수 없는</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>추억을 만들고가시길 바랍니다.</Text>
            </InfoWrapper>
          }
          {
            (organizerId === 8) &&
            <InfoWrapper style={{ marginTop: `12px`, gap: '4px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('대회 기간')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>3일간의 도전!</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{moment(data.startDate).format('YYYY.MM.DD HH:mm') + ' ~ ' + moment(data.endDate).format('YYYY.MM.DD HH:mm')}</Text>
            </InfoWrapper>
          }
          {
            (organizerId === 9) &&
            <InfoWrapper style={{ marginTop: `12px`, gap: '4px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('대회 기간 / 장소')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>1. 부산권 [온오프짐 일광점]</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>2023.09.09 (선택1) ~ 2023.09.10 (선택2)</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>2. 충청권 [크로스핏 세종]</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>2023.09.16 (선택1) ~ 2023.09.17 (선택2)</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>3. 수도권 [리온짐]</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>2023.09.23 (선택1) ~ 2023.09.24 (선택2)</Text>
            </InfoWrapper>
          }
          {
            (organizerId !== 8 && organizerId !== 9) &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('대회 기간')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{moment(data.startDate).format('YYYY.MM.DD HH:mm') + ' ~ ' + moment(data.endDate).format('YYYY.MM.DD HH:mm')}</Text>
            </InfoWrapper>
          }
          {
            organizerId === 7 &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('장소')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>완도 명사십리해수욕장 ( 전남 완도군 )</Text>
            </InfoWrapper>
          }
          {
            organizerId === 8 &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('장소')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>부산 Far East Throwdawn  스웻프로젝트 부스</Text>
            </InfoWrapper>
          }
          {/* {
            organizerId === 9 &&
            <InfoWrapper style={{ marginTop: `12px`, gap: '4px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('장소')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>예선전 - 미정</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>본선전 - 충주 호암체육관</Text>
            </InfoWrapper>
          } */}
          {
            organizerId === 7 ?
              <InfoWrapper style={{ marginTop: `12px` }}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('신청 기간')}</Text>
                <InfoInnerWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>EarlyBird 접수 : 4월 3일(월) ~ 4월 14일(금)</Text>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>Officail Registration 접수 : 4월 15일(토) ~ 4월 24일(월)</Text>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>Last Chance 접수 : 4월 25일(화) ~ 5월 15일(월)</Text>
                </InfoInnerWrapper>
              </InfoWrapper>
              :
              <InfoWrapper style={{ marginTop: `12px` }}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('신청 기간')}</Text>
                <InfoInnerWrapper>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{moment(data.applicationStartDate).format('YYYY.MM.DD HH:mm') + ' ~ ' + moment(data.applicationEndDate).format('YYYY.MM.DD HH:mm')}</Text>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('신청 정보 수정은') + ' ' + moment(data.availableModifyDate).format('YYYY.MM.DD') + ' ' + t('까지 가능합니다.')}</Text>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{t('접수 취소는') + ' ' + moment(data.availableDeleteDate).format('YYYY.MM.DD') + ' ' + t('까지 가능합니다.')}</Text>
                </InfoInnerWrapper>
              </InfoWrapper>
          }
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('주관사')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.subjectivity}</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('후원사')}</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.sponsor}</PreText>
          </InfoWrapper>
          {
            data.support !== 'None' &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('협찬사')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.support}</Text>
            </InfoWrapper>
          }
          {
            data.host !== 'None' &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('주최')}</Text>
              {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('장소')}</Text> */}
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.host}</Text>
            </InfoWrapper>
          }
        </InfoTotalWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={EventRef} style={{ paddingTop: `36px` }}>
        {
          data.events !== undefined &&
          <EventWrapper>
            {
              data.events.map((data, index) => (
                <EventBox key={index} data={data} index={index} language={language} eventTitleFontSize={eventTitleFontSize} />
              ))
            }
          </EventWrapper>
        }
        {
          (isAlreadyPayment && !(eventId === 1 || eventId === 2)) ?
            <ButtonWrapper marginTop={buttonTopMargin}>
              <ModifyButton onClick={onClickModifyInfo}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#F9F9F9'>{t('수정')}</Text>
              </ModifyButton>
              <CancelButton onClick={onClickCancellation}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#F9F9F9'>{t('참가 취소')}</Text>
              </CancelButton>
            </ButtonWrapper>
            :
            <Button marginTop={buttonTopMargin} onClick={onClickAppication}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#F9F9F9'>{t('참가 신청 바로 가기')}</Text>
            </Button>
        }
      </ComponentWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background-color: #F9F9F9;
`;

const ComponentWrapper = styled.div`
  position: ${props => props.isFixed ? 'fixed' : 'static'};
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  
  margin-top: 30px;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;

  // margin-top: 36px;
  margin-top: 12px;
`;

const SectionTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  // margin-top: 28px;
  margin-top: 18px;

  :hover {
    cursor: pointer;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 35px;
`;

const SectionTextBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 33px;
`;

const SectionDivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3px;

  background-color: ${props => props.isClicked ? '#FF8B48' : '#D9D9D9'};

  transition: all 0.3s ease-in-out;
`;

const InfoTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const InfoInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PreText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  white-space: pre-line;

  :hover {
    cursor: default;
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 60px;

  margin-top: ${props => props.marginTop}px;
  margin-bottom: 27px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
  
  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 93.23%;

  margin-top: ${props => props.marginTop}px;
  margin-bottom: 27px;
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.44%;
  height: 60px;

  border-radius: 12px;
  background-color: #E0E0E0;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.44%;
  height: 60px;

  border-radius: 12px;
  background-color: #FF3131;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;