import React, { useState, useRef } from 'react';
import styled from 'styled-components';

export default function FindMemberInputBox(props) {
	const { value, setValue, index, placeholder, onClickFindUserInfo } = props;

	const [changed, setChanged] = useState(false);

	const InputRef = useRef();

	function handleClick(e) {
		// InputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
		setValue(e.target.value, index);
		setChanged(true);
	}

	function handleFocus(e) {
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
	}

	return (
    <TotalWrapper>
      <Input
        ref={InputRef}
        placeholder={placeholder}
        value={value}
        type='number'
        pattern='\d*'
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
      <FindButton onClick={() => onClickFindUserInfo(value, index)}>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>검색</Text>
      </FindButton>
    </TotalWrapper>
	)
}

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	// width: calc(100% - 24px);
  width: calc(75% - 24px);
	height: 40px;

	padding: 0 12px 0 12px;
	margin: 0 0 0 0;

	outline: none;
	border: 1px solid #E8E8E8;
	border-radius: 8px;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const FindButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20%;
  height: 42px;

  border-radius: 8px;
  background-color: #6DD49E;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;