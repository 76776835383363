import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { loadTossPayments } from '@tosspayments/payment-sdk';

import LoadingAnimation from '../Model/EventApplicationModel/LoadingAnimation';

export default function FinalPaymentPage() {

  // 테스트 클라이언트 키 //
  const testClientKey = 'test_ck_dP9BRQmyarYAYvaBWd9rJ07KzLNk';
  // 라이브 클라이언트 키 (일반 결제) //
  const normalClientKey = 'live_ck_dP9BRQmyarYOKL40JyW3J07KzLNk';

  const navigate = useNavigate();
  const params = useParams();

  // return ()
  useEffect(() => {
    const merchantUid = params.merchantUid;
    const orderName = params.orderName;
    const amount = params.amount;

    window.localStorage.setItem('Token', params.token);

    (
      async () => {
        // const tossPayments = await loadTossPayments(testClientKey);
        const tossPayments = await loadTossPayments(normalClientKey);

        tossPayments.requestPayment('카드', { // 결제 수단 파라미터
          // 결제 정보 파라미터
          amount: amount,
          orderId: merchantUid,
          orderName: orderName,
          successUrl: 'https://event.link-coach.io/payment/complete/success',
          failUrl: 'https://event.link-coach.io/payment/complete/fail',
          // successUrl: 'http://localhost:3000/payment/complete/success',
          // failUrl: 'http://localhost:3000/payment/complete/fail',
          // successUrl: 'http://172.30.1.10:3000/payment/complete/success',
          // failUrl: 'http://172.30.1.10:3000/payment/complete/fail',
        }).catch(function (error) {
          if (error.code === 'USER_CANCEL') {
            alert("결제 진행중에 취소하셨습니다.");
          }
          alert(error);
        })
      })();
  }, [params])
}