import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import NoneProfile from '../../../Image/NoneProfile.png';
import FirstPlaceImg from '../../../Image/FirstPlace.png';
import SecondPlaceImg from '../../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../../Image/ThirdPlace.png';
import DownProfileIconImg from '../../../Image/DownProfileIcon.png';
import UpProfileIconImg from '../../../Image/UpProfileIcon.png';
import EmptyIconImg from '../../../Image/EmptyIcon.png';
import PictureRightIcon from '../../../Image/PictureRightArrow.png';
import PictureLeftIcon from '../../../Image/PictureLeftArrow.png';

import ImageFocusModal from '../../../Model/EventDetailModel/ImageFocusModal';

export default function CustomRankDetailPage(props) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [eventTitle, setEventTitle] = useState(location.state.eventTitle);
  const [eventDate, setEventDate] = useState(location.state.eventDate);
  const [data, setData] = useState(location.state.data);

  const [totalVideoWidth, setTotalVideoWidth] = useState(374);
  const [totalVideoHeight, setTotalVideoHeight] = useState(374);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [isOpenRecord, setIsOpenRecord] = useState(true);
  const [imageURL, setImageURL] = useState('');
  const [pictureSize, setPictureSize] = useState(374);
  const [picture, setPicture] = useState([]);
  const [nowIndex, setNowIndex] = useState(0);
  const [isClickedFocusImage, setIsClickedFocusImage] = useState(false);

  const RightRef = useRef();
  const LeftRef = useRef();

  // 화면 크기 추출후 총 사진 크기 계산 //
  useEffect(() => {
    var totalPictureSize = windowSize.width * 0.9033;

    var totalVideoWidth = windowSize.width * 0.9033;
    var totalVideoHeight = totalVideoWidth * 0.5614;

    // console.log(totalPictureSize);
    setPictureSize(totalPictureSize);

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('순위표'), isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // 사진 정리
  useEffect(() => {
    if (data.picture !== '') {
      let pictures = data.picture.split('<-@->');

      setPicture(pictures);
    }
  }, [data])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    var responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
  }

  // 걸린시간 분/초로 계산 //
  function calcTime() {
    let min = 0;
    let sec = 0;

    min = parseInt(data.time / 60);
    sec = data.time % 60;

    if (min === 0 && sec === 0) return 'DNF';
    return min + ':' + sec;
  }

  // 유튜브 영상 추출 //
  function calcYoutubeLink() {
    let videoLink = data.link;


    // if (data.link.includes('https://www.youtube.com/watch?v=')) videoLink = videoLink.replace('https://www.youtube.com/watch?v=', '');
    // else videoLink = videoLink.replace('https://youtu.be/', '');

    if (data.link.includes('https://www.youtube.com/watch?v=')) videoLink = videoLink.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
    else videoLink = videoLink.replace('https://youtu.be/', 'https://www.youtube.com/embed/');

    return videoLink;
  }

  // 사진 크게보기 클릭시 //
  function onClickPicture(e) {
    // alert(e.target.includes(LeftRef.current));
    // alert(e.target.includes(RightRef.current));
    // if (e.target === LeftRef.current || e.target === RightRef.current) alert('!!!');
    // // if (url === '') return;

    // // setImageURL(url);
    // // setIsClickedFocusImage(true);
  }

  // 이벤트 구분 색상 정리 //
  function calcEventDivisionColor() {
    if (data.eventId === 4 || data.eventId === 5) return '#2F80ED';
    else if (data.eventId === 6) return '#4C532A';
    else if (data.eventId === 7) return '#881B20';
    else if (data.eventId === 8) return '#C1B70A';
  }

  // 이벤트 구분 텍스트 정리 //
  function claclEventDivisionText() {
    if (data.eventId === 4 || data.eventId === 5) return 'Jan';
    else if (data.eventId === 6) return 'Feb';
    else if (data.eventId === 7) return 'Mar';
    else if (data.eventId === 8 && data.section === 'A') return 'Apr(A)';
    else if (data.eventId === 8 && data.section === 'B') return 'Apr(B)';
  }

  // 이전 사진 클릭시 //
  function onClickPrevPicture() {
    if (nowIndex === 0) return;

    setNowIndex(nowIndex - 1);
  }

  // 다음 사진 클릭시 //
  function onClickNextPicture() {
    if (nowIndex > 5 || picture.length === (nowIndex + 1)) return;

    setNowIndex(nowIndex + 1);
  }

  return (
    <TopLevelWrapper>
      {isClickedFocusImage && <ImageFocusModal size={windowSize.width} image={imageURL} closeFn={() => setIsClickedFocusImage(false)} />}
      <TopTotalWrapper>
        <TopLineWrapper>
          <TitleWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{eventTitle}</Text>
            {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>{eventDate}</Text> */}
          </TitleWrapper>
        </TopLineWrapper>
        <DivisionLine />
        <ProfileWrapper>
          <RankingWrapper>
            <EventDivisionBox backgroundColor={calcEventDivisionColor}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{claclEventDivisionText()}</Text>
            </EventDivisionBox>
            {
              <RankingNumberBoxWrapper>
                {
                  (data.rank !== 0 && data.rank < 4) ?
                    <RankingLeaderBoxWrapper>
                      <RankingLeaderBox src={data.rank === 1 ? FirstPlaceImg : data.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                      <RankingImageTextWrapper>
                        <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                      </RankingImageTextWrapper>
                    </RankingLeaderBoxWrapper>
                    :
                    <RankingNumberBox>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank === 0 ? 'DNF' : data.rank}</Text>
                    </RankingNumberBox>
                }
              </RankingNumberBoxWrapper>
            }
          </RankingWrapper>
          <RankingInfoBox>
            <RankingInnerWrapper>
              <RankingLeftWrapper onClick={() => onClickPicture(data.profile)} >
                <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                <RankingTextWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{calcTime()}</Text>
                </RankingTextWrapper>
              </RankingLeftWrapper>
              <RankingRightWrapper onClick={() => setIsOpenRecord(!isOpenRecord)}>
                <UpAndDownIcon style={!isOpenRecord ? { opacity: 0 } : { opacity: 1 }} src={DownProfileIconImg} />
                <UpAndDownIcon style={!isOpenRecord ? { position: 'absolute', opacity: 1 } : { position: 'absolute', opacity: 0 }} src={UpProfileIconImg} />
              </RankingRightWrapper>
              {/* <RankingRightWrapper onClick={() => setIsOpenRecord(!isOpenRecord)}>
                <UpAndDownIcon style={ isOpenRecord ? { opacity: 0 } : { opacity: 1 }} src={DownProfileIconImg}/>
                <UpAndDownIcon style={isOpenRecord ? { position: 'absolute', opacity: 1 } : { position: 'absolute', opacity : 0}} src={UpProfileIconImg}/>
              </RankingRightWrapper> */}
            </RankingInnerWrapper>
          </RankingInfoBox>
        </ProfileWrapper>
        {
          isOpenRecord &&
          <ProfileTotalWrapper>
            {/* {
              (data.eventId === 4 || data.eventId === 5 || data.eventId === 6) &&
              <InfoDataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{t('걸린 시간')}</Text>
                <InfoDivisionLine width={56} />
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#000000'>{calcTime()}</Text>
              </InfoDataWrapper>
            }
            {
              data.eventId === 7 &&
              <InfoDataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{t('운동 결과')}</Text>
                <InfoDivisionLine width={56} />
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#000000'>{data.completeRep}</Text>
              </InfoDataWrapper>
            }
            {
              (data.eventId === 8 && data.section === 'A') &&
              <InfoDataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{t('끝난 라운드')}</Text>
                <InfoDivisionLine width={56} />
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#000000'>{data.completeRound}</Text>
              </InfoDataWrapper>
            }
            {
              (data.eventId === 8 && data.section === 'A') &&
              <InfoDataWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{t('끝난 운동')}</Text>
                <InfoDivisionLine width={56} />
                <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#000000'>{data.lastExerciseRep + " " + data.lastExercise}</Text>
              </InfoDataWrapper>
            }
              {
                (data.eventId === 8 && data.section === 'B') &&
                <InfoDataWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>운동 기록</Text>
                  <InfoDivisionLine width={68} />
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#000000'>{data.lastExerciseRep + " " + data.lastExercise}</Text>
                </InfoDataWrapper>
              } */}
            {
              (picture.length === 0 && data.link === '') &&
              <EmptyImageWrapper>
                <EmptyImage src={EmptyIconImg} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{t('등록된 사진과 영상이 없습니다')}</Text>
              </EmptyImageWrapper>
            }
            {
              picture.length !== 0  &&
              <ProfileWrapper style={{ marginTop: `20px` }}>
                <ProfileBoxWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{t('운동 사진')}</Text>
                  <PictureWrapper>
                    <ExercisePicture size={pictureSize} src={picture[nowIndex]}/>
                    <ButtonWrapper size={pictureSize} onClick={onClickPicture} >
                      <ImageMoveButtonWrapper>
                        {
                          nowIndex !== 0 ?
                            <MoveButton ref={LeftRef} onClick={onClickPrevPicture}>
                              <ImageMoveButton src={PictureLeftIcon} />
                            </MoveButton>
                            :
                            <MoveButton/>
                        }
                        {
                          (nowIndex !== 4 && picture.length >= 2 && picture.length !== (nowIndex + 1)) ?
                            <MoveButton ref={RightRef} onClick={onClickNextPicture}>
                              <ImageMoveButton src={PictureRightIcon} />
                            </MoveButton>
                            :
                            <MoveButton />
                        }
                      </ImageMoveButtonWrapper>
                    </ButtonWrapper>
                  </PictureWrapper>
                </ProfileBoxWrapper>
              </ProfileWrapper>
            }
            {
              data.link !== '' &&
              <ProfileWrapper style={{ marginTop: `20px`, marginBottom: `60px` }}>
                <ProfileBoxWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{t('이벤트 영상')}</Text>
                  <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
                    <iframe
                      width='100%'
                      height='100%'
                      // src={`https://www.youtube.com/embed/` + calcYoutubeLink()}
                      src={calcYoutubeLink()}
                      style={{ borderRadius: '12px' }}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube" />
                  </TotalVideoBox>
                </ProfileBoxWrapper>
              </ProfileWrapper>
            }
          </ProfileTotalWrapper>
        }
      </TopTotalWrapper>
      {/* <GobackButton onClick={() => navigate(-1)}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('돌아가기')}</Text>
      </GobackButton> */}
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TopTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%; 
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const ProfileTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 90.33%;
`;

const RankingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 2px;
`;

const EventDivisionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 22px;

  border-radius: 7px;
  background-color: ${props => props.backgroundColor};
`;

const RankingInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 78.74%;
  width: 87.16%;
  height: 64px;

  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);
`;

const RankingInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 92.33%;
`;

const RankingLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;

  width: 80%;
  height: 100%;
`;

const RankingRightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 20%;
  height: 100%;
`;

const UpAndDownIcon = styled.img`
  width: 24px;
  height: 24px;
  
  transition: all 0.3s ease-in-out;
`;

const RankingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 8px;
  background-color: #E0E0E0;
`;

const EmptyImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;

  margin-top: 100px;
`;

const EmptyImage = styled.img`
  width: 148px;
  height: 62px;
`;


const ProfileBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;

  object-fit: cover;
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 90.33%;
`;

const InfoDivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const ExercisePicture = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 6px;

  object-fit: cover;
`;

const ButtonWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 24px);
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 12px 0px 0px 0px;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.6);
`;

const DeleteImage = styled.img`
  width: 24px;
  height: 24px;
`;

const ImageMoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: ${props => props.marginTop}px;
  padding: 0px 12px 0px 12px;

  width: calc(100% - 24px);
`;

const MoveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 30px;
  width: 50px;
  // height: 30px;
  height: 50px;
`;

const ImageMoveButton = styled.img`
  width: 30px;
  height: 30px;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const GobackButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 56px;

  width: 100%;
  height: 86px;

  border-radius: 20px 20px 0px 0px;
  background-color: #6DD49E;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;