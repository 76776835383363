import { React, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SelectOnPath from '../../Image/SelectOn.png';
import SelectOffPath from '../../Image/SelectOff.png';

export default function SuccessSelectBox(props) {
	const { unit, setUnit } = props;

  const { t } = useTranslation();

	const [isClicked, setIsClicked] = useState(false);
  const [width, setWidth] = useState(0);

  const TopLevelRef = useRef();
	const SelectRef = useRef();

	const options = [
    {
      value: '완료하였습니다'
    },
    {
      value: '타임켑안에 못했어요'
    }
	];

  useEffect(() => {
    if (TopLevelRef === null) return;

    setWidth(TopLevelRef.current.clientWidth);

    setTimeout(() => {
      SelectRef.current.style.transition = 'all 0.3s ease-in-out';
    }, 500);
  }, [TopLevelRef])

	function onClick() {
		if (isClicked) {
			SelectRef.current.style.border = '1px solid #E8E8E8';
			SelectRef.current.style.height = `38px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `70px`;
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setUnit(options[index]);
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<SelectBox
				ref={SelectRef}
				isClicked={isClicked}
        width={width}
				buttonPath={isClicked ? SelectOnPath : SelectOffPath}
				onClick={onClick}>
				{
					isClicked ?
						options.map((data, index) =>
							<OptionWrapper key={index} onClick={() => optionalClick(index)}>
								<Option>{t(data.value)}</Option>
							</OptionWrapper>
						)
						:
						<OptionWrapper>
							<Option>
								{
									t(unit.value)
								}
							</Option>
						</OptionWrapper>
				}
			</SelectBox>
		</TopLevelWrapper>
	)
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

  // width: 28.97%;
  width: 100%;
  height: 40px;
`;

const SelectBox = styled.div`
	position: absolute;

	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: flex-start;

  // width: 15.7%;
  // width: 100%;
  width: ${props => props.width}px;
	height: 38px;

	border: 1px solid #E8E8E8;
	border-radius: 12px;
	overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 99% ${props => props.isClicked ? `3` : `55`}%;
	background-color: rgba(255, 255, 255, 1);

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;

	width: 100%;

	:hover {
		cursor: pointer;
	}
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	// color: #CBCBCB;
	color: #333333;

	margin: 3px 0 3px 13px;
`;