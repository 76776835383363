import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../Model/Network/FetchModule';

import CrownIcon from '../Image/CrownIcon.png';
import FirstPlaceImg from '../Image/FirstPlace.png';
import SecondPlaceImg from '../Image/SecondPlace.png';
import ThirdPlaceImg from '../Image/ThirdPlace.png';
import RightImg from '../Image/NotAvailableRight.png';
import NoneProfile from '../Image/NoneProfile.png';

export default function RankingPage() {

  const params = useParams();
  const navigate = useNavigate();

  const [organizerId, setOrganizerId] = useState(params.organizerId);
  const [eventId, setEventId] = useState(params.eventId);
  const [eventTitle, setEventTitle] = useState(params.eventName);
  const [eventDate, setEventDate] = useState(params.eventDate);
  const [nowSection, setNowSection] = useState(params.section);

  const [isTeamFight, setIsTeamFight] = useState(false);
  const [data, setData] = useState([]);
  const [sections, setSections] = useState([]);
  const [rankingList, setRankingList] = useState([]);

  // useEffect(() => {
  //   console.log(organizerId);
  //   console.log(eventId);
  //   console.log(eventTitle);
  //   console.log(eventDate);
  //   console.log(nowSection);
  // }, [organizerId, eventId, eventTitle, eventDate, nowSection])

  // Member Or Team List API Call //
  useEffect(() => {
    var requestData = {
      eventId: params.eventId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('score/', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          setData(responseData.data);

          if (responseData.error === 'Team') setIsTeamFight(true);
          else setIsTeamFight(false);
        }
      }
    )();

    var sections = [];

    for (var i = 0; i < params.sectionLength; i++) {
      sections.push((i === params.sectionLength - 1) ? 'Total' : String.fromCharCode(i + 65));
    }

    setSections(sections);
  }, [params])

  // 순위 정리 //
  useEffect(() => {
    var newData = data.slice();
    var finalData = [];

    for (var i = 0; i < newData.length; i++) {
      var innerData;

      // 점수 내역이 없을경우 //
      if (newData[i].scores.length === 0) {
        if (isTeamFight) {
          innerData = {
            id: newData[i].teamId,
            name: newData[i].teamName,
            score: -1,
            // time: 999999,
            minute: 0,
            second: 0,
            rank: 99999,
            profile: newData[i].profile,
            members: newData[i].members
          }
        }
        else {
          innerData = {
            id: newData[i].memberId,
            name: newData[i].name,
            score: -1,
            // time: 999999,
            minute: 0,
            second: 0,
            rank: 99999,
            profile: newData[i].linkCoachProfile
          }
        }
      }
      // 점수 내역이 있을경우 //
      else {

        var isExist = false;

        for (var j = 0; j < newData[i].scores.length; j++) {

          if (newData[i].scores[j].section === nowSection) {

            // 팀전인 경우 //
            if (isTeamFight) {
              innerData = {
                id: newData[i].teamId,
                name: newData[i].teamName,
                score: newData[i].scores[j].point,
                // time: newData[i].scores[j].time,
                minute: newData[i].scores[j].minute,
                second: newData[i].scores[j].second,
                rank: newData[i].scores[j].rank,
                profile: newData[i].profile,
                members: newData[i].members
              }
            }
            // 개인전인 경우 //
            else {
              innerData = {
                id: newData[i].memberId,
                name: newData[i].name,
                score: newData[i].scores[j].point,
                // time: newData[i].scores[j].time,
                minute: newData[i].scores[j].minute,
                second: newData[i].scores[j].second,
                rank: newData[i].scores[j].rank,
                profile: newData[i].linkCoachProfile
              }
            }
            isExist = true;

          }
        }

        // 섹션에 저장된 내역이 없는경우 //
        if (!isExist) {
          // 팀전일 경우 //
          if (isTeamFight) {
            innerData = {
              id: newData[i].teamId,
              name: newData[i].teamName,
              score: -1,
              // time: 999999,
              minute: 0,
              second: 0,
              rank: 99999,
              profile: newData[i].profile,
              members: newData[i].members
            }
          }
          // 개인전일 경우 //
          else {
            innerData = {
              id: newData[i].memberId,
              name: newData[i].name,
              score: -1,
              // time: 999999,
              minute: 0,
              second: 0,
              rank: 99999,
              profile: newData[i].lickCoachProfile
            }
          }
        }
      }

      finalData.push(innerData);
    }

    // finalData.desc 진행 //
    // finalData.sort(function (a, b) {
    //   return a.score > b.score ? -1 : a.score < b.score ? 1 : 0;
    // })

    // finalData Rank 별로 asc 진행 //
    finalData.sort(function (a, b) {
      return a.rank < b.rank ? -1 : a.rank > b.rank ? 1 : 0;
    })
    // console.log(finalData);
    setRankingList(finalData);
  }, [data, isTeamFight, nowSection])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: '순위표', isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // Section 불러오기 //
  useEffect(() => {
    (
      async () => { 
        setNowSection(await window.localStorage.getItem('NowSection'));
      }
    )();
  }, [])

  // Section 저장 //
  useEffect(() => {
    (
      async () => { 
        await window.localStorage.setItem('NowSection', nowSection);
      }
    )();
  }, [nowSection])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    var responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
  }

  // Section Button 클릭시 //
  function onClickSectionButton(section) {
    // 날짜별 공개시점 정리 //
    setNowSection(section);
  }

  // 팀 버튼 클릭 //
  function onClickTeamButton(data) {
    // console.log(data);
    if (isTeamFight) {
      navigate('/member/detail', {
        state: {
          eventTitle: eventTitle,
          eventDate: eventDate,
          data: data
        }
      })
    }
  }

  // 점수 뷰 정리 //
  function calcViewPointAndTimeText(data) {
    // if (data.minute !== 0 && data.second !== 0) return 'Point ' + data.score + ' - ' + data.minute + 'M ' + data.second + 'S';
    // else if (data.minute !== 0 && data.second === 0) return 'Point ' + data.score + ' - ' + data.minute + 'M';
    // else if (data.minute === 0 && data.second !== 0) return 'Point ' + data.score + ' - ' + data.second + 'S';
    // else return 'Point ' + data.score;
    var str = '';

    if (data.minute !== 0) str += data.minute + '분 ';
    if (data.second !== 0) str += data.second + '초 ';
    if (data.score !== 0) {
      if (str === '') str += 'Point ' + data.score;
      else str += '(Point ' + data.score + ')';
    }

    return str;
  }

  return (
    <TopLevelWrapper>
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{eventTitle}</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>{eventDate}</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        {
          sections.map((data, index) => (
            data === 'Total' ?
              <TotalSectionButton key={index} isClicked={nowSection === 'Total'} onClick={() => onClickSectionButton('Total')}>
                <CrownImage src={CrownIcon} />
              </TotalSectionButton>
              :
              <SectionButton key={index} isClicked={nowSection === data} onClick={() => onClickSectionButton(data)}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data}</Text>
              </SectionButton>
          ))
        }
      </SectionButtonWrapper>
      <DivisionLine />
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{nowSection === 'Total' ? '전체 합산 순위' : "Event " + (nowSection.charCodeAt(0) - 64) + ' 순위'}</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <RankingTotalWrapper>
        {
          rankingList.map((data, index) => (
            <RankingViewWrapper key={index} onClick={() => onClickTeamButton(data)}>
              {
                data.score !== -1 ?
                  <RankingNumberBoxWrapper>
                    {
                      index < 3 ?
                        <RankingLeaderBoxWrapper>
                          <RankingLeaderBox src={index === 0 ? FirstPlaceImg : index === 1 ? SecondPlaceImg : ThirdPlaceImg} />
                          <RankingImageTextWrapper>
                            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                          </RankingImageTextWrapper>
                        </RankingLeaderBoxWrapper>
                        :
                        <RankingNumberBox>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                        </RankingNumberBox>
                    }
                  </RankingNumberBoxWrapper>
                  :
                  <RankingNumberBoxWrapper>
                    <RankingNumberBox>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>0</Text>
                    </RankingNumberBox>
                  </RankingNumberBoxWrapper>
              }
              <RankingClickBox>
                <InfoBox>
                  <LeftWrapper>
                    <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                    <InfoTextWrapper style={{ marginLeft: '8px' }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                      {
                        data.score !== -1 ?
                          // <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>Point {data.score}</Text>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcViewPointAndTimeText(data)}</Text>
                          :
                          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{nowSection !== 'Total' ? '점수 없음' : ''}</Text>
                      }
                    </InfoTextWrapper>
                  </LeftWrapper>
                  {
                    isTeamFight && <DetailButton src={RightImg} />
                  }
                </InfoBox>
              </RankingClickBox>
            </RankingViewWrapper>
          ))
        }
      </RankingTotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#F1DA0D`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin: 4px 0px 80px 0px;

  width: 90.33%;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(87.16% - 28px);
  height: 62px;

  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
