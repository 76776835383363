import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../Model/Network/FetchModule';
import TermsModal from '../../Model/EventAgreeModel/TermsModal';
import { t } from 'i18next';

export default function ShowYourPerformanceAgreePage() {

  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState([
    {
      termsId: 0,
      termsTitle: '',
      termsText: '',
      isEssential: true,
      isClicked: false
    }]);
    
  const [isAllAgree, setIsAllAgree] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);
  const [language, setLanguage] = useState('Korea');

  const organizerId = 26;

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('동의하기'), isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 약관 API Call //
  useEffect(() => {
    let requestData = {
      organizerId: organizerId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        let language = window.localStorage.getItem('Language');
        
        setLanguage(language);

        const responseData = await fetchModule.postDataOfEvent('terms/', 'POST', requestData);
        
        if (responseData.status === 200) {
          setData(responseData.data);
				}
      }
    )();
  }, [organizerId])

  // 전체 동의여부 확인 //
  useEffect(() => {
    for(let i = 0; i < data.length; i++) {
      if (data[i].isEssential && !data[i].isClicked) {
        setIsAllAgree(false);
        return;
      }
    }

    setIsAllAgree(true);
  }, [data])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
  }

  // 전체 동의 클릭 //
  function onClickAllAgree() {
    let newData = data.slice();

    for (let i = 0; i < newData.length; i++) {
      newData[i].isClicked = !newData[i].isClicked;
    }

    setData(newData);
  }
  
  // 동의 버튼 클릭 //
  function onClickAgree(index) {
    let newData = data.slice();

    newData[index].isClicked = !newData[index].isClicked;
    setData(newData);
  }

  // 이용약관 보기 클릭 //
  function onClickTermsViewButton() {
    // console.log('이용약관 보기')
    setIsViewModal(true);
  }

  // 참가 신청 바로가기 클릭 //
  function onClickGoNext() {
    if (!isAllAgree) return;

    // let requestData = {
    //   eventId: 27
    // }
    
    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.postDataOfEvent('tournament/check', 'POST', requestData);
    //     // console.log(responseData);
    //     if (responseData.status === 200) {
          
    //       if (responseData.data) alert('이미 신청내역이 존재합니다!');
    //       else {
    //         // let newData = {
    //         //   title: data.title,
    //         //   organizerId: data.organizerId,
    //         //   selectedEvent: selectedEvent,
    //         //   teamNumber: parseInt(selectedEvent.eventId) === 24 ? teamNumber : 1,
    //         //   commonSouvenirCount: commonSouvenirCount,
    //         //   commonSouvenir: data.commonSouvenir,
    //         //   additionalSouvenirs: data.additionalSouvenirs,
    //         //   additionalSouvenirList: additionalSouvenirList,
    //         //   totalString: totalString,
    //         //   commonPrice: commonPrice,
    //         //   additionalPrice: additionalPrice,
    //         //   totalPrice: totalPrice,
    //         //   vat: vat,
    //         //   // eventDay: eventDay
    //         // }
        
    //         // window.localStorage.setItem('EventData', JSON.stringify(newData));
            
    //         // navigate('/application/' + selectedEvent.eventId + '/' + selectedEvent.isTeamFight + '/false');
    //         // navigate('/application/battlecrew3/' + selectedEvent.eventId + '/' + eventDay);
    //         navigate('/application/girlcrush/' + 27);
    //       }
    //     }
    //   }
    // )();
    // navigate('/select/fitforceteamleague/');
    navigate('/select/show_your_performance/');
  }
  
  return (
    <TopLevelWrapper>
      { isViewModal && <TermsModal data={data[0]} language={language} closeFn={() => setIsViewModal(false)}/> }
      <ComponentWrapper>
        <TitleWrapper style={{ marginTop: `22px`}}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('약관에 동의해 주세요.')}</Text>
        </TitleWrapper>
        <AllAgreeBox onClick={onClickAllAgree}>
          <TitleWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('약관 전체 동의')}</Text>
          </TitleWrapper>
        </AllAgreeBox>
        <TitleWrapper style={{ marginTop: `47px`}}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('참가 신청 약관')}</Text>
        </TitleWrapper>
        {
          data.map((data, index) => (
            <AgreeBox key={index}>
              <CheckBox isClicked={data.isClicked} onClick={() => onClickAgree(index)}/>
              <TextWrapper onClick={() => onClickAgree(index)}>
                <Text style={{ minWidth: t('[필수]') === '[필수]' ? '41px' : '46px', marginRight: `4px` }} fontFamily='NotoSansKR-Medium' fontSize={16} color={data.isEssential ? '#5BD98A' : '#FF8B48'}>{data.isEssential ? t('[필수]') : t('[선택]')}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{language === 'Korea' ? data.termsTitle : data.termsTitleEn}</Text>
              </TextWrapper>
              <ViewButton onClick={onClickTermsViewButton}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#5BD98A'>{t('보기')}</Text>
              </ViewButton>
            </AgreeBox>
          ))
        }
      </ComponentWrapper>
      <GoNextButton isClicked={isAllAgree} onClick={onClickGoNext}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('참가 신청 바로가기')}</Text>
      </GoNextButton>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  // height: 100%;
  height: 100vh;

  background-color: #FFFFFF;
`;

const ComponentWrapper = styled.div`
display: flex;
flex-direction: column;

justify-content: center;
align-items: center;

width: 100%;
`;

const TitleWrapper = styled.div`
display: flex;
flex-direction: column;

justify-content: flex-start;
align-items: flex-start;

width: 90.33%;
`;

const AllAgreeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 49px;

  margin-top: 16px;

  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
    // background-color: #E0E0E0;
  }

  transition: all 0.3s ease-in-out;
`;

const AgreeBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 90.33%;

  margin-top: 16px;
`;

const CheckBox = styled.div`
  width: 20px;
  height: 20px;

  margin-top: 4px;

  border: 1px solid #E0E0E0;
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 79.94%;
`;

const ViewButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const GoNextButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-bottom: 34px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;