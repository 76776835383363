import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../Network/FetchModule';

import FirstPlaceImg from '../../Image/FirstPlace.png';
import SecondPlaceImg from '../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../Image/ThirdPlace.png';
import RightImg from '../../Image/NotAvailableRight.png';
import NoneProfile from '../../Image/NoneProfile.png';
import WaitIcon from '../../Image/WaitIcon.png';
import LazyTeamComponent from './LazyTeamComponent';

export default function TotalSection(props) {
  const { eventId, eventTitle, eventDate, openDate } = props;

  const navigate = useNavigate();

  const [isTeamFight, setIsTeamFight] = useState(false);
  const [sections, setSections] = useState([]);
  const [data, setData] = useState([]);
  const [rankingList, setRankingList] = useState([]);
  
  const TopLevelRef = useRef();

  // Member Or Team List API Call //
  useEffect(() => {
    var requestData = {
      eventId: eventId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('score/', 'POST', requestData);
        console.log(responseData.data);
        if (responseData.status === 200) {
          setData(responseData.data);

          if (responseData.error === 'Team') setIsTeamFight(true);
          else setIsTeamFight(false);
        }
      }
    )();
  }, [eventId])

  // 순위 정리 //
  useEffect(() => {
    var newData = data.slice();
    var finalData = [];

    for (var i = 0; i < newData.length; i++) {
      var innerData;

      // 점수 내역이 없을경우 //
      if (newData[i].scores.length === 0) {
        if (isTeamFight) {
          innerData = {
            id: newData[i].teamId,
            name: newData[i].teamName,
            score: -1,
            // time: 999999,
            minute: 0,
            second: 0,
            rank: 99999,
            profile: newData[i].profile,
            members: newData[i].members
          }
        }
        else {
          innerData = {
            id: newData[i].memberId,
            name: newData[i].name,
            score: -1,
            // time: 999999,
            minute: 0,
            second: 0,
            rank: 99999,
            profile: newData[i].linkCoachProfile
          }
        }
      }
      // 점수 내역이 있을경우 //
      else {

        var isExist = false;

        for (var j = 0; j < newData[i].scores.length; j++) {

          if (newData[i].scores[j].section === 'Total') {

            // 팀전인 경우 //
            if (isTeamFight) {
              innerData = {
                id: newData[i].teamId,
                name: newData[i].teamName,
                score: newData[i].scores[j].point,
                minute: newData[i].scores[j].minute,
                second: newData[i].scores[j].second,
                rank: newData[i].scores[j].rank,
                // time: newData[i].scores[j].time,
                profile: newData[i].profile,
                members: newData[i].members
              }
            }
            // 개인전인 경우 //
            else {
              innerData = {
                id: newData[i].memberId,
                name: newData[i].name,
                score: newData[i].scores[j].point,
                minute: newData[i].scores[j].minute,
                second: newData[i].scores[j].second,
                rank: newData[i].scores[j].rank,
                // time: newData[i].scores[j].time,
                profile: newData[i].linkCoachProfile
              }
            }
            isExist = true;

          }
        }

        // 섹션에 저장된 내역이 없는경우 //
        if (!isExist) {
          // 팀전일 경우 //
          if (isTeamFight) {
            innerData = {
              id: newData[i].teamId,
              name: newData[i].teamName,
              score: -1,
              // time: 999999,
              minute: 0,
              second: 0,
              rank: 99999,
              profile: newData[i].profile,
              members: newData[i].members
            }
          }
          // 개인전일 경우 //
          else {
            innerData = {
              id: newData[i].memberId,
              name: newData[i].name,
              score: -1,
              // time: 999999,
              minute: 0,
              second: 0,
              rank: 99999,
              profile: newData[i].lickCoachProfile
            }
          }
        }
      }

      finalData.push(innerData);
    }

    // // finalData.desc 진행 //
    // finalData.sort(function (a, b) {
    //   return a.score > b.score ? -1 : a.score < b.score ? 1 : 0;
    // })

    // finalData Rank 별로 asc 진행 //
    finalData.sort(function (a, b) {
      return a.rank < b.rank ? -1 : a.rank > b.rank ? 1 : 0;
    })

    setRankingList(finalData);
    // TopLevelRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    setTimeout(() => {
      window.scrollTo({ top: 1, behavior: 'smooth' })
    }, 300)
    // onTop();
  }, [data, isTeamFight, TopLevelRef])

  // 팀 버튼 클릭 //
  function onClickTeamButton(data) {
    // console.log(data);
    if (isTeamFight) {
      navigate('/member/detail', {
        state: {
          eventTitle: eventTitle,
          eventDate: eventDate,
          data: data
        }
      })
    }
  }

  // 점수 뷰 정리 //
  function calcViewPointAndTimeText(data) {
    // if (data.minute !== 0 && data.second !== 0) return 'Point ' + data.score + ' - ' + data.minute + 'M ' + data.second + 'S';
    // else if (data.minute !== 0 && data.second === 0) return 'Point ' + data.score + ' - ' + data.minute + 'M';
    // else if (data.minute === 0 && data.second !== 0) return 'Point ' + data.score + ' - ' + data.second + 'S';
    // else return 'Point ' + data.score;

    var str = '';

    if (data.minute !== 0) str += data.minute + '분 ';
    if (data.second !== 0) {
      // var seconds = data.second.toString().split('.');

      // console.log(seconds.length);
      // if (seconds.length === 1) str += seconds[0] + '초 ';
      // else str += seconds[0] + '초 ' + seconds[1];
      str += data.second + '초 ';
    }
    if (data.score !== 0) {
      if (str === '') str += 'Point ' + data.score;
      else str += '(Point ' + data.score + ')';
    }

    return str;
  }

  return (
    <TopLevelWrapper>
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment(openDate).format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{eventTitle}</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{moment(openDate).format('M월 DD일')} 공개예정</Text>
          </WaitWrapper>
          :
          <RankingTotalWrapper ref={TopLevelRef}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>총 참여팀 수 : {rankingList.length}팀</Text>
            {
              rankingList.map((data, index) => (
                // <LazyTeamComponent key={index} data={data} index={index} isTeamFight={isTeamFight} onClickTeamButton={onClickTeamButton}/>
                // index < 17 &&
                <RankingViewWrapper key={index} onClick={() => onClickTeamButton(data)}>
                  {
                    data.score !== -1 ?
                      <RankingNumberBoxWrapper>
                        {
                          index < 3 ?
                            <RankingLeaderBoxWrapper>
                              <RankingLeaderBox src={index === 0 ? FirstPlaceImg : index === 1 ? SecondPlaceImg : ThirdPlaceImg} />
                              <RankingImageTextWrapper>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                              </RankingImageTextWrapper>
                            </RankingLeaderBoxWrapper>
                            :
                            <RankingNumberBox>
                              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                            </RankingNumberBox>
                        }
                      </RankingNumberBoxWrapper>
                      :
                      <RankingNumberBoxWrapper>
                        <RankingNumberBox>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>0</Text>
                        </RankingNumberBox>
                      </RankingNumberBoxWrapper>
                  }
                  <RankingClickBox>
                    <InfoBox>
                      <LeftWrapper>
                        <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                        <InfoTextWrapper style={{ marginLeft: '8px' }}>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                          {/* { data.score !== -1 && <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>Point {data.score}</Text> } */}
                          { data.score !== -1 && <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcViewPointAndTimeText(data)}</Text> }
                        </InfoTextWrapper>
                      </LeftWrapper>
                      {
                        isTeamFight && <DetailButton src={RightImg} />
                      }
                    </InfoBox>
                  </RankingClickBox>
                </RankingViewWrapper>
              ))
            }
            {/* <FlatList
              list={rankingList}
              renderItem={(item, idx) => <LazyTeamComponent data={item} index={idx} isTeamFight={isTeamFight} onClickTeamButton={onClickTeamButton}/>}
              // renderItem={ <LazyTeamComponent/> }
              /> */}
          </RankingTotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin: 4px 0px 80px 0px;

  width: 90.33%;
  // height: 100%;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(87.16% - 28px);
  height: 62px;

  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
