import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import PoweredImage from '../../Image/PoweredByLink.png';

export default function FitForceTeamLeagueCompletePage() {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [totalVideoWidth, setTotalVideoWidth] = useState(366);
  const [totalVideoHeight, setTotalVideoHeight] = useState(456);

  const navigate = useNavigate();
  const params = useParams();

  // 시작시 스크롤 맨 위로 //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [handleMassage])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    // alert(e.data);
    if (responseData.goBack) navigate(-1);
  }

  // 페이지 이름 변경내역 업로드 및 받아온 데이터 정리 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: '이벤트 등록 완료', isAvailableGoback: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, [])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    let totalVideoWidth = windowSize.width * 0.884;
    let totalVideoHeight = totalVideoWidth * 1.245;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])


  // 메인화면으로 클릭시 //
  function onClickGoHome() {
    if (params.isFirst === 'true') {
      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
      }
      catch (e) {
        console.log("Web Message Post Error : " + e);
      }
    }
    else {
      navigate('/check/girlcrush/' + 27 + '/false');
    }
  }

  // 참가 종목 띄우기 //
  function onCalcEventTitle(eventId) {
    if (eventId === 34) return '남/남 팀전-온라인 예선';
    else if (eventId === 35) return '여/여 팀전-온라인 예선';
    else return'4인 팀전-오프라인 본선';
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>F.F TEAM LEAGUE</Text>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>이벤트 등록 신청 완료</Text>
        </TitleWrapper>
        <ImageWrapper width={totalVideoWidth} height={totalVideoWidth} >
          <Image src='https://picture.link-zone.org/event/FitForceTeamLeague/FitForceTeamLeagueMain.png'/>
        </ImageWrapper>
        <PoweredImageWrapper>
          <PoweredImg src={PoweredImage}/>
        </PoweredImageWrapper>
        <TextComponentWrapper>
          <LayerWrapper>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>종목</Text>
              <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{onCalcEventTitle(parseInt(params.eventId))}</Text>
            </TextWrapper>
            <TextWrapper style={{ width: '35%' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>팀이름</Text>
              <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{params.teamName}</Text>
            </TextWrapper>
          </LayerWrapper>
            <TextWrapper style={{ marginTop: '4px', width: '100%' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>참가자</Text>
              <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{params.memberNames}</Text>
            </TextWrapper>
          <TextWrapper style={{ marginTop: '16px', width: '100%'}}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{'<참가접수 이벤트>'}</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{'참가신청 등록완료를 인스타 스토리에 핏포스팀리그 계정(@ff_team_league)과 함께 공유해주시면 추첨을 통해 다양한 상품을 드립니다!'}</Text>
          </TextWrapper>
          <TextWrapper style={{ marginTop: '96px', width: '100%'}}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{'[FITXBLUE2024] 외부 유출 금지'}</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{'>1258*6521520226'}</Text>
          </TextWrapper>
          <TextWrapper style={{ marginTop: '32px', width: '100%'}}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{'[핏포스 기어 이벤트]'}</Text>
            {/* <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{'>1258*6521520226'}</Text> */}
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>참가 접수 기간 중</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>케이핏포스 스마트스토어 구매시 40% 할인</Text>
          </TextWrapper>
        </TextComponentWrapper>
      </TotalWrapper>
      <MainButtonWrapper>
        <MainButton onClick={onClickGoHome}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{params.isFirst === 'true' ? '메인 화면으로' : '신청 정보 보기'}</Text>
        </MainButton>
      </MainButtonWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 22px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;

  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const TextComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 88.4%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const PoweredImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 4px;

  width: 88%;
`;

const PoweredImg = styled.img`
  width: 174px;
  height: 25px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const MainButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  padding-bottom: 15px;
  margin-top: 54px;

  width: 100%;
  height: 68px;

  background-color: #FFFFFF;
`;

const MainButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;