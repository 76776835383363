import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";

import FetchModule from "../../../Model/Network/FetchModule";
import TeamOfFourSubmitModel from "./TeamOfFourSubmitModel";
import TeamOfFourSubmitViewModel from "./TeamOfFourSubmitViewModel";

import WaitIcon from '../../../Image/WaitIcon.png';
import DownloadIcon from '../../../Image/DownloadIcon.png';

export default function TeamOfFourEvent4(props) {
  const { eventId, totalVideoWidth, totalVideoHeight, setIsCompleteSubmit } = props;

  const { t } = useTranslation();

  const [members, setMembers] = useState([]);
  
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  // 팀장 기록 //
  const [completeRep, setCompleteRep] = useState(0);
  const [firstExercise, setFirstExercise] = useState(0);
  const [viewCompleteRep, setViewCompleteRep] = useState(0);
  const [viewFirstExercise, setViewFirstExercise] = useState(0);
  // 팀장 기록 //

  // 팀원1 기록 //
  const [completeRound, setCompleteRound] = useState(0);
  const [secondExercise, setSecondExercise] = useState(0);
  const [viewCompleteRound, setViewCompleteRound] = useState(0);
  const [viewSecondExercise, setViewSecondExercise] = useState(0);
  // 팀원1 기록 //

  // 팀원2 기록 //
  const [lastExerciseRep, setLastExerciseRep] = useState(0);
  const [thirdExercise, setThirdExercise] = useState(0);
  const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);
  const [viewThirdExercise, setViewThirdExercise] = useState(0);
  // 팀원2 기록 //

  // 팀원3 기록 //
  const [bodyWeight, setBodyWeight] = useState(0);
  const [distance, setDistance] = useState(0);
  const [viewBodyWeight, setViewBodyWeight] = useState(0);
  const [viewDistance, setViewDistance] = useState(0);
  // 팀원3 기록 //

  const [tieBreakMin, setTieBreakMin] = useState(0);
  const [tieBreakSec, setTieBreakSec] = useState(0);
  // const [lastExercise, setLastExercise] = useState('');
  // const [lastExerciseRep, setLastExerciseRep] = useState(0);

  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });

  const [isRx, setIsRx] = useState(true);
  const [isExistRecord, setIsExistRecord] = useState(false);
  // const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [viewIsRx, setViewIsRx] = useState(false);
  const [viewMin, setViewMin] = useState(0);
  const [viewSec, setViewSec] = useState(0);
  // const [viewCompleteRep, setViewCompleteRep] = useState(0);
  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);
  // const [viewLastExercise, setViewLastExercise] = useState(0);
  // const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    let requestData = {
      eventId: eventId,
      section: 'D'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/team_of_4/recently', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {

          // let time = parseInt(responseData.data.time);
          // let decimalPoint = (responseData.data.time % 1).toFixed(2);

          // if (responseData.data.time === 0) setIsSuccess({value: '완료하였습니다'});
          // else setIsSuccess({value: '타임켑안에 못했어요'});

          // setViewMin(Math.floor(time / 60));
          // setMin(Math.floor(time / 60));

          // if (decimalPoint === '0.00') {
          //   setViewSec(time % 60);
          //   setSec(time % 60);
          // }
          // else {
          //   setViewSec((time % 60) + parseFloat(decimalPoint));
          //   setSec((time % 60) + parseFloat(decimalPoint));
          // }

          // let tieBreakTime = parseInt(responseData.data.tieBreak);
          // let tieBreakDecimalPoint = (responseData.data.tieBreak % 1).toFixed(2);

          // setViewTieBreakMin(Math.floor(tieBreakTime / 60));
          // setTieBreakMin(Math.floor(tieBreakTime / 60));

          // if (tieBreakDecimalPoint === '0.00') {
          //   setViewTieBreakSec((tieBreakTime % 60));
          //   setTieBreakSec((tieBreakTime % 60));
          // }
          // else {
          //   setViewTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
          //   setTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
          // }
          let newMembers = responseData.data.members.slice();

          // newMembers.sort((a, b) => b.division - a.division || a.memberId - b.memberId);
          newMembers.sort((a, b) => b.division.localeCompare(a.division) || a.memberId - b.memberId);
          // console.log(newMembers);
          setMembers(newMembers);

          if (responseData.data.eventRecord !== undefined) {
            setIsExistRecord(true);
            // setViewIsRx(responseData.data.isRx);
            // setCompleteRep(responseData.data.completeRep);
            // setLastExerciseRep(responseData.data.lastExerciseRep)
            // setViewCompleteRep(responseData.data.completeRep);
            // setViewCompleteRound(responseData.data.completeRound);
            // setViewLastExerciseRep(responseData.data.lastExerciseRep);
            // setViewLastExercise(responseData.data.lastExercise);

            // 팀장 데이터 정리 //
            setCompleteRep(responseData.data.eventRecord.completeRep);
            setViewCompleteRep(responseData.data.eventRecord.completeRep);
            setFirstExercise(responseData.data.eventRecord.firstExercise);
            setViewFirstExercise(responseData.data.eventRecord.firstExercise);
            // 팀장 데이터 정리 //

            // 팀원1 데이터 정리 //
            setCompleteRound(responseData.data.eventRecord.completeRound);
            setViewCompleteRound(responseData.data.eventRecord.completeRound);
            setSecondExercise(responseData.data.eventRecord.secondExercise);
            setViewSecondExercise(responseData.data.eventRecord.secondExercise);
            // 팀원1 데이터 정리 //

            // 팀원2 데이터 정리 //
            setLastExerciseRep(responseData.data.eventRecord.lastExerciseRep);
            setViewLastExerciseRep(responseData.data.eventRecord.lastExerciseRep);
            setThirdExercise(responseData.data.eventRecord.thirdExercise);
            setViewThirdExercise(responseData.data.eventRecord.thirdExercise);
            // 팀원2 데이터 정리 //

            // 팀원3 데이터 정리 //
            setBodyWeight(responseData.data.eventRecord.bodyWeight);
            setViewBodyWeight(responseData.data.eventRecord.bodyWeight);
            setDistance(responseData.data.eventRecord.distance);
            setViewDistance(responseData.data.eventRecord.distance);
            // 팀원3 데이터 정리 //
            

            setViewLink(responseData.data.eventRecord.link);
            setPicture(responseData.data.eventRecord.picture);
          }
        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'TO4_EVENT4_RuleBook.pdf';
      // https://link-picture.s3.ap-northeast-2.amazonaws.com/event/ShowYourPerformance/ShowYourPerformanceEvent1.pdf
      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/TeamOfFour', fileName: fileName }));
      // window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://link-picture.s3.ap-northeast-2.amazonaws.com/event/believer', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {

    // if (moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-11-05T00:00:00').format('YYYY.MM.DD HH:mm:ss')) {
    //   alert('제출 기한이 마감되었습니다.');
    //   return;
    // }

    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {

      let finalCompleteRep = completeRep === '' ? 0 : completeRep;
      let finalFirstExercise = firstExercise === '' ? 0 : firstExercise;

      let finalCompleteRound = completeRound === '' ? 0 : completeRound;
      let finalSecondExercise = secondExercise === '' ? 0 : secondExercise;

      let finalLastExerciseRep = lastExerciseRep === '' ? 0 : lastExerciseRep;
      let finalThirdExercise = thirdExercise === '' ? 0 : thirdExercise;

      let finalBodyWeight = bodyWeight === '' ? 0 : bodyWeight;
      let finalDistance = distance === '' ? 0 : distance;

      // if (finalCompleteRep === 0) {
      //   alert('기록을 입력해주세요');
      //   return;
      // }
      // else if ((link !== ''
      //   && !link.includes('https://youtube.com/shorts/')
      //   && !link.includes('https://www.youtube.com/watch?v=')
      //   && !link.includes('https://m.youtube.com/watch?v=')
      //   && !link.includes('https://youtube.com/watch?v=')
      //   && !link.includes('https://youtu.be/'))) {
      //   alert('영상링크를 정확히 입력해주세요');
      //   return;
      // }
      // else if (finalTieBreakMin + finalTieBreakSec === 0) {
      //   alert('TieBreak & Time Cap을 입력해주세요!');
      //   return;
      // }
      if ((link !== ''
        && !link.includes('https://youtube.com/shorts/')
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let requestData = {
        eventId: eventId,
        section: 'D',
        isRx: true,
        time: 0,
        tieBreak: 0,
        completeRep: finalCompleteRep,
        completeRound: finalCompleteRound,
        lastExercise: '',
        lastExerciseRep: finalLastExerciseRep,
        firstExercise: finalFirstExercise,
        secondExercise: finalSecondExercise,
        thirdExercise: finalThirdExercise,
        bodyWeight: finalBodyWeight,
        distance: finalDistance,
        point: 0,
        link: link,
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);

          if (responseData.status === 200) {
            setIsCompleteSubmit(true);
          }
        }
      )();

      setIsClickedSubmit(false);
    }
    else {
      let requestData = {
        eventId: eventId,
        section: 'D'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().isBefore(moment('2024-09-25T00:00:00')) ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Team Of 4</Text>
            <Text style={{ marginTop: '2px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2024.09.25 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox >
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 4</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>Total 12min 1RM: Snatch,C&J</Text>
                {/* <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF3131'>Timecap 10min</Text> */}
              </TitleBox>
            </TitleWrapper>
            {/* <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                // src={`https://picture.link-zone.org/event/ShowYourPerformance/ShowYourPerformanceEvent2RuleVideo.mp4`}
                src={`https://www.youtube.com/embed/nkFsk5NP0Tk`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox> */}
            <WodWrapper>
              <OrangeBoxWrapper style={{ marginTop: '10px'}}>
                {/* <OrangeBox> */}
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>[Team of 4]</Text>
                {/* </OrangeBox> */}
              </OrangeBoxWrapper>

              <AndThenBox style={{ marginTop: '4px' }}>
                <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#FFFFFF'>In 6min [A/B Athlete]</Text>
              </AndThenBox>

              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Find 1RM Snatch</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '12px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={62} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>

              <AndThenBox /*style={{ marginTop: '8px' }}*/>
                <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#FFFFFF'>In 6min [C/D Athlete]</Text>
              </AndThenBox>

              <ExerciseWrapper /*style={{ marginTop: '8px' }}*/>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Find 1RM Clean & Jerk</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '12px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={62} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
            </WodWrapper>

            {
              (!isExistRecord && !isClickedSubmit) && 
              <WodImage style={{ marginTop: '20px' }} src='https://picture.link-zone.org/event/TeamOfFour/TeamOf4Event4.png' />
            }
            {
              (isExistRecord || isClickedSubmit) ?
                <ExerciseWrapper >
                  {
                    isClickedSubmit ?
                      <TeamOfFourSubmitModel
                        eventId={eventId}
                        section='D'
                        members={members}

                        // isSuccess={isSuccess}
                        // setIsSuccess={setIsSuccess}

                        // min={min}
                        // setMin={setMin}
                        // sec={sec}
                        // setSec={setSec}
                        // completeRep={completeRep}
                        // setCompleteRep={setCompleteRep}

                        // lastExerciseRep={lastExerciseRep}
                        // setLastExerciseRep={setLastExerciseRep}

                        // tieBreakMin={tieBreakMin}
                        // setTieBreakMin={setTieBreakMin}
                        // tieBreakSec={tieBreakSec}
                        // setTieBreakSec={setTieBreakSec}

                        // 팀장 //
                        completeRep={completeRep}
                        setCompleteRep={setCompleteRep}
                        firstExercise={firstExercise}
                        setFirstExercise={setFirstExercise}
                        // 팀장 //

                        // 팀원1 //
                        completeRound={completeRound}
                        setCompleteRound={setCompleteRound}
                        secondExercise={secondExercise}
                        setSecondExercise={setSecondExercise}
                        // 팀원1 //

                        // 팀원2 //
                        lastExerciseRep={lastExerciseRep}
                        setLastExerciseRep={setLastExerciseRep}
                        thirdExercise={thirdExercise}
                        setThirdExercise={setThirdExercise}
                        // 팀원2 //

                        // 팀원3 //
                        bodyWeight={bodyWeight}
                        setBodyWeight={setBodyWeight}
                        distance={distance}
                        setDistance={setDistance}
                        // 팀원3 //

                        link={link}
                        setLink={setLink}
                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                      />
                      :
                      <TeamOfFourSubmitViewModel
                        eventId={eventId}
                        section='D'
                        members={members}
                        // setIsExistRecord={setIsExistRecord}

                        // min={viewMin}
                        // sec={viewSec}
                        // completeRep={viewCompleteRep}
                        // tieBreakMin={viewTieBreakMin}
                        // tieBreakSec={viewTieBreakSec}
                        // completeRep={viewCompleteRep}
                        // lastExerciseRep={lastExerciseRep}

                        // 팀장 //
                        completeRep={viewCompleteRep}
                        firstExercise={viewFirstExercise}
                        // 팀장 //

                        // 팀원1 //
                        completeRound={viewCompleteRound}
                        secondExercise={viewSecondExercise}
                        // 팀원1 //

                        // 팀원2 //
                        lastExerciseRep={viewLastExerciseRep}
                        thirdExercise={viewThirdExercise}
                        // 팀원2 //

                        // 팀원3 //
                        bodyWeight={viewBodyWeight}
                        distance={viewDistance}
                        // 팀원3 //

                        link={viewLink}
                        picture={picture}
                      />
                  }
                </ExerciseWrapper>
                :
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  <InfoWrapper>
                    <InfoTitleWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                    </InfoTitleWrapper>
                  </InfoWrapper>
                  {/* <ScoreGuideLineImageWrapper onClick={downloadPDF}>
                    <ScoreGuideLineImage src='https://picture.link-zone.org/event/FitForceTeamLeague/FitForceTeamLeagueEvent1RuleBooke.png' />
                  </ScoreGuideLineImageWrapper> */}
                  <RuleBookDownloadButtonWrapper>
                    <RuleBookDownloadButton onClick={downloadPDF}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>룰북 다운 받기</Text>
                      <RuleBookDownloadImage src={DownloadIcon}/>
                    </RuleBookDownloadButton>
                  </RuleBookDownloadButtonWrapper>
                  <RuleBookWrapper>
                    <RuleColumnTextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>“룰북 다운 받기” 아이콘을 클릭해주세요</Text>
                    </RuleColumnTextWrapper>
                  </RuleBookWrapper>
                </ExerciseWrapper>
            }
            {
              moment().isAfter(moment('2024-09-25T00:00:00')) &&
              moment().isBefore(moment('2024-10-06T23:59:59')) &&
              <RankingButton onClick={onClickRecordAndSubmit}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>제출하기</Text>
              </RankingButton>
            }
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 90.33%;

  gap: 4px;
`;

const ColorBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 0px 6px 0px 6px;

  width: 74px;
  height: 18px;

  border-radius: 2px;
  background-color: ${props => props.backgroundColor};
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-between;
  justify-content: flex-start;
  align-items: center;

  padding: 0px 12px 0px 12px;
  // padding: 0px 8px 0px 8px;
  // padding: 0px 3px 0px 3px;
  margin: 0px 0px 0px 0px;

  // width: calc(90.33% - 69px);
  width: calc(100% - 85px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 10px 0px 10px 0px;

  padding-bottom: 10px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const AndThenBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 21px;

  background-color: #6DD49E;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 10px;
  
  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 40px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleBookDownloadButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 100%;
`;

const RuleBookDownloadButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  width: calc(90.33% - 20px);
  height: 35px;

  border-radius: 5px;
  background-color: #FFB800;
`;

const RuleBookDownloadImage = styled.img`
  width: 21px;
  height: 21px;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  border-radius: 10px;
  background-color: ${props => props.color};
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 34px 0px 34px 0px;
  // margin-top: 34px;

  // width: 90.33%;
  width: 100%;
  height: 70px;

  // border-radius: 12px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const WodImage = styled.img`
  width: 100%;
  height: 100%;
`;
