import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


export default function BloodTypeBox(props) {
  const { value, setValue, index} = props;

  const { t } = useTranslation();

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        <InfoBox isSelected={value === 'A'} onClick={() => setValue('A', index)}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'A' ? '#FFFFFF' : '#595959'}>{'A' + t('형')}</Text>
        </InfoBox>
        <InfoBox isSelected={value === 'B'} onClick={() => setValue('B', index)}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'B' ? '#FFFFFF' : '#595959'}>{'B' + t('형')}</Text>
        </InfoBox>
      </LayerWrapper>
      <LayerWrapper style={{ marginTop: '12px' }}>
        <InfoBox isSelected={value === 'O'} onClick={() => setValue('O', index)}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'O' ? '#FFFFFF' : '#595959'}>{'O' + t('형')}</Text>
        </InfoBox>
        <InfoBox isSelected={value === 'AB'} onClick={() => setValue('AB', index)}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'AB' ? '#FFFFFF' : '#595959'}>{'AB' + t('형')}</Text>
        </InfoBox>
      </LayerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(48.39% - 4px);
  height: 36px;

  border: ${props => props.isSelected ? '2px solid #6DD49E' : '2px solid #E0E0E0'};
  border-radius: 8px;
  background-color: ${props => props.isSelected ? '#6DD49E' : '#FFFFFF'};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;