import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import VideoIcon from '../../../Image/VideoIcon.png';

export default function MarvelCSection(props) {
  const { totalVideoWidth, totalVideoHeight, onClickVideo } = props;

  const navigate = useNavigate();

  // 순위표 버튼 클릭시 //
  function onClickRankingButton() {
    navigate('/ranking/1/1/6/C/마블 썸머 쓰로다운/2022.07.30(토)');
  }

  return (
    <TopLevelWrapper>
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment('2022-07-27T00:00:00').format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>마블 써머 쓰로다운</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>7월 29일 공개예정</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>C</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>For Time with Time cap 12min</Text>
              </TitleBox>
            </TitleWrapper>
            <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                style={{ borderRadius: '12px' }}
                src={`https://www.youtube.com/embed/Zd_lftAKXf8`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox>
            <ExerciseWrapper>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                    </ExerciseNumberBox>
                    <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>SNATCHE</Text>
                  </ExerciseNumberBoxWrapper>
                  <Image src={VideoIcon} onClick={() => onClickVideo('5GipmmgzbyQ')} />
                </InfoTitleWrapper>
                <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
                  <DivisionLine width={54} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:65</Text>
                  <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:95</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                </InfoDataWrapper>
                <InfoDataWrapper style={{ marginTop: '16px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
                  <DivisionLine width={68} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>15</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <ExerciseWrapper>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                    </ExerciseNumberBox>
                    <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>BURPEES</Text>
                  </ExerciseNumberBoxWrapper>
                  <Image src={VideoIcon} onClick={() => onClickVideo('fCZAQNevkik')} />
                </InfoTitleWrapper>
                <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
                  <DivisionLine width={68} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>15</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <ExerciseWrapper>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                    </ExerciseNumberBox>
                    <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>SNATCHE</Text>
                  </ExerciseNumberBoxWrapper>
                  <Image src={VideoIcon} onClick={() => onClickVideo('5GipmmgzbyQ')} />
                </InfoTitleWrapper>
                <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
                  <DivisionLine width={55} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:65</Text>
                  <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:95</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                </InfoDataWrapper>
                <InfoDataWrapper style={{ marginTop: '16px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
                  <DivisionLine width={68} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>10</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <ExerciseWrapper>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>04</Text>
                    </ExerciseNumberBox>
                    <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>BURPEES</Text>
                  </ExerciseNumberBoxWrapper>
                  <Image src={VideoIcon} onClick={() => onClickVideo('fCZAQNevkik')} />
                </InfoTitleWrapper>
                <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
                  <DivisionLine width={68} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>15</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <ExerciseWrapper>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>05</Text>
                    </ExerciseNumberBox>
                    <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>SNATCHE</Text>
                  </ExerciseNumberBoxWrapper>
                  <Image src={VideoIcon} onClick={() => onClickVideo('5GipmmgzbyQ')} />
                </InfoTitleWrapper>
                <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
                  <DivisionLine width={55} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:65</Text>
                  <Text style={{ marginRight: '7px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>M:95</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                </InfoDataWrapper>
                <InfoDataWrapper style={{ marginTop: '16px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
                  <DivisionLine width={68} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>5</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <ExerciseWrapper>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>06</Text>
                    </ExerciseNumberBox>
                    <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>BURPEES</Text>
                  </ExerciseNumberBoxWrapper>
                  <Image src={VideoIcon} onClick={() => onClickVideo('fCZAQNevkik')} />
                </InfoTitleWrapper>
                <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
                  <DivisionLine width={68} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>15</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <ExerciseWrapper>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#6DD49E'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>07</Text>
                    </ExerciseNumberBox>
                    <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Rest</Text>
                  </ExerciseNumberBoxWrapper>
                  {/* <Image src={VideoIcon}/> */}
                </InfoTitleWrapper>
                <InfoDataWrapper style={{ marginTop: '20px' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>시간</Text>
                  <DivisionLine width={71} />
                  <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>3</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>min</Text>
                </InfoDataWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <ExerciseWrapper>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>기록 측정 기준</Text>
                </InfoTitleWrapper>
                <InfoTitleWrapper style={{ marginTop: '20px' }}>
                  <ExerciseNumberBoxWrapper>
                    <ExerciseNumberBox backgroundColor='#FF8B48'>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                    </ExerciseNumberBox>
                    <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>SNATCHE</Text>
                  </ExerciseNumberBoxWrapper>
                </InfoTitleWrapper>
                <PointWrapper>
                  <PointRowWrapper>
                    <Text style={{marginRight: '3px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF3131'>RX</Text>
                    <Text style={{marginRight: '10px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>- F: 65 / M:95 lb</Text>
                    <Text style={{marginRight: '3px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF3131'>INT</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>- F:55 / M:75 lb</Text>
                  </PointRowWrapper>
                  <PointRowWrapper>
                    <Text  style={{marginRight: '3px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FF3131'>SCALED</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>- F: 35 / M:45 lb</Text>
                  </PointRowWrapper>
                </PointWrapper>
              </InfoWrapper>
            </ExerciseWrapper>
            <RankingButton onClick={onClickRankingButton}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>순위표</Text>
            </RankingButton>
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: #6DD49E;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  width: calc(89.3% - 24px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 25px 0px 25px 0px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;
  margin: 0px 0px 8px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;
  
  width: 81.28%;
`;

const PointRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
  // background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
