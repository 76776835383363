import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FetchModule from '../../Model/Network/FetchModule';

export default function FitForceTeamLeaguePaymentPage() {

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    // amount: amount,
    // orderId: merchantUid,
    // orderName: orderName,

    // window.localStorage.setItem('Token', params.token);

    window.localStorage.setItem('Token', 'Bearer ' + params.token);

    const obj = {};

    obj.PCD_PAY_TYPE = 'card'
    obj.PCD_PAY_WORK = 'CERT';
    obj.PCD_CARD_VER = '02';
    obj.PCD_PAYER_AUTHTYPE = 'sms';

    obj.PCD_PAY_GOODS = params.orderName;
    // obj.PCD_PAY_TOTAL = 100;
    obj.PCD_PAY_TOTAL = params.amount;
    obj.PCD_PAY_OID = params.merchantUid;


    obj.callbackFunction = getResult;
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';

    window.PaypleCpayAuthCheck(obj);
  }, [params])

  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && res.PCD_PAY_CODE === '0000') {

      let authKey = res.PCD_AUTH_KEY;
      let reqKey = res.PCD_PAY_REQKEY;
      let billingKey = res.PCD_PAYER_ID;
      let merchant_uid = res.PCD_PAY_OID;
      let approval = res.PCD_PAY_TIME;
      let totalPrice = parseInt(res.PCD_PAY_TOTAL);
      let approval_date = approval.slice(0, 4) + '-' + approval.slice(4, 6) + '-' + approval.slice(6, 8) + 'T' + approval.slice(8, 10) + ':' + approval.slice(10, 12) + ':' + approval.slice(12, 14);


      let requestData = {
        merchantUid: merchant_uid,
        approvalDate: approval_date,
        totalPrice: totalPrice,
        authKey: authKey,
        reqKey: reqKey,
        billingKey: billingKey
      }
      
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEventVer2('sales/payment/fitforceteamleague', 'POST', requestData);

          if (responseData.status === 200) {
            // navigate('/check/fitforceteamleague/' + params.eventId);
            navigate('/payment/complete/success');
          }
          else if (responseData.status === 201) {
            alert('올바른 데이터가 아닙니다');
            navigate('/payment/complete/fail');
          }
          else if (responseData.status === 202) {
            alert('올바른 접근이 아닙니다');
            navigate('/payment/complete/fail');
          }
          else if (responseData.status === 203) {
            alert('이미 결제된 내역입니다');
            navigate('/payment/complete/fail');
          }
          else if (responseData.status === 204) {
            alert('올바르지 않은 데이터입니다');
            navigate('/payment/complete/fail');
          }
          else if (responseData.status === 205) {
            alert('신청인원을 초과하였습니다.');
            navigate('/payment/complete/fail');
          }
          else {
            alert(responseData.error);
            navigate('/payment/complete/fail');
          // successUrl: 'https://event.link-coach.io/payment/complete/success',
          // failUrl: 'https://event.link-coach.io/payment/complete/fail',
          }
        }
      )();
    }
    else {
      if (res.PCD_PAY_CODE === undefined) {
        console.log(res.PCD_PAY_MSG);
        alert(res.PCD_PAY_MSG);
      }
      else {
        console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
        alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      }
    }
  }

  return <></>
}