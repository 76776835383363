import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Model/Network/FetchModule';

import CrownIcon from '../../Image/CrownIcon.png';
import FirstPlaceImg from '../../Image/FirstPlace.png';
import SecondPlaceImg from '../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../Image/ThirdPlace.png';
import RightImg from '../../Image/NotAvailableRight.png';
import NoneProfile from '../../Image/NoneProfile.png';
import WhiteMale from '../../Image/WhiteMale.png';
import ColorMale from '../../Image/ColorMale.png';
import WhiteFemale from '../../Image/WhiteFemale.png';
import ColorFemale from '../../Image/ColorFemale.png';
import WaitIcon from '../../Image/WaitIcon.png';

import { useTranslation } from 'react-i18next';

export default function BattleCrew3TotalA(props) {
  const { section } = props;

  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [nowSection, setNowSection] = useState('A');

  // const [data, setData] = useState([]);
  const [isClickedMyRank, setIsClickedMyRank] = useState(false);
  const [myRanking, setMyRanking] = useState(undefined);
  // const [responseRankingList, setResponseRankingList] = useState([]);
  const [rankingList, setRankingList] = useState([]);

  const [myRankTotalHeight, setMyRankTotalHeight] = useState(64);
  const [myRankHeight, setMyRankHeight] = useState(62);

  const [isLoading, setIsLoading] = useState(false);

  // Ranking Info API CALL //
  useEffect(() => {
    setIsLoading(true);
    setIsClickedMyRank(false);

    let eventId = 13;

    if (section === 'TotalA') eventId = 13;
    else if (section === 'TotalB') eventId = 15;
    else if (section === 'TotalC') eventId = 14;

    setMyRanking(undefined);
    setRankingList([]);

    let requestData = {
      eventId: eventId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('score/rank/battlecrew3', 'POST', requestData);
        
        // Rx로 구분 //
        if (responseData.status === 200) {
          let rankData = responseData.data.eventRankList.slice();
          let myRank = responseData.data.myRankData;
          let totalRankData = [];
          // console.log(myRank);
          let validRankData = [];
          let repValidRankData = [];
          let invalidRankData = [];

          // // 순위 데이터 분류 //
          // for (let i = 0; i < rankData.length; i++) {
          //   if (rankData[i].completeRep === 0) invalidRankData.push(rankData[i]);
          //   // 데이터가 올바른 경우 //
          //   else validRankData.push(rankData[i]);
          // }

          // // 데이터 순위 정렬 //
          // validRankData.sort((a, b) => b.completeRep - a.completeRep || b.tieBreak - a.tieBreak);

          // 순위 데이터 분류 //
          for (let i = 0; i < rankData.length; i++) {
            if (rankData[i].time !== 0) validRankData.push(rankData[i]);
            else if (rankData[i].completeRep !== 0) repValidRankData.push(rankData[i]);
            // 데이터가 올바르지 않은경우 //
            else invalidRankData.push(rankData[i]);
          }

          // console.log(validRankData);
          // console.log(repValidRankData);
          // console.log(invalidRankData);
          // // 데이터 순위 정렬 //
          // validRankData.sort((a, b) => a.time - b.time || b.tieBreak - a.tieBreak);
          // 데이터 순위 정렬 //
          validRankData.sort((a, b) => a.time - b.time);
          // 데이터 순위 정렬 //
          repValidRankData.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);

          // 올바르지 않은 데이터는 이름순 정렬 //
          invalidRankData.sort(function (a, b) {
            let x = a.name;
            let y = b.name;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          validRankData = validRankData.concat(repValidRankData);
          
          for (let i = 0; i < validRankData.length; i++) {
            if (i !== 0 && validRankData[i].completeRep === 0 && validRankData[i].time === 0) {
              validRankData[i].rank = validRankData[i - 1].rank;
            }
            else if (i !== 0 
              && validRankData[i].time !== 0
              && validRankData[i].time === validRankData[i - 1].time) validRankData[i].rank = (i + 1);
            else if (i !== 0
              && validRankData[i].completeRep !== 0
              && validRankData[i].tieBreak !== 0
              && validRankData[i].tieBreak === validRankData[i - 1].tieBreak
              && validRankData[i].completeRep === validRankData[i - 1].completeRep) {
              validRankData[i].rank = validRankData[i - 1].rank;
            }
            else {
              validRankData[i].rank = (i + 1);
            }
          }

          totalRankData = validRankData.concat(invalidRankData);

          // 남성 여성 순으로 정렬 //
          totalRankData.map((data) => {
            let rankMembers = data.members;
            let finalRankMembers = [];

            let rankMales = [];
            let rankFemales = [];

            rankMembers.map((memberData) => {
              if (memberData.gender === 'male') rankMales.push(memberData);
              else rankFemales.push(memberData);
            })

            rankMales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            rankFemales.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            finalRankMembers = rankMales.concat(rankFemales);

            data.members = finalRankMembers;
            // data.profile = finalRankMembers[0].profile;
            // let isExist = false;

            // for (let i = 0; i < finalRankMembers.length; i++) {
            //   if (finalRankMembers[i].profile !== '') {
            //     data.profile = finalRankMembers[i].profile;
            //     isExist = true;
            //     break;
            //   }
            // }

            // if (!isExist) data.profile = '';
          })

          // 내 순위 찾기
          if (myRank !== undefined) {
            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0 && validRankData[i].completeRep === 0 && validRankData[i].time === 0) {
                validRankData[i].rank = validRankData[i - 1].rank;
                if (validRankData[i].teamId === myRank.teamId) myRank.rank = validRankData[i - 1].rank;
              }
              else if (i !== 0 
                && validRankData[i].time !== 0
                && validRankData[i].time === validRankData[i - 1].time) {
                if (validRankData[i].teamId === myRank.teamId){
                  console.log(validRankData[i]);
                  myRank.rank = validRankData[i - 1].rank;
                }
              }
              else if (i !== 0
                && validRankData[i].completeRep !== 0
                && validRankData[i].tieBreak !== 0
                && validRankData[i].tieBreak === validRankData[i - 1].tieBreak
                && validRankData[i].completeRep === validRankData[i - 1].completeRep) {
                if (validRankData[i].teamId === myRank.teamId) myRank.rank = validRankData[i - 1].rank;
              }
              else {
                validRankData[i].rank = (i + 1);

                if (validRankData[i].teamId === myRank.teamId) myRank.rank = (i + 1);
              }
            }

            let members = myRank.members;
            let finalMembers = [];

            let males = [];
            let females = [];

            members.map((data) => {
              if (data.gender === 'male') males.push(data);
              else females.push(data);
            })

            males.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            females.sort(function (a, b) {
              let x = a.name;
              let y = b.name;

              if (x > y) return 1;
              if (x < y) return -1;
            })

            finalMembers = males.concat(females);

            myRank.members = finalMembers;
            // myRank.profile = finalMembers[0].profile;

            setMyRanking(myRank);
          }

          setRankingList(totalRankData);
        }

        // setIsLoading(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    )();
  }, [params, section])

  // 내 순위 자세히 보기에 따른 컴포넌트 크기 변환 //
  useEffect(() => {
    if (isClickedMyRank) {
      if (nowSection === 'Total') {
        if (myRanking.members.length < 4) {
          setMyRankTotalHeight(330);
          setMyRankHeight(148);
        }
        else if (myRanking.members.length < 7) {
          setMyRankTotalHeight(401);
          setMyRankHeight(219);
        }
        else {
          setMyRankTotalHeight(471);
          setMyRankHeight(289);
        }
      }
      else {
        if (myRanking.members.length < 4) {
          setMyRankTotalHeight(150);
          setMyRankHeight(148);
        }
        else if (myRanking.members.length < 7) {
          setMyRankTotalHeight(221);
          setMyRankHeight(219);
        }
        else {
          setMyRankTotalHeight(291);
          setMyRankHeight(289);
        }
      }
    }
    else {
      setMyRankTotalHeight(64);
      setMyRankHeight(62);
    }
  }, [isClickedMyRank, myRanking, nowSection])

  // // Message Detect Save And Delete //
  // useEffect(() => {
  //   // Android //
  //   document.addEventListener('message', handleMassage);

  //   // IOS //
  //   window.addEventListener('message', handleMassage);

  //   return () => {
  //     // Android //
  //     document.removeEventListener('message', handleMassage);

  //     // IOS //
  //     window.removeEventListener('message', handleMassage);
  //   }
  // }, [])

  // // 페이지 이름 변경내역 업로드 //
  // useEffect(() => {
  //   try {
  //     window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('순위표'), isAvailableGoback: false }));
  //   }
  //   catch (e) {
  //     console.log("Web Message Post Error : " + e);
  //   }
  // }, []);

  // // React Native에서 웹으로 보내는 데이터 handler //
  // async function handleMassage(e) {
  //   var responseData = JSON.parse(e.data);

  //   if (responseData.goBack) {
  //     await window.localStorage.removeItem('GenderDivision');
  //     navigate(-1);
  //   }
  // }

  // 내순위 팀 구성원 자세히보기 클릭시 //
  function onClickMyRecordDetailButton() {
    let data = rankingList.slice();

    for (let i = 0; i < data.length; i++) {
      data[i].isClicked = false;
    }

    setRankingList(data);
    setIsClickedMyRank(!isClickedMyRank);
  }

  // 팀 구성원 자세히보기 클릭시 //
  function onClickRecordDetailButton(index) {
    let data = rankingList.slice();

    for (let i = 0; i < data.length; i++) {
      if (i !== index) data[i].isClicked = false;
    }

    data[index].isClicked = !data[index].isClicked;

    setIsClickedMyRank(false);
    setRankingList(data);
  }

  // 걸린시간 분/초로 계산 //
  function calcTime(data) {

    if (nowSection === 'A' || nowSection === 'B') {
      if (data.time !== 0) {
        let min = 0;
        let sec = 0;
        let decimalPoint = (data.time % 1).toFixed(2);

        min = parseInt(data.time / 60);

        if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
        else sec = parseInt(data.time % 60);

        if (min === 0 && sec === 0) return 'DNF';
        else {
          if (sec === 0) {
            if (min < 10) return '0' + min + ':00';
            else return min + ':00';
          }
          else {
            if (min < 10) {
              if (sec < 10) return '0' + min + ':0' + sec;
              else return '0' + min + ':' + sec;
            }
            else {
              if (sec < 10) return min + ':0' + sec;
              else return min + ':' + sec;
            }
          }
        }
      }
      else if (data.completeRep !== 0) {
        return data.completeRep;
      }
      else return '미제출';
    }
  }

  // 걸린시간 분/초로 계산의 단위 정리 //
  function calcTimeUnit(data) {
    if (data.completeRep !== 0) return 'Point';
    else return '';
  }

  // Tie Break 계산 //
  function calcTieBreak(data) {
    if (data.tieBreak === 0 || data.tieBreak === undefined || data.completeRep === 0) return '';
    else {
      let min = 0;
      let sec = 0;
      let decimalPoint = (data.tieBreak % 1).toFixed(2);

      min = parseInt(data.tieBreak / 60);
      // sec = data.tieBreak % 60;

      if (decimalPoint !== '0.00') sec = parseInt(data.tieBreak % 60) + parseFloat(decimalPoint);
      else sec = parseInt(data.tieBreak % 60);


      if (sec === 0) {
        if (min < 10) return '0' + min + ':00';
        else return min + ':00';
      }
      else {
        if (min < 10) {
          if (sec < 10) return '0' + min + ':' + '0' + sec;
          else return '0' + min + ':' + sec;
        }
        else {
          if (sec < 10) return min + ':' + '0' + sec;
          else return min + ':' + sec;
        }
      }
    }
  }

  // 각 섹션별 올바른 데이터인지 확인 //
  function checkValidData(data) {
    if ((nowSection === 'A' || nowSection === 'B') && data.completeRep === 0) return false;
    else if (nowSection === 'C' && data.firstExercise === 0) return false;
    else if (nowSection === 'D' && data.time === 0) return false;
    else if (nowSection === 'Total' && data.rank === undefined) return false;
    else return true;
  }

  // 클릭시 최종컴포넌트 늘어나는 크기 정하기 //
  function calcTotalComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    if (data.isClicked) {
      if (nowSection === 'Total') {
        if (data.members.length < 4) return 330;
        else if (data.members.length < 7) return 401;
        else return 471;
      }
      else {
        if (data.members.length < 4) return 150;
        else if (data.members.length < 7) return 221;
        else return 291;
      }
    }
    else return 64;
  }

  // 클릭시 팀원 보기 컴포넌트 늘어나는 크기 정하기 //
  function calcComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    if (data.isClicked) {
      if (data.members.length < 4) return 148;
      else if (data.members.length < 7) return 219;
      else return 289;
    }
    else return 62;
  }

  // 최종 순위표 내의 순위 산출시 배경색 결정 //
  function selectTotalEventRankingBoxBackgroundColor(data) {
    if (nowSection === 'Total' && data === undefined) return '#E0E0E0';
    else if (data.rank === 1) return '#FEB501';
    else if (data.rank === 2) return '#94B1C1';
    else if (data.rank === 3) return '#F38D30';
    else return '#E0E0E0';
  }

  // 걸린시간 분/초로 계산 //
  function calcTotalTime(data) {

    // if (data.time === 0) return '미제출';
    // else {
    let min = 0;
    let sec = 0;
    let decimalPoint = (data.time % 1).toFixed(2);

    min = parseInt(data.time / 60);

    if (decimalPoint !== '0.00') sec = parseInt(data.time % 60) + parseFloat(decimalPoint);
    else sec = parseInt(data.time % 60);

    // if (min === 0 && sec === 0) return '미제출';
    // else {
    if (sec === 0) {
      if (min < 10) return '0' + min + ':00';
      else return min + ':00';
    }
    else {
      if (min < 10) {
        if (sec < 10) return '0' + min + ':0' + sec;
        else return '0' + min + ':' + sec;
      }
      else {
        if (sec < 10) return min + ':0' + sec;
        else return min + ':' + sec;
      }
    }
    // }
    // }
  }

  // 현재 섹션 수정시 //
  function onClickNowSection(data) {
    setIsLoading(true);
    setNowSection(data);
  }

  return (
    <TopLevelWrapper>
      {/* <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#333333'>SUFF Online Team Rxd Event</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        <SectionButton isClicked={nowSection === 'A'} onClick={() => onClickNowSection('A')}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>EVENT 1</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'B'} onClick={() => onClickNowSection('B')}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>EVENT 2</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'C'} onClick={() => onClickNowSection('C')}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>EVENT 3</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'D'} onClick={() => onClickNowSection('D')}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>EVENT 4</Text>
        </SectionButton>
        <TotalSection isClicked={nowSection === 'Total'} onClick={() => onClickNowSection('Total')}>
          <CrownImage src={CrownIcon} />
        </TotalSection>
      </SectionButtonWrapper>
      <DivisionLine /> */}
      {
        <TotalWrapper>
          {
            (myRanking !== undefined) &&
            <TempWrapper>
              <TopLineWrapper>
                <TitleWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('내 순위')}</Text>
                </TitleWrapper>
              </TopLineWrapper>
              {
                isLoading ?
                  <RankingTotalWrapper>
                    <RankingLoadingView>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                    </RankingLoadingView>
                  </RankingTotalWrapper>
                  :
                  <RankingTotalWrapper>
                    <RankingViewWrapper height={myRankTotalHeight} onClick={() => onClickMyRecordDetailButton()}>
                      {
                        // (myRanking.completeRep !== 0) ?
                        // checkValidData(myRanking) ?
                        <RankingNumberBoxWrapper>
                          {
                            (myRanking.rank < 4 && myRanking.rank !== -1) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={myRanking.rank === 1 ? FirstPlaceImg : myRanking.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{ myRanking.rank !== -1 ? myRanking.rank : 0}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                        // :
                        // <RankingNumberBoxWrapper>
                        //   <RankingNumberBox>
                        //     <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>DNF</Text>
                        //   </RankingNumberBox>
                        // </RankingNumberBoxWrapper>
                      }
                      <RankingClickBoxWrapper>
                        <RankingClickBox height={myRankHeight} >
                          <TotalInfoBox>
                            <InfoBox>
                              <LeftWrapper>
                                <Profile src={myRanking.profile === '' ? NoneProfile : myRanking.profile} />
                                <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                  <InnerInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{myRanking.name}</Text>
                                  </InnerInfoTextWrapper>
                                  <RecordBox>
                                    <RecordTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(myRanking)}</Text>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTimeUnit(myRanking)}</Text>
                                    </RecordTextWrapper>
                                    <RecordTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#828282'>{(myRanking.tieBreak === 0 || myRanking.tieBreak === undefined || myRanking.completeRep === 0) ? `` : `TieBreak`}</Text>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#4F4F4F'>{calcTieBreak(myRanking)}</Text>
                                    </RecordTextWrapper>
                                  </RecordBox>
                                </InfoTextWrapper>
                              </LeftWrapper>
                              {/* <DetailButton isClicked={isClickedMyRank} src={RightImg} /> */}
                            </InfoBox>
                            <MemberRowView>
                              {
                                myRanking.members.map((data, index) => (
                                  <MemberView key={index}>
                                    <MemberProfile src={data.profile === '' ? NoneProfile : data.profile} />
                                    <ColorBoxWrapper>
                                      <ColorBox backgroundColor={data.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                        <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                      </ColorBox>
                                      <ColorBoxInTextWrapper>
                                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                      </ColorBoxInTextWrapper>
                                    </ColorBoxWrapper>
                                  </MemberView>
                                ))
                              }
                            </MemberRowView>
                          </TotalInfoBox>
                        </RankingClickBox>
                        {
                          nowSection === 'Total' &&
                          <TotalRecordWrapper isClicked={isClickedMyRank && nowSection === 'Total'}>
                            {/* Event 1 */}
                            <EventRowWrapper>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 13px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event1)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event1.rank === -1 ? '0' : myRanking.event1.rank}</Text>
                                </EventRankingBox>
                                {
                                  // myRanking.event1.rank !== -1 ?
                                  myRanking.event1.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.event1.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(myRanking.event1)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                }
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 1 */}
                            {/* Event 2 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event2)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event2.rank === -1 ? '0' : myRanking.event2.rank}</Text>
                                </EventRankingBox>
                                {
                                  // myRanking.event2.rank !== -1 ?
                                  myRanking.event2.rank !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.event2.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(myRanking.event2)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                }
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 2 */}
                            {/* Event 3 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event3)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event3.rank === -1 ? '0' : myRanking.event3.rank}</Text>
                                </EventRankingBox>
                                {
                                  // myRanking.event3.rank !== -1 ?
                                  myRanking.event3.firstExercise !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{myRanking.event3.firstExercise}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                }
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 3 */}
                            {/* Event 4 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT4</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(myRanking.event4)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{myRanking.event4.rank === -1 ? '0' : myRanking.event4.rank}</Text>
                                </EventRankingBox>
                                {
                                  // myRanking.event4.rank !== -1 ?
                                  myRanking.event4.time !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalTime(myRanking.event4)}</Text>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                }
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 4 */}
                          </TotalRecordWrapper>
                        }
                      </RankingClickBoxWrapper>
                    </RankingViewWrapper>
                  </RankingTotalWrapper>
              }
            </TempWrapper>
          }
          <TopLineWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('전체 순위')}</Text>
            </TitleWrapper>
          </TopLineWrapper>
          {
            isLoading ?
              <RankingTotalWrapper>
                <RankingLoadingView>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                </RankingLoadingView>
              </RankingTotalWrapper>
              :
              <RankingTotalWrapper>
                {
                  rankingList.map((data, index) => (
                    <RankingViewWrapper key={index} height={calcTotalComponentHeight(data)} onClick={() => onClickRecordDetailButton(index)}>
                      {
                        // checkValidData(data) ?
                        <RankingNumberBoxWrapper>
                          {
                            (data.rank < 4 && data.rank !== -1) ?
                              <RankingLeaderBoxWrapper>
                                <RankingLeaderBox src={data.rank === 1 ? FirstPlaceImg : data.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                <RankingImageTextWrapper>
                                  <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                                </RankingImageTextWrapper>
                              </RankingLeaderBoxWrapper>
                              :
                              <RankingNumberBox>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank !== -1 ? data.rank : '0'}</Text>
                              </RankingNumberBox>
                          }
                        </RankingNumberBoxWrapper>
                        // :
                        // <RankingNumberBoxWrapper>
                        //   <RankingNumberBox>
                        //     <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>DNF</Text>
                        //   </RankingNumberBox>
                        // </RankingNumberBoxWrapper>
                      }
                      <RankingClickBoxWrapper>
                        <RankingClickBox height={calcComponentHeight(data)}>
                          <InfoBox>
                            <LeftWrapper>
                              <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                              <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                <InnerInfoTextWrapper>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                </InnerInfoTextWrapper>
                                <RecordBox>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{calcTime(data)}</Text>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTimeUnit(data)}</Text>
                                  </RecordTextWrapper>
                                  <RecordTextWrapper>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#828282'>{(data.tieBreak === 0 || data.tieBreak === undefined || data.completeRep === 0) ? `` : `TieBreak`}</Text>
                                    <Text fontFamily='NotoSansKR-Regular' fontSize={9} color='#4F4F4F'>{calcTieBreak(data)}</Text>
                                  </RecordTextWrapper>
                                </RecordBox>
                              </InfoTextWrapper>
                            </LeftWrapper>
                            {/* <DetailButton isClicked={data.isClicked} src={RightImg} /> */}
                          </InfoBox>
                          <MemberRowView>
                            {
                              data.members.map((innerData, index) => (
                                <MemberView key={index}>
                                  <MemberProfile src={innerData.profile === '' ? NoneProfile : innerData.profile} />
                                  <ColorBoxWrapper>
                                    <ColorBox backgroundColor={innerData.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                      <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                    </ColorBox>
                                    <ColorBoxInTextWrapper>
                                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{innerData.name}</Text>
                                    </ColorBoxInTextWrapper>
                                  </ColorBoxWrapper>
                                </MemberView>
                              ))
                            }
                          </MemberRowView>
                        </RankingClickBox>
                        {
                          nowSection === 'Total' &&
                          <TotalRecordWrapper isClicked={data.isClicked && nowSection === 'Total'}>
                            {/* Event 1 */}
                            <EventRowWrapper>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT1</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 13px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event1)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event1.rank === -1 ? '0' : data.event1.rank}</Text>
                                </EventRankingBox>
                                {
                                  // data.event1.rank !== -1 ?
                                  data.event1.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.event1.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(data.event1)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                }
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 1 */}
                            {/* Event 2 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT2</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event2)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event2.rank === -1 ? '0' : data.event2.rank}</Text>
                                </EventRankingBox>
                                {
                                  // data.event2.rank !== -1 ?
                                  data.event2.completeRep !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.event2.completeRep}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REP</Text>
                                      <ScaleText style={{ marginLeft: '8px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>Tie Break</ScaleText>
                                      <ScaleText style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Regular' fontSize={10} color='#BDBDBD' scale={0.9}>{calcTieBreak(data.event2)}</ScaleText>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                }
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 2 */}
                            {/* Event 3 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT3</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 0px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event3)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event3.rank === -1 ? '0' : data.event3.rank}</Text>
                                </EventRankingBox>
                                {
                                  // data.event3.rank !== -1 ?
                                  data.event3.firstExercise !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.event3.firstExercise}</Text>
                                      <Text style={{ marginLeft: '4px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                }
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 3 */}
                            {/* Event 4 */}
                            <EventRowWrapper style={{ marginTop: '3px' }}>
                              <EventBox>
                                <ScaleText fontFamily='Poppins-Bold' fontSize={10} color='#FFFFFF' scale={0.8}>EVENT4</ScaleText>
                              </EventBox>
                              <EventInfoBox style={{ borderRadius: `13px 0px 13px 13px` }} >
                                <EventRankingBox backgroundColor={selectTotalEventRankingBoxBackgroundColor(data.event4)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{data.event4.rank === -1 ? '0' : data.event4.rank}</Text>
                                </EventRankingBox>
                                {
                                  // data.event4.rank !== -1 ?
                                  data.event4.time !== -1 ?
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{calcTotalTime(data.event4)}</Text>
                                    </EventInfoTextWrapper>
                                    :
                                    <EventInfoTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>None</Text>
                                    </EventInfoTextWrapper>
                                }
                              </EventInfoBox>
                            </EventRowWrapper>
                            {/* Event 4 */}
                          </TotalRecordWrapper>
                        }
                      </RankingClickBoxWrapper>
                    </RankingViewWrapper>
                  ))
                }
              </RankingTotalWrapper>
          }
        </TotalWrapper>
        // :
        // <TotalWrapper>
        //   <WaitWrapper>
        //     <WaitImage src={WaitIcon} />
        //     <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>온라인 저징 이후</Text>
        //     <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>재업로드 예정입니다</Text>
        //   </WaitWrapper>
        // </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 80px;

  width: 100%;
  height: 100%;

  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const GenderSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const GenderSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  width: 50%;
  height: 32px;

  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.isClicked ? '#F9F9F9' : '#E0E0E0'};

  transition: all 0.3s ease-in-out;
`;

const GenderImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const GenderImage = styled.img`
  width: 11px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin: 4px 0px 80px 0px;
  marigin-top: 4px;

  width: 90.33%;
`;

const RankingLoadingView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  // height: 64px;
  // height: ${props => props.isClicked ? '219px' : '64px'};
  height: ${props => props.height}px;

  // overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 87.16%;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 16px 0px 12px;

  // width: calc(87.16% - 28px);
  width: calc(100% - 28px);
  // height: 62px;
  // height: ${props => props.isClicked ? '219px' : '62px'};
  height: ${props => props.height}px;

  overflow: hidden;
  
  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const TotalRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isClicked ? 10 : 0}px;

  width: 100%;
  height: ${props => props.isClicked ? 170 : 0}px;

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const EventRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const EventBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 7px;
  background-color: #E0E0E0;
`;

const EventInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 85.27%;
  height: 40px;

  background-color: #FFFFFF;
`;

const EventRankingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: ${props => props.backgroundColor};
`;

const EventInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-end;

  margin-left: 8px;

  height: 20px;
`;

const TotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const MemberRowView = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const MemberView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 28px;
`;

const MemberProfile = styled.img`
  width: 40px;
  height: 40px;

  margin-right: 8px;

  border-radius: 6px;

  object-fit: contain;
`;

const ColorBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 41px;
  height: 21px;

  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
`;

const ColorBoxInTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 41px;
  height: 20px;
  
  overflow: hidden;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  // width: 95.7%;
  width: 100%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const InnerInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const RecordBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RecordTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const RxBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 23px;
  height: 13px;

  border-radius: 3px;
  background-color: #FF3131;
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 33px;
  height: 13px;

  border-radius: 3px;
  background-color: #4161AF;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;

  transform: ${props => props.isClicked ? `rotateZ(90deg)` : `rotateZ(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
  
  -webkit-transform: scale(${props => props.scale});
  transition: all 0.3s ease-in-out;
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;