import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import VideoIcon from '../../../Image/VideoIcon.png';
import { useTranslation } from "react-i18next";
import FetchModule from "../../Network/FetchModule";
import SweatChallengeSubmitModel from "./SweatChallengeSubmitModel";
import SweatChallengeSubmitViewModel from "./SweatChallengeSubmitViewModel";
import SubmittingRecordsModal from "./SubmittingRecordsModal";
// import FETOnlineMarchRX from "./FETOnlineMarchRX";
// import FETOnlineMarchScale from "./FETOnlineMarchScale";

export default function SweatChallengeEvent1(props) {
  const { language, setIsCompleteSubmit, totalVideoWidth, totalVideoHeight, onClickVideo } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isRx, setIsRx] = useState(true);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [tieBreakMin, setTieBreakMin] = useState(0);
  const [tieBreakSec, setTieBreakSec] = useState(0);

  const [completeRep, setCompleteRep] = useState(0);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);

  const [isExistRecord, setIsExistRecord] = useState(false);
  const [viewIsRx, setViewIsRx] = useState(false);

  const [viewMin, setViewMin] = useState(0);
  const [viewSec, setViewSec] = useState(0);

  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);
 
  const [viewCompleteRep, setViewCompleteRep] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    let requestData = {
      eventId: 12,
      section: 'A'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently', 'POST', requestData);
        
        if (responseData.status === 200) {
          setIsExistRecord(true);
          setViewIsRx(responseData.data.isRx);

          let time = parseInt(responseData.data.time);
          let decimalPoint = (responseData.data.time % 1).toFixed(2);

          setViewMin(Math.floor(time / 60));
          setMin(Math.floor(time / 60));

          if (decimalPoint === '0.00') {
            setViewSec(time % 60);
            setSec(time % 60);
          }
          else {
            setViewSec((time % 60) + parseFloat(decimalPoint));
            setSec((time % 60) + parseFloat(decimalPoint));
          }

          setPicture(responseData.data.picture);
          setIsRx(responseData.data.isRx);

        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 순위표 버튼 클릭시 //
  function onClickRankingButton() {
    navigate('/rank/12');
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {
    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {
      if ((link !== ''
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let finalMin = min === '' ? 0 : parseInt(min * 60);
      let finalSec = sec === '' ? 0 : parseFloat(sec);

      if (finalMin + finalSec === 0) {
        alert('기록된 시간을 입력해주세요');
        return;
      }

      setIsSubmitting(true);

      let requestData = {
        eventId: 12,
        section: 'A',
        time: finalMin + finalSec,
        tieBreak: 0,
        isRx: isRx,
        distance: 0,
        completeRep: 0,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: 0,
        firstExercise: 0,
        secondExercise: 0,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: link,
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            // setIsSavingRecord(false);
            setIsCompleteSubmit(true);
          }
          else alert('기록 제출에 실패했습니다.')

          setIsSubmitting(false);
          setIsClickedSubmit(false);
        }
      )();
    }
    else {
      let requestData = {
        eventId: 12,
        section: 'A'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      { isSubmitting && <SubmittingRecordsModal/> }
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment('2023-06-02T00:00:00').format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>FET SWEAT CHALLENGE</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2023.06.02 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 1</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>MAX SANDBAG HOLD</Text>
              </TitleBox>
            </TitleWrapper>
            {
              // (!isExistRecord && !isClickedSubmit) &&
              <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
                <iframe
                  width='100%'
                  height='100%'
                  src={`https://www.youtube.com/embed/9CD4QNK-4OQ`}
                  style={{ borderRadius: '12px' }}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube" />
              </TotalVideoBox>
            }
            <ScoreGuideLineImageWrapper size={totalVideoWidth}>
              <ScoreGuideLineImage src='https://picture.link-zone.org/event/FETSweat/SweatChallengeEvent1.png' />
            </ScoreGuideLineImageWrapper>
            <ExerciseWrapper>
              <RuleBookWrapper>
                <RuleRowTextWrapper>
                  <Point />
                  <TextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('샌드백 잡고 가장 오랜 시간 동안 버티기')}</Text>
                  </TextWrapper>
                </RuleRowTextWrapper>
              </RuleBookWrapper>
            </ExerciseWrapper>
            {
              (isExistRecord || isClickedSubmit) &&
                <ExerciseWrapper>
                  {
                    isClickedSubmit ?
                      <SweatChallengeSubmitModel
                        eventId={12}
                        section='A'

                        isRx={isRx}
                        setIsRx={setIsRx}
                        isSuccess={isSuccess}
                        setIsSuccess={setIsSuccess}
                        min={min}
                        setMin={setMin}
                        sec={sec}
                        setSec={setSec}

                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                        setIsExistRecord={setIsExistRecord} 
                      />
                      :
                      <SweatChallengeSubmitViewModel
                        eventId={12}
                        section='A'

                        isRx={viewIsRx}

                        min={viewMin}
                        sec={viewSec}
                        picture={picture}
                        setIsExistRecord={setIsExistRecord} />
                  }
                </ExerciseWrapper>
            }
            {
              moment().format('YYYY.MM.DD HH:mm:ss') > moment('2022-06-04T18:00:00').format('YYYY.MM.DD HH:mm:ss') ?
                <RankingButton onClick={onClickRankingButton}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
                </RankingButton>
                :
                <RankingButton onClick={onClickRecordAndSubmit}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{isExistRecord ? t('수정하기') : isClickedSubmit ? t('제출하기') : t('기록하기')}</Text>
                </RankingButton>
            }
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  // width: 32px;
  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  // width: calc(89.3% - 69px);
  width: calc(90.33% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const WodRoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 0px;

  height: 18px;

  border-radius: 2px;
  background-color: #6DD49E;
`;

const EllipsisWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 28px 0px 28px 0px;

  width: 100%;

  gap: 10px;
`;

const Ellipsis = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;

  border-radius: 10px;

  background-color: #D9D9D9;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  // width: ${props => props.size}px;
  // height: ${props => props.size}px;
  width: 90.33%;
  height: auto;

  border-radius: 10px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 49px;

  margin-top: 25px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;

  border-radius: 10px;
  background-color: #FF3131;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const OvertextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
