import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export default function LinkCoachDeadLiftVideoViewModal(props) {
  const { videoId, close } = props;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [totalVideoWidth, setTotalVideoWidth] = useState(374);
  const [totalVideoHeight, setTotalVideoHeight] = useState(374);

  const { t } = useTranslation();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    let totalVideoWidth = (windowSize.width * 0.9033) * 0.9033;
    let totalVideoHeight = totalVideoWidth * 0.5614;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])

  return (
    <Background>
      <Modal>
        <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
          {
            videoId === '' ?
              <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#FFFFFF'>영상 미제출</Text>
              :
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/` + videoId}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
          }
        </TotalVideoBox>
        <ButtonWrapper>
          <Button onClick={close}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>닫기</Text>
          </Button>
        </ButtonWrapper>
      </Modal>
    </Background>
  )
} // ??

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 26px 0px 26px 0px;
	width: 90.33%;
	// height: 202px;
  
	border-radius: 12px;
  background-color: #FFFFFF;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  // margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  background-color: #DFDFDF;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
`;

const Button = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60px;

  border-radius: 8px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;