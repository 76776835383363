import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import { useTranslation } from "react-i18next";
import LinkCoachDeadLiftSubmitViewModel from "./LinkCoachDeadLiftSubmitViewModal";
import LinkCoachDeadLiftSubmitModel from "./LinkCoachDeadLiftSubmitModal";
import FetchModule from "../../../Model/Network/FetchModule";

export default function LinkCoachDeadLiftEvent(props) {
  const { setIsCompleteSubmit, totalVideoWidth, totalVideoHeight } = props;

  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [totalWeight, setTotalWeight] = useState(0);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isExistRecord, setIsExistRecord] = useState(false);
  const [isRx, setIsRx] = useState(true);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [tieBreakMin, setTieBreakMin] = useState(0);
  const [tieBreakSec, setTieBreakSec] = useState(0);

  const [completeRep, setCompleteRep] = useState(0);
  const [firstExercise, setFirstExercise] = useState(0);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);

  const [viewIsRx, setViewIsRx] = useState(false);

  const [viewMin, setViewMin] = useState(0);
  const [viewSec, setViewSec] = useState(0);

  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);
 
  const [viewCompleteRep, setViewCompleteRep] = useState(0);
  const [viewFirstExercise, setViewFirstExercise] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');


  // 가장 최근기록 가져오기 //
  useEffect(() => {
    let requestData = {
      eventId: 39,
      section: 'A'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          // setIsExistRecord(true);
          // setViewIsRx(responseData.data.isRx);

          // let time = parseInt(responseData.data.time);
          // let decimalPoint = (responseData.data.time % 1).toFixed(2);

          // setViewMin(Math.floor(time / 60));
          // setMin(Math.floor(time / 60));

          // if (decimalPoint === '0.00') {
          //   setViewSec(time % 60);
          //   setSec(time % 60);
          // }
          // else {
          //   setViewSec((time % 60) + parseFloat(decimalPoint));
          //   setSec((time % 60) + parseFloat(decimalPoint));
          // }

          setIsExistRecord(true);
          setFirstExercise(responseData.data.firstExercise);
          setPicture(responseData.data.picture);
          setIsRx(responseData.data.isRx);
          setLink(responseData.data.link);

        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 순위 정리 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {

        const responseData = await fetchModule.getDataOfEvent('score/rank/linkcoach_deadlift', 'GET');

        if (responseData.status === 200) {
          let dates = responseData.data.eventRankList.slice();
          let totalWeight = 0;

          for (let i = 0; i < dates.length; i++) {
            totalWeight += dates[i].firstExercise;
          }
          
          setTotalWeight(totalWeight);
        }

      }
    )();
  }, [])

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'GirlFitTeamRuleBook1.pdf';

      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/GirlFit', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {
    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {
      if ((link !== ''
        && !link.includes('https://youtube.com/shorts/')
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let finalCompleteRep = completeRep === '' ? 0 : completeRep;
      let finalFirstExercise = firstExercise === '' ? 0 : firstExercise;
      // let finalMin = min === '' ? 0 : parseInt(min * 60);
      // let finalSec = sec === '' ? 0 : parseFloat(sec);

      // if (finalMin + finalSec === 0) {
      //   alert('기록된 시간을 입력해주세요');
      //   return;
      // }

      setIsSubmitting(true);

      let requestData = {
        eventId: 39,
        section: 'A',
        time: 0,
        tieBreak: 0,
        isRx: isRx,
        distance: 0,
        completeRep: 0,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: 0,
        firstExercise: finalFirstExercise,
        secondExercise: 0,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: link,
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            // setIsSavingRecord(false);
            setIsCompleteSubmit(true);
          }
          else alert('기록 제출에 실패했습니다.')

          setIsSubmitting(false);
          setIsClickedSubmit(false);
        }
      )();
    }
    else {
      let requestData = {
        eventId: 39,
        section: 'A'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment('2022-09-16T00:00:00').format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Link Coach Deadlift</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2024.04.08 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <DonationBox>
              {/* <DonationTextWrapper style={{ marginLeft: '10px', gap: '4px' }}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>누적</Text>
                <Text style={{ marginTop: '2px' }} fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>{totalWeight.toLocaleString() + 'lbs'}</Text>
              </DonationTextWrapper>
              <DonationTextWrapper style={{ marginRight: '10px' }}>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>{(Math.floor(totalWeight / 10) * 200).toLocaleString()}</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF'>원 기부 진행중</Text>
              </DonationTextWrapper> */}
              <DonationTextWrapper style={{ marginLeft: '10px', gap: '4px' }}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>누적</Text>
                <Text style={{ marginTop: '2px' }} fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>11,095.83 lbs</Text>
              </DonationTextWrapper>
              <DonationTextWrapper style={{ marginRight: '10px' }}>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>221,800</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF'>원 기부 확정</Text>
              </DonationTextWrapper>
            </DonationBox>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>Deadlift</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>데드리프트 1RM 10lbs 당 200원 기부!</Text>
              </TitleBox>
            </TitleWrapper>
            {/* <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/dKU9-9-Ch0Y`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox> */}

        <ImageWrapper>
          <Image src='https://picture.link-zone.org/event/linkcoach_deadlift/LDMain.png' width={totalVideoWidth} height={totalVideoWidth} />
        </ImageWrapper>
            <WodWrapper>
              {/* <OrangeBoxWrapper>
                <OrangeBox>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>Athlete 1,2,3,4</Text>
                </OrangeBox>
              </OrangeBoxWrapper> */}
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Deadlift</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              {/* <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Wall ball shot</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>100</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Synchro dumbbell snatch</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>35</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>100</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper> */}
            </WodWrapper>
            {/* <ExerciseWrapper style={{ marginTop: '40px' }}>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                </InfoTitleWrapper>
              </InfoWrapper>
              <ScoreGuideLineImageWrapper onClick={downloadPDF}>
                <ScoreGuideLineImage src='https://picture.link-zone.org/event/GirlFit/GirlFitRuleBookIcon.png' />
              </ScoreGuideLineImageWrapper>
              <RuleBookWrapper>
                <RuleColumnTextWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                </RuleColumnTextWrapper>
                <RuleRowTextWrapper style={{ marginTop: '24px' }}>
                <Point />
                <TextWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>tie break time 은 WBS과 DB스내치 두 동작에</Text>
                  <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>모두 끝난시간입니다</Text>
                </TextWrapper>
              </RuleRowTextWrapper>
              <RuleRowTextWrapper style={{ marginTop: '10px' }}>
                <Point />
                <TextWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('Barbell 무게 변환 기준')}</Text>
                  <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'95lb ↔ 43.5kg or 43.5kg or more'}</Text>
                  <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'65lb ↔ 30kg'}</Text>
                  <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'35lb ↔ 15kg'}</Text>
                </TextWrapper>
              </RuleRowTextWrapper>
              </RuleBookWrapper>
            </ExerciseWrapper> */}
            {
              (isExistRecord || isClickedSubmit) &&
                <ExerciseWrapper style={{ marginTop: '24px' }}>
                  {
                    isClickedSubmit ?
                      <LinkCoachDeadLiftSubmitModel
                        eventId={39}
                        section='A'

                        isRx={isRx}
                        setIsRx={setIsRx}
                        isSuccess={isSuccess}
                        setIsSuccess={setIsSuccess}

                        firstExercise={firstExercise}
                        setFirstExercise={setFirstExercise}

                        link={link}
                        setLink={setLink}

                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                        setIsExistRecord={setIsExistRecord} 
                      />
                      :
                      <LinkCoachDeadLiftSubmitViewModel
                        eventId={39}
                        section='A'

                        isRx={true}

                        link={link}

                        firstExercise={firstExercise}
                        picture={picture}
                        setIsExistRecord={setIsExistRecord} />
                  }
                </ExerciseWrapper>
            }
            {
              moment().format('YYYY.MM.DD HH:mm:ss') < moment('2024-05-19T19:00:00').format('YYYY.MM.DD HH:mm:ss') &&
                // <RankingButton /*onClick={onClickRankingButton}*/>
                //   <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
                // </RankingButton>
                // :
                <RankingButton onClick={onClickRecordAndSubmit}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{isExistRecord ? t('수정하기') : isClickedSubmit ? t('제출하기') : t('기록하기')}</Text>
                </RankingButton>
            }
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const DonationBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-bottom: 12px;

  width: 90.33%;
  height: 36px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const DonationTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const OrangeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  height: 18px;

  border-radius: 2px;
  background-color: #FF8B48;
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  // width: calc(89.3% - 69px);
  width: calc(90.33% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const MissionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 1px 9px 1px 9px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 130px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  
  margin-top: 30px;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  background-color: #FF3131;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;