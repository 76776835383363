import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

export default function VideoViewModal(props) {
  const { videoId, videoWidth, videoHeight, closeFn } = props;

  const [pointY, setPointY] = useState(undefined);

  const TopLevelRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      TopLevelRef.current.style.marginTop = `0px`;
    }, 100)
  }, [TopLevelRef])

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%;`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // useEffect(() => {
  //   console.log(data);
  // }, [data])

  // PC일경우 마우스 클릭 감지 //
  function onMouseDown(e) {
    if (pointY !== undefined) return;

    setPointY(e.screenY);
    TopLevelRef.current.style.transition = 'none';
  }

  // PC일경우 마우스 움직임 감지 //
  function onMouseMove(e) {
    if (pointY === undefined) return;

    if (e.screenY - pointY > 0) TopLevelRef.current.style.marginTop = (e.screenY - pointY) + 'px';
  }

  // PC일경우 마우스 클릭 마무리 감지 //
  function onMouseUp(e) {
    TopLevelRef.current.style.transition = 'all 0.5s ease-in-out';

    if (e.screenY - pointY > 130) onClickExitButton();
    else {
      TopLevelRef.current.style.marginTop = `0px`;
      setPointY(undefined);
    }
  }

  // 모바일일 경우 터치 감지 //
  function onTouchStart(e) {
    if (pointY !== undefined) return;

    setPointY(e.changedTouches[0].screenY);
    TopLevelRef.current.style.transition = 'none';
  }

  // 모바일일 경우 터치 움직임 감지 //
  function onTouchMove(e) {
    if (pointY === undefined) return;

    if (e.changedTouches[0].screenY - pointY > 0) TopLevelRef.current.style.marginTop = (e.changedTouches[0].screenY - pointY) + 'px';
  }

  // 모바일일 경우 터치 움직임 마무리 감지 //
  function onTouchEnd(e) {
    TopLevelRef.current.style.transition = 'all 0.5s ease-in-out';

    if (e.changedTouches[0].screenY - pointY > 130) {
      onClickExitButton();
    }
    else {
      TopLevelRef.current.style.marginTop = `0px`;
      setPointY(undefined);
    }
  }

  //  닫기 버튼 클릭시 //
  function onClickExitButton() {
    TopLevelRef.current.style.marginTop = `790px`;

    setTimeout(() => {
      closeFn();
    }, 300);
  }

  return (
    <Background>
      <TopLevelWrapper
        ref={TopLevelRef}>
        <TitleLineWrapper
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}>
          <TitleWrapper>
            <HoverText fontFamily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF'>닫기</HoverText>
            <TitleMaximumWrapper>
              <HoverText fontFamily='NotoSansKR-Bold' fontSize={17} color='#4F4F4F'>동작 영상</HoverText>
            </TitleMaximumWrapper>
            <HoverText onClick={onClickExitButton} fontFamily='NotoSansKR-Medium' fontSize={16} color='#6DD49E'>닫기</HoverText>
          </TitleWrapper>
        </TitleLineWrapper>
        <VideoView width={videoWidth} height={videoHeight}>
          <iframe
            width='100%'
            height='100%'
            src={`https://www.youtube.com/embed/` + videoId}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube" />
        </VideoView>
      </TopLevelWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 790px;

	width: 100%;
	height: 88.16%;
  
	border-radius: 28px 28px 0px 0px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.5s ease-in-out;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 56px;

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const TitleMaximumWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 83.68%;
  height: 25px;

  overflow: hidden;
`;

const VideoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  background-color: #C4C4C4;
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;