import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import styled from "styled-components";
import Lottie from 'lottie-react-web';


// import LoadingAnimation from '../../Animation/LoadingAnimation.json';
import LoadingAnimation from '../Animation/LoadingAnimation.json';
import FetchModule from "../Model/Network/FetchModule";

export default function AdminPaymentPage() {

  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);

  // 결제창 실행 //
  useEffect(() => {
    // window.localStorage.setItem('Token', 'Bearer ' + params.token);

    // const fetchModule = new FetchModule();
    // (
    //   async() => {
    //     const responseData = await fetchModule.getDataOfEventVer2('sales/payment_data/' + params.merchantUid, 'GET');
        
    //     if (responseData.status === 200) {
    //       let merchantUid = responseData.data.merchantUid;
    //       let userName = responseData.data.userName;
    //       let phone = responseData.data.phone;
    //       let price = responseData.data.price;

    //       const obj = {};

    //       obj.PCD_PAY_TYPE = 'card'
    //       obj.PCD_PAY_WORK = 'CERT';
    //       obj.PCD_CARD_VER = '02';
    //       obj.PCD_PAYER_AUTHTYPE = 'sms';

    //       obj.PCD_PAY_GOODS = params.itemName;
          
    //       obj.PCD_PAY_TOTAL = price;
    //       // obj.PCD_PAY_TOTAL = 150;
    //       obj.PCD_PAY_OID = merchantUid;
    //       obj.PCD_PAYER_NAME = userName;
    //       obj.PCD_PAYER_HP = phone;

    //       // obj.callbackFunction = getResult;
    //       // obj.PCD_RST_URL = 'http://172.30.1.53:8080/api2/sales/payment/' + params.merchantUid;
    //       obj.PCD_RST_URL = 'https://tournament.link-coach.io/api2/sales/payment/' + params.merchantUid;

    //       // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
    //       obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';

    //       setTimeout(() => {
    //         window.PaypleCpayAuthCheck(obj);
    //       }, 1500)
    //     }
    //     else if (responseData.status === 201) {
    //       navigate('/payment/complete/201/' + params.error + '/' + params.merchantUid);
    //     }
    //     else if (responseData.status === 202) {
    //       // navigate('/payment/complete/202/팀장만 결제를 진행할 수 있습니다/' + params.merchantUid);
    //       navigate('/payment/complete/202/신청 인원이 마감되었습니다/' + params.merchantUid);
    //     }
    //     else if (responseData.status === 203) {
    //       navigate('/payment/complete/203/이미 진행된 결제내역입니다/' + params.merchantUid);
    //     }
    //     else if (responseData.status === 401) {
    //       navigate('/payment/complete/401/결제 데이터가 존재하지 않습니다/' + params.merchantUid);
    //     }
    //     else if (responseData.status === 402) {
    //       navigate('/payment/complete/402/결제자 정보가 일치하지 않습니다/' + params.merchantUid);
    //     }
    //     else {
    //       navigate('/payment/complete/400/결제모듈 로딩에 실패했습니다/' + params.merchantUid);
    //     }
    //   }
    // )();

    setTimeout(() => {
      setIsLoading(false);
    }, 3000)
  }, []);


  // 모달 타이틀 텍스트 결정 //
  function decideModalTitle() {
    // if (status === '200') return '결제가 완료되었습니다';
    // else return '결제에 실패하였습니다';
    return '결제가 완료되었습니다';
  }

  // 모달 텍스트 결정 //
  function decideModalText() {
    // if (status === '200') return '앱에서 계속 진행해주세요';
    // else if (status === '201') return error;
    // else if (status === '202') return '참가 신청인원이 초과되었습니다';
    // else if (status === '401') return '유효하지 않은 인증입니다';
    // else if (status === '402') return '결제자 정보가 일치하지 않습니다';
    // else return error;
    return '앱에서 계속 진행해주세요';
  }

  // 앱으로 돌아가기 클릭시 //
  function onClickGoApp() {
    // console.log('Go App!!');
    window.location.href = "linkcoach://";

    setTimeout(() => {
      window.open("about:blank", "_self");
      window.close();
    }, 3000)
  }

  return (
    <TopLevelWrapper>
      {
        isLoading ?
          <LottieWrapper>
            <LottieBox>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>관리자 결제 진행중...</Text>
          </LottieWrapper>
          :
          <CompleteModal>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#333333'>{decideModalTitle()}</Text>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{decideModalText()}</Text>
            </TextWrapper>
            <GoAppButton onClick={onClickGoApp}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>앱으로 돌아가기</Text>
            </GoAppButton>
          </CompleteModal>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  // background-color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.2);
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const LottieBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;

const CompleteModal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px 12px 0px;

  width: 80%;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 90.33%;
`;

const GoAppButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 90.33%;
  height: 40px;

  border-radius: 8px;
  background-color: #6DD49E;
`;


const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};

  color: ${props => props.color};

  margin: 0 0 0 0;
`;