import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import { useTranslation } from "react-i18next";
import FetchModule from "../../../Model/Network/FetchModule";
import FitForceTeamLeagueSubmitModel from "./FitForceTeamLeagueSubmitModel";
import FitForceTeamLeagueSubmitViewModel from "./FitForceTeamLeagueSubmitViewModel";

export default function FitForceTeamLeagueEvent2(props) {
  const { eventId, totalVideoWidth, totalVideoHeight, setIsCompleteSubmit } = props;

  const { t } = useTranslation();

  // const eventId = 35;

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [completeRep, setCompleteRep] = useState(0);
  const [lastExerciseRep, setLastExerciseRep] = useState(0);

  const [tieBreakMin, setTieBreakMin] = useState(0);
  const [tieBreakSec, setTieBreakSec] = useState(0);
  // const [lastExercise, setLastExercise] = useState('');
  // const [lastExerciseRep, setLastExerciseRep] = useState(0);
  
  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });

  const [isRx, setIsRx] = useState(true);
  const [isExistRecord, setIsExistRecord] = useState(false);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [viewIsRx, setViewIsRx] = useState(false);
  // const [viewMin, setViewMin] = useState(0);
  // const [viewSec, setViewSec] = useState(0);
  const [viewCompleteRep, setViewCompleteRep] = useState(0);
  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);
  // const [viewLastExercise, setViewLastExercise] = useState(0);
  // const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    let requestData = {
      eventId: eventId,
      section: 'B'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {

          let tieBreakTime = parseInt(responseData.data.tieBreak);
          let tieBreakDecimalPoint = (responseData.data.tieBreak % 1).toFixed(2);

          setViewTieBreakMin(Math.floor(tieBreakTime / 60));
          setTieBreakMin(Math.floor(tieBreakTime / 60));

          if (tieBreakDecimalPoint === '0.00') {
            setViewTieBreakSec((tieBreakTime % 60));
            setTieBreakSec((tieBreakTime % 60));
          }
          else {
            setViewTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
            setTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
          }

          setIsExistRecord(true);
          setViewIsRx(responseData.data.isRx);
          setCompleteRep(responseData.data.completeRep);
          setViewCompleteRep(responseData.data.completeRep);
          // setLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewCompleteRound(responseData.data.completeRound);
          // setViewLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewLastExercise(responseData.data.lastExercise);
          setViewLink(responseData.data.link);
          setPicture(responseData.data.picture);
        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'FitForceTeamLeagueEvent2RuleBook.pdf';

      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/FitForceTeamLeague', fileName: fileName }));
      // window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://link-picture.s3.ap-northeast-2.amazonaws.com/event/believer/', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {

    // if (moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-11-05T00:00:00').format('YYYY.MM.DD HH:mm:ss')) {
    //   alert('제출 기한이 마감되었습니다.');
    //   return;
    // }

    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {

      let finalTieBreakMin = tieBreakMin === '' ? 0 : parseInt(tieBreakMin * 60);
      let finalTieBreakSec = tieBreakSec === '' ? 0 : parseFloat(tieBreakSec);
      let finalCompleteRep = completeRep === '' ? 0 : completeRep;

      if (finalCompleteRep === 0) {
        alert('기록을 입력해주세요');
        return;
      }
      else if ((link !== ''
        && !link.includes('https://youtube.com/shorts/')
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }
      else if (finalTieBreakMin + finalTieBreakSec === 0) {
        alert('TieBreak & Time Cap을 입력해주세요!');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let requestData = {
        eventId: eventId,
        section: 'B',
        isRx: true,
        time: 0,
        tieBreak: finalTieBreakMin + finalTieBreakSec,
        completeRep: finalCompleteRep,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: 0,
        firstExercise: 0,
        secondExercise: 0,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: link,
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);

          if (responseData.status === 200) {
            setIsCompleteSubmit(true);
          }
        }
      )();

      setIsClickedSubmit(false);
    }
    else {
      let requestData = {
        eventId: eventId,
        section: 'B'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().isBefore(moment('2024-05-05T00:00:00')) ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Fit Force Team League</Text>
            <Text style={{ marginTop: '2px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2024.05.05 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 2</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>10min Amrap</Text>
              </TitleBox>
            </TitleWrapper>
            <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/Urlk-dSNJeE`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox>
            <WodWrapper>
              <OrangeBoxWrapper>
                {/* <OrangeBox> */}
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FF8B48'>Athletes Rotate after each movement complete.</Text>
                {/* </OrangeBox> */}
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Toes to bar</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('거리')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>10</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
{/* 
              <OrangeBoxWrapper style={{ marginTop: '8px' }}>
                <ColorBoxWrapper>
                  <ColorBox backgroundColor='#FF8B48'>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>PLAYER 1</Text>
                  </ColorBox>
                  <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>: Perform the Rowing</Text>
                </ColorBoxWrapper>
                <ColorBoxWrapper>
                  <ColorBox backgroundColor='#00A3FF'>
                    <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>PLAYER 2</Text>
                  </ColorBox>
                  <Text fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>: Deadlift Hold</Text>
                </ColorBoxWrapper>
              </OrangeBoxWrapper>

              <AndThenBox style={{ marginTop: '16px' }}>
                <Text fontFamily='Poppins-SemiBold' fontSize={12} color='#FFFFFF'>AND THEN</Text>
              </AndThenBox>
              
              <OrangeBoxWrapper style={{ marginTop: '8px' }}>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FF8B48'>Max reps Ground to overhead</Text>
              </OrangeBoxWrapper> */}

              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Wallball shot</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>10</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '8px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={54} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>F:14 M:20</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lbs</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
            </WodWrapper>
            {
              (isExistRecord || isClickedSubmit) ?
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  {
                    isClickedSubmit ?
                      <FitForceTeamLeagueSubmitModel
                        eventId={eventId}
                        section='B'

                        isSuccess={isSuccess}
                        setIsSuccess={setIsSuccess}

                        min={min}
                        setMin={setMin}
                        sec={sec}
                        setSec={setSec}
                        completeRep={completeRep}
                        setCompleteRep={setCompleteRep}

                        // lastExerciseRep={lastExerciseRep}
                        // setLastExerciseRep={setLastExerciseRep}
                        tieBreakMin={tieBreakMin}
                        setTieBreakMin={setTieBreakMin}
                        tieBreakSec={tieBreakSec}
                        setTieBreakSec={setTieBreakSec}

                        link={link}
                        setLink={setLink}
                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                      />
                      :
                      <FitForceTeamLeagueSubmitViewModel
                        eventId={eventId}
                        section='B'

                        setIsExistRecord={setIsExistRecord}

                        // min={viewMin}
                        // sec={viewSec}
                        completeRep={viewCompleteRep}
                        // lastExerciseRep={viewLastExerciseRep}
                        tieBreakMin={viewTieBreakMin}
                        tieBreakSec={viewTieBreakSec}

                        link={viewLink}
                        picture={picture}
                      />
                  }
                </ExerciseWrapper>
                :
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  <InfoWrapper>
                    <InfoTitleWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                    </InfoTitleWrapper>
                  </InfoWrapper>
                  <ScoreGuideLineImageWrapper onClick={downloadPDF}>
                    <ScoreGuideLineImage src='https://picture.link-zone.org/event/FitForceTeamLeague/FitForceTeamLeagueEvent2RuleBooke.png' />
                  </ScoreGuideLineImageWrapper>
                  <RuleBookWrapper>
                    <RuleColumnTextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                    </RuleColumnTextWrapper>
                    {/* <RuleRowTextWrapper style={{ marginTop: '24px' }}>
                      <Point color='#FFB800'/>
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>측정 시 인증 요소</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper style={{ marginTop: '4px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 선수 소개 (영상에 얼굴이 보이게끔 3명의 선수 이름 호명)</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'> - 로잉 화면 보일 수 있게끔 카메라 위치 필수</Text>
                    </TextWrapper>
                    <RuleRowTextWrapper style={{ marginTop: '10px' }}>
                      <Point color='#FFB800'/>
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Row 3000m 설정과 burpee 영상 판독을 위한</Text>
                        <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>가운데 라인 선 영상으로 인증</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- Row 3000m 설정 가이드</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'Select Workout -> New Workout ->'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'Single Distance -> 3000m로 설정 후 위에서부터'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>5 번째 체크 버튼 Push.</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '32px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>이벤트 수행</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>3명의 선수는 출발선에서 타이머와 함께 시작하며 </Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>3000m 로잉을 우선 수행 합니다.</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px', marginLeft: '4px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>1. 3000m Row 수행</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>· 교대 자유롭게 가능합니다 (교대 시 태그 필수)</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>· 3000m Row 완료 후 3명의 선수 모두 머리부터</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>· 발 끝까지 출발선으로 다시 들어와야 합니다 (그 시간이 기록)</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px', marginLeft: '4px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>2. Synchronized line facing burpee 수행</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>· 싱크로 라인 패싱 버피는 스텝오버 가능, 가슴만 동시에 터치하면 됩니다. 한 선수가 먼저 버피하고 대기 가능합니다.</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>· 반드시 두 손은 가운데 라인을 넘어가야하며 라인에 손이 걸쳐지면 노랩입니다.</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>· 또한 몸이 1자로 정렬되지 않아도 노랩입니다.</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>(가운데 라인 선 인증)</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>노랩 기준</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 타이머 Up으로만 가능합니다. (Down - 실격)</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 이벤트1 모든 동작 마무리 후 로잉 기록을 필수</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>로잉 인증은 Time cap(시간 제한)시간 기록 외 별도로 측정 마무리 후 진행하셔도 무방 합니다.</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 로잉 인증 가이드 (Tie Break)</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'Memory -> List by Date -> 수행한 기록 선택 후 위에서부터 4 번째 체크 버튼 Push.(기록 리스트 중 최상단에 있는 기록이 가장 최근의 기록 입니다.) -> 데이터 기록중 time, meter 를 필수로 영상 인증하여주면 완료되며 위 기록은 Tie Break 요소로 적용 됩니다. 위 Row 데이터의 인증을 하지 않았을 경우, 동일한 기록의 순위를 정할 때 후순위로 설정 됩니다.'}</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>실격 기준</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 영상은 무조건 원테이크로 촬영되어야 함. (영상이 편집 되거나, 공정한 기록 측정에 영향이 가는 재가공은 실격)</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>순위</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>3000m Row 끝난시간,</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>75 Synchronized line facing burpee 모두 수행한 시간과 Row 데이터 인증 기록을 활용해 순위 집계</Text>
                    </TextWrapper> */}

                  </RuleBookWrapper>
                </ExerciseWrapper>
            }
            {
              moment().isBefore(moment('2024-05-15T23:59:59')) &&
              <RankingButton onClick={onClickRecordAndSubmit}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>제출하기</Text>
              </RankingButton>
            }
            {/* {
            moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-04-30T23:59:59').format('YYYY.MM.DD HH:mm:ss') ?
              <RankingButton>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
              </RankingButton>
              :
              <RankingButton>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'></Text>
              </RankingButton>
          } */}
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 90.33%;

  gap: 4px;
`;

const ColorBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 0px 6px 0px 6px;

  width: 74px;
  height: 18px;

  border-radius: 2px;
  background-color: ${props => props.backgroundColor};
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-between;
  justify-content: flex-start;
  align-items: center;

  padding: 0px 12px 0px 12px;
  // padding: 0px 8px 0px 8px;
  // padding: 0px 3px 0px 3px;
  margin: 0px 0px 0px 0px;

  // width: calc(90.33% - 69px);
  width: calc(100% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const AndThenBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 21px;

  background-color: #C1C1C1;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 130px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  border-radius: 10px;
  background-color: ${props => props.color};
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;