import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../../Model/Network/FetchModule';

import CrownIcon from '../../../Image/CrownIcon.png';
import FirstPlaceImg from '../../../Image/FirstPlace.png';
import SecondPlaceImg from '../../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../../Image/ThirdPlace.png';
import RightImg from '../../../Image/NotAvailableRight.png';
import NoneProfile from '../../../Image/NoneProfile.png';
import WhiteMale from '../../../Image/WhiteMale.png';
import ColorMale from '../../../Image/ColorMale.png';
import WhiteFemale from '../../../Image/WhiteFemale.png';
import ColorFemale from '../../../Image/ColorFemale.png';

import { useTranslation } from 'react-i18next';

export default function JanuaryCustomRankPage() {

  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [nowSection, setNowSection] = useState('A');
  const [isClickedGender, setIsClickedGender] = useState(window.localStorage.getItem('GenderDivision') === null ? 'female' : window.localStorage.getItem('GenderDivision'));

  // const [data, setData] = useState([]);
  const [myRanking, setMyRanking] = useState(undefined);
  const [responseRankingList, setResponseRankingList] = useState([]);
  const [rankingList, setRankingList] = useState([]);

  // Ranking Info API CALL //
  useEffect(() => {
    var requestData = {
      eventId: 5,
      section: 'A'
    }

    const fetchModule = new FetchModule();
    (
      async () => {

        const responseData = await fetchModule.postDataOfEvent('score/rank', 'POST', requestData);
        
        if (responseData.status === 200) {
          var rankData = responseData.data.eventRankList.slice();
          var myRank = responseData.data.myRankData;
          var totalRankData = [];
          var maleValidRankData = [];
          var femaleValidRankData = [];

          var timecapInMaleValidRankData = [];
          var timecapInFemaleValidRankData = [];
          var timecapOverMaleValidRankData = [];
          var timecapOverFemaleValidRankData = [];

          var validRankData = [];
          var invalidRankData = [];

          for (var i = 0; i < rankData.length; i++) {
            if (rankData[i].time !== 0 || rankData[i].distance !== 0) {
              if (rankData[i].gender === 'male') {
                if (rankData[i].distance !== 0) timecapOverMaleValidRankData.push(rankData[i]);
                else timecapInMaleValidRankData.push(rankData[i]);
              }
              else {
                if (rankData[i].distance !== 0) timecapOverFemaleValidRankData.push(rankData[i]);
                else timecapInFemaleValidRankData.push(rankData[i]);
              }
            }
            else invalidRankData.push(rankData[i]);
          }

          timecapInMaleValidRankData.sort(function (a, b) {
            let x = a.time;
            let y = b.time;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          timecapOverMaleValidRankData.sort(function (a, b) {
            let x = a.distance;
            let y = b.distance;

            if (x < y) return 1;
            if (x > y) return -1;
          })

          maleValidRankData = timecapInMaleValidRankData.concat(timecapOverMaleValidRankData);

          timecapInFemaleValidRankData.sort(function (a, b) {
            let x = a.time;
            let y = b.time;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          timecapOverFemaleValidRankData.sort(function (a, b) {
            let x = a.distance;
            let y = b.distance;

            if (x < y) return 1;
            if (x > y) return -1;
          })

          femaleValidRankData = timecapInFemaleValidRankData.concat(timecapOverFemaleValidRankData);

          invalidRankData.sort(function (a, b) {
            let x = a.name;
            let y = b.name;

            if (x > y) return 1;
            if (x < y) return -1;
          })

          if (myRank !== undefined) {
            
            if (myRank.gender === 'male') {
              for (var i = 0; i < maleValidRankData.length; i++) {
                // maleValidRankData[i].rank = (i + 1);
                // if (maleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                
                if (i !== 0 && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
                  maleValidRankData[i].rank = maleValidRankData[i - 1].rank;

                  if (maleValidRankData[i].userId === myRank.userId) myRank.rank = maleValidRankData[i - 1].rank;
                }
                else {
                  maleValidRankData[i].rank = (i + 1);

                  if (maleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                }
              }
  
              setMyRanking(myRank);
            }
            else {
              for (var i = 0; i < femaleValidRankData.length; i++) {
                // femaleValidRankData[i].rank = (i + 1);
    
                // if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);

                if (i !== 0 && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
                  femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;

                  if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = femaleValidRankData[i - 1].rank;
                }
                else {
                  femaleValidRankData[i].rank = (i + 1);

                  if (femaleValidRankData[i].userId === myRank.userId) myRank.rank = (i + 1);
                }
              }
  
              setMyRanking(myRank);
            }

            let savedGender = await window.localStorage.getItem('GenderDivision');
            
            if (savedGender === null) setIsClickedGender(myRank.gender);
          }

          for (i = 0; i < maleValidRankData.length; i++) {
            // maleValidRankData[i].rank = (i + 1);

            if (i !== 0 && maleValidRankData[i].time === maleValidRankData[i - 1].time) {
              maleValidRankData[i].rank = maleValidRankData[i - 1].rank;
            }
            else {
              maleValidRankData[i].rank = (i + 1);
            }
          }

          for (i = 0; i < femaleValidRankData.length; i++) {
            // femaleValidRankData[i].rank = (i + 1);
            if (i !== 0 && femaleValidRankData[i].time === femaleValidRankData[i - 1].time) {
              femaleValidRankData[i].rank = femaleValidRankData[i - 1].rank;
            }
            else {
              femaleValidRankData[i].rank = (i + 1);
            }
          }

          validRankData = maleValidRankData.concat(femaleValidRankData);
          totalRankData = validRankData.concat(invalidRankData);
          
          setResponseRankingList(totalRankData);
        }
      }
    )();
  }, [params])

  // 성별 구분으로 정리하기 //
  useEffect(() => {
    let finalList = [];

    for (let i = 0; i < responseRankingList.length; i++) {
      if (responseRankingList[i].gender === isClickedGender) finalList.push(responseRankingList[i]);
    }
    // console.log(responseRankingList);
    setRankingList(finalList);
  }, [isClickedGender, responseRankingList])  

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('순위표'), isAvailableGoback: false }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // Section 불러오기 //
  useEffect(() => {
    (
      async () => { 
        setNowSection(await window.localStorage.getItem('NowSection'));
      }
    )();
  }, [])

  // Section 저장 //
  useEffect(() => {
    (
      async () => { 
        await window.localStorage.setItem('NowSection', nowSection);
      }
    )();
  }, [nowSection])

  // React Native에서 웹으로 보내는 데이터 handler //
  async function handleMassage(e) {
    var responseData = JSON.parse(e.data);

    if (responseData.goBack) {
      await window.localStorage.removeItem('GenderDivision');
      navigate(-1);
    }
  }

  // 기록 자세히보기 버튼 클릭 //
  function onClickRecordDetailButton(data) {
    // navigate('/rank/detail', {
    //   state: {
    //     eventTitle: 'FET: Community Event: January',
    //     eventDate: '2023.01.01',
    //     data: data
    //   }
    // })
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToFemale() {
    await window.localStorage.setItem('GenderDivision', 'female');
    setIsClickedGender('female');
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToMale() {
    await window.localStorage.setItem('GenderDivision', 'male');
    setIsClickedGender('male');
  }

  // 걸린시간 분/초로 계산 //
  function calcTime(data) {
    
    if (data.distance === 0 || data.distance === undefined) {
      let min = 0;
      let sec = 0;
  
      min = parseInt(data.time / 60);
      sec = data.time % 60;
  
      if (min === 0 && sec === 0) return 'DNF';
      else {
        if (sec === 0) return min + ':00';
        else return min + ':' + sec;
      }
    }
    else {
      return data.distance + ' M';
    }
  }

  return (
    <TopLevelWrapper>
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>FET: Community Event: January</Text>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#BDBDBD'>2023.01.01</Text> */}
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        <SectionButton isClicked={true}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>A</Text>
        </SectionButton>
      </SectionButtonWrapper>
      <DivisionLine />
      <GenderSelectWrapper>
        <GenderSelectBox isClicked={isClickedGender === 'female'} onClick={onClickChangeGenderToFemale}>
          <GenderImageWrapper>
            <GenderImage style={ isClickedGender === 'female' ? { opacity: 1 } : { opacity: 0 }} src={ColorFemale}/>
            <GenderImage style={ isClickedGender === 'female' ? { position: 'absolute', opacity: 0 } : { position: 'absolute', opacity: 1 }} src={WhiteFemale}/>
          </GenderImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color={ isClickedGender === 'female' ? `#4F4F4F` : '#828282'}>{t('여성')}</Text>
        </GenderSelectBox>
        <GenderSelectBox isClicked={isClickedGender === 'male'} onClick={onClickChangeGenderToMale}>
          <GenderImageWrapper>
            <GenderImage style={ isClickedGender === 'male' ? { opacity: 1 } : { opacity: 0 }} src={ColorMale}/>
            <GenderImage style={ isClickedGender === 'male' ? { position: 'absolute', opacity: 0 } : { position: 'absolute', opacity: 1 }} src={WhiteMale}/>
          </GenderImageWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={15} color={ isClickedGender === 'male' ? `#4F4F4F` : '#828282' }>{t('남성')}</Text>
        </GenderSelectBox>
      </GenderSelectWrapper>
      {
        (myRanking !== undefined && myRanking.gender === isClickedGender) &&
        <TempWrapper>
          <TopLineWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('내 순위')}</Text>
            </TitleWrapper>
          </TopLineWrapper>
          <RankingTotalWrapper>
            <RankingViewWrapper onClick={() => onClickRecordDetailButton(myRanking)}>
              {
                (myRanking.time !== 0 || myRanking.distance !== 0) ?
                  <RankingNumberBoxWrapper>
                    {
                      myRanking.rank < 4 ?
                        <RankingLeaderBoxWrapper>
                          <RankingLeaderBox src={myRanking.rank === 1 ? FirstPlaceImg : myRanking.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                          <RankingImageTextWrapper>
                            <Text style={{ marginTop: `-8px`}}  fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                          </RankingImageTextWrapper>
                        </RankingLeaderBoxWrapper>
                        :
                        <RankingNumberBox>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                        </RankingNumberBox>
                    }
                  </RankingNumberBoxWrapper>
                  :
                  <RankingNumberBoxWrapper>
                    <RankingNumberBox>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>DNF</Text>
                    </RankingNumberBox>
                  </RankingNumberBoxWrapper>
              }
              <RankingClickBox>
                <InfoBox>
                  <LeftWrapper>
                    <Profile src={myRanking.profile === '' ? NoneProfile : myRanking.profile} />
                    <InfoTextWrapper style={{ marginLeft: '8px' }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{myRanking.name}</Text>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTime(myRanking)}</Text>
                    </InfoTextWrapper>
                  </LeftWrapper>
                  {/* <DetailButton src={RightImg} /> */}
                </InfoBox>
              </RankingClickBox>
            </RankingViewWrapper>
          </RankingTotalWrapper>
        </TempWrapper>
      }
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('전체 순위')}</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <RankingTotalWrapper>
        {
          rankingList.map((data, index) => (
            <RankingViewWrapper key={index} onClick={() => onClickRecordDetailButton(data)}>
              {
                (data.time !== 0 || data.distance !== 0) ?
                  <RankingNumberBoxWrapper>
                    {
                      data.rank <= 3 ?
                        <RankingLeaderBoxWrapper>
                          <RankingLeaderBox src={index === 0 ? FirstPlaceImg : index === 1 ? SecondPlaceImg : ThirdPlaceImg} />
                          <RankingImageTextWrapper>
                            <Text style={{ marginTop: `-8px`}}  fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                          </RankingImageTextWrapper>
                        </RankingLeaderBoxWrapper>
                        :
                        <RankingNumberBox>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                        </RankingNumberBox>
                    }
                  </RankingNumberBoxWrapper>
                  :
                  <RankingNumberBoxWrapper>
                    <RankingNumberBox>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>DNF</Text>
                    </RankingNumberBox>
                  </RankingNumberBoxWrapper>
              }
              <RankingClickBox>
                <InfoBox>
                  <LeftWrapper>
                    <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                    <InfoTextWrapper style={{ marginLeft: '8px' }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{calcTime(data)}</Text>
                    </InfoTextWrapper>
                  </LeftWrapper>
                  {/* <DetailButton src={RightImg} /> */}
                </InfoBox>
              </RankingClickBox>
            </RankingViewWrapper>
          ))
        }
      </RankingTotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 80px;

  width: 100%;
  height: 100%;

  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TotalSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#F1DA0D`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const GenderSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const GenderSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  width: 50%;
  height: 32px;

  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.isClicked ? '#F9F9F9' : '#E0E0E0'};

  transition: all 0.3s ease-in-out;
`;

const GenderImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const GenderImage = styled.img`
  width: 11px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 19px;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin: 4px 0px 80px 0px;
  marigin-top: 4px;

  width: 90.33%;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(87.16% - 28px);
  height: 62px;

  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;
