import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import { useTranslation } from "react-i18next";
import FetchModule from "../../../Model/Network/FetchModule";
import BelieverSubmitViewModel from "./BelieverSubmitViewModel";
import BelieverSubmitModel from "./BelieverSubmitModel";

export default function BelieverEvent3(props) {
  const { totalVideoWidth, totalVideoHeight, setIsCompleteSubmit } = props;

  const { t } = useTranslation();

  const eventId = 37;

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [completeRep, setCompleteRep] = useState(0);
  const [lastExerciseRep, setLastExerciseRep] = useState(0);

  const [tieBreakMin, setTieBreakMin] = useState(0);
  const [tieBreakSec, setTieBreakSec] = useState(0);
  // const [lastExercise, setLastExercise] = useState('');
  // const [lastExerciseRep, setLastExerciseRep] = useState(0);
  
  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });

  const [isRx, setIsRx] = useState(true);
  const [isExistRecord, setIsExistRecord] = useState(false);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [viewIsRx, setViewIsRx] = useState(false);
  const [viewMin, setViewMin] = useState(0);
  const [viewSec, setViewSec] = useState(0);
  const [viewCompleteRep, setViewCompleteRep] = useState(0);
  const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);
  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);
  // const [viewLastExercise, setViewLastExercise] = useState(0);
  // const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    let requestData = {
      eventId: eventId,
      section: 'C'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently', 'POST', requestData);
        console.log(responseData);
        if (responseData.status === 200) {

          // let time = parseInt(responseData.data.time);
          // let decimalPoint = (responseData.data.time % 1).toFixed(2);

          // setViewMin(Math.floor(time / 60));
          // setMin(Math.floor(time / 60));
      
          // if (decimalPoint === '0.00') {
          //   setViewSec(time % 60);
          //   setSec(time % 60);
          // }
          // else {
          //   setViewSec((time % 60) + parseFloat(decimalPoint));
          //   setSec((time % 60) + parseFloat(decimalPoint));
          // }

          let tieBreakTime = parseInt(responseData.data.tieBreak);
          let tieBreakDecimalPoint = (responseData.data.tieBreak % 1).toFixed(2);

          setViewTieBreakMin(Math.floor(tieBreakTime / 60));
          setTieBreakMin(Math.floor(tieBreakTime / 60));

          if (tieBreakDecimalPoint === '0.00') {
            setViewTieBreakSec((tieBreakTime % 60));
            setTieBreakSec((tieBreakTime % 60));
          }
          else {
            setViewTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
            setTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
          }

          setIsExistRecord(true);
          setViewIsRx(responseData.data.isRx);
          setCompleteRep(responseData.data.completeRep);
          setViewCompleteRep(responseData.data.completeRep);

          setLastExerciseRep(responseData.data.lastExerciseRep);
          setViewLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewCompleteRound(responseData.data.completeRound);
          // setViewLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewLastExercise(responseData.data.lastExercise);
          setViewLink(responseData.data.link);
          setPicture(responseData.data.picture);
        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'EVENT3RULEBOOK.pdf';

      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/believer', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {

    // if (moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-11-05T00:00:00').format('YYYY.MM.DD HH:mm:ss')) {
    //   alert('제출 기한이 마감되었습니다.');
    //   return;
    // }

    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {

      // let finalMin = min === '' ? 0 : parseInt(min * 60);
      // let finalSec = sec === '' ? 0 : parseFloat(sec);
      let finalTieBreakMin = tieBreakMin === '' ? 0 : parseInt(tieBreakMin * 60);
      let finalTieBreakSec = tieBreakSec === '' ? 0 : parseFloat(tieBreakSec);
      let finalCompleteRep = completeRep === '' ? 0 : completeRep;

      if (finalCompleteRep === 0) {
        alert('기록을 입력해주세요');
        return;
      }
      else if ((link !== ''
        && !link.includes('https://youtube.com/shorts/')
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }
      else if (finalTieBreakMin + finalTieBreakSec === 0) {
        alert('TieBreak & Time Cap을 입력해주세요!');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let requestData = {
        eventId: eventId,
        section: 'C',
        isRx: true,
        time: 0,
        tieBreak: finalTieBreakMin + finalTieBreakSec,
        completeRep: finalCompleteRep,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: 0,
        firstExercise: 0,
        secondExercise: 0,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: link,
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);

          if (responseData.status === 200) {
            setIsCompleteSubmit(true);
          }
        }
      )();

      setIsClickedSubmit(false);
    }
    else {
      let requestData = {
        eventId: eventId,
        section: 'C'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().isBefore(moment('2024-05-13T00:00:00')) ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>컴피티션 빌리버 [Believer]</Text>
            <Text style={{ marginTop: '2px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2024.05.13 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 3-1</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>AMRAP 6min</Text>
              </TitleBox>
            </TitleWrapper>
            <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/5Pqe4Kma3wk?si=1xhHFsIu4dGzOtWA`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox>
            <WodWrapper>
              <OrangeBoxWrapper>
                {/* <OrangeBox> */}
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FF8B48'>Weight Lifting WOD</Text>
                {/* </OrangeBox> */}
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Snatch</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>3</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Hang Snatch</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>3</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Overhead Squats</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>3</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
                <InfoWrapper style={{ marginTop: '16px' }}>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player A</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>75/55 lbs (34/25 kg)</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoTitleWrapper style={{ marginTop: '12px' }}>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#5BD98A'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player B</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>95/65 lbs (43/29 kg)</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoTitleWrapper style={{ marginTop: '12px' }}>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#2F80ED'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player C</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>115/75 (52/34 kg)</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                </InfoWrapper>
              <RestInfoBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#FFFFFF'>3-2 시작 전 REST 2min</Text>
              </RestInfoBox>
            </WodWrapper>
            {
              (isExistRecord || isClickedSubmit) ?
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  {
                    isClickedSubmit ?
                      <BelieverSubmitModel
                        eventId={eventId}
                        section='C'

                        isSuccess={isSuccess}
                        setIsSuccess={setIsSuccess}

                        // min={min}
                        // setMin={setMin}
                        // sec={sec}
                        // setSec={setSec}
                        completeRep={completeRep}
                        setCompleteRep={setCompleteRep}

                        lastExerciseRep={lastExerciseRep}
                        setLastExerciseRep={setLastExerciseRep}

                        tieBreakMin={tieBreakMin}
                        setTieBreakMin={setTieBreakMin}
                        tieBreakSec={tieBreakSec}
                        setTieBreakSec={setTieBreakSec}

                        link={link}
                        setLink={setLink}
                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                      />
                      :
                      <BelieverSubmitViewModel
                        eventId={eventId}
                        section='C'

                        setIsExistRecord={setIsExistRecord}
                        
                        // min={viewMin}
                        // sec={viewSec}
                        completeRep={viewCompleteRep}
                        // lastExerciseRep={viewLastExerciseRep}
                        tieBreakMin={viewTieBreakMin}
                        tieBreakSec={viewTieBreakSec}

                        link={viewLink}
                        picture={picture}
                      />
                  }
                </ExerciseWrapper>
                :
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  <InfoWrapper>
                    <InfoTitleWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                    </InfoTitleWrapper>
                  </InfoWrapper>
                  <ScoreGuideLineImageWrapper onClick={downloadPDF}>
                    <ScoreGuideLineImage src='https://picture.link-zone.org/event/believer/BelieverEvent3RuleBook.png' />
                  </ScoreGuideLineImageWrapper>
                  <RuleBookWrapper>
                    <RuleColumnTextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                    </RuleColumnTextWrapper>
                    {/* <RuleRowTextWrapper style={{ marginTop: '24px' }}>
                      <Point color='#FFB800'/>
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>측정 시 인증 요소</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper style={{ marginTop: '4px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 선수 소개 (영상에 얼굴이 보이게끔 3명의 선수 이름 호명)</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 와드 시작 전 처음 세팅되어 있는 3명의 시작 무게 A, B, C 각각 인증 (인증 시간은 와드 시간에 포함되지 않음)</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 와드 종료 후 마지막 성공한 무게 인증</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>(인증 시간은 와드 시간에 포함되지 않음)</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 3-1. kg 플레이트 사용 시 괄호 안에 있는 숫자보다 높거나 같아야함</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 3-1. A, B, C 무게는 한 선수가 처음부터 끝까지 수행.</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 와드 종료 후 마지막 성공한 무게 인증</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>이후 Heavy Complex 수행 시에도 본인 바벨을 그대로 사용.</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- Snatch</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>파워, 스쾃, 스플릿 전부 가능합니다. (스플릿 시 양발이 평행한 위치로 되돌아와야 함.)</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- Hang Snatch</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>DL 후 무릎 위에서만 시작 가능. 연속 수행 시 DL 생략 가능.</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '8px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- Overhead Squat</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>오픈 룰과 동일, 풀 스쾃기준</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>2분 휴식 시 Heavy Complex 첫번째 시도 무게로 미리 교체 가능</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>2분 휴식 시 Heavy Complex 연습 가능, 단 기록으로 인정되지 않음.</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '32px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>기록</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>기록 : 총 갯수</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Tie Break : 3-1. 짝수 라운드 끝낸 시간</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>(ex. Reps 기록이 7R 4 면 6라운드 끝냈을 때의 시간)</Text>
                    </TextWrapper> */}
                  </RuleBookWrapper>
                </ExerciseWrapper>
            }
            {
              moment().isBefore(moment('2024-05-20T00:00:00')) &&
              <RankingButton onClick={onClickRecordAndSubmit}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>제출하기</Text>
              </RankingButton>
            }
            {/* {
            moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-04-30T23:59:59').format('YYYY.MM.DD HH:mm:ss') ?
              <RankingButton>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
              </RankingButton>
              :
              <RankingButton>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'></Text>
              </RankingButton>
          } */}
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 76px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const OrangeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  height: 18px;

  border-radius: 2px;
  background-color: #FF8B48;
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // padding: 0px 12px 0px 12px;
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 0px 0px;

  width: calc(90.33% - 76px);
  // width: calc(100% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const MissionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 1px 9px 1px 9px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const RestInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: 33px;

  background-color: #5BD98A;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const ExercisePlayerBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 59px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 130px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  border-radius: 10px;
  background-color: ${props => props.color};
`;

const NotRoundPoint = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  min-width: 10px;
  min-height: 10px;
  
  background-color: ${props => props.color};
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;