import react, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import FirstPlaceImg from '../../Image/FirstPlace.png';
import SecondPlaceImg from '../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../Image/ThirdPlace.png';
import RightImg from '../../Image/NotAvailableRight.png';
import NoneProfile from '../../Image/NoneProfile.png';

export default function LazyTeamComponent(props) {
  const { data, index, isTeamFight, onClickTeamButton } = props;

  const [isLoading, setIsLoading] = useState(false);

  const ComponentRef = useRef();
  const ObserveRef = useRef();

  // useEffect(() => {
  //   ObserveRef.current = new IntersectionObserver(IntersectionObserver);
  //   ComponentRef.current && ObserveRef.current.observe(ComponentRef.current);
  // }, [ComponentRef, ObserveRef])

  // // useEffect(() => {
  // //   console.log(data);
  // // }, [data])
  // const IntersectionObserver = (entries, io) => {
  //   entries.forEach((entry) => {
  //     if (entries.isInstersecting) {
  //       io.unobserve(entry.target);
  //       setIsLoading(true);
  //     }
  //   })
  // }

  return (
    <RankingViewWrapper ref={ComponentRef} key={index} onClick={() => onClickTeamButton(data)}>
      {
        data.score !== -1 ?
          <RankingNumberBoxWrapper>
            {
              index < 3 ?
                <RankingLeaderBoxWrapper>
                  <RankingLeaderBox src={index === 0 ? FirstPlaceImg : index === 1 ? SecondPlaceImg : ThirdPlaceImg} />
                  <RankingImageTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                  </RankingImageTextWrapper>
                </RankingLeaderBoxWrapper>
                :
                <RankingNumberBox>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{index + 1}</Text>
                </RankingNumberBox>
            }
          </RankingNumberBoxWrapper>
          :
          <RankingNumberBoxWrapper>
            <RankingNumberBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>0</Text>
            </RankingNumberBox>
          </RankingNumberBoxWrapper>
      }
      <RankingClickBox>
        <InfoBox>
          <LeftWrapper>
            <Profile src={data.profile === '' ? NoneProfile : data.profile} />
            <InfoTextWrapper style={{ marginLeft: '8px' }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
              {data.score !== -1 && <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>Point {data.score}</Text>}
            </InfoTextWrapper>
          </LeftWrapper>
          {
            isTeamFight && <DetailButton src={RightImg} />
          }
        </InfoBox>
      </RankingClickBox>
    </RankingViewWrapper>
  )
}

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(87.16% - 28px);
  height: 62px;

  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
