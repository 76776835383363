import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import FirstPlaceImg from '../../../Image/FirstPlace.png';
import SecondPlaceImg from '../../../Image/SecondPlace.png';
import ThirdPlaceImg from '../../../Image/ThirdPlace.png';
import RightImg from '../../../Image/NotAvailableRight.png';
import NoneProfile from '../../../Image/NoneProfile.png';
import WhiteMale from '../../../Image/WhiteMale.png';
import ColorMale from '../../../Image/ColorMale.png';
import WhiteFemale from '../../../Image/WhiteFemale.png';
import ColorFemale from '../../../Image/ColorFemale.png';
import WaitIcon from '../../../Image/WaitIcon.png';
import CrownIcon from '../../../Image/CrownIcon.png';
import GoldCrownIcon from '../../../Image/GoldCrownIcon.png';

import FetchModule from '../../../Model/Network/FetchModule';
// import LazyTeamComponent from './LazyTeamComponent';

export default function FitForceTeamLeagueRankingSection(props) {
  const { eventId } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [totalWeight, setTotalWeight] = useState(0);
  const [isClickedGender, setIsClickedGender] = useState(window.localStorage.getItem('GenderDivision') === null ? 'female' : window.localStorage.getItem('GenderDivision'));
  const [isTeamFight, setIsTeamFight] = useState(false);
  const [sections, setSections] = useState([]);
  const [myRankTotalHeight, setMyRankTotalHeight] = useState(64);
  const [myRankHeight, setMyRankHeight] = useState(62);

  const [myRanking, setMyRanking] = useState(undefined);
  const [responseRankingList, setResponseRankingList] = useState([]);
  const [rankingList, setRankingList] = useState([]);
  const [isClickedMyRank, setIsClickedMyRank] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const TopLevelRef = useRef();

  // // Member Or Team List API Call //
  // useEffect(() => {
  //   let requestData = {
  //     eventId: eventId
  //   }

  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.postDataOfEvent('score/', 'POST', requestData);
  //       console.log(responseData.data);
  //       if (responseData.status === 200) {
  //         setData(responseData.data);

  //         if (responseData.error === 'Team') setIsTeamFight(true);
  //         else setIsTeamFight(false);
  //       }
  //     }
  //   )();
  // }, [eventId])

  // 순위 정리 //
  useEffect(() => {
    
    setIsLoading(true);
    setIsClickedMyRank(false);

    let requestData = {
      eventId: eventId,
      section: 'A'
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('score/rank/team', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let rankData = responseData.data.eventRankList.slice();
          let myRank = responseData.data.myRankData;
          let totalRankData = [];

          let validRankData = [];
          let invalidRankData = [];

          // 순위 데이터 정리 //
          for (let i = 0; i < rankData.length; i++) {
            if (rankData[i].completeRep !== 0) validRankData.push(rankData[i]);
            else invalidRankData.push(rankData[i]);
            // if (eventId === 34) {
            //   if (rankData[i].completeRep !== 0) validRankData.push(rankData[i]);
            //   else invalidRankData.push(rankData[i]);
            // }
            // else if (eventId === 'B') {
            //   if (rankData[i].completeRep !== 0) validRepsRankData.push(rankData[i]);
            //   else invalidRankData.push(rankData[i]);
            // }
            // else if (eventId === 'C') {
            //   if (rankData[i].completeRep !== 0) validRankData.push(rankData[i]);
            //   else invalidRankData.push(rankData[i]);
            // }
            // else if (eventId === 'D') {
            //   if (rankData[i].completeRep !== 0) validRankData.push(rankData[i]);
            //   else invalidRankData.push(rankData[i]);
            // }
          }

          if (eventId === 34 || eventId === 36) {
            // 데이터 순위 정렬 //
            validRankData.sort((a, b) => b.completeRep - a.completeRep || a.tieBreak - b.tieBreak);
  
            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;
  
              if (x > y) return 1;
              if (x < y) return -1;
            })
  
            // validRankData = validTimeRankData.concat(validRepsRankData);
  
            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0
                && validRankData[i].completeRep !== 0
                && validRankData[i].completeRep === validRankData[i - 1].completeRep
                && validRankData[i].tieBreak === validRankData[i - 1].tieBreak) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else validRankData[i].rank = (i + 1);
            }
  
            for (let i = 0; i < invalidRankData.length; i++) {
              if (validRankData.length === 0) invalidRankData[i].rank = 0;
              else invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;
            }
  
            totalRankData = validRankData.concat(invalidRankData);
  
            // 남성 여성 순으로 정렬 //
            totalRankData.map((data) => {
              let rankMembers = data.members;
              let finalRankMembers = [];
  
              let rankMales = [];
              let rankFemales = [];
  
              rankMembers.map((memberData) => {
                if (memberData.gender === 'male') rankMales.push(memberData);
                else rankFemales.push(memberData);
              })
  
              rankMales.sort(function (a, b) {
                let x = a.name;
                let y = b.name;
  
                if (x > y) return 1;
                if (x < y) return -1;
              })
  
              rankFemales.sort(function (a, b) {
                let x = a.name;
                let y = b.name;
  
                if (x > y) return 1;
                if (x < y) return -1;
              })
  
              finalRankMembers = rankMales.concat(rankFemales);
  
              data.members = finalRankMembers;

              data.isClicked = false;
            })
  
            // 내 순위 찾기
            if (myRank !== undefined) {
              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].teamId === myRank.teamId) {
                  myRank.rank = totalRankData[i].rank;
                  myRank.profile = totalRankData[i].profile;
                  break;
                }
              }

              setMyRanking(myRank);
            }
  
            setResponseRankingList(totalRankData);
          }
          else if (eventId === 35) {
            // 데이터 순위 정렬 //
            validRankData.sort((a, b) => b.completeRep - a.completeRep || b.lastExerciseRep - a.lastExerciseRep);
  
            // 올바르지 않은 데이터는 이름순 정렬 //
            invalidRankData.sort(function (a, b) {
              let x = a.name;
              let y = b.name;
  
              if (x > y) return 1;
              if (x < y) return -1;
            })
  
            // validRankData = validTimeRankData.concat(validRepsRankData);
  
            for (let i = 0; i < validRankData.length; i++) {
              if (i !== 0
                && validRankData[i].completeRep !== 0
                && validRankData[i].completeRep === validRankData[i - 1].completeRep
                && validRankData[i].lastExerciseRep === validRankData[i - 1].lastExerciseRep) {
                validRankData[i].rank = validRankData[i - 1].rank;
              }
              else validRankData[i].rank = (i + 1);
            }
  
            for (let i = 0; i < invalidRankData.length; i++) {
              if (validRankData.length === 0) invalidRankData[i].rank = 0;
              else invalidRankData[i].rank = validRankData.length + invalidRankData.length - 1;
            }
  
            totalRankData = validRankData.concat(invalidRankData);
  
            // 남성 여성 순으로 정렬 //
            totalRankData.map((data) => {
              let rankMembers = data.members;
              let finalRankMembers = [];
  
              let rankMales = [];
              let rankFemales = [];
  
              rankMembers.map((memberData) => {
                if (memberData.gender === 'male') rankMales.push(memberData);
                else rankFemales.push(memberData);
              })
  
              rankMales.sort(function (a, b) {
                let x = a.name;
                let y = b.name;
  
                if (x > y) return 1;
                if (x < y) return -1;
              })
  
              rankFemales.sort(function (a, b) {
                let x = a.name;
                let y = b.name;
  
                if (x > y) return 1;
                if (x < y) return -1;
              })
  
              finalRankMembers = rankMales.concat(rankFemales);
  
              data.members = finalRankMembers;

              data.isClicked = false;
            })
  
            // 내 순위 찾기
            if (myRank !== undefined) {
              for (let i = 0; i < totalRankData.length; i++) {
                if (totalRankData[i].teamId === myRank.teamId) {
                  myRank.rank = totalRankData[i].rank;
                  myRank.profile = totalRankData[i].profile;
                  break;
                }
              }

              setMyRanking(myRank);
            }
  
            setResponseRankingList(totalRankData);
          }
        }

        // setIsLoading(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    )();
  }, [eventId, TopLevelRef])

  // 내 순위 자세히 보기에 따른 컴포넌트 크기 변환 //
  useEffect(() => {
    if (isClickedMyRank) {
      if (myRanking.members.length < 4) {
        setMyRankTotalHeight(150);
        setMyRankHeight(148);
      }
      else if (myRanking.members.length < 7) {
        setMyRankTotalHeight(221);
        setMyRankHeight(219);
      }
      else {
        setMyRankTotalHeight(291);
        setMyRankHeight(289);
      }
    }
    else {
      setMyRankTotalHeight(64);
      setMyRankHeight(62);
    }
  }, [isClickedMyRank, myRanking])


  // 클릭시 최종컴포넌트 늘어나는 크기 정하기 //
  function calcTotalComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    if (data.isClicked) {
      if (data.members.length < 4) return 150;
      else if (data.members.length < 7) return 221;
      else return 291;
    }
    else return 64;
  }

  // 클릭시 팀원 보기 컴포넌트 늘어나는 크기 정하기 //
  function calcComponentHeight(data) {
    // 자세히 보기가 눌려있는 경우 //
    if (data.isClicked) {
      if (data.members.length < 4) return 148;
      else if (data.members.length < 7) return 219;
      else return 289;
    }
    else return 62;
  }

  // 내순위 팀 구성원 자세히보기 클릭시 //
  function onClickMyRecordDetailButton() {
    
    let data = responseRankingList.slice();
    
    for (let i = 0; i < data.length; i++) {
      data[i].isClicked = false;
    }

    setResponseRankingList(data);
    setIsClickedMyRank(!isClickedMyRank);
  }

  // 팀 구성원 자세히보기 클릭시 //
  function onClickRecordDetailButton(index) {
    let data = responseRankingList.slice();

    for (let i = 0; i < data.length; i++) {
      if (i !== index) data[i].isClicked = false;
    }

    data[index].isClicked = !data[index].isClicked;

    setIsClickedMyRank(false);
    setResponseRankingList(data);
  }


  // 기록 보여주기 //
  function decideExerciseRecordText(data) {
    if (eventId === 34 || eventId === 36) {
      if (data.completeRep !== 0) {
        let tieBreak = '';

        let tieMin = 0;
        let tieSec = 0;
        let tieDecimalPoint = (data.tieBreak % 1).toFixed(2);
  
        tieMin = parseInt(data.tieBreak / 60);
  
        if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.tieBreak % 60) + parseFloat(tieDecimalPoint);
        else tieSec = parseInt(data.tieBreak % 60);
  
        if (tieMin !== 0 || tieSec !== 0) {
          if (tieSec === 0) {
            if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
            else tieBreak = tieMin + ':00';
          }
          else {
            if (tieMin < 10) {
              if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
              else tieBreak = '0' + tieMin + ':' + tieSec;
            }
            else {
              if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
              else tieBreak = tieMin + ':' + tieSec;
            }
          }
        }
  
        if (tieBreak === '') return data.completeRep + ' REPS';
        else return data.completeRep + ' REPS - TieBreak ' + tieBreak;
      }
      else return '미제출';
    }
    else if (eventId === 35) {

      if (data.completeRep !== 0) {
        let tieBreak = '';

        let tieMin = 0;
        let tieSec = 0;
        let tieDecimalPoint = (data.tieBreak % 1).toFixed(2);
  
        tieMin = parseInt(data.tieBreak / 60);
  
        if (tieDecimalPoint !== '0.00') tieSec = parseInt(data.tieBreak % 60) + parseFloat(tieDecimalPoint);
        else tieSec = parseInt(data.tieBreak % 60);
  
        if (tieMin !== 0 || tieSec !== 0) {
          if (tieSec === 0) {
            if (tieMin < 10) tieBreak = '0' + tieMin + ':00';
            else tieBreak = tieMin + ':00';
          }
          else {
            if (tieMin < 10) {
              if (tieSec < 10) tieBreak = '0' + tieMin + ':0' + tieSec;
              else tieBreak = '0' + tieMin + ':' + tieSec;
            }
            else {
              if (tieSec < 10) tieBreak = tieMin + ':0' + tieSec;
              else tieBreak = tieMin + ':' + tieSec;
            }
          }
        }
  
        if (data.lastExerciseRep === 0) return data.completeRep + ' REPS';
        else return data.completeRep + ' REPS - TieBreak ' + data.lastExerciseRep + ' REPS';
      }
      else return '미제출';
    }
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToFemale() {
    await window.localStorage.setItem('GenderDivision', 'female');
    setIsClickedGender('female');
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToMale() {
    await window.localStorage.setItem('GenderDivision', 'male');
    setIsClickedGender('male');
  }

  // 성별 구분 보기 변경 버튼 클릭시 //
  async function onClickChangeGenderToTotal() {
    await window.localStorage.setItem('GenderDivision', 'total');
    setIsClickedGender('total');
  }

  // // 기록 클릭시 유튜브 영상 보이기 //
  // function onClickRecordDetail(data) {
  //   // console.log(data);

  //   // (link !== ''
  //   // && !link.includes('https://youtube.com/shorts/')
  //   // && !link.includes('https://www.youtube.com/watch?v=')
  //   // && !link.includes('https://m.youtube.com/watch?v=')
  //   // && !link.includes('https://youtube.com/watch?v=')
  //   // && !link.includes('https://youtu.be/'))

  //   if (data.link.includes('https://youtube.com/shorts/')) setVideoId(data.link.replace('https://youtube.com/shorts/', ''));
  //   else if (data.link.includes('https://www.youtube.com/watch?v=')) setVideoId(data.link.replace('https://www.youtube.com/watch?v=', ''));
  //   else if (data.link.includes('https://m.youtube.com/watch?v=')) setVideoId(data.link.replace('https://m.youtube.com/watch?v=', ''));
  //   else if (data.link.includes('https://youtube.com/watch?v=')) setVideoId(data.link.replace('https://youtube.com/watch?v=', ''));
  //   else if (data.link.includes('https://youtu.be/')) setVideoId(data.link.replace('https://youtu.be/', ''));
  //   else setVideoId('');

  //   setIsViewVideo(true);
  // }

  return (
    <TopLevelWrapper>
      {
        // moment().format('YYYY.MM.DD HH:mm:ss') < moment('2024.05.01').format('YYYY.MM.DD HH:mm:ss') ?
        //   <WaitWrapper>
        //     <WaitImage src={WaitIcon} />
        //     {/* <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{eventTitle}</Text>
        //     <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{moment(openDate).format('M월 DD일')} 공개예정</Text> */}
        //     <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>링크코치 [데드리프트] 첼린지</Text>
        //     <Text style={{ marginTop: '0px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>공개 예정</Text>
        //   </WaitWrapper>
        //   :
        <RankingTotalWrapper ref={TopLevelRef}>

          <TotalWrapper>
            {
              (myRanking !== undefined) &&
              <TempWrapper>
                <TopLineWrapper>
                  <TitleWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('내 순위')}</Text>
                  </TitleWrapper>
                </TopLineWrapper>
                {
                  isLoading ?
                    <RankingInnerWrapper>
                      <RankingLoadingView>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                      </RankingLoadingView>
                    </RankingInnerWrapper>
                    :
                    <RankingInnerWrapper>
                      <RankingViewWrapper height={myRankTotalHeight} onClick={() => onClickMyRecordDetailButton(myRanking)}>
                        {
                          <RankingNumberBoxWrapper>
                            {
                              (myRanking.rank > 0 && myRanking.rank < 4) ?
                                <RankingLeaderBoxWrapper>
                                  <RankingLeaderBox src={myRanking.rank === 1 ? FirstPlaceImg : myRanking.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                  <RankingImageTextWrapper>
                                    <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                                  </RankingImageTextWrapper>
                                </RankingLeaderBoxWrapper>
                                :
                                <RankingNumberBox>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{myRanking.rank}</Text>
                                </RankingNumberBox>
                            }
                          </RankingNumberBoxWrapper>
                        }
                        <RankingClickBoxWrapper>
                          <RankingClickBox height={myRankHeight} >
                            <InfoBox>
                              <LeftWrapper>
                                <Profile src={myRanking.profile === '' ? NoneProfile : myRanking.profile} />
                                <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                  <InnerInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{myRanking.name}</Text>
                                  </InnerInfoTextWrapper>
                                  <RecordBox>
                                    <RecordTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{decideExerciseRecordText(myRanking)}</Text>
                                    </RecordTextWrapper>
                                  </RecordBox>
                                </InfoTextWrapper>
                              </LeftWrapper>
                              <DetailButton isClicked={isClickedMyRank} src={RightImg} />
                            </InfoBox>
                            <MemberRowView>
                              {
                                  myRanking.members.map((data, index) => (
                                    <MemberView key={index}>
                                      <MemberProfile src={data.profile === '' ? NoneProfile : data.profile} />
                                      <ColorBoxWrapper>
                                        <ColorBox backgroundColor={data.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                          <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                        </ColorBox>
                                        <ColorBoxInTextWrapper>
                                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                        </ColorBoxInTextWrapper>
                                      </ColorBoxWrapper>
                                    </MemberView>
                                  ))
                                }
                            </MemberRowView>
                          </RankingClickBox>
                        </RankingClickBoxWrapper>
                      </RankingViewWrapper>
                    </RankingInnerWrapper>
                }
              </TempWrapper>
            }
            <TopLineWrapper>
              <TitleWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333'>{t('전체 순위')}</Text>
              </TitleWrapper>
            </TopLineWrapper>
            {
              isLoading ?
                <RankingInnerWrapper>
                  <RankingLoadingView>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('순위 계산중...')}</Text>
                  </RankingLoadingView>
                </RankingInnerWrapper>
                :
                <RankingInnerWrapper>
                  {
                    responseRankingList.map((data, index) => (
                      <RankingViewWrapper key={index} height={calcTotalComponentHeight(data)} onClick={() => onClickRecordDetailButton(index)}>
                        {
                          <RankingNumberBoxWrapper>
                            {
                              (data.rank > 0 && data.rank < 4) ?
                                <RankingLeaderBoxWrapper>
                                  <RankingLeaderBox src={data.rank === 1 ? FirstPlaceImg : data.rank === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                  <RankingImageTextWrapper>
                                    <Text style={{ marginTop: `-8px` }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                                  </RankingImageTextWrapper>
                                </RankingLeaderBoxWrapper>
                                :
                                <RankingNumberBox>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>{data.rank}</Text>
                                </RankingNumberBox>
                            }
                          </RankingNumberBoxWrapper>
                        }
                        <RankingClickBoxWrapper>
                          <RankingClickBox height={calcComponentHeight(data)}>
                            <InfoBox>
                              <LeftWrapper>
                                <Profile src={data.profile === '' ? NoneProfile : data.profile} />
                                <InfoTextWrapper style={{ marginLeft: '8px' }}>
                                  <InnerInfoTextWrapper>
                                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.name}</Text>
                                  </InnerInfoTextWrapper>
                                  <RecordBox>
                                    <RecordTextWrapper>
                                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#4F4F4F'>{decideExerciseRecordText(data)}</Text>
                                    </RecordTextWrapper>
                                    <RecordTextWrapper>
                                    </RecordTextWrapper>
                                  </RecordBox>
                                </InfoTextWrapper>
                              </LeftWrapper>
                              <DetailButton isClicked={data.isClicked} src={RightImg} />
                            </InfoBox>
                            <MemberRowView>
                              {
                                  data.members.map((innerData, index) => (
                                    <MemberView key={index}>
                                      <MemberProfile src={innerData.profile === '' ? NoneProfile : innerData.profile} />
                                      <ColorBoxWrapper>
                                        <ColorBox backgroundColor={innerData.gender === 'male' ? '#2F80ED' : '#FF3131'}>
                                          <ScaleText fontFamily='NotoSansKR-Medium' fontSize={10} scale={0.8} color='#FFFFFF'>ATHLETE</ScaleText>
                                        </ColorBox>
                                        <ColorBoxInTextWrapper>
                                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{innerData.name}</Text>
                                        </ColorBoxInTextWrapper>
                                      </ColorBoxWrapper>
                                    </MemberView>
                                  ))
                                }
                            </MemberRowView>
                          </RankingClickBox>
                        </RankingClickBoxWrapper>
                      </RankingViewWrapper>
                    ))
                  }
                </RankingInnerWrapper>
            }
          </TotalWrapper>
        </RankingTotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const DonationBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-bottom: 12px;

  // width: 90.33%;
  width: 100%;
  height: 36px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const DonationTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 90.33%; 
  width: 100%;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const RankingLoadingView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 64px;
`;

const RankingTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  margin: 4px 0px 80px 0px;

  width: 90.33%;
  // width: 100%;
  // height: 100%;
`;

const RankingInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin: 4px 0px 80px 0px;
  margin-top: 4px;

  // width: 90.33%;
  width: 100%;
  // height: 100%;

  transition: all 0.3s ease-in-out;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 12px;

  width: 100%;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  width: 40px;
  height: 40px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
   display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 16px 0px 12px;

  width: calc(100% - 28px);
  height: ${props => props.height}px;

  overflow: hidden;
  
  border-radius: 12px;
  background-color: #FFFFFF;

  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);

  :hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  // width: 95.7%;
  width: 100%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // gap: 8px;
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 6px;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;
`;

const RankingClickBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 87.16%;
  width: 85%;
`;

const InnerInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const RecordBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const RecordTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const MemberRowView = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;


const GenderSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 16px;

  width: 100%;
`;

const GenderSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  // width: 50%;
  width: 33.33%;
  height: 32px;

  border-radius: 10px 10px 0px 0px;
  // background-color: ${props => props.isClicked ? '#F9F9F9' : '#E0E0E0'};
  background-color: ${props => props.isClicked ? '#6DD49E' : '#E0E0E0'};

  transition: all 0.3s ease-in-out;
`;

const GenderImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
`;

const GenderImage = styled.img`
  width: 11px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const CrownImage = styled.img`
  width: 22px;
  height: 20px;

  transition: all 0.3s ease-in-out;
`;

const MemberView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 28px;
`;

const MemberProfile = styled.img`
  width: 40px;
  height: 40px;

  margin-right: 8px;

  border-radius: 6px;

  object-fit: contain;
`;

const ColorBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 41px;
  height: 21px;

  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
`;

const ColorBoxInTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 41px;
  height: 20px;
  
  overflow: hidden;
`;

const ScaleText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
  
  -webkit-transform: scale(${props => props.scale});
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
