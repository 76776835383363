import React from "react";
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import { useTranslation } from "react-i18next";

export default function GirlFitTeamEvent2(props) {
  const { totalVideoWidth, totalVideoHeight } = props;

  const { t } = useTranslation();

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'GirlFitTeamRuleBook2.pdf';

      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/GirlFit', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment('2022-09-16T00:00:00').format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>GirlFit</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2023.09.16 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 2</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>10min AMRAP</Text>
              </TitleBox>
            </TitleWrapper>
            <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/wHE66JqN66U`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox>
            <WodWrapper>
              <OrangeBoxWrapper>
                <OrangeBox>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>Athlete 1, 2</Text>
                </OrangeBox>
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Relay Bear complex with OH hold</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>65</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={58} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>12-9-6</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Synchro Pull-up</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={58} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>12-9-6</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ThenLine>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>Then</Text>
              </ThenLine>
              <OrangeBoxWrapper>
                <BlueBox>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>Athlete 3, 4</Text>
                </BlueBox>
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Relay Bear complex with OH hold</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>65</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>lb</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={58} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>9-6-3</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>04</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Synchro Pull-up</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={58} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>9-6-3</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
            </WodWrapper>
            <ThenLine>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>Then</Text>
            </ThenLine>
            <OrangeBoxWrapper>
              <OrangeBox>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>Athlete 1, 2</Text>
              </OrangeBox>
              <BlueBox style={{ marginLeft: '8px' }}>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>Athlete 3, 4</Text>
              </BlueBox>
            </OrangeBoxWrapper>
            <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>05</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Max Synchro Burpee</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={62} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
            <ExerciseWrapper style={{ marginTop: '40px' }}>
              <InfoWrapper>
                <InfoTitleWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                </InfoTitleWrapper>
              </InfoWrapper>
              <ScoreGuideLineImageWrapper onClick={downloadPDF}>
                <ScoreGuideLineImage src='https://picture.link-zone.org/event/GirlFit/GirlFitRuleBookIcon.png' />
              </ScoreGuideLineImageWrapper>
              <RuleBookWrapper>
                <RuleColumnTextWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                </RuleColumnTextWrapper>
                
                <RuleRowTextWrapper style={{ marginTop: '24px' }}>
                <Point />
                <TextWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>tie break time 은 WBS과 DB스내치 두 동작에</Text>
                  <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>모두 끝난시간입니다</Text>
                </TextWrapper>
              </RuleRowTextWrapper>
              {/* <RuleRowTextWrapper style={{ marginTop: '10px' }}>
                <Point />
                <TextWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('Barbell 무게 변환 기준')}</Text>
                  <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'95lb ↔ 43.5kg or 43.5kg or more'}</Text>
                  <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'65lb ↔ 30kg'}</Text>
                  <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'35lb ↔ 15kg'}</Text>
                </TextWrapper>
              </RuleRowTextWrapper> */}
              </RuleBookWrapper>
            </ExerciseWrapper>
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const OrangeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 16px;

  height: 18px;

  border-radius: 2px;
  background-color: #FF8B48;
`;

const BlueBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 16px 0px 16px;

  height: 18px;

  border-radius: 2px;
  background-color: #4161AF;
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  // width: calc(89.3% - 69px);
  width: calc(90.33% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const MissionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 1px 9px 1px 9px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 130px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  background-color: #FF3131;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ThenLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 16px 0px 16px 0px;

  width: 100%;
  height: 27px;

  background-color: #D9D9D9;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;