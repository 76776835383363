import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import PoweredImage from '../../Image/PoweredByLink.png';
import FetchModule from '../../Model/Network/FetchModule';

export default function LinkCoachDeadLiftApplicationStatePage() {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [totalVideoWidth, setTotalVideoWidth] = useState(366);
  const [totalVideoHeight, setTotalVideoHeight] = useState(456);

  const [teamName, setTeamName] = useState('');
  const [memberNames, setMemberNames] = useState('');
  const [totalPrice, setTotalPrice] = useState(105000);
  const [members, setMembers] = useState([]);
  const [salesDetails, setSalesDetails] = useState([]);

  const [data, setData] = useState({
    name: '',
    phone: '',
    gender: '',
    birth: '',
    address: '',
    addressDetail: '',
    zipCode: ''
  })

  const navigate = useNavigate();
  const params = useParams();

  // 시작시 스크롤 맨 위로 //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [handleMassage])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    // alert(e.data);
    if (responseData.goBack) navigate(-1);
  }

  // 페이지 이름 변경내역 업로드 및 받아온 데이터 정리 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: '이벤트 등록 완료', isAvailableGoback: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, [])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    let totalVideoWidth = windowSize.width * 0.884;
    let totalVideoHeight = totalVideoWidth * 1.245;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])

  // 데이터 정리 //
  useEffect(() => {
    let token = params.token;

    window.localStorage.setItem('Token', 'Bearer ' + token);
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('event/application_state/linkcoach_deadlift/' + 39, 'GET');
        // console.log(responseData);
        // alert(responseData.status);
        if (responseData.status === 200) {
          setData(responseData.data);
        }
        else if (responseData.status === 201) {
          navigate('/info/linkcoach_deadlift');
        }
      }
    )();
  }, [])



  // 메인화면으로 클릭시 //
  function onClickGoHome() {
    // if (params.isFirst === 'true') {
    try {
      // window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
      window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'TabView' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>링크코치 [데드리프트] 첼린지</Text>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>이벤트 등록 신청 완료</Text>
        </TitleWrapper>
        <ImageWrapper width={totalVideoWidth} height={totalVideoWidth} >
          <Image src='https://picture.link-zone.org/event/linkcoach_deadlift/LDMain.png' />
        </ImageWrapper>
        <PoweredImageWrapper>
          <PoweredImg src={PoweredImage} />
        </PoweredImageWrapper>
        <TextComponentWrapper>
          <TextWrapper style={{ marginTop: '8px', width: '100%' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{'<참가접수 이벤트>'}</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{'참가신청 등록완료를 인스타 스토리에 링크코치 계정(@link.coach)과 함께 공유해주시면 추첨을 통해 다양한 상품을 드립니다!'}</Text>
          </TextWrapper>
        </TextComponentWrapper>
      </TotalWrapper>
      <TotalStateBox>
        <MemberTotalInfoBox>
          <DivisionLine />
          <LayerInfoBox>
            <InfoBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>이름</Text>
              <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.name}</Text>
            </InfoBox>
            <InfoBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>전화번호</Text>
              <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.phone}</Text>
            </InfoBox>
          </LayerInfoBox>
          <LayerInfoBox style={{ marginTop: `24px` }}>
            <InfoBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>성별</Text>
              <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.gender === 'male' ? '남자' : '여자'}</Text>
            </InfoBox>
            <InfoBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>생년월일</Text>
              <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.birth}</Text>
            </InfoBox>
          </LayerInfoBox>
          <LayerInfoBox style={{ marginTop: `24px` }}>
            <AddressInfoBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>체육관 주소</Text>
              <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.address}</Text>
              <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.addressDetail}</Text>
            </AddressInfoBox>
          </LayerInfoBox>
          <LayerInfoBox style={{ marginTop: `24px` }}>
            {/* <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>기념품 티셔츠 사이즈</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.belong}</Text>
                </InfoBox> */}
          </LayerInfoBox>
        </MemberTotalInfoBox>
        <DivisionLine />
        {/* <TotalSalesWrapper>
          <SalesWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>참가비</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>105,000 원</Text>
          </SalesWrapper>
          {
            salesDetails.map((data, index) => (
              <SalesWrapper>
                <SalesTextWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{decideSalesText(data)}</Text>
                </SalesTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{data.totalPrice.toLocaleString('ko-KR') + ' 원'}</Text>
              </SalesWrapper>
            ))
          }
          <DivisionLine />
          <SalesWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>총 주문 금액</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{totalPrice.toLocaleString('ko-KR') + ' 원'}</Text>
          </SalesWrapper>
        </TotalSalesWrapper> */}
      </TotalStateBox>
      {/* <MainButtonWrapper>
        <MainButton onClick={onClickGoHome}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{params.isFirst === 'true' ? '메인 화면으로' : '신청 정보 보기'}</Text>
        </MainButton>
      </MainButtonWrapper> */}
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 22px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;

  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const TextComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 88.4%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const PoweredImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 4px;

  width: 88%;
`;

const PoweredImg = styled.img`
  width: 174px;
  height: 25px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const MainButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  padding-bottom: 15px;
  margin-top: 54px;

  width: 100%;
  height: 68px;

  background-color: #FFFFFF;
`;

const MainButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const TotalStateBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 36px;

  width: 100%;
`;

const MemberTotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LayerInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 90.33%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 50%;
  width: 45%;
`;

const AddressInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 24px 0px 24px 0px;

  width: 90.33%;
  height: 1px;

  background-color: #E0E0E0;
`;

const TotalSalesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const SalesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-bottom: 4px;

  width: 90.33%;
`;

const SalesTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 70%;
  height: 21px;
  
  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;