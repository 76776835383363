import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


export default function GenderBox(props) {
  const { value, setValue, index} = props;

  const { t } = useTranslation();

  return (
    <TopLevelWrapper>
      <InfoBox isSelected={value === 'male'} onClick={() => setValue('male', index)}>
        {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'male' ? '#FFFFFF' : '#595959'}>{t('남성')}</Text> */}
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'male' ? '#FFFFFF' : '#595959'}>{t('남')}</Text>
      </InfoBox>
      <InfoBox isSelected={value === 'female'} onClick={() => setValue('female', index)}>
        {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'female' ? '#FFFFFF' : '#595959'}>{t('여성')}</Text> */}
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'female' ? '#FFFFFF' : '#595959'}>{t('여')}</Text>
      </InfoBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(48.39% - 4px);
  // height: 36px;
  height: 46px;

  border: ${props => props.isSelected ? '2px solid #6DD49E' : '2px solid #E0E0E0'};
  border-radius: 8px;
  // background-color: ${props => props.isSelected ? '#6DD49E' : '#FFFFFF'};
  background-color: ${props => props.isSelected ? '#6DD49E' : '#F9F9F9'};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;