import { React, forwardRef, useState, useRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';

import SelectOnPath from '../../../Image/SelectOn.png';
import SelectOffPath from '../../../Image/SelectOff.png';
import { useTranslation } from 'react-i18next';
// import SelectOnPath from '../../../Image/DownIcon.png';

const LastRoundSelectBox = forwardRef((props, ref) => {
  const { disabled, changeFn } = props;

  const { t } = useTranslation();

	const [isClicked, setIsClicked] = useState(false);
	// const [options, setOptions] = useState(data);
	const [defaultVal, setDefaultVal] = useState({id: 0, name: t('선택')});

	const TopLevelRef = useRef();
  const ViewBoxRef = useRef();
	const SelectRef = useRef();

  const options = [
    {
      id: 1,
      name: 'Double Unders'
    },
    {
      id: 2,
      name: 'Wall-ball shot'
    },
    {
      id: 3,
      name: 'Toes to bar'
    },
    {
      id: 4,
      name: 'Box Jump Overs'
    },
    {
      id: 5,
      name: 'Muscle up'
    }
  ]

	useImperativeHandle(ref, () => ({
		getValue: () => {
			return defaultVal;
		},
	}))

  // Select Box 크기 변경 //
  useEffect(() => {
    SelectRef.current.style.width = (ViewBoxRef.current.offsetWidth - 2) + 'px';
  }, [ViewBoxRef, SelectRef])

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
				SelectRef.current.style.border = '0px solid #CBCBCB';
				SelectRef.current.style.height = `0px`;
				SelectRef.current.style.overflow = 'hidden';
        setIsClicked(false);
    }
  }

	function onClick() {
    if (disabled) return;
		else if (!options[0]) return;
		
		if (isClicked) {
      // SelectRef.current.style.width = ViewBoxRef.current.offsetWidth + 'px';
			SelectRef.current.style.border = '0px solid #CBCBCB';
			SelectRef.current.style.height = `0px`;
			SelectRef.current.style.overflow = 'hidden';
			setIsClicked(false);
		}
		else {
      // SelectRef.current.style.width = ViewBoxRef.current.offsetWidth + 'px';
			SelectRef.current.style.border = '1px solid #FF8B48';
			SelectRef.current.style.height = `${38 * (options.length)}px`;
			// SelectRef.current.style.maxHeight = '170px';
			SelectRef.current.style.overflowY = 'scroll';
			SelectRef.current.style.overflowX = 'none';
			setIsClicked(true);
		}
	}

	function optionalClick(index) {
		setDefaultVal(options[index]);
		changeFn(options[index]);
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
      <ViewBox
        ref={ViewBoxRef}
        isClicked={isClicked}
        buttonPath={isClicked ? SelectOnPath : SelectOffPath}
        disabled={disabled}
        onClick={onClick}>
          <ViewText>{defaultVal.name}</ViewText>
      </ViewBox>
      <SelectBox
        ref={SelectRef}
        isClicked={isClicked}
        buttonPath={isClicked ? SelectOnPath : SelectOffPath}
        onClick={onClick}>
        {
          isClicked ?
            options.map((data, index) =>
              <OptionWrapper key={index} onClick={() => optionalClick(index)}>
                <NumberBox>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{data.id}</Text>
                </NumberBox>
                <TextWrapper>
                  <Option>{data.name}</Option>
                </TextWrapper>
              </OptionWrapper>
            )
            :
            <OptionWrapper>
              <Option>
                {
                  defaultVal.name
                }
              </Option>
            </OptionWrapper>
        }
			</SelectBox>
		</TopLevelWrapper>
	)
})

export default LastRoundSelectBox;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;

  width: 47%;
	height: 38px;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 38px;

  border: 1px solid #E8E8E8;
  border-radius: 12px;
  overflow: hidden;

  background: url(${props => props.buttonPath}) no-repeat 93% ${props => props.isClicked ? `40` : `55`}%;
  background-color: ${props => props.disabled ? '#ECECEC' : '#FFFFFF'};

	:hover {
		cursor: pointer;
	}

  transition: all 0.3s ease-in-out;
`;

const ViewText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #4F4F4F;
  margin: 0 0 0 12px;
`;

const SelectBox = styled.div`
	position: absolute;
  
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

  // width: 100%;
	height: 0px;

  margin-top: 40px;

	border-radius: 8px;
	overflow: hidden;

  z-index: 3;
  
	background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 8px 0px 8px;

	width: calc(100% - 11px);

	:hover {
		cursor: pointer;
	}
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Option = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  margin: 6px 0 6px 0;
	// margin: 6px 0 6px 8px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
