import React, { useRef, forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';

export default function KakaoAddress(props) {
  const { windowSize, setZipCode, setAddress, exitClick } = props;

  // const [name, setName] = useState();
  // const [address, setAddress] = useState();
  // const [zoneCode, setZoneCode] = useState();
  const [fullAddress, setFullAddress] = useState();
  const [isDaumPost, setIsDaumPost] = useState('false');

  const TopLevelRef = useRef();

  // 화면 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // 주소 겟챠~ //
  function handleAddress(data) {
    let AllAddress = data.address;
    let extraAddress = '';
    let zoneCodes = data.zonecode;

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      AllAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    // setFullAddress(AllAddress);
    // setZoneCode(zoneCodes);
    setAddress(AllAddress);
    setZipCode(zoneCodes);
    exitClick();
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <ModalWrapper>
        <TopBarWrapper>
          <ExitButton onClick={exitClick}>닫기</ExitButton>
        </TopBarWrapper>
        <DoumPostcodeWrapper>
          <DaumPostcode
            onComplete={handleAddress}
            autoClose
            style={{ width: windowSize.width, height: windowSize.height - 24 }}
            isdaumdost={isDaumPost} />
        </DoumPostcodeWrapper>
      </ModalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 10;
  opacity: 1;

  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  aling-items: center;

  width: 100%;
  height: 100%;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  padding: 0px 12px 0px 0px;
  margin: 0 0 0 0;

  width: clac(100% - 12px);

  background-color: #FF8B48;
`;

const ExitButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 16px;

  color: #FFFFFF;

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const DoumPostcodeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
`;

