import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export default function ImageFocusModal(props) {
  const { size, image, closeFn } = props;

  const ImageRef = useRef();

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // 배경화면 클릭시 //
  function onClickBackground(e) {
    // console.log(e.target.contains(ImageRef.current));

    // if (e.target.contains(ImageRef.current)) closeFn();
    closeFn();
  }

  return (
    <Background onClick={onClickBackground}>
     <ImageWrapperWrapper ref={ImageRef} size={size}>
      {/* <ButtonWrapper>
        <Button onClick={closeFn}>
          <Image src={ExitButton}/>
        </Button>
      </ButtonWrapper> */}
      <Image src={image}/>
     </ImageWrapperWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.8);
`;

const ImageWrapperWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

	width: ${props => props.size}px;
	height: ${props => props.size}px;
  
	border-radius: 12px;

  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  margin: 10px 20px 0px 0px;

  justify-content: center;
  align-items: flex-end;

  width: 100%;

  z-index: 600;
`;

const Button = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: 20px;
	height: 20px;

  :hover {
    cursor: pointer;
  }
`;