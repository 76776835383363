import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


export default function TshirtsSizeBox(props) {
  const { isNormal, value, setValue, index } = props;

  const { t } = useTranslation();

  return (
    <TopLevelWrapper>
      {
        isNormal ?
          <TotalWrapper>
            <InfoBox isSelected={value === 'M'} onClick={() => setValue('M', index)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'M' ? '#FFFFFF' : '#595959'}>M</Text>
            </InfoBox>
            <InfoBox isSelected={value === 'L'} onClick={() => setValue('L', index)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'L' ? '#FFFFFF' : '#595959'}>L</Text>
            </InfoBox>
            <InfoBox isSelected={value === 'XL'} onClick={() => setValue('XL', index)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'XL' ? '#FFFFFF' : '#595959'}>XL</Text>
            </InfoBox>
          </TotalWrapper>
          :
          <TotalWrapper>
            <InfoBox isSelected={value === 'S'} onClick={() => setValue('S', index)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'S' ? '#FFFFFF' : '#595959'}>S</Text>
            </InfoBox>
            <InfoBox isSelected={value === 'M'} onClick={() => setValue('M', index)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'M' ? '#FFFFFF' : '#595959'}>M</Text>
            </InfoBox>
            <InfoBox isSelected={value === 'L'} onClick={() => setValue('L', index)}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={value === 'L' ? '#FFFFFF' : '#595959'}>L</Text>
            </InfoBox>
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: calc(48.39% - 4px);
  width: calc(30% - 4px);
  height: 36px;

  border: ${props => props.isSelected ? '2px solid #6DD49E' : '2px solid #E0E0E0'};
  border-radius: 8px;
  background-color: ${props => props.isSelected ? '#6DD49E' : '#FFFFFF'};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;