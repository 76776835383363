import React from 'react';
import styled from 'styled-components';

import { useTranslation } from "react-i18next";

import VideoIcon from '../../../Image/VideoIcon.png';

export default function FETOnlineMarchRX(props) {
  const { onClickVideo } = props;

  const { t } = useTranslation();

  return (
    <TopLevelWrapper>
      <AthleteBox>
        <AthleteTextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>Amrap 12 minutes</Text>
        </AthleteTextWrapper>
      </AthleteBox>
      <ExerciseWrapper style={{ marginTop: '10px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Walk walk</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>
      <ExerciseWrapper style={{ marginTop: '2px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Chest to bar</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>8</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>
      <ExerciseWrapper style={{ marginTop: '2px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Pistol squat</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>16</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>
      <ExerciseWrapper style={{ marginTop: '2px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Walk walk</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>2</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>
      <ExerciseWrapper style={{ marginTop: '2px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Chest to bar</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>8</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>
      <ExerciseWrapper style={{ marginTop: '2px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Pistol squat</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>16</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>

      <ExerciseWrapper style={{ marginTop: '2px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Walk walk</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>3</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>
      <ExerciseWrapper style={{ marginTop: '2px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Chest to bar</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>8</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>
      <ExerciseWrapper style={{ marginTop: '2px' }}>
        <InfoWrapper>
          <InfoTitleWrapper>
            <ExerciseNumberBoxWrapper>
              <ExerciseNumberBox backgroundColor='#FF8B48'>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
              </ExerciseNumberBox>
              <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Pistol squat</Text>
            </ExerciseNumberBoxWrapper>
            <Image src={VideoIcon} onClick={() => onClickVideo('0yldFSZ7iGA')} />
          </InfoTitleWrapper>
          <InfoDataWrapper style={{ marginTop: '20px' }}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
            <DivisionLine width={63} />
            <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>16</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
          </InfoDataWrapper>
        </InfoWrapper>
      </ExerciseWrapper>
    </TopLevelWrapper>
  )
}


const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: #6DD49E;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  width: calc(89.3% - 24px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 25px 0px 25px 0px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;
  margin: 15px 0px 8px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const EllipsisWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 28px 0px 28px 0px;

  width: 100%;

  gap: 10px;
`;

const Ellipsis = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;

  border-radius: 10px;

  background-color: #D9D9D9;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const BetweenBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 9px 0px 13px 0px;

  width: 100%;
  height: 24px;

  background-color: #6DD49E;
`;

const BetweenTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const AthleteBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
  height: 20px;
`;

const AthleteTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 13px;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 10px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 35px;

  margin-top: 15px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 10px;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;

  border-radius: 10px;
  
  background-color: #FF3131;
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 81.28%;
`;

const PointRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
  // background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const OvertextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
