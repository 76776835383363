import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import { useTranslation } from "react-i18next";
import FetchModule from "../../../Model/Network/FetchModule";
import BelieverSubmitViewModel from "./BelieverSubmitViewModel";
import BelieverSubmitModel from "./BelieverSubmitModel";

export default function BelieverEvent2(props) {
  const { totalVideoWidth, totalVideoHeight, setIsCompleteSubmit } = props;

  const { t } = useTranslation();

  const eventId = 37;

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [completeRep, setCompleteRep] = useState(0);
  const [lastExerciseRep, setLastExerciseRep] = useState(0);

  // const [tieBreakMin, setTieBreakMin] = useState(0);
  // const [tieBreakSec, setTieBreakSec] = useState(0);
  // const [lastExercise, setLastExercise] = useState('');
  // const [lastExerciseRep, setLastExerciseRep] = useState(0);
  
  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState({ value: '완료하였습니다' });

  const [isRx, setIsRx] = useState(true);
  const [isExistRecord, setIsExistRecord] = useState(false);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);

  const [viewIsRx, setViewIsRx] = useState(false);
  const [viewMin, setViewMin] = useState(0);
  const [viewSec, setViewSec] = useState(0);
  const [viewCompleteRep, setViewCompleteRep] = useState(0);
  const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);
  const [viewTieBreakMin, setViewTieBreakMin] = useState(0);
  const [viewTieBreakSec, setViewTieBreakSec] = useState(0);
  // const [viewLastExercise, setViewLastExercise] = useState(0);
  // const [viewLastExerciseRep, setViewLastExerciseRep] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    let requestData = {
      eventId: eventId,
      section: 'B'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {

          // let time = parseInt(responseData.data.time);
          // let decimalPoint = (responseData.data.time % 1).toFixed(2);

          // setViewMin(Math.floor(time / 60));
          // setMin(Math.floor(time / 60));
      
          // if (decimalPoint === '0.00') {
          //   setViewSec(time % 60);
          //   setSec(time % 60);
          // }
          // else {
          //   setViewSec((time % 60) + parseFloat(decimalPoint));
          //   setSec((time % 60) + parseFloat(decimalPoint));
          // }

          // let tieBreakTime = parseInt(responseData.data.tieBreak);
          // let tieBreakDecimalPoint = (responseData.data.tieBreak % 1).toFixed(2);

          // setViewTieBreakMin(Math.floor(tieBreakTime / 60));
          // setTieBreakMin(Math.floor(tieBreakTime / 60));

          // if (tieBreakDecimalPoint === '0.00') {
          //   setViewTieBreakSec((tieBreakTime % 60));
          //   setTieBreakSec((tieBreakTime % 60));
          // }
          // else {
          //   setViewTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
          //   setTieBreakSec((tieBreakTime % 60) + parseFloat(tieBreakDecimalPoint));
          // }

          setIsExistRecord(true);
          setViewIsRx(responseData.data.isRx);
          setCompleteRep(responseData.data.completeRep);
          setViewCompleteRep(responseData.data.completeRep);

          setLastExerciseRep(responseData.data.lastExerciseRep);
          setViewLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewCompleteRound(responseData.data.completeRound);
          // setViewLastExerciseRep(responseData.data.lastExerciseRep);
          // setViewLastExercise(responseData.data.lastExercise);
          setViewLink(responseData.data.link);
          setPicture(responseData.data.picture);
        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'EVENT2RULEBOOK.pdf';

      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/believer', fileName: fileName }));
      // window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://link-picture.s3.ap-northeast-2.amazonaws.com/event/believer/', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {

    // if (moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-11-05T00:00:00').format('YYYY.MM.DD HH:mm:ss')) {
    //   alert('제출 기한이 마감되었습니다.');
    //   return;
    // }

    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {

      // let finalMin = min === '' ? 0 : parseInt(min * 60);
      // let finalSec = sec === '' ? 0 : parseFloat(sec);
      // let finalTieBreakMin = tieBreakMin === '' ? 0 : parseInt(tieBreakMin * 60);
      // let finalTieBreakSec = tieBreakSec === '' ? 0 : parseFloat(tieBreakSec);
      let finalCompleteRep = completeRep === '' ? 0 : completeRep;
      let finalLastExerciseRep = lastExerciseRep === '' ? 0 : lastExerciseRep;

      if (finalLastExerciseRep === 0
        && finalCompleteRep === 0) {
        alert('기록을 입력해주세요');
        return;
      }
      else if ((link !== ''
        && !link.includes('https://youtube.com/shorts/')
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }
      else if (finalLastExerciseRep === 0) {
        alert('TieBreak 을 입력해주세요!');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      let requestData = {
        eventId: eventId,
        section: 'B',
        isRx: true,
        time: 0,
        tieBreak: 0,
        completeRep: finalCompleteRep,
        completeRound: 0,
        lastExercise: '',
        lastExerciseRep: finalLastExerciseRep,
        firstExercise: 0,
        secondExercise: 0,
        thirdExercise: 0,
        bodyWeight: 0,
        point: 0,
        link: link,
        picture: imageString
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);

          if (responseData.status === 200) {
            setIsCompleteSubmit(true);
          }
        }
      )();

      setIsClickedSubmit(false);
    }
    else {
      let requestData = {
        eventId: eventId,
        section: 'B'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().isBefore(moment('2024-05-06T00:00:00')) ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>컴피티션 빌리버 [Believer]</Text>
            <Text style={{ marginTop: '2px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2024.05.06 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 2</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>3 Sets of 3min AMRAP</Text>
              </TitleBox>
            </TitleWrapper>
            <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/pFhg8LFQeJk?si=WbMeZRWUazkIU_kl`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox>
            <WodWrapper>
              <OrangeBoxWrapper>
                {/* <OrangeBox> */}
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FF8B48'>Record : Total reps of T2B, HSPU, PU</Text>
                <Text style={{ marginTop: '16px' }} fontFamily='Poppins-SemiBold' fontSize={14} color='#4F4F4F'>SET 1 00:00 - 03:00</Text>
                {/* </OrangeBox> */}
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 1</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Toes to Bar</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#5BD98A'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 2</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Bar Dead Hang Hold</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#2F80ED'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 3</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Chest Facing Handstand Hold</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>

              <OrangeBoxWrapper>
                {/* <OrangeBox> */}
                <Text style={{ marginTop: '8px' }} fontFamily='Poppins-SemiBold' fontSize={14} color='#4F4F4F'>SET 2 03:00 - 06:00</Text>
                {/* </OrangeBox> */}
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 1</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Handstand Push Up</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#5BD98A'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 2</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Bar Dead Hang Hold</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#2F80ED'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 3</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Bar Dead Hang Hold</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              
              <OrangeBoxWrapper>
                {/* <OrangeBox> */}
                <Text style={{ marginTop: '8px' }} fontFamily='Poppins-SemiBold' fontSize={14} color='#4F4F4F'>SET 3 06:00 - 09:00</Text>
                {/* </OrangeBox> */}
              </OrangeBoxWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 1</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Pull Up</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#5BD98A'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 2</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Bar Dead Hang Hold</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '8px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExercisePlayerBox backgroundColor='#2F80ED'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>Player 3</Text>
                      </ExercisePlayerBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Chest Facing Handstand Hold</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                    <DivisionLine width={64} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MAX</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
            </WodWrapper>
            {
              (isExistRecord || isClickedSubmit) ?
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  {
                    isClickedSubmit ?
                      <BelieverSubmitModel
                        eventId={eventId}
                        section='B'

                        isSuccess={isSuccess}
                        setIsSuccess={setIsSuccess}

                        // min={min}
                        // setMin={setMin}
                        // sec={sec}
                        // setSec={setSec}
                        completeRep={completeRep}
                        setCompleteRep={setCompleteRep}

                        lastExerciseRep={lastExerciseRep}
                        setLastExerciseRep={setLastExerciseRep}

                        // tieBreakMin={tieBreakMin}
                        // setTieBreakMin={setTieBreakMin}
                        // tieBreakSec={tieBreakSec}
                        // setTieBreakSec={setTieBreakSec}

                        link={link}
                        setLink={setLink}
                        imgBase64={imgBase64}
                        setImgBase64={setImgBase64}
                      />
                      :
                      <BelieverSubmitViewModel
                        eventId={eventId}
                        section='B'

                        setIsExistRecord={setIsExistRecord}
                        
                        // min={viewMin}
                        // sec={viewSec}
                        completeRep={viewCompleteRep}
                        lastExerciseRep={viewLastExerciseRep}
                        // tieBreakMin={viewTieBreakMin}
                        // tieBreakSec={viewTieBreakSec}

                        link={viewLink}
                        picture={picture}
                      />
                  }
                </ExerciseWrapper>
                :
                <ExerciseWrapper style={{ marginTop: '40px' }}>
                  <InfoWrapper>
                    <InfoTitleWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                    </InfoTitleWrapper>
                  </InfoWrapper>
                  <ScoreGuideLineImageWrapper onClick={downloadPDF}>
                    <ScoreGuideLineImage src='https://picture.link-zone.org/event/believer/BelieverEvent2RuleBook.jpeg' />
                  </ScoreGuideLineImageWrapper>
                  <RuleBookWrapper>
                    <RuleColumnTextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                    </RuleColumnTextWrapper>
                    {/* <RuleRowTextWrapper style={{ marginTop: '24px' }}>
                      <Point color='#FFB800'/>
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>측정 시 인증 요소</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper style={{ marginTop: '4px' }}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 선수 소개 (영상에 얼굴이 보이게끔 3명의 선수 이름 호명)</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 벽에서 부터 50cm 거리 임을 마스킹 테이프를 활용한 길이 인증</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 풀업바 높이 인증 (풀업바 밑에 서서 팔을 뻗었을 때 손 끝이 닿지 않는 높이여야함. 3명의 선수 모두 각자 메달릴 위치에서 인증)</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '32px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>이벤트 수행</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- P1, P2, P3은 고정 아님. 전략적으로 언제든지 교대가능.</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>단, 3명의 선수 모두 T2B, HSPU, PU 모두 1rep 이상은 수행해야함.</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px'}}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>3명의 선수는 출발선에서 타이머가 울리면</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>[00분 부터 03분 까지]</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>한 명의 선수는 Toes to Bar,</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>한 명의 선수는 Bar Dead Hang Hold,</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>또 한 명의 선수는 Chest Facing Handstand Hold 위치해 동시에 동작을 시작합니다.</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Bar Dead Hang Hold 와 C F HS Hold 동작이 완성 된 이후 부터 Toes to Bar 수행 가능.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Bar Dead Hang Hold 와 C F HS Hold 중 한 가지라도 동작이 무너진 상태로 수행된 Toes to Bar 는 노랩으로 분류.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>동작 Broken 시에 의무적으로 교대할 필요없음.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <TextWrapper style={{ marginTop: '12px'}}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>[03분 부터 06분 까지]</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>한 명의 선수는 Handstand Push Up,</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>두 명의 선수는 Bar Dead Hang Hold</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>두 명의 선수가 Bar Dead Hang Hold 동작이 완성 된 이후 부터 Handstand Push Up 수행 가능.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Bar Dead Hang Hold 동작이 무너진 상태로 수행된 Handstand Push Up 은 노랩으로 분류.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>동작 Broken 시에 의무적으로 교대할 필요없음.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <TextWrapper style={{ marginTop: '12px'}}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>[06분 부터 09분 까지]</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>한 명의 선수는 Pull Up</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>한 명의 선수는 Bar Dead Hang Hold</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>또 한 명의 선수는 Chest Facing Handstand Hold 위치해 동시에 동작을 시작합니다.</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Bar Dead Hang Hold 와 C F HS Hold 동작이 완성 된 이후 부터 Pull Up 수행 가능.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Bar Dead Hang Hold 와 C F HS Hold 중 한 가지라도 동작이 무너진 상태로 수행된 Pull Up 은 노랩으로 분류.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '12px' }}>
                      <NotRoundPoint color='#5BD98A' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>동작 Broken 시에 의무적으로 교대할 필요없음.</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>노랩 기준</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 타이머 Up으로만 가능합니다. (Down - 실격)</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- Bar Dead Hang Hold 와 C F HS Hold 중 한 가지라도 동작이 무너진 상태로 수행된 Toes to Bar, Handstand Push Up, Pull Up 은 노랩 입니다.</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>로잉 인증은 Time cap(시간 제한)시간 기록 외 별도로 측정 마무리 후 진행하셔도 무방 합니다.</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px' }}>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>º Toes to Bar</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 오픈 룰과 동일'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'(손바닥 보호대와 풀업바 테이핑 중 하나만 가능)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 풀업바 밑에서 팔을 뻗었을 때 손끝이 닿지 않는 높이여야함.'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 키핑 아치 - 두 발끝 철봉 뒤로 안넘어가면 노랩'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 양발 동시에 터치(한발씩 따닥 노랩)'}</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px' }}>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>º Handstand Push Up</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 양 손은 76cm 테이플 라인 안에 있어야 함. '}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 벽 to 손 위치 상의 후 결정 (50cm 라인)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 시작 시 팔을 완전히 편 상태에서 몸이 일자로 정렬, 양발만 벽에 터치되어 있어야함.'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 하강 시 머리가 땅에 닿아야 함. (플레이트와 ab매트 사용가능하나, 세팅 되어 있을 때 플레이트의 높이가 ab매트의 높이보다 높거나 같아야함. 또한 라인이 명확하게 보여야 함. 카메라 구도 상 명확히 보이지 않을 시 페널티)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 발을 움직임이 지속되는 동안 벽과 계속 접촉되어 있을 필요는 없지만 각 동작의 시작과 끝에서 발은 벽에 닿아야 함.'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 키핑, 스트릭 모두 허용'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 동작의 완성 시에 양 발의 넓이는 양 손의 넓이보다 넓으면 안됨. 넓으면 노랩)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 다시 시작 자세로 돌아와야 함.'}</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px' }}>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>º Pull Up</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 오픈 룰과 동일 (손바닥 보호대와 풀업바 테이핑 중 하나만 가능)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 풀업바 밑에서 팔을 뻗었을 때 손끝이 닿지 않는 높이여야함.'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 메달렸을 때 팔이 완전히 신전된 상태에서 바닥으로 부터 발이 떨어져있는 상태에서 시작'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 턱이 분명하게 풀업바의 수평선 위를 넘어야 함.'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 어떤 형태의 그립이든 상관 없음.'}</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px' }}>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>º Bar Dead Hang</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 풀업바 밑에서 팔을 뻗었을 때 손끝이 닿지 않는 높이여야함.'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 메달렸을 때 팔이 완전히 신전된 상태에서 바닥으로 부터 발이 떨어져있는 상태여야 함.'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 어떤 형태의 그립이든 상관 없음. (or 언더그립만 허용)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 맨손만 가능'}</Text>
                    </TextWrapper>

                    <TextWrapper style={{ marginTop: '12px' }}>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>º Chest Facing Handstand Hold</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> 벽에서부터 동작 정면 방향의 50cm 거리에 손 위치 하지 못하면 노랩. (인증 된 마스킹 테이프에 손가락이 닿으면 동작 인정)'}</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>{'-> C F HS Hold 동작 완성 시, 발 외(무릎과 허벅지 등) 신체부위가 벽에 닿지 않아야 동작으로 인정됩니다.'}</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>실격 기준</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>- 영상은 무조건 원테이크로 촬영되어야 함. (영상이 편집 되거나, 공정한 기록 측정에 영향이 가는 재가공은 실격)</Text>
                    </TextWrapper>

                    <RuleRowTextWrapper style={{ marginTop: '16px' }}>
                      <Point color='#FF3131' />
                      <TextWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>기록 / Tie Break</Text>
                      </TextWrapper>
                    </RuleRowTextWrapper>
                    <TextWrapper>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>기록 기준 : 토투바, 핸푸, 풀업 갯수의 총합</Text>
                      <Text style={{ marginTop: '3px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#4F4F4F'>Tie Break 기록 기준 : 토투바, 핸푸 갯수 총합</Text>
                    </TextWrapper> */}

                  </RuleBookWrapper>
                </ExerciseWrapper>
            }
            {
              moment().isBefore(moment('2024-05-13T00:00:00')) &&
              <RankingButton onClick={onClickRecordAndSubmit}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>제출하기</Text>
              </RankingButton>
            }
            {/* {
            moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-04-30T23:59:59').format('YYYY.MM.DD HH:mm:ss') ?
              <RankingButton>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
              </RankingButton>
              :
              <RankingButton>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'></Text>
              </RankingButton>
          } */}
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const OrangeBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const OrangeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  height: 18px;

  border-radius: 2px;
  background-color: #FF8B48;
`;

const TieBreakSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 75px;
  height: 32px;

  border-radius: 6px;
  background-color: #FFB800;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // padding: 0px 12px 0px 12px;
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 0px 0px;

  // width: calc(90.33% - 69px);
  width: calc(100% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
`;

const MissionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 1px 9px 1px 9px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
  height: 27px;

  background-color: #FF3131;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const ExercisePlayerBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 59px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 90.33%;
  // width: 100%;
  height: auto;

  border-radius: 8px;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // padding-bottom: 49px;

  margin-top: 21px;
  margin-bottom: 130px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  width: 10px;
  height: 10px;
  
  border-radius: 10px;
  background-color: ${props => props.color};
`;

const NotRoundPoint = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 5px 10px 0px 0px;

  min-width: 10px;
  min-height: 10px;
  
  background-color: ${props => props.color};
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin: 25px 0px 25px 0px;
  margin-top: 25px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;