import React from 'react';
import styled from 'styled-components';

export default function AdminPage() {

  return (
    <TopLevelWrapper>
      <ComponentWrapper>

      </ComponentWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: #C9C9C9;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
  max-width: 560px;

  height: 600px;
  max-height: 70%;

  border-radius: 12px;
  background-color: #FFFFFF;
`;