import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../Model/Network/FetchModule';

export default function PaymentCompletePage() {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    var status = params.status;

    setIsSuccess(status === 'success');

    // if (status === 'success') {
    //   var paymentKey = new URLSearchParams(window.location.search).get("paymentKey");
    //   var orderId = new URLSearchParams(window.location.search).get("orderId");
    //   var amount = new URLSearchParams(window.location.search).get("amount");

    //   var requestData = {
    //     paymentKey: paymentKey,
    //     merchant_uid: orderId,
    //     price: amount
    //   }

    //   const fetchModule = new FetchModule();
    //   (
    //     async () => {
    //       const responseData = await fetchModule.postDataOfEvent('sales/tosspayments', 'POST', requestData);

    //       if (responseData.status === 200) {

    //         // var eventData = JSON.parse(window.localStorage.getItem('EventData'));
    //         // var memberData = JSON.parse(window.localStorage.getItem('EventMemberData'));

    //         // if (eventData.selectedEvent.isTeamFight) {
    //         //   var teamData = JSON.parse(window.localStorage.getItem('EventTeamData'));

    //         //   var members = [];

    //         //   for (var i = 0; i < memberData.length; i++) {
    //         //     members.push({
    //         //       teamId: 0,
    //         //       eventId: eventData.selectedEvent.eventId,
    //         //       name: memberData[i].name,
    //         //       email: memberData[i].email === '' ? 'None' : memberData[i].email,
    //         //       birth: memberData[i].birth,
    //         //       bloodType: memberData[i].bloodType,
    //         //       phone: memberData[i].phone,
    //         //       zipCode: memberData[i].zipCode === '' ? 'None' : memberData[i].zipCode,
    //         //       address: memberData[i].address === '' ? 'None' : memberData[i].address,
    //         //       addressDetail: memberData[i].addressDetail === '' ? 'None' : memberData[i].addressDetail,
    //         //       division: memberData[i].division,
    //         //       gender: memberData[i].gender
    //         //     })
    //         //   }

    //         //   var teamData = {
    //         //     eventId: eventData.selectedEvent.eventId,
    //         //     teamName: teamData.teamName,
    //         //     profile: teamData.teamLogo,
    //         //     isAdmin: false,
    //         //     members: members
    //         //   }

    //         //   const teamResponseData = await fetchModule.postDataOfEvent('team/save', 'POST', teamData);

    //         //   if (teamResponseData.status === 200) {
    //         //     window.localStorage.removeItem('EventData');
    //         //     window.localStorage.removeItem('EventMemberData');
    //         //     window.localStorage.removeItem('EventTeamData');
    //         //   }
    //         // }
    //         // else {

    //         //   var member = {
    //         //     teamId: 0,
    //         //     eventId: eventData.selectedEvent.eventId,
    //         //     name: memberData[0].name,
    //         //     email: memberData[0].email === '' ? 'None' : memberData[0].email,
    //         //     birth: memberData[0].birth,
    //         //     bloodType: memberData[0].bloodType,
    //         //     phone: memberData[0].phone,
    //         //     zipCode: memberData[0].zipCode === '' ? 'None' : memberData[0].zipCode,
    //         //     address: memberData[0].address === '' ? 'None' : memberData[0].address,
    //         //     addressDetail: memberData[0].addressDetail === '' ? 'None' : memberData[0].addressDetail,
    //         //     division: memberData[0].division,
    //         //     gender: memberData[0].gender
    //         //   };
              
    //         //   const memberResponseData = await fetchModule.postDataOfEvent('member/save', 'POST', member);

    //         //   // console.log(responseData);
    //         //   if (memberResponseData.status === 200) {
    //         //     window.localStorage.removeItem('EventData');
    //         //     window.localStorage.removeItem('EventMemberData');
    //         //     window.localStorage.removeItem('EventTeamData');
    //         //   }

    //         // }
    //       }
    //     }
    //   )();
    // }
  }, [])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);

      window.open("about:blank", "_self");
      window.close();
    }
  }, [])

  // 페이지 이름 변경내역 업로드 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('신청 완료'), isAvailableGoback: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, []);

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    var responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
  }

  // 메인화면으로 버튼 클릭시 //
  function onClickGoMainButton() {
    // try {
    //   window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
    // }
    // catch (e) {
    //   console.log("Web Message Post Error : " + e);
    // }

    window.location.href = "linkcoach://tabview/";
    // window.open("about:blank", "_self");
    // window.close();
  }

  return (
    <TopLevelWrapper>
      <MessageBox isSuccess={isSuccess}>
        {
          isSuccess ?
            // <TextWrapper marginTop={53}>
            <TextWrapper marginTop={30}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('결제 및 등록신청이 완료되었습니다')}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>Complete</Text>
            </TextWrapper>
            :
            <TextWrapper marginTop={40}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('결제 및 등록신청에 실패하였습니다')}</Text>
              <Text style={{ marginTop: `4px` }} fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('잠시 후 다시 시도해주세요')}</Text>
            </TextWrapper>
        }
        <GoMainButton marginTop={isSuccess ? 53 : 39} onClick={onClickGoMainButton}>
          {/* <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('닫기')}</Text> */}
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>GO</Text>
        </GoMainButton>
      </MessageBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // height: 100%;
  height: 100vh;

  background-color: #F9F9F9;
`;

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
  height: 202px;

  border-radius: 12px;
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.marginTop}px;
`;

const GoMainButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.marginTop}px;

  width: 89.3%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;