import React, { useEffect } from "react";
import XLSX from 'sheetjs-style';

import FetchModule from "../../Model/Network/FetchModule";

export default function BelieverGetParticipantListPage() {
  
  useEffect (() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('tournament/participant/' + 19, 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {

          let dates = responseData.data.slice();
          let finalList = [];

          for (let i = 0; i < dates.length; i++) {
            
            let salesList = '';
            let address = dates[i].members[0].address + ' ' + dates[i].members[0].addressDetail + ' ' + dates[i].members[0].zipCode;
            
            for (let j = 0; j < dates[i].salesDetailSet.length; j++) {
              if (dates[i].salesDetailSet[j].itemName === '참가비') continue;

              if (j !== 0) salesList += '\n';
              if (dates[i].salesDetailSet[j].count === 1) salesList += dates[i].salesDetailSet[j].itemName;
              else salesList += dates[i].salesDetailSet[j].itemName + ' - ' + dates[i].salesDetailSet[j].count + ' 개';
            }
            
            finalList.push({
              teamId: dates[i].teamId,
              createdDate: dates[i].createdDate,
              profile: dates[i].profile,
              teamName: dates[i].teamName,
              address: address,
              memberName1: dates[i].members[0].name,
              memberPhone1: dates[i].members[0].phone,
              memberBelong1: dates[i].members[0].belong,
              memberName2: dates[i].members[1].name,
              memberPhone2: dates[i].members[1].phone,
              memberBelong2: dates[i].members[1].belong,
              memberName3: dates[i].members[2].name,
              memberPhone3: dates[i].members[2].phone,
              memberBelong3: dates[i].members[2].belong,
              merchantUid: dates[i].merchantUid,
              totalPrice: dates[i].totalPrice,
              salesList: salesList
            })
          }
          // console.log(finalList);
          const ws = await XLSX.utils.json_to_sheet(finalList);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Member")
          XLSX.writeFile(wb, 'believer가입자명단.xlsx');
        }
      }
    )();

    // const fetchModule = new FetchModule();
    // (
    //   async () => {
    //     const responseData = await fetchModule.getDataOfZoneVer2('member/attendance/status', 'GET');
    //     console.log(responseData.data);
    //     if (responseData.status === 200) {

    //       let dates = responseData.data.slice();

    //       // dates.sort((a, b) => a.memberId - b.memberId ||  b.reserveCount - a.reserveCount);
    //       dates.sort((a, b) => b.reserveCount - a.reserveCount);

    //       const ws = await XLSX.utils.json_to_sheet(dates);
    //       const wb = XLSX.utils.book_new();
    //       XLSX.utils.book_append_sheet(wb, ws, "Member")
    //       XLSX.writeFile(wb, 'CrossfitGrit_7일간_출석현황.xlsx');
    //     }
    //   }
    // )();
  }, [])

  return <></>;
}