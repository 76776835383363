import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../../Image/WaitIcon.png';
import VideoIcon from '../../../Image/VideoIcon.png';
import { useTranslation } from "react-i18next";
import SubmitModel from "./SubmitModel";
import FetchModule from "../../Network/FetchModule";
import SubmitViewModel from "./SubmitViewModel";

export default function FETOnlineAPRIL_BSection(props) {
  const { setIsCompleteSubmit, totalVideoWidth, totalVideoHeight, onClickVideo, onClickImageFocus } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [firstExercise, setFirstExercise] = useState(0);
  const [secondExercise, setSecondExercise] = useState(0);
  const [thirdExercise, setThirdExercise] = useState(0);
  const [bodyWeight, setBodyWeight] = useState(0);
  const [point, setPoint] = useState(0);
  const [link, setLink] = useState('');
  const [imgBase64, setImgBase64] = useState([]);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);

  const [isRx, setIsRx] = useState(true);
  const [isExistRecord, setIsExistRecord] = useState(false);

  const [viewIsRx, setViewIsRx] = useState(true);
  const [viewFirstExercise, setViewFirstExercise] = useState(0);
  const [viewSecondExercise, setViewSecondExercise] = useState(0);
  const [viewThirdExercise, setViewThirdExercise] = useState(0);
  const [viewBodyWeight, setViewBodyWeight] = useState(0);
  const [viewPoint, setViewPoint] = useState(0);

  const [viewLink, setViewLink] = useState('');
  const [picture, setPicture] = useState('');

  // 가장 최근기록 가져오기 //
  useEffect(() => {
    var requestData = {
      eventId: 8,
      section: 'B'
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('record/recently', 'POST', requestData);
        // console.log(responseData);
        // alert(responseData.status);
        if (responseData.status === 200) {
          setIsExistRecord(true);
          // setViewIsRx(responseData.data.isRx);
          setFirstExercise(responseData.data.firstExercise);
          setSecondExercise(responseData.data.secondExercise);
          setThirdExercise(responseData.data.thirdExercise);
          setBodyWeight(responseData.data.bodyWeight);
          setPoint(responseData.data.point);

          setViewFirstExercise(responseData.data.firstExercise);
          setViewSecondExercise(responseData.data.secondExercise);
          setViewThirdExercise(responseData.data.thirdExercise);
          setViewBodyWeight(responseData.data.bodyWeight);
          setViewPoint(responseData.data.point);
          setViewLink(responseData.data.link);
          setPicture(responseData.data.picture);
        }
        else if (responseData.status === 201) {
          setIsExistRecord(false);
        }
      }
    )();
  }, [])



  // 순위표 버튼 클릭시 //
  function onClickRankingButton() {
    navigate('/rank/8');
  }

  // 제출하기 클릭시 //
  function onClickRecordAndSubmit() {

    // 제출하기 상태일 경우 //
    if (isClickedSubmit) {
      if (firstExercise === '') {
        alert('Shoulder Press의 기록을 정확히 입력해주세요');
        return;
      }
      else if (secondExercise === '') {
        alert('Back Squat의 기록을 정확히 입력해주세요');
        return;
      }
      else if (thirdExercise === '') {
        alert('Deadlift의 기록을 정확히 입력해주세요');
        return;
      }
      else if ((link !== ''
        && !link.includes('https://www.youtube.com/watch?v=')
        && !link.includes('https://m.youtube.com/watch?v=')
        && !link.includes('https://youtube.com/watch?v=')
        && !link.includes('https://youtu.be/'))) {
        alert('영상링크를 정확히 입력해주세요');
        return;
      }

      let imageString = '';

      for (let i = 0; i < imgBase64.length; i++) {
        if (i === 0) imageString = imgBase64[i];
        else imageString = imageString + '<-@->' + imgBase64[i];
      }

      var requestData = {
        eventId: 8,
        section: 'B',
        isRx: true,
        time: 0,
        completeRep: 0,
        completeRound: 0,
        lastExercise: 0,
        lastExerciseRep: 0,
        firstExercise: firstExercise,
        secondExercise: secondExercise,
        thirdExercise: thirdExercise,
        bodyWeight: bodyWeight,
        point: point,
        link: link,
        picture: imageString
      }
      // console.log(requestData);

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/save', 'POST', requestData);

          if (responseData.status === 200) {
            setIsCompleteSubmit(true);
          }
        }
      )();

      setIsClickedSubmit(false);
    }
    else {
      var requestData = {
        eventId: 8,
        section: 'B'
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('record/check', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            setIsExistRecord(false);
            setIsClickedSubmit(true);
          }
          else if (responseData.status === 201) {
            if (window.confirm(t("이전 저장내역이 존재합니다\n계속 진행하시면 이전 데이터는 삭제됩니다"))) {
              setIsExistRecord(false);
              setIsClickedSubmit(true);
            }
          }
        }
      )();
    }
  }

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/FETOnline', fileName: '[FET_LinkCoach]final_ScorecardOQEvent4.pdf' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  return (
    <TopLevelWrapper>
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment('2023-04-01T00:00:00').format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>[FET] Online APRIL</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2023.04.01 Open</Text>
          </WaitWrapper>
          :
        <TotalWrapper>
          <TitleWrapper>
            <SectionBox>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>B</Text>
            </SectionBox>
            <TitleBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>CrossFit Total</Text>
            </TitleBox>
          </TitleWrapper>
          {
            (!isExistRecord && !isClickedSubmit) &&
            <TotalVideoBox width={totalVideoWidth} height={totalVideoHeight}>
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/tO3wFHvOKhQ`}
                style={{ borderRadius: '12px' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </TotalVideoBox>
          }
          {/* <DifficultySelectWrapper>
            <DifficultySelectRX onClick={() => setIsRx(true)} isClicked={isRx}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>Rx</Text>
            </DifficultySelectRX>
            <DifficultySelectScale onClick={() => setIsRx(false)} isClicked={!isRx}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>Scale</Text>
            </DifficultySelectScale>
          </DifficultySelectWrapper> */}
          <AthleteBox>
            <AthleteTextWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF8B48'>Amrap 12 minutes</Text>
            </AthleteTextWrapper>
          </AthleteBox>
          <ExerciseWrapper style={{ marginTop: '10px' }}>
            <InfoWrapper>
              <InfoTitleWrapper>
                <ExerciseNumberBoxWrapper>
                  <ExerciseNumberBox backgroundColor='#FF8B48'>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                  </ExerciseNumberBox>
                  <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Shoulder Press</Text>
                </ExerciseNumberBoxWrapper>
                <Image src={VideoIcon} onClick={() => onClickVideo('tO3wFHvOKhQ')} />
              </InfoTitleWrapper>
              <InfoDataWrapper style={{ marginTop: '20px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                <DivisionLine width={63} />
                <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>RM</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MAX</Text>
                </UnitWrapper>
              </InfoDataWrapper>
              <InfoDataWrapper style={{ marginTop: '16px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                <DivisionLine width={53} />
                <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>00:00 - 04:00</Text>
              </InfoDataWrapper>
            </InfoWrapper>
          </ExerciseWrapper>
          <ExerciseWrapper style={{ marginTop: '2px' }}>
            <InfoWrapper>
              <InfoTitleWrapper>
                <ExerciseNumberBoxWrapper>
                  <ExerciseNumberBox backgroundColor='#FF8B48'>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                  </ExerciseNumberBox>
                  <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Back Squat</Text>
                </ExerciseNumberBoxWrapper>
                <Image src={VideoIcon} onClick={() => onClickVideo('tO3wFHvOKhQ')} />
              </InfoTitleWrapper>
              <InfoDataWrapper style={{ marginTop: '20px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('무게')}</Text>
                <DivisionLine width={63} />
                <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>RM</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MAX</Text>
                </UnitWrapper>
              </InfoDataWrapper>
              <InfoDataWrapper style={{ marginTop: '16px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                <DivisionLine width={53} />
                <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>04:00 - 08:00</Text>
              </InfoDataWrapper>
            </InfoWrapper>
          </ExerciseWrapper>
          <ExerciseWrapper style={{ marginTop: '2px' }}>
            <InfoWrapper>
              <InfoTitleWrapper>
                <ExerciseNumberBoxWrapper>
                  <ExerciseNumberBox backgroundColor='#FF8B48'>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>03</Text>
                  </ExerciseNumberBox>
                  <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Deadlift</Text>
                </ExerciseNumberBoxWrapper>
                <Image src={VideoIcon} onClick={() => onClickVideo('tO3wFHvOKhQ')} />
              </InfoTitleWrapper>
              <InfoDataWrapper style={{ marginTop: '20px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                <DivisionLine width={63} />
                <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>1</Text>
                <UnitWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>RM</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MAX</Text>
                </UnitWrapper>
              </InfoDataWrapper>
              <InfoDataWrapper style={{ marginTop: '16px' }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('시간')}</Text>
                <DivisionLine width={53} />
                <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>08:00 - 12:00</Text>
              </InfoDataWrapper>
            </InfoWrapper>
          </ExerciseWrapper>

          {
            (isExistRecord || isClickedSubmit) ?
              <ExerciseWrapper>
                {
                  isClickedSubmit ?
                    <SubmitModel
                      eventId={8}
                      section='B'

                      firstExercise={firstExercise}
                      setFirstExercise={setFirstExercise}
                      secondExercise={secondExercise}
                      setSecondExercise={setSecondExercise}
                      thirdExercise={thirdExercise}
                      setThirdExercise={setThirdExercise}
                      bodyWeight={bodyWeight}
                      setBodyWeight={setBodyWeight}
                      point={point}
                      setPoint={setPoint}
                      link={link}
                      setLink={setLink}
                      imgBase64={imgBase64}
                      setImgBase64={setImgBase64}
                    />
                    :
                    <SubmitViewModel
                      eventId={8}
                      section='B'

                      firstExercise={viewFirstExercise}
                      secondExercise={viewSecondExercise}
                      thirdExercise={viewThirdExercise}
                      bodyWeight={viewBodyWeight}
                      point={viewPoint}

                      setIsExistRecord={setIsExistRecord}

                      link={viewLink}
                      picture={picture} />
                }
              </ExerciseWrapper>
              :
              <ExerciseWrapper>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333'>RULE BOOK</Text>
                  </InfoTitleWrapper>
                </InfoWrapper>
                <ScoreGuideLineImageWrapper size={totalVideoWidth} onClick={downloadPDF}>
                  <ScoreGuideLineImage src='https://picture.link-zone.org/event/FETOnline/FAREvent4B.png' />
                </ScoreGuideLineImageWrapper>
                <RuleBookWrapper>
                  <RuleColumnTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('상세한 내용은 반드시 [스코어카드 / 동작기준]을')}</Text>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('다운로드 / 확인 하여 확인하시기 바랍니다.')}</Text>
                  </RuleColumnTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `21px` }}>
                    <Point />
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('기록 산정 방식')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('(shoulder press + back squat + deadlift) - (선수 몸무게 x 1.5)')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `15px` }}>
                    <Point />
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('이벤트 수행전 몸무게를 인증하여야합니다')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ alignItems: 'flex-start', marginTop: `15px` }}>
                    <Point style={{ marginTop: '7px' }} />
                    <OvertextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('몸무게 환산 (kg을 lb로 환산시) : 소수점은 반올림합니다.')}</Text>
                    </OvertextWrapper>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `15px` }}>
                    <Point />
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('바벨은 한개만 사용해야 합니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `15px` }}>
                    <Point />
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('바벨 세팅 선수가 외부의 도움없이 직접 해야합니다.')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ marginTop: `5px`, marginLeft: `20px`, width: `calc(100% - 20px)` }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('(실패 후 리셋의 경우도 마찬가지)')}</Text>
                  </RuleTextWrapper>
                  <RuleTextWrapper style={{ alignItems: 'flex-start', marginTop: `15px` }}>
                    <Point style={{ marginTop: '7px' }} />
                    <OvertextWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{t('그 외 동작기준은 CrossFit 2023 Judge Course 를 따른다.')}</Text>
                    </OvertextWrapper>
                  </RuleTextWrapper>
                </RuleBookWrapper>
              </ExerciseWrapper>
          }
          {
            moment().format('YYYY.MM.DD HH:mm:ss') > moment('2023-04-30T23:59:59').format('YYYY.MM.DD HH:mm:ss') ?
              <RankingButton onClick={onClickRankingButton}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('순위표')}</Text>
              </RankingButton>
              :
              <RankingButton onClick={onClickRecordAndSubmit}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{isExistRecord ? t('수정하기') : isClickedSubmit ? t('제출하기') : t('기록하기')}</Text>
              </RankingButton>
          }
        </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const DifficultySelectWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // margin-top: 25px;
  
  width: 90.33%;
`;

const DifficultySelectRX = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 37px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? '#FF3131' : '#E0E0E0'};
  transition: all 0.3s ease-in-out;
`;

const DifficultySelectScale = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 37px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? '#4161AF' : '#E0E0E0'};
  transition: all 0.3s ease-in-out;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 32px;
  height: 32px;

  border-radius: 8px;
  background-color: #6DD49E;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  width: calc(89.3% - 24px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TotalVideoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 25px 0px 25px 0px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;
  margin: 15px 0px 8px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const UnitWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 3px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RestBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 9px 0px 13px 0px;

  width: 100%;
  height: 24px;

  background-color: #6DD49E;
`;

const RestTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const AthleteBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: 100%;
  height: 20px;
`;

const AthleteTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 13px;

  // width: ${props => props.size}px;
  // height: ${props => props.size}px;
  width: 90.33%;
  height: auto;

  border-radius: 10px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 34px 0px 34px 0px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
  // background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;


const RuleBookWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 35px;

  margin-top: 15px;

  width: 90.33%;
`;

const RuleColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const RuleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 10px;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;

  border-radius: 10px;
  
  background-color: #FF3131;
`;

const OvertextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
