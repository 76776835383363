import React from "react";
import styled from "styled-components";
import moment from "moment";

import WaitIcon from '../../Image/WaitIcon.png';
import { useTranslation } from "react-i18next";

export default function FinalScaleEvent3() {

  const { t } = useTranslation();

  // 체점 기준표 다운로드 //
  function downloadPDF() {
    try {
      let fileName = 'Event3_Scale_SUFF_Offline.pdf';

      window.ReactNativeWebView.postMessage(JSON.stringify({ downloadURL: 'https://picture.link-zone.org/event/SUFF/Final', fileName: fileName }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }
  
  return (
    <TopLevelWrapper>
      {
        moment().format('YYYY.MM.DD HH:mm:ss') < moment('2022-06-26T00:00:00').format('YYYY.MM.DD HH:mm:ss') ?
          <WaitWrapper>
            <WaitImage src={WaitIcon} />
            <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SUFF Final Scale Event</Text>
            <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>2023.06.26 Open</Text>
          </WaitWrapper>
          :
          <TotalWrapper>
            <TitleWrapper>
              <SectionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 3</Text>
              </SectionBox>
              <TitleBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>6 Round For Time</Text>
              </TitleBox>
            </TitleWrapper>
            <WodWrapper>
              <WodTextWrapper>
                <WodRoundBox style={{ padding: '0px 9px 0px 9px', backgroundColor: '#4161AF' }}>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF'>B:M-M-F Pair</Text>
                </WodRoundBox>
              </WodTextWrapper>
              <ExerciseWrapper style={{ marginTop: '12px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>01</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Pull-up (2) Rope Hold (1)</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>10</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
              <ExerciseWrapper style={{ marginTop: '12px' }}>
                <InfoWrapper>
                  <InfoTitleWrapper>
                    <ExerciseNumberBoxWrapper>
                      <ExerciseNumberBox backgroundColor='#FF8B48'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>02</Text>
                      </ExerciseNumberBox>
                      <Text style={{ marginTop: '-2px' }} fontFamily='Poppins-SemiBold' fontSize={12} color='#4F4F4F'>Synchro Burpee Box jump over</Text>
                    </ExerciseNumberBoxWrapper>
                  </InfoTitleWrapper>
                  <InfoDataWrapper style={{ marginTop: '20px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('높이')}</Text>
                    <DivisionLine width={60} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>20/24</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>Inch</Text>
                  </InfoDataWrapper>
                  <InfoDataWrapper style={{ marginTop: '15px' }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('횟수')}</Text>
                    <DivisionLine width={68} />
                    <Text style={{ marginRight: '5px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>12</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>REPS</Text>
                  </InfoDataWrapper>
                </InfoWrapper>
              </ExerciseWrapper>
            </WodWrapper>
            <ScoreGuideLineImageWrapper onClick={downloadPDF}>
              <ScoreGuideLineImage src='https://picture.link-zone.org/event/SUFF/FinalSUFFDay2RuleBook.png' />
            </ScoreGuideLineImageWrapper>
          </TotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 79vh;
  // background-color: red;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 12px 0px 12px;
  margin: 0px 0px 0px 0px;

  // width: calc(89.3% - 69px);
  width: calc(90.33% - 69px);
  height: 32px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const ExerciseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 0px 10px 0px;
  // margin: 15px 0px 8px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const WodWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;

const WodTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const WodRoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 0px;

  height: 18px;

  border-radius: 2px;
  background-color: #6DD49E;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExerciseNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ExerciseNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0 0 0 0;
  margin-right: 8px;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;

const InfoDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: ${props => props.width}%;
  height: 1px;

  margin: 0px 12px 0px 12px;

  background-color: #E0E0E0;
`;

const ScoreGuideLineImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 18px;
  margin-bottom: 130px;

  // width: 90.33%;
  width: 100%;
  height: auto;

  // border-radius: 10px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ScoreGuideLineImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;
