import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FetchModule from '../../Model/Network/FetchModule';

export default function RecordSubmitModalForAdmin(props) {
  const { clickData, closeFn } = props;

  const [eventSelect, setEventSelect] = useState('A');
  const [firstMission, setFirstMission] = useState(0);
  const [secondMission, setSecondMission] = useState(0);
  const [thirdMissionTime, setThirdMissionTime] = useState(0);
  const [thirdMissionReps, setThirdMissionReps] = useState(0);
  const [weight, setWeight] = useState(0);
  const [tieBreak, setTieBreak] = useState(0);
  const [time, setTime] = useState(0);
  const [reps, setReps] = useState(0);
  const [distance, setDistance] = useState(0);

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  function calcSports() {
    if (clickData === undefined) return;

    if (clickData.teamId === undefined) {
      if (clickData.gender === 'male') return '종목 : Individual M';
      else return '종목 : Individual W';
    }
    else {
      if (clickData.eventId === 21) return '종목 : Scale Team';
      else return '종목 : Rxd Team';
    }
  }

  function handleFirstMission(e) {
    setFirstMission(e.target.value);
    setSecondMission(0);
    setThirdMissionTime(0);
    setThirdMissionReps(0);
  }

  function handleSecondMission(e) {
    setSecondMission(e.target.value);
    setFirstMission(0);
    setThirdMissionTime(0);
    setThirdMissionReps(0);
  }

  function handleThirdMissionReps(e) {
    setThirdMissionReps(e.target.value);
    setFirstMission(0);
    setSecondMission(0);
  }

  function handleThirdMissionTime(e) {
    setThirdMissionTime(e.target.value);
    setFirstMission(0);
    setSecondMission(0);
  }

  function handleWeight(e) {
    setWeight(e.target.value);
  }

  function handleTieBreak(e) {
    setTieBreak(e.target.value);
  }

  function handleTime(e) {
    setTime(e.target.value);
  }

  function handleReps(e) {
    setReps(e.target.value);
  }

  function handleDistance(e) {
    setDistance(e.target.value);
  }

  function onClickSubmit() {
    // // 개인전인경우 //
    // if (clickData.teamId === undefined) {

    // }

    let requestData = {
      completeRep: 0,
      completeRound: 0,
      eventId: clickData.eventId === undefined ? 19 : clickData.eventId,
      firstExercise: 0,
      secondExercise: 0,
      thirdExercise: 0,
      lastExercise: '',
      lastExerciseRep: 0,
      link: '',
      picture: '',
      section: '1',
      time: 0,
      userId: clickData.userId === undefined ? '' : clickData.userId,
      distance: 0,
      isRx: true,
      tieBreak: 0,
      bodyWeight: 0,
      point: 0,
      teamId: clickData.teamId
    }

    if (eventSelect === 'A') {
      if (firstMission !== 0) {
        // requestData.tieBreak = parseFloat(firstMission);

        let newTime = 0;

        let min = firstMission.split(':')[0];
        let sec = firstMission.split(':')[1].split('.')[0];
        let point = firstMission.split(':')[1].split('.')[1];

        if (point === undefined) newTime = (parseInt(min) * 60) + (parseInt(sec));
        else newTime = (parseInt(min) * 60) + (parseInt(sec)) + '.' + point;

        requestData.tieBreak = newTime;
        requestData.section = 'A1';
      }
      else if (secondMission !== 0) {
        // requestData.time = secondMission;
        let newTime = 0;

        let min = secondMission.split(':')[0];
        let sec = secondMission.split(':')[1].split('.')[0];
        let point = secondMission.split(':')[1].split('.')[1];

        if (point === undefined) newTime = (parseInt(min) * 60) + (parseInt(sec));
        else newTime = (parseInt(min) * 60) + (parseInt(sec)) + '.' + point;

        requestData.time = newTime;
        requestData.section = 'A2';
      }
      else {
        // requestData.time = thirdMissionTime;
        let newTime = 0;

        if (thirdMissionTime !== 0) {
          let min = thirdMissionTime.split(':')[0];
          let sec = thirdMissionTime.split(':')[1].split('.')[0];
          let point = thirdMissionTime.split(':')[1].split('.')[1];
  
          if (point === undefined) newTime = (parseInt(min) * 60) + (parseInt(sec));
          else newTime = (parseInt(min) * 60) + (parseInt(sec)) + '.' + point;
        }

        requestData.time = newTime;
        requestData.completeRep = thirdMissionReps;
        requestData.section = 'A3';
      }
    }
    else if (eventSelect === 'B') {
      let newTime = 0;

      if (tieBreak !== 0) {
        let min = tieBreak.split(':')[0];
        let sec = tieBreak.split(':')[1].split('.')[0];
        let point = tieBreak.split(':')[1].split('.')[1];
  
        if (point === undefined) newTime = (parseInt(min) * 60) + (parseInt(sec));
        else newTime = (parseInt(min) * 60) + (parseInt(sec)) + '.' + point;
      }

      requestData.tieBreak = newTime;
      requestData.firstExercise = weight;
      // requestData.tieBreak = tieBreak;
      requestData.section = 'B';
    }
    else if (eventSelect === 'C') {
      let newTime = 0;

      if (time !== 0) {
        let min = time.split(':')[0];
        let sec = time.split(':')[1].split('.')[0];
        let point = time.split(':')[1].split('.')[1];

        if (point === undefined) newTime = (parseInt(min) * 60) + (parseInt(sec));
        else newTime = (parseInt(min) * 60) + (parseInt(sec)) + '.' + point;
      }

      // console.log(newTime);
      // requestData.time = time;
      requestData.time = newTime;
      requestData.completeRep = reps;
      requestData.section = 'C';
    }
    else if (eventSelect === 'D') {
      // requestData.time = time;
      let newTime = 0;

      if (time !== 0) {
        let min = time.split(':')[0];
        let sec = time.split(':')[1].split('.')[0];
        let point = time.split(':')[1].split('.')[1];
  
        if (point === undefined) newTime = (parseInt(min) * 60) + (parseInt(sec));
        else newTime = (parseInt(min) * 60) + (parseInt(sec)) + '.' + point;
      }

      requestData.time = newTime;
      requestData.completeRep = reps;
      requestData.section = 'D';
    }
    else if (eventSelect === 'E') {
      // requestData.time = time;
      requestData.distance = distance;
      requestData.section = 'E';
    }
    else if (eventSelect === 'F') {
      if (clickData.gender === 'male') {
        requestData.completeRep = reps;
        requestData.time = 0;
      }
      else if (clickData.gender === 'female') {
      // console.log(time);
        // let newTime = 0;

        // let min = time.split(':')[0];
        // let sec = time.split(':')[1].split('.')[0];
        // let point = time.split(':')[1].split('.')[1];

        // if (point === undefined) newTime = (parseInt(min) * 60) + (parseInt(sec));
        // else newTime = (parseInt(min) * 60) + (parseInt(sec)) + '.' + point;

        // requestData.time = newTime;
        requestData.time = time;
        requestData.completeRep = 0;
      }
      else requestData.time = time;

      requestData.section = 'F';
    }
    console.log(requestData);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEventVer2('record/final_SUFF/submit/admin', 'POST', requestData);
        console.log(responseData);
        if (responseData.status === 200) {
          closeFn();
        }
        else alert('Error');
      }
    )();
  }
  return (
    <Background>
      {
        clickData !== undefined &&
        <Modal>
          <Text style={{ marginTop: '16px' }} fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>기록 제출 ( 관리자용 )</Text>
          <TextWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{'이름 : ' + clickData.name}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{calcSports()}</Text>
          </TextWrapper>
          <EventSelectButtonWrapper>
            <EventSelectButton isClicked={eventSelect === 'A'} onClick={() => setEventSelect('A')}>
              <Text fontFamily='Poppins-Bold' fontSize={14} color={eventSelect === 'A' ? '#FFFFFF' : '#4F4F4F'}>E1</Text>
            </EventSelectButton>
            <EventSelectButton isClicked={eventSelect === 'B'} onClick={() => setEventSelect('B')}>
              <Text fontFamily='Poppins-Bold' fontSize={14} color={eventSelect === 'B' ? '#FFFFFF' : '#4F4F4F'}>E2</Text>
            </EventSelectButton>
            <EventSelectButton isClicked={eventSelect === 'C'} onClick={() => setEventSelect('C')}>
              <Text fontFamily='Poppins-Bold' fontSize={14} color={eventSelect === 'C' ? '#FFFFFF' : '#4F4F4F'}>E3</Text>
            </EventSelectButton>
            <EventSelectButton isClicked={eventSelect === 'D'} onClick={() => setEventSelect('D')}>
              <Text fontFamily='Poppins-Bold' fontSize={14} color={eventSelect === 'D' ? '#FFFFFF' : '#4F4F4F'}>E4</Text>
            </EventSelectButton>
            <EventSelectButton isClicked={eventSelect === 'E'} onClick={() => setEventSelect('E')}>
              <Text fontFamily='Poppins-Bold' fontSize={14} color={eventSelect === 'E' ? '#FFFFFF' : '#4F4F4F'}>H1</Text>
            </EventSelectButton>
            <EventSelectButton isClicked={eventSelect === 'F'} onClick={() => setEventSelect('F')}>
              <Text fontFamily='Poppins-Bold' fontSize={14} color={eventSelect === 'F' ? '#FFFFFF' : '#4F4F4F'}>H2</Text>
            </EventSelectButton>
          </EventSelectButtonWrapper>
          {
            eventSelect === 'A' &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>First Mission</Text>
              <Input value={firstMission} onChange={handleFirstMission} />
            </InputWrapper>
          }
          {
            eventSelect === 'A' &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>Second Mission</Text>
              <Input value={secondMission} onChange={handleSecondMission} />
            </InputWrapper>
          }
          {
            eventSelect === 'A' &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>Third Mission Time</Text>
              <Input value={thirdMissionTime} onChange={handleThirdMissionTime} />
            </InputWrapper>
          }
          {
            eventSelect === 'A' &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>Third Mission Reps</Text>
              <Input value={thirdMissionReps} onChange={handleThirdMissionReps} />
            </InputWrapper>
          }
          {
            eventSelect === 'B' &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>Weight</Text>
              <Input value={weight} onChange={handleWeight} />
            </InputWrapper>
          }
          {
            eventSelect === 'B' &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>TieBreak</Text>
              <Input value={tieBreak} onChange={handleTieBreak} />
            </InputWrapper>
          }
          {
            (eventSelect === 'C' || eventSelect === 'D' || eventSelect === 'F') &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>Time</Text>
              <Input value={time} onChange={handleTime} />
            </InputWrapper>
          }
          {
            (eventSelect === 'E') &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>Distance</Text>
              <Input value={distance} onChange={handleDistance} />
            </InputWrapper>
          }
          {
            (eventSelect === 'C' || eventSelect === 'D' || (eventSelect === 'F' && clickData.gender === 'male')) &&
            <InputWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>Reps</Text>
              <Input value={reps} onChange={handleReps} />
            </InputWrapper>
          }
          <ButtonWrapper>
            <Button backgroundColor='#6DD49E' onClick={onClickSubmit}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>등록</Text>
            </Button>
            <Button backgroundColor='#FF3131' onClick={closeFn}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>취소</Text>
            </Button>
          </ButtonWrapper>
        </Modal>
      }
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 24px 0px 24px 0px;

	width: 90.33%;
	// height: 600px;
  
	border-radius: 12px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 25px;
`;

const EventSelectButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  
  margin-top: 16px;
  
  gap: 12px;
`;

const EventSelectButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #BDBDBD`};
  border-radius:8px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  gap: 4px;

  width: 90.33%;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	
	padding: 0 8px 0 8px;

	outline: none;
	// border-width: 0 0 1px 0;
	// border-color: #E0E0E0;
  border: 1px solid #E0E0E0;
	color: #000000;

  width: calc(100% - 18px);
  height: 30px;

  border-radius: 8px;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.5s ease-in-out;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 39px;

  width: 85.02%;
`;

const Button = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 47.16%;
  height: 60px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;