import React, { useState, useRef } from 'react';
import styled from 'styled-components';

export default function NumberInput(props) {
	const { value, setValue, placeholder } = props;

	const [changed, setChanged] = useState(false);

	const InputRef = useRef();

	function handleClick(e) {
		// InputRef.current.style.borderColor = '#FF8B48';
		// setDefaultPlaceholder('');
	}

	function handleChange(e) {
		setValue(e.target.value);
		setChanged(true);
	}

	function handleFocus(e) {
		if (changed) {
			// do nothing
		} else {
			setValue("");
		}
	}

	function handleBlur(e) {
		if (value === "") {
		}
		else {
		}
	}

	return (
		<Input
			ref={InputRef}
			placeholder={placeholder}
			value={value}
			type='number'
      pattern='\d*'
			onClick={handleClick}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur} />
	)
}

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	width: calc(100% - 24px);
	height: 40px;

	padding: 0 12px 0 12px;
	margin: 0 0 0 0;

	outline: none;
	border: 1px solid #E8E8E8;
	border-radius: 8px;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;