import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import FetchModule from '../../Model/Network/FetchModule';

import WaitIcon from '../../Image/WaitIcon.png';

export default function BattleCrew3FinalRankingPage() {

  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [nowSection, setNowSection] = useState('A');
  const [images, setImages] = useState({
    a: 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Rank1-4.jpeg',
    b: 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Rank2-1.jpeg',
    c: 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Rank3-1.jpeg',
    d: 'https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Rank4.jpeg'
  });
  const [imageSrc, setImageSrc] = useState('https://picture.link-zone.org/event/BattleCrew3Final/BattleCrew3Final_Rank1-2.png');

  // useEffect(() => {
  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.getDataOfEvent('score/rank/battlecrew3final', 'GET');
        
  //       if (responseData.status === 200) {
  //         // console.log(responseData);
  //         setImages(responseData.data);
  //       }
  //     }
  //   )();
  // }, [])

  useEffect(() => {
    if (nowSection === 'A') setImageSrc(images.a);
    else if (nowSection === 'B') setImageSrc(images.b);
    else if (nowSection === 'C') setImageSrc(images.c);
    else if (nowSection === 'D') setImageSrc(images.d);
  }, [images, nowSection])

  function decideTitleText() {
    if (nowSection === 'A') return '배틀크루 3 리그전';
    else if (nowSection === 'B') return '배틀크루 3 8강전';
    else if (nowSection === 'C') return '배틀크루 3 4강전';
    else if (nowSection === 'D') return '배틀크루 3 결승전';
  }
  
  function decideExplanationText() {
    // return '결과를 집계중입니다'
    // if (nowSection === 'A') return '배틀크루 3 리그전';
    // else if (nowSection === 'B') return '배틀크루 3 8강전';
    // else if (nowSection === 'C') return '배틀크루 3 4강전';
    // else if (nowSection === 'D') return '배틀크루 3 결승전';
  }

  return (
    <TopLevelWrapper>
      <TopLineWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#333333'>배틀크루3 최종전</Text>
        </TitleWrapper>
      </TopLineWrapper>
      <DivisionLine />
      <SectionButtonWrapper>
        <SectionButton isClicked={nowSection === 'A'} onClick={() => setNowSection('A')}>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#FFFFFF'>리그전</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'B'} onClick={() => setNowSection('B')}>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#FFFFFF'>8강전</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'C'} onClick={() => setNowSection('C')}>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#FFFFFF'>4강전</Text>
        </SectionButton>
        <SectionButton isClicked={nowSection === 'D'} onClick={() => setNowSection('D')}>
          <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#FFFFFF'>결승</Text>
        </SectionButton>
      </SectionButtonWrapper>
      <DivisionLine />
      <TotalWrapper>
        {
          imageSrc === '' ?
            <WaitWrapper>
              <WaitImage src={WaitIcon} />
              <Text style={{ marginTop: '8px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{decideTitleText()}</Text>
              <Text style={{ marginTop: '2px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>{decideExplanationText()}</Text>
            </WaitWrapper>
            :
            <Image src={imageSrc} />
        }
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 80px;

  width: 100%;
  height: 100%;

  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%; 
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-right: 8px;

  width: 69px;
  height: 32px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  margin: 20px 0px 20px 0px;

  background-color: #E0E0E0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;  

  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const WaitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 74px;
`;

const WaitImage = styled.img`
  width: 148px;
  height: 62px;

  :hover {
    cursor: default;
  }
`;