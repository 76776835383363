import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PictureRightIcon from '../../../Image/PictureRightArrow.png';
import PictureLeftIcon from '../../../Image/PictureLeftArrow.png';

export default function ShowYourtPerformanceSubmitViewModel(props) {
  const {
    eventId,
    section,

    setIsExistRecord,

    isRx,

    min,
    sec,

    tieBreakMin,
    tieBreakSec,

    distance,
    completeRep,
    completeRound,
    lastExerciseRep,
    lastExercise,
    firstExercise,
    secondExercise,
    thirdExercise,
    bodyWeight,
    point,
    link,
    picture,
  } = props;

  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [pictureSize, setPictureSize] = useState(374);
  const [pictureList, setPictureList] = useState([]);
  const [nowIndex, setNowIndex] = useState(0);

  // 화면 크기 추출후 총 사진 크기 계산 //
  useEffect(() => {
    var totalPictureSize = windowSize.width * 0.9033;
    // console.log(totalPictureSize);
    setPictureSize(totalPictureSize);
  }, [windowSize])

  // 사진 구분 //
  useEffect(() => {
    let pictures = picture.split('<-@->');

    setPictureList(pictures[0] === '' ? [] : pictures);
  }, [picture])

  // 이전 사진 클릭시 //
  function onClickPrevPicture() {
    if (nowIndex === 0) return;

    setNowIndex(nowIndex - 1);
  }

  // 다음 사진 클릭시 //
  function onClickNextPicture() {
    if (nowIndex > 5 || pictureList.length === (nowIndex + 1)) return;

    setNowIndex(nowIndex + 1);
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleTextWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>EVENT {t('기록하기')}</Text>
          <Text onClick={() => setIsExistRecord(false)} fontFamily='NotoSansKR-Medium' fontSize={14} color='#6DD49E'>RuleBook 보기</Text>
        </TitleTextWrapper>
        {
          (section === 'A') &&
          <ComponentWrapper>
            <ComponentInnerWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 랩 수를 성공했나요?</Text>
              <TimeWrapper>
                <InputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{completeRep}</Text>
                </InputBox>
              </TimeWrapper>
            </ComponentInnerWrapper>
            <ComponentInnerWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Tie Break: 3R 끝난 시간</Text>
              <TimeWrapper>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tieBreakMin}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MIN</Text>
                </TimeInputBox>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tieBreakSec}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                </TimeInputBox>
              </TimeWrapper>
            </ComponentInnerWrapper>
          </ComponentWrapper>
        }
        {
          (section === 'B') &&
          <ComponentWrapper>
            <AdditionComponentWrapper>
              <ComponentInnerWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Tose-to-bar 얼마나 많은 랩 수를 성공했나요?</Text>
                <TimeWrapper>
                  <InputBox>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{completeRep}</Text>
                  </InputBox>
                </TimeWrapper>
              </ComponentInnerWrapper>
            </AdditionComponentWrapper>
            <AdditionComponentWrapper style={{ marginTop: '12px' }}>
              <ComponentInnerWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Pull - up 얼마나 많은 랩 수를 성공했나요?</Text>
                <TimeWrapper>
                  <InputBox>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{lastExerciseRep}</Text>
                  </InputBox>
                </TimeWrapper>
              </ComponentInnerWrapper>
            </AdditionComponentWrapper>
          </ComponentWrapper>
        }
        {
          (section === 'D') &&
          <ComponentWrapper>
            <ComponentInnerWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 point를 성공했나요?</Text>
              <TimeWrapper>
                <InputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{completeRep}</Text>
                </InputBox>
              </TimeWrapper>
            </ComponentInnerWrapper>
            <ComponentInnerWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Tie Break: 시간</Text>
              <TimeWrapper>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tieBreakMin}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>MIN</Text>
                </TimeInputBox>
                <TimeInputBox>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{tieBreakSec}</Text>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD'>SEC</Text>
                </TimeInputBox>
              </TimeWrapper>
            </ComponentInnerWrapper>
          </ComponentWrapper>
        }
        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>EVENT {t('기록 영상')}</Text>
          <TimeWrapper>
            <InputBox>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{link === '' ? t('없음') : link}</Text>
            </InputBox>
          </TimeWrapper>
        </ComponentWrapper>
        {
          pictureList.length !== 0 &&
          <ComponentWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('운동 사진')}</Text>
            <PictureWrapper size={pictureSize}>
              <UserPicture src={pictureList[nowIndex]} />
              <ButtonWrapper size={pictureSize}>
                <ImageMoveButtonWrapper>
                  {
                    nowIndex !== 0 ?
                      <MoveButton onClick={onClickPrevPicture}>
                        <ImageMoveButton src={PictureLeftIcon} />
                      </MoveButton>
                      :
                      <MoveButton />
                  }
                  {
                    (nowIndex !== 4 && pictureList.length >= 2 && pictureList.length !== (nowIndex + 1)) ?
                      <MoveButton onClick={onClickNextPicture}>
                        <ImageMoveButton src={PictureRightIcon} />
                      </MoveButton>
                      :
                      <MoveButton />
                  }
                </ImageMoveButtonWrapper>
              </ButtonWrapper>
            </PictureWrapper>
          </ComponentWrapper>
        }
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 20px 0px 20px 0px;

  width: 100%;

  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;

  gap: 8px;
`;

const AdditionComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  // margin-top: 20px;

  gap: 20px;
`;

const ComponentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(48.39% - 26px);
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const DistanceInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  overflow: hidden;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 26px);
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  overflow: hidden;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 26px);
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const Event8Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const ExerciseInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const GreenNumberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const WeightInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(85% - 26px);
  height: 38px;

	border: 1px solid #E8E8E8;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const TotalWeightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TotalWeightTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: calc(40.9% - 2px);
  width: calc(35% - 2px);
  height: 38px;

  gap: 2px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60%;
  height: 1px;

  background-color: #FF3131;
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  border-radius: 12px;

  overflow: hidden;
`;

const UserPicture = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const ImageMoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: calc(100% - 24px);
`;

const MoveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
`;

const ImageMoveButton = styled.img`
  width: 30px;
  height: 30px;
`;


const SizeButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const RxButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 37px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#FF3131` : `#E0E0E0`};
  
  transition: all 0.3s ease-in-out;
`;

const ScaleButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 37px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#4161AF` : `#E0E0E0`};
  
  transition: all 0.3s ease-in-out;
`;