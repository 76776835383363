import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

export default function SelectEventModal() {

  const navigate = useNavigate();

  return (
    <Background>
      <TopLevelWrapper>
        <ButtonWrapper>
          <Button onClick={() => navigate('/modify/ranking/1')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>마블</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323'>썸머 쓰로다운</Text>
          </Button>
          <Button onClick={() => navigate('/modify/ranking/2')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>배틀크루</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323'>부산전</Text>
          </Button>
          <Button onClick={() => navigate('/modify/ranking/3')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323'>배틀크루</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323'>수도권전</Text>
          </Button>
        </ButtonWrapper>
      </TopLevelWrapper>
    </Background>
  )

}


const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

	width: 90.33%;
	height: 330px;
  
  padding: 0 0 0 0;
  margin: 0 0 0 0;

	border-radius: 28px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.5s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30%;
  height: 120px;

  gap: 8px;

  border-radius: 8px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;


const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;