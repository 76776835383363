import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import styled from "styled-components";

export default function FinalFitForceTeamLeaguePaymentCompletePage() {

  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const params = useParams();


  // 카드 등록을 위한 토클 발행 후 카드 등록 실행 //
  useEffect(() => {
    let status = params.status;
    let error =params.error;

    setStatus(status);
    setError(error);
  }, []);

  // 모달 타이틀 텍스트 결정 //
  function decideModalTitle() {
    if (status === '200') return '결제가 완료되었습니다';
    else return '결제에 실패하였습니다';
  }

  // 모달 텍스트 결정 //
  function decideModalText() {
    if (status === '200') return '앱에서 계속 진행해주세요';
    else if (status === '401') return '유효하지 않은 인증입니다';
    else if (status === '402') return '결제자 정보가 일치하지 않습니다';
    else return error;
  }

  // 앱으로 돌아가기 클릭시 //
  function onClickGoApp() {
    // console.log('Go App!!');
    window.location.href = "linkcoach://";

    setTimeout(() => {
      window.open("about:blank", "_self");
      window.close();
    }, 3000)
  }

  return (
    <TopLevelWrapper>
      <CompleteModal>
        <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#333333'>{decideModalTitle()}</Text>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{decideModalText()}</Text>
        </TextWrapper>
        <GoAppButton onClick={onClickGoApp}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>앱으로 돌아가기</Text>
        </GoAppButton>
      </CompleteModal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
`;

const CompleteModal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px 12px 0px;

  width: 80%;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 90.33%;
`;

const GoAppButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 90.33%;
  height: 40px;

  border-radius: 8px;
  background-color: #6DD49E;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
`;