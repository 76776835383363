import React, { useEffect, useState } from "react";
import styled from "styled-components";

import moment from "moment";

import NameArrow from '../../../Image/NameArrow.png';
import { useTranslation } from "react-i18next";

export default function GirlFitEventBox(props) {
  const { data, index, language, eventTitleFontSize } = props;

  const { t } = useTranslation();

  const [ageDetail, setAgeDetail] = useState(t('(2003년 01월 01일 이전 출생자)'));

  // 참가 가능 생년월일 계산 //
  useEffect(() => {
    var ageDetailDate = moment().subtract(parseInt(data.minimumAgeLimit), 'years');
    // console.log('=====');
    // console.log(data);
    setAgeDetail('(' + ageDetailDate.format('YYYY') + t('년 01월 01일 이전 출생자)'));
  }, [data])

  // 참가비 글자화 //
  function numberToKorean(number, usd){

    if (data.eventId === 24) return '160,000 KRW';
    else return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' KRW';

    // // 외국 돈이 0원일 경우 //
    // if (usd === 0) {
    //   // let inputNumber  = number < 0 ? false : number;
    //   // let unitWords    = ['', '만', '억', '조', '경'];
    //   // let splitUnit    = 10000;
    //   // let splitCount   = unitWords.length;
    //   // let resultArray  = [];
    //   // let resultString = '';
  
    //   // for (let i = 0; i < splitCount; i++){
    //   //      let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    //   //     unitResult = Math.floor(unitResult);
    //   //     if (unitResult > 0){
    //   //         resultArray[i] = unitResult;
    //   //     }
    //   // }
  
    //   // for (let i = 0; i < resultArray.length; i++){
    //   //     if(!resultArray[i]) continue;
    //   //     resultString = String(resultArray[i]) + unitWords[i] + resultString;
    //   // }
  
    //   // return resultString + ' 원';
      
    //   if (data.organizerId === 9) return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' KRW ( 1인 기준 / 기념티 포함 )';
    //   else return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' KRW';
    // }
    // // 외국돈이 0원이 아닐경우 //
    // else {
    //   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' KRW / ' + usd + ' USD';
    // }
  }

  // Suff만을 걸러내기 위한 참가 자격 설정 함수 //
  function calcEligibility(data) {
    // if (data.organizerId === 9) return '4~6명 1팀';
    // else if (data.organizerId !== 7) return data.minimumAgeLimit === 0 ? t('제한 없음') : t('만') + ' ' + data.minimumAgeLimit + ' ' + t('세 이상');
    // else {
    //   if (data.eventId === 9) return data.minimumAgeLimit === 0 ? t('제한 없음') : t('만') + ' ' + data.minimumAgeLimit + ' ' + t('세 이상');
    //   else if (data.eventId === 10) return '4일 1팀 남3/여1 엘리트 1명으로 제한';
    //   else if (data.eventId === 11) return '7인 1팀 : 남4/여2/후보1 스케일 참여 제한';
    // }
    if (data.eventId === 22) return '1명 / 여성';
    else return '4명 1팀 / 여성';
  }

  // Suff 대회 장소 표출을 위한 설정 함수 //
  function calcPlace(data) {
    if (data.organizerId !== 7) return data.place;
    else return '예선 : Online / 본선 : 완도 명사십리해수욕장';
  }

  return (
    <TopLevelWrapper isFirst={index === 0}>
      <EventInnerWrapper>
        <EventNameWrapper>
          <EventNameImage src={NameArrow} style={{ marginRight: `6px` }}/>
          {/* <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#333333'>{data.eventTitle}</Text> */}
          <Text fontFamily='NotoSansKR-Black' fontSize={eventTitleFontSize} color='#333333'>{data.eventId === 22 ? 'Individual [개인전]' : data.eventTitle}</Text>
        </EventNameWrapper>
        <EventInfoWrapper style={{ marginTop: `24px` }}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('참가자격')}</Text>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#333333'>{ data.minimumAgeLimit === 0 ? t('제한 없음') : t('만') + ' ' + data.minimumAgeLimit + ' ' + t('세 이상')}</Text> */}
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#333333'>{calcEligibility(data)}</Text>
        </EventInfoWrapper>
        <EventInfoWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'></Text>
          {/* <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={data.minimumAgeLimit === 0 ? '#FFFFFF' : '#828282'}>{ data.minimumAgeLimit === 0 ? t('누구나') : (language === 'Korea' && ageDetail)}</Text> */}
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={'#828282'}>{t('(미성년자는 부모님 동의가 필요할 수 있습니다)')}</Text>
        </EventInfoWrapper>
        <EventInfoWrapper style={{ marginTop: `16px` }}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('참가비')}</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.participationFee === 0 ? t('무료') : numberToKorean(data.participationFee, data.participationFeeUSD)}</Text>
        </EventInfoWrapper>
        <EventInfoWrapper style={{ marginTop: `3px` }}>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('대회장소')}</Text> */}
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('장소')}</Text>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#333333'>{data.place}</Text> */}
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{calcPlace(data)}</Text>
        </EventInfoWrapper>
      </EventInnerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 178px;

  margin-top: ${props => props.isFirst ? 0 : 24}px;

  border-radius: 12px;
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);
  background-color: #FFFFFF;
`;

const EventInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 92.24%;
`;

const EventNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const EventNameImage = styled.img`
  width: 12px;
  height: 12px;
`;

const EventInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;