import { React, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import styled from "styled-components";
import Lottie from 'lottie-react-web';


import LoadingAnimation from '../../Animation/LoadingAnimation.json';
import FetchModule from "../../Model/Network/FetchModule";

export default function ShowYourPerformanceRequestPaymentPage() {

  const navigate = useNavigate();
  const params = useParams();

  // 결제창 실행 //
  useEffect(() => {
    window.localStorage.setItem('Token', 'Bearer ' + params.token);

    const fetchModule = new FetchModule();
    (
      async() => {
        const responseData = await fetchModule.getDataOfEventVer2('sales/payment_data/' + params.merchantUid, 'GET');
        
        if (responseData.status === 200) {
          let merchantUid = responseData.data.merchantUid;
          let userName = responseData.data.userName;
          let phone = responseData.data.phone;
          let price = responseData.data.price;

          const obj = {};

          obj.PCD_PAY_TYPE = 'card'
          obj.PCD_PAY_WORK = 'CERT';
          obj.PCD_CARD_VER = '02';
          obj.PCD_PAYER_AUTHTYPE = 'sms';

          obj.PCD_PAY_GOODS = params.itemName;
          
          obj.PCD_PAY_TOTAL = price;
          // obj.PCD_PAY_TOTAL = 150;
          obj.PCD_PAY_OID = merchantUid;
          obj.PCD_PAYER_NAME = userName;
          obj.PCD_PAYER_HP = phone;

          // obj.callbackFunction = getResult;
          // obj.PCD_RST_URL = 'http://172.30.1.53:8080/api2/sales/payment/' + params.merchantUid;
          obj.PCD_RST_URL = 'https://tournament.link-coach.io/api2/sales/payment/' + params.merchantUid;

          // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';
          obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';

          setTimeout(() => {
            window.PaypleCpayAuthCheck(obj);
          }, 1500)
        }
        else if (responseData.status === 201) {
          navigate('/payment/complete/201/' + params.error + '/' + params.merchantUid);
        }
        else if (responseData.status === 202) {
          // navigate('/payment/complete/202/팀장만 결제를 진행할 수 있습니다/' + params.merchantUid);
          navigate('/payment/complete/202/신청 인원이 마감되었습니다/' + params.merchantUid);
        }
        else if (responseData.status === 203) {
          navigate('/payment/complete/203/이미 진행된 결제내역입니다/' + params.merchantUid);
        }
        else if (responseData.status === 401) {
          navigate('/payment/complete/401/결제 데이터가 존재하지 않습니다/' + params.merchantUid);
        }
        else if (responseData.status === 402) {
          navigate('/payment/complete/402/결제자 정보가 일치하지 않습니다/' + params.merchantUid);
        }
        else {
          navigate('/payment/complete/400/결제모듈 로딩에 실패했습니다/' + params.merchantUid);
        }
      }
    )();
  }, []);

  return (
    <TopLevelWrapper>
      <LottieWrapper>
        <LottieBox>
          <Lottie options={{ animationData: LoadingAnimation }} />
        </LottieBox>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>결제 모듈 로딩중...</Text>
      </LottieWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const LottieBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};

  color: ${props => props.color};

  margin: 0 0 0 0;
`;