import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import EmptyIcon from '../Image/BigSizeEmptyIcon.jpg';

// 페이지 URL을 찾을 수 없는 경우 //
export default function NotFoundPage() {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  // Window Resize Handler //
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  // Window Resize Handler //
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  // 이미지 넓이 결정 //
  function decideImageWidth() {
    return windowSize.width * 0.5;
  }

  // 이미지 높이 결정 //
  function decideImageHeight() {
    return windowSize.width * 0.5 * 0.42;
  }

  // 폰트 크기 결정 //
  function decideFontSize() {
    if (windowSize.width <= 375) return 14;
    else if (windowSize.width <= 460) return 16;
    else if (windowSize.width <= 560) return 18;
    else return 20;
  }

  return (
    <TopLevelWrapper width={windowSize.width} height={windowSize.height}>
      <ImageWrapper>
        <Image width={decideImageWidth()} height={decideImageHeight()} src={EmptyIcon}/>
        <Text fontFamily='NotoSansKR-Medium' fontSize={decideFontSize()} color='#BDBDBD'>페이지를 찾을 수 없습니다</Text>
      </ImageWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Image = styled.img`
  // width: 300px;
  // height: 126px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;