import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// import DefaultLogoPath from '../../image/DefaultLogo.png'
import ProfilePlusIcon from '../../Image/ProfilePlusIcon.png';

export default function LogoFileInput(props) {
  const { size, plusIconSize, imgBase64, setImgBase64 } = props;

  // const [imgBase64, setImgBase64] = useState();
  const [pictureWidth, setPictureWidth] = useState(0);
  const [pictureHeight, setPictureHeight] = useState(0);
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');

  const hiddenFileInput = useRef(null);
  const CanvasRef = useRef();
  const FileInputRef = useRef();

  // useImperativeHandle(ref, () => ({
  //   getImgBase64: () => {
  //     return imgBase64;
  //   },
  //   getValue: () => {
  //     return imgLocalPath;
  //   },
  //   setBold: () => {

  //   },
  //   reset: () => {
  //     setImgBase64();
  //     setImgFile();
  //     setImgLocalPath();
  //   }
  // }));
  useEffect(() => {
    if (imgBase64 !== undefined && imgBase64 !== '') setImgFile(true);
  }, [imgBase64])

  const handleClick = e => {
    hiddenFileInput.current.click();
  };

  const handleChange = e => {
    // var pathpoint = e.target.value.lastIndexOf('.');
    // var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    // var filetype = filepoint.toLowerCase();

    // if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

    //   // 정상적인 이미지 확장자 파일인 경우
    //   const reader = new FileReader();
    //   // setImgLocalPath(e.target.value);
    //   var tmp = e.target.value.split('\\');
    //   setImgLocalPath(tmp[tmp.length - 1]);
    //   reader.onloadend = () => {
    //     const base64 = reader.result;

    //     if (base64) {
    //       setImgBase64(base64.toString());
    //     }
    //   };
    //   if (e.target.files[0]) {
    //     reader.readAsDataURL(e.target.files[0]);
    //     setImgFile(e.target.files[0]);
    //   }
    // }
    // else {
    //   alert('.jpeg .jpg .png 파일만 가능합니다!');
    // }



    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');
      setImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;
        const naturalImage = new Image();

        naturalImage.src = reader.result.toString();

        // if (base64){
        //   setStoreLogo(base64.toString());

        // }
        naturalImage.onload = function (e) {

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          if (naturalWidth < 600) {
            resizeWidth = naturalWidth;
            resizeHeight = naturalHeight;
          }
          else {
            resizeWidth = 600;
            resizeHeight = (600 * naturalHeight) / naturalWidth;
          }


          let finalHeight = (resizeHeight * 368) / resizeWidth;

          setPictureWidth(368);
          setPictureHeight(finalHeight);

          const image = new Image();
          const canvas = CanvasRef.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {
            if (naturalWidth > 600) {
              canvas.width = resizeWidth;
              canvas.height = resizeHeight;
              // canvas.backgroundColor = '#FFFFFF';

              image.src = base64.toString();

              // 사진 크기 조정후 Canvas에 그리기 //
              image.onload = function () {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
              }

              // 크기 조절된 사진 데이터 정리하기 //
              canvas.toBlob(function (blob) {
                const reader = new FileReader();
                // Canvas에 다 그려졌을때 Event //
                reader.onload = function (e) {
                  // setPicture(canvas.toDataURL())
                  setImgBase64(canvas.toDataURL());
                }
                reader.readAsDataURL(blob);
              })
            }
            else {
              // setPicture(base64.toString());
              setImgBase64(canvas.toDataURL());
            }
          }
          else {
            alert('사용할 수 없는 파일입니다');
            console.log("Could not get context");
          }
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
  };

  return (
    <TopLevelWrapper>
      <InputWrapper>
        <canvas ref={CanvasRef} style={{ display: 'none' }} />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }} />
        <ProfileBox size={size} onClick={handleClick}>
          {
            imgFile ?
              <Logo size={size} src={imgBase64} />
              :
              <Logo size={plusIconSize} src={ProfilePlusIcon} />
          }
        </ProfileBox>
      </InputWrapper>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-end;
`;

const FileInput = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
  // line-height: 20px;

  padding: 0 0 4px 0;
  margin-top: 16px;
	// margin-top: max(12px, 0.8333333333333333vw);

  width: 273px;
  // width: max(140px, 14.21875vw);
  height: 25px;
  // background-color: red;
  // outline: none;
  border: 1px solid;
  border-width: 0 0 1px 0;
  border-color: #E8E8E8;
  color: #000000;

  ::placeholder {
    color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
  }

  transition: all 0.5s ease-in-out;
`;

const InputButton = styled.div`
  display: flex;
  flex-direction: row;

  width: 60px;
  margin-left: 9px;
  // margin-left: max(6px, 0.46875vw);

  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #777777;
  // background-color: red;
  :hover {
    cursor: pointer;
  }
`;

const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  margin-top: 8px;
  border-radius: 8px;
  background-color: #D9D9D9;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

// const LogoWrapper = styled.div`
//   display: flex;
//   flex-direction: row;

//   margin-left: 61px;
//   // margin-left: max(15px, 3.177083333333333vw);
// `;

const Logo = styled.img`
  // width: 80px;
  // // width: max(65px, 4.166666666666667vw);
  // height: 80px;
  // // height: max(65px, 4.166666666666667vw);
  
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  // object-fit: contain;
  object-fit: cover;
`;